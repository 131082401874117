import { theme } from "src/shared/theme/theme";
import { ChipWrapper } from "./StatusChips.style";
import {
  StatusChipsProps,
  StatusChipsType,
} from "src/shared/models/component/base.model";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const handleStatusChipsText = (
  _type: StatusChipsType = "In Progress"
) => {
  switch (_type) {
    case "In Progress":
      return "In Progress";
    default:
      return _type;
  }
};

export const handleStatusChipsStyle = (
  _type: StatusChipsType = "In Progress"
) => {
  switch (_type) {
    case "In Progress":
      return { color: "blue.300", backgroundColor: "blue.75" };
    case "On Hold":
      return { color: "red.500", backgroundColor: "#FCEDB9" };
    case "Cancelled":
      return { color: "red.500", backgroundColor: "#FCEDB9" };
    case "Closed":
      return { color: "green.500", backgroundColor: "green.100" };
    case "Completed":
      return { color: "green.500", backgroundColor: "green.75" };
    case "Not Started":
      return { color: "darkBlue.500", backgroundColor: "darkBlue.50" };
    case "Scheduled":
      return { color: "orange.500", backgroundColor: "orange.75" };
    case "Submitted":
      return { color: "blue.500", backgroundColor: "blue.50" };
    case "Payment":
      return { color: "green.500", backgroundColor: "green.75" };
    case "Deposit":
      return { color: "blue.500", backgroundColor: "blue.75" };
    case "Charge":
      return { color: "gray.800", backgroundColor: "gray.100" };
    case "On Time":
      return { color: "green.500", backgroundColor: "green.75" };
    case "Delayed":
      return { color: "red.500", backgroundColor: "red.75" };
    case "Delinquent":
      return { color: "orange.500", backgroundColor: "orange.75" };
    default:
      break;
  }
};

export const StatusChips = ({
  type,
  isResponsive,
  isArrowIconVisible,
  chipWidth = "auto",
  chipWidthMobile = "auto",
}: StatusChipsProps) => {
  const setAdditionalStyle = handleStatusChipsStyle(type);
  return (
    <ChipWrapper
      sx={{
        padding: "2px 6px",
        fontSize: "12px",
        borderRadius: "4px",
        width: chipWidth,
        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          fontSize: isResponsive ? "10px" : "12px",
          padding: isResponsive ? "1px 6px" : "2px 6px",
          width: isResponsive ? chipWidthMobile : chipWidth,
        },
        ...setAdditionalStyle,
        justifyContent: isArrowIconVisible ? "space-between" : "center",
      }}
      component={"div"}
    >
      {handleStatusChipsText(type)}
      {isArrowIconVisible && (
        <KeyboardArrowDownIcon
          sx={{
            fontSize: "14px",
            marginLeft: 0,
            position: "relative",
            right: -4,
          }}
        />
      )}
    </ChipWrapper>
  );
};
