import React from "react";
import classNames from "classnames";
import { Container } from "@mui/material";

import { WrpperAuthorized } from "../../CommonNestedStyles/FormWrapper.style";
import { WrapperContainer } from "./Wrapper.style";

import { WrapperProps } from "../../../shared/models/component/base.model";

export const Wrapper = ({
  type = "sm",
  children,
  isFullScreen = false,
  isAuthorizedWrapper,
  iAuthorizedHeaderDesktopFixed = true,
  isTabNavigationAvailable = false,
  isSettingDesktop,
  idSelector = "wrapperContainer",
}: WrapperProps) => {
  // handle Authorized screens wrapper classes __
  const handleAuthorizedWrapperClass = iAuthorizedHeaderDesktopFixed
    ? "header_desktop_fixed"
    : null;
  // handle wrapper height, if tab navigation available __
  const handleTabNavContainWrapperClass = isTabNavigationAvailable
    ? "tab_available"
    : null;

  /**
   * Return primary Ui interaction
   */
  return (
    <>
      {!isAuthorizedWrapper && (
        <WrapperContainer id={idSelector}>
          <Container
            maxWidth={type}
            sx={{
              height: "auto",
              position: "relative",
            }}
          >
            {children}
          </Container>
        </WrapperContainer>
      )}

      {isAuthorizedWrapper && (
        <WrpperAuthorized
          className={classNames([
            handleAuthorizedWrapperClass,
            handleTabNavContainWrapperClass,
          ])}
          id={idSelector}
        >
          {children}
        </WrpperAuthorized>
      )}
    </>
  );
};
