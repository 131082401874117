import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import * as FullStory from "@fullstory/browser";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  HeadingSummaryBlock,
  FormInput,
  Wrapper,
  FromAutoCompleteSelect,
  ManualLoader,
  FormInputPhoneNumber,
  FormWrapper,
  ContentContainerBottom,
  Header,
  Messages,
} from "../../../components";

import { commonSpacing, theme } from "../../../shared/theme/theme";
import {
  AccountSearchFormParams,
  PropertySearchRouteInits,
  PropertySearchRouteParams,
  fullStoryCustomPropertiesParams,
  invitationResponseParams,
  invitationStatusTypes,
  propertySearchResponseDataStateInit,
  residentSearchScreenVisibleTypes,
  searchPropertyBodyParams,
} from "src/shared/models/containers/account.model";
import {
  phoneOnChangeHandler,
  phoneMaskWithoutCountryCode,
  updateBindingsChange,
  getOnlyPhoneDigits,
  isValidPhoneFormatOrNot,
} from "src/shared/utilities/formUtilities";
import {
  useGetAllPropertiesMutation,
  useGetPropertiesMutation,
  useGetUnitsMutation,
  useSearchPropertyForInvitationMutation,
  useSearchPropertyMutation,
} from "src/store/services/publicApi";
import { SearchPropertyFailiureContainer } from "./SearchPropertyFailiure";
import { SearchPropertySuccessContainer } from "./SearchPropertySuccess";
import { ResidentAccountAlreadyAvailable } from "./ResidentAccountAlreadyAvailable";
import { InvitationExpiredScreen } from "./InvitationExpired";
import { registerFormValidationSchemObj } from "src/shared/utilities/validationSchemas";
import { IdentityVerification } from "../Reporting/Identity";
import { IdentityApproved } from "../Reporting/Approved";
import { IdentityFailed } from "../Reporting/Failed";

export const AccountSearchScreenContainer = () => {
  const { loginWithRedirect } = useAuth0(); // get loginWithRedirect mwthod from useAuth0 hook
  const navigate = useNavigate(); // get navigate method from useNavigate hook
  const { t } = useTranslation(); // get t - translation method from useTranslation hook
  const location = useLocation(); // get location method from useLocation hook
  const { invitationId } = useParams(); // get route parameter - invitationId from useParams hook
  const { tpEnableFullstoryConfigs, tpSignupIdentityVerification } = useFlags(); // get tpEnableFullstoryConfigs from useFlags hook
  const [isKycSuccess, setIsKycSuccess] = useState(false);

  const {
    isFromePropertyLanding,
    propertyCodeFPL,
    propertyNameFPL,
    propertyIdFPL,
    propertyIsActiveFPL,
  } =
    location?.state != null
      ? (location?.state as PropertySearchRouteParams)
      : PropertySearchRouteInits;

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [unitsData, setUnitsData] = useState<any>();
  const [propertiesList, setPropertiesList] = useState<any>();
  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const [visibleScreen, setVisibleScreen] =
    useState<residentSearchScreenVisibleTypes>("NONE");

  const [responseData, setResponseData] = useState(
    propertySearchResponseDataStateInit
  );
  const [invitationResponseData, setInvitationResponseData] =
    useState<invitationResponseParams>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [registrationParams, setRegistrationParams] = useState<any>(null);

  const [getUnitsMutation, { isLoading }] = useGetUnitsMutation();
  const [
    searchPropertyMutation,
    { isLoading: isLoadingSearchMutation, data: validateResidentResponse },
  ] = useSearchPropertyMutation();
  const [getAllPropertiesMutation, { isLoading: isLoadingPropertiesList }] =
    useGetAllPropertiesMutation();
  const [
    searchPropertyForInvitation,
    { isLoading: isLoadingSearchPropertyForInvitation },
  ] = useSearchPropertyForInvitationMutation();
  const [
    getPropertiesForCodeMutation,
    { isLoading: isLoadingGetPropertiesForCode },
  ] = useGetPropertiesMutation();

  /**
   * define the validation schema for form validation
   */
  const registerFormSchema = yup
    .object(registerFormValidationSchemObj)
    .required();

  /**
   * initialize the hook form
   */
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<AccountSearchFormParams>({
    resolver: yupResolver(registerFormSchema),
  });

  const handleButtonDisabledState = () => {
    const isAllFieldsAreNotNull =
      getValues("email") &&
      getValues("phone") &&
      getValues("propertyCode") &&
      getValues("unit")
        ? true
        : false;
    setIsButtonDisabled(isAllFieldsAreNotNull);
  };

  const getPropertyDataForInvitationId = async (invitationId: string) => {
    const invitationResponse: any = await searchPropertyForInvitation(
      invitationId
    );

    if (invitationResponse && invitationResponse.data) {
      const invitationResponseDataObj = invitationResponse.data;

      const getInvitationStatus: invitationStatusTypes =
        invitationResponseDataObj.status;

      let phoneWithMask = "";
      let phoneOnlyDigitsValue = "";

      const isValidPhoneNumber = isValidPhoneFormatOrNot(
        invitationResponseDataObj.phone
      );

      if (isValidPhoneNumber) {
        phoneWithMask = phoneMaskWithoutCountryCode(
          invitationResponseDataObj.phone
        );
        phoneOnlyDigitsValue = getOnlyPhoneDigits(
          invitationResponseDataObj.phone
        );
        setValue("phone", phoneWithMask ?? "");
      } else {
        setErrorMessage(
          `Sorry! unable to use this phone number ${invitationResponseDataObj.phone} to do the property search`
        );
        setValue("phone", "");
      }

      if (getInvitationStatus === "EXPIRED") {
        setVisibleScreen("INVITATION_EXPIRED");
      }

      if (getInvitationStatus === "COMPLETED") {
        setVisibleScreen("ACCOUNT_EXIST");
      }

      if (getInvitationStatus === "PENDING") {
        setVisibleScreen("SEARCH_FORM");

        // eslint-disable-next-line no-lone-blocks
        setValue("propertyCode", invitationResponseDataObj.property ?? "");
        setValue("email", invitationResponseDataObj.email ?? "");
        setValue("unit", invitationResponseDataObj.unit ?? "");

        if (
          invitationResponseDataObj.property &&
          invitationResponseDataObj.email &&
          isValidPhoneNumber &&
          invitationResponseDataObj.phone &&
          invitationResponseDataObj.unit
        ) {
          setIsButtonDisabled(true);
        } else {
          setIsButtonDisabled(false);
        }

        if (invitationResponseDataObj.property) {
          const propertyDetailResponse: any =
            await getPropertiesForCodeMutation(
              invitationResponseDataObj.property
            );
          setValue("propertyName", propertyDetailResponse?.data?.name ?? "");
        }
      }

      if (
        invitationResponseDataObj.property &&
        !invitationResponseDataObj.unit
      ) {
        getUnitsOfTheProperty(invitationResponseDataObj.property);
      }

      setInvitationResponseData(invitationResponseDataObj);

      setResponseData({
        ...responseData,
        unit: invitationResponseDataObj.unit ?? "",
        email: invitationResponseDataObj.email ?? "",
        phone: invitationResponseDataObj.phone ? phoneOnlyDigitsValue : "",
        propertyCode: invitationResponseDataObj.property ?? "",
        phoneWithtMaskValue: phoneWithMask ?? "",
        invitationId: invitationId ?? "",
      });

      // set the custom properties __
      if (tpEnableFullstoryConfigs) {
        let fullStoryParams: fullStoryCustomPropertiesParams = {
          unit: invitationResponseDataObj.unit ?? "",
          propertyCode: invitationResponseDataObj.property ?? "",
          invitationId: invitationId,
          invitationStatus: getInvitationStatus,
        };

        FullStory.setUserVars(fullStoryParams);
      }

      let setSearchParamFromLoading = `?phone=${
        invitationResponseDataObj.phone ? phoneOnlyDigitsValue : ""
      }&propertyCode=${invitationResponseDataObj.property}&unit=${
        invitationResponseDataObj.unit
      }&invitationId=${invitationId}`;

      setRegistrationParams(setSearchParamFromLoading);
    }

    if (invitationResponse && invitationResponse.error) {
      setVisibleScreen("INVITATION_EXPIRED");
    }
  };

  /**
   * Handle single property details for propertycode
   */
  const getSingleProperty = async () => {
    if (propertyIsActiveFPL) {
      // eslint-disable-next-line no-lone-blocks
      setValue("propertyCode", propertyCodeFPL ?? "");
      setValue("propertyName", propertyNameFPL ?? "");

      if (propertyIdFPL) {
        getUnitsOfTheProperty(propertyIdFPL);
      }

      setResponseData({
        ...responseData,
        propertyCode: propertyCodeFPL ?? "",
      });

      setInvitationResponseData({
        ...invitationResponseData,
        property: propertyCodeFPL,
      });
    }
  };

  useEffect(() => {
    if (invitationId && !isFromePropertyLanding) {
      getPropertyDataForInvitationId(invitationId);
    } else if (
      propertyCodeFPL !== undefined &&
      isFromePropertyLanding &&
      propertyIsActiveFPL
    ) {
      setVisibleScreen("SEARCH_FORM");
      getSingleProperty();
    } else {
      setVisibleScreen("SEARCH_FORM");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * function to get All properties
   */
  const getAllProperties = async () => {
    const response: any = await getAllPropertiesMutation(null);

    if (response && response?.data) {
      const responseForDropDown =
        response?.data &&
        response?.data.map((node: { code: string; name: string }) => ({
          label: node.name,
          abbreviation: node.code,
        }));
      setPropertiesList(responseForDropDown);
    }
  };

  /**
   * function used to get unit list
   */
  const getUnitsOfTheProperty = async (_propertyCode: string = "") => {
    if (_propertyCode !== "") {
      const unitResponse: any = await getUnitsMutation(_propertyCode);

      if (unitResponse && unitResponse?.data) {
        const responseForDropDown =
          unitResponse?.data &&
          unitResponse?.data.map(
            (node: { id: string; unitNumber: string }) => ({
              label: node.unitNumber,
              abbreviation: node.id,
            })
          );
        setUnitsData(responseForDropDown);
      }
    }
  };

  // render in the initial load
  useEffect(() => {
    getAllProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * handle the search form submition
   */
  const appSubmit = handleSubmit(async (data) => {
    const searchParams: searchPropertyBodyParams = {
      email: data.email,
      phone: data.phone?.replace(/[^0-9]/g, ""),
      propertyCode: data.propertyCode ?? "",
      unit: data.unit,
    };

    const fullStoryParams: fullStoryCustomPropertiesParams = {
      propertyCode: data.propertyCode ?? "",
      unit: data.unit,
    };

    const createResponseObj: any = {
      ...searchParams,
      phone: data.phone?.replace(/[^0-9]/g, ""),
      property: data?.propertyName ?? "",
      phoneWithtMaskValue: data.phone,
      invitationId: invitationId,
    };

    let setSearchParam = `?phone=${data.phone?.replace(
      /[^0-9]/g,
      ""
    )}&propertyCode=${data.propertyCode}&unit=${data.unit}`;

    setResponseData(createResponseObj);

    if (invitationId) {
      if (
        tpSignupIdentityVerification &&
        !invitationResponseData?.isKycSuccess
      ) {
        setVisibleScreen("RENT_REPORTING_IDENTITY");
      } else {
        setSearchParam = `${setSearchParam}&invitationId=${invitationId}`;
        webAuthnSignUp(data.email, setSearchParam);
      }

      // set the custom properties __
      if (tpEnableFullstoryConfigs) {
        FullStory.setUserVars({
          ...fullStoryParams,
          invitationId: invitationId,
        });
      }
    } else {
      setRegistrationParams(setSearchParam);
      const response: any = await searchPropertyMutation(searchParams);
      if (response) {
        setVisibleScreen("NONE");
        if (response.data) {
          if (!response.data.new) {
            setVisibleScreen("ACCOUNT_EXIST");
          } else if (response.data.residentStatus && response.data.new) {
            setIsKycSuccess(response.data.isKycSuccess);
            setVisibleScreen("USER_EXIST");
          } else if (!response.data.residentStatus && response.data.new) {
            setVisibleScreen("USER_NOT_FOUND");
          }
        } else {
          setVisibleScreen("SEARCH_FORM");
          setErrorMessage(
            response?.error?.data?.error ?? response?.error?.data?.message
          );
        }
      }

      // set the custom properties __
      if (tpEnableFullstoryConfigs) {
        FullStory.setUserVars(fullStoryParams);
      }
    }
  });

  const handleStatusScreensBackButtonClick = useCallback(() => {
    if (invitationId) {
      const getInvitationStatusFromState =
        invitationResponseData && invitationResponseData.status;

      if (getInvitationStatusFromState === "COMPLETED") {
        navigate("/");
      }
    } else {
      setVisibleScreen("SEARCH_FORM");
    }
  }, [invitationId, invitationResponseData, navigate]);

  const webAuthnSignIn = async () => {
    await loginWithRedirect({
      authorizationParams: {
        responseType: "code",
        redirectUri: `${window.location.origin}`,
        mode: "login",
        login_hint: responseData.email,
      },
      appState: {
        returnTo: `/${registrationParams}`,
      },
    });
  };

  const webAuthnSignUp = async (login_hint?: string, setSearchParam?: any) => {
    await loginWithRedirect({
      authorizationParams: {
        responseType: "code",
        redirectUri: `${window.location.origin}`,
        prompt: "login",
        screen_hint: "signup",
        mode: "signUp",
        login_hint: login_hint ?? responseData.email,
      },
      appState: {
        returnTo: `/${setSearchParam ?? registrationParams}`,
      },
    });
  };

  const handleNextUserExist = () => {
    if (tpSignupIdentityVerification) {
      if (isKycSuccess) {
        webAuthnSignUp(undefined).then();
      } else {
        setVisibleScreen("RENT_REPORTING_IDENTITY");
      }
    } else {
      webAuthnSignUp(undefined).then();
    }
  };

  const handleKycRequest = async (status: string) => {
    if (status === "success") setVisibleScreen("IDENTITY_APPROVED");
    else setVisibleScreen("IDENTITY_FAILED");
  };

  const handleApprovedSubmitEvent = () => {
    if (invitationId) {
      const setSearchParam = `?phone=${responseData.phone?.replace(
        /[^0-9]/g,
        ""
      )}&propertyCode=${responseData.propertyCode}&unit=${
        responseData.unit
      }&invitationId=${invitationId}`;
      webAuthnSignUp(responseData.email, setSearchParam);
    } else {
      webAuthnSignUp(undefined);
    }
  };

  const isUnitNumberAvailableInInvitation =
    invitationResponseData && invitationResponseData.unit ? true : false;

  const getPropertyCodeForOnChange = async (selectedProperty: string = "") => {
    if (selectedProperty !== "") {
      getAllProperties();

      if (propertiesList && propertiesList.length > 0) {
        const selectedPropertyNode = propertiesList.filter(
          (node: { label: string }) => node.label === selectedProperty
        );
        const selectedPropertyCode = selectedPropertyNode[0].abbreviation ?? "";

        if (selectedPropertyCode !== "") {
          setValue("propertyCode", selectedPropertyCode);

          const propertyDetailResponse: any =
            await getPropertiesForCodeMutation(selectedPropertyCode);

          if (propertyDetailResponse && propertyDetailResponse.data) {
            getUnitsOfTheProperty(propertyDetailResponse.data.id);
          }
        }
      }

      handleButtonDisabledState();
    }
  };

  return (
    <>
      {(isLoading ||
        isLoadingSearchMutation ||
        isLoadingPropertiesList ||
        isLoadingSearchPropertyForInvitation ||
        isLoadingGetPropertiesForCode ||
        visibleScreen === "NONE") && <ManualLoader />}

      {visibleScreen === "INVITATION_EXPIRED" && (
        <InvitationExpiredScreen onBackButtonClick={() => navigate("/")} />
      )}

      {visibleScreen === "ACCOUNT_EXIST" && (
        <ResidentAccountAlreadyAvailable
          onBackButtonClick={handleStatusScreensBackButtonClick}
          onSubmitEvent={webAuthnSignIn}
          email={responseData.email}
          number={responseData.phoneWithtMaskValue}
        />
      )}

      {visibleScreen === "USER_EXIST" && (
        <SearchPropertySuccessContainer
          onBackButtonClick={handleStatusScreensBackButtonClick}
          customer={"Resprop"}
          property={responseData.property}
          onSubmitEvent={handleNextUserExist}
        />
      )}

      {visibleScreen === "USER_NOT_FOUND" && (
        <SearchPropertyFailiureContainer
          onBackButtonClick={handleStatusScreensBackButtonClick}
          customer={"Resprop"}
        />
      )}

      {visibleScreen === "RENT_REPORTING_IDENTITY" && (
        <IdentityVerification
          phoneNumber={getValues("phone")}
          email={getValues("email")}
          onBackButtonClick={() =>
            setVisibleScreen(invitationId ? "SEARCH_FORM" : "USER_EXIST")
          }
          personId={validateResidentResponse?.personId}
          fullName={validateResidentResponse?.name}
          onSubmitEvent={handleKycRequest}
        />
      )}

      {visibleScreen === "IDENTITY_APPROVED" && (
        <IdentityApproved onSubmitEvent={handleApprovedSubmitEvent} />
      )}

      {visibleScreen === "IDENTITY_FAILED" && (
        <IdentityFailed
          onBackButtonClick={() => setVisibleScreen("RENT_REPORTING_IDENTITY")}
          onSubmitEvent={handleStatusScreensBackButtonClick}
        />
      )}

      {visibleScreen === "SEARCH_FORM" && (
        <Wrapper>
          <FormWrapper onSubmit={appSubmit} className="no_fullscreen">
            <Box
              component={"div"}
              className={"container_content"}
              sx={{
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: 400 + commonSpacing.desktopPaddingB * 4,
                },
              }}
            >
              <Header
                isBackButtonVisible={invitationId === undefined ? true : false}
                onBackButtonClick={() =>
                  navigate(
                    isFromePropertyLanding
                      ? `/property/${propertyCodeFPL}`
                      : `/`
                  )
                }
                paddingX={0}
              />
              <Box sx={{ marginBottom: 6 }}>
                <HeadingSummaryBlock
                  heading={t("headingContent.commonSteps.searchVendor.heading")}
                  headingAlignment="left"
                  content={t("headingContent.commonSteps.searchVendor.content")}
                  contentAlignment="left"
                />
              </Box>
              <Box>
                {propertiesList && propertiesList.length > 0 && (
                  <Controller
                    control={control}
                    name="propertyName"
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      return (
                        <FromAutoCompleteSelect
                          label={t("formInput.property")}
                          id={"propertyName"}
                          {...register("propertyName")}
                          placeholder={t("placeHolder.property")}
                          error={errors?.propertyName?.message}
                          inputValue={value || ""}
                          onChange={(_event: any, selectedOption: any) => {
                            if (selectedOption) {
                              onChange(selectedOption.label);
                              getPropertyCodeForOnChange(selectedOption.label);
                            }
                          }}
                          inputFocus={(e: any) => {
                            trigger("propertyName");
                          }}
                          options={propertiesList}
                          readonly={
                            invitationResponseData &&
                            invitationResponseData.property
                              ? true
                              : false
                          }
                        />
                      );
                    }}
                  />
                )}

                {!isUnitNumberAvailableInInvitation &&
                  unitsData &&
                  unitsData.length > 0 && (
                    <Controller
                      control={control}
                      name="unit"
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <FromAutoCompleteSelect
                            label={t("formInput.unit")}
                            id={"unit"}
                            {...register("unit")}
                            placeholder={t("placeHolder.unit")}
                            error={errors?.unit?.message}
                            inputValue={value || ""}
                            onChange={(_event: any, selectedOption: any) => {
                              if (selectedOption) {
                                onChange(selectedOption.label);
                                handleButtonDisabledState();
                              }
                            }}
                            inputFocus={(e: any) => {
                              trigger("unit");
                            }}
                            options={unitsData}
                          />
                        );
                      }}
                    />
                  )}

                {isUnitNumberAvailableInInvitation && (
                  <Controller
                    control={control}
                    name="unit"
                    render={({ field: { onChange, value } }) => (
                      <FormInput
                        label={t("formInput.unit")}
                        id={"unit"}
                        placeholder={t("placeHolder.unit")}
                        {...register("unit")}
                        error={errors?.unit?.message}
                        onChange={(event: any) => {
                          updateBindingsChange(event, "unit", setValue);
                          handleButtonDisabledState();
                        }}
                        value={value}
                        readonly
                        inputFocus={(e: any) => {
                          trigger("unit");
                        }}
                      />
                    )}
                  />
                )}

                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <FormInput
                      label={t("formInput.email")}
                      id={"email"}
                      placeholder={t("placeHolder.emailAddress")}
                      {...register("email")}
                      inputMode="email"
                      error={errors?.email?.message}
                      onChange={(event: any) => {
                        updateBindingsChange(event, "email", setValue);
                        handleButtonDisabledState();
                      }}
                      value={value}
                      inputFocus={(e: any) => {
                        trigger("email");
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { value } }) => (
                    <FormInputPhoneNumber
                      label={t("formInput.phone")}
                      id={"phone"}
                      placeholder={"(123) 543-3454"}
                      {...register("phone")}
                      inputError={errors.phone?.message}
                      onChange={(e: any) => {
                        phoneOnChangeHandler(e, setValue, btnSubmitted);
                        handleButtonDisabledState();
                      }}
                      inputMode="numeric"
                      value={value}
                    />
                  )}
                />
              </Box>
              {errorMessage !== "" && (
                <Messages
                  messageHeading="Property Search"
                  messageContent={errorMessage}
                  closeEvent={() => setErrorMessage("")}
                />
              )}
            </Box>

            <ContentContainerBottom className={"no_fullscreen"}>
              <Button
                variant="containedLarge"
                color="primary"
                type="submit"
                onClick={() => {
                  setBtnSubmitted(true);
                }}
                fullWidth
                disabled={!isButtonDisabled}
                sx={{ overflow: "hidden" }}
              >
                {t("buttonTexts.buttonTextSignup")}
              </Button>
            </ContentContainerBottom>
          </FormWrapper>
        </Wrapper>
      )}
    </>
  );
};
