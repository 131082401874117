import React from "react";

import {
  RightArrow,
  UserIcon,
  BuildingIcon,
  RefreshIcon,
  VerifyConfirmationIcon,
  TermsIcon,
  EyeIcon,
  BackIcon,
  SpinIcon,
  HumbergIcon,
  FilterIcon,
  RightAlignedArrow,
  InvoiceIcon,
  EditIcon,
  TickIcon,
  InfoIcon,
  OfficeIcon,
  DollarIcon,
  FileIcon,
  InvoicePayup,
  LeftArrow,
  BankIcon,
  UploadIcon,
  DropDownIcon,
  DocumentIcon,
  CloseIcon,
  CloseIconLarge,
  AccordionArrow,
  TickIconCircle,
  EditIconLined,
  TickIconWhite,
  PlusIcon,
  SearchIcon,
  SortingIcon,
  SortingIconDSC,
  DatePicker,
  CameraIcon,
  DownloadCSVIcon,
  SendCSVIcon,
} from "./Icons/Icons";

type IconType =
  | "RightArrow"
  | "UserIcon"
  | "BuildingIcon"
  | "RefreshIcon"
  | "VerifyConfirmationIcon"
  | "TermsIcon"
  | "EyeIcon"
  | "BackIcon"
  | "SpinIcon"
  | "HumbergIcon"
  | "FilterIcon"
  | "RightAlignedArrow"
  | "EditIcon"
  | "TickIcon"
  | "InfoIcon"
  | "OfficeIcon"
  | "DollarIcon"
  | "FileIcon"
  | "InvoicePayup"
  | "InvoiceAccount"
  | "LeftArrow"
  | "BankIcon"
  | "UploadIcon"
  | "DropDownIcon"
  | "DocumentIcon"
  | "CloseIcon"
  | "CloseIconLarge"
  | "AccordionArrow"
  | "TickIconCircle"
  | "EditIconLined"
  | "TickIconWhite"
  | "PlusIcon"
  | "SearchIcon"
  | "SortingIcon"
  | "SortingIconDSC"
  | "DatePicker"
  | "CameraIcon"
  | "DownloadCSVIcon"
  | "SendCSVIcon"
  | string;

interface IconProps {
  /**
   * Set Icon Type
   */
  iconType?: IconType;
}

const handleIconTYpes = (_iconType: string | undefined) => {
  switch (_iconType) {
    case "RightArrow":
      return <RightArrow />;
    case "UserIcon":
      return <UserIcon />;
    case "BuildingIcon":
      return <BuildingIcon />;
    case "RefreshIcon":
      return <RefreshIcon />;
    case "VerifyConfirmationIcon":
      return <VerifyConfirmationIcon />;
    case "TermsIcon":
      return <TermsIcon />;
    case "EyeIcon":
      return <EyeIcon />;
    case "BackIcon":
      return <BackIcon />;
    case "SpinIcon":
      return <SpinIcon />;
    case "HumbergIcon":
      return <HumbergIcon />;
    case "FilterIcon":
      return <FilterIcon />;
    case "RightAlignedArrow":
      return <RightAlignedArrow />;
    case "InvoiceIcon":
      return <InvoiceIcon />;
    case "EditIcon":
      return <EditIcon />;
    case "TickIcon":
      return <TickIcon />;
    case "InfoIcon":
      return <InfoIcon />;
    case "OfficeIcon":
      return <OfficeIcon />;
    case "FileIcon":
      return <FileIcon />;
    case "DollarIcon":
      return <DollarIcon />;
    case "InvoicePayup":
      return <InvoicePayup />;
    case "BankIcon":
      return <BankIcon />;
    case "LeftArrow":
      return <LeftArrow />;
    case "UploadIcon":
      return <UploadIcon />;
    case "DropDownIcon":
      return <DropDownIcon />;
    case "DocumentIcon":
      return <DocumentIcon />;
    case "CloseIcon":
      return <CloseIcon />;
    case "CloseIconLarge":
      return <CloseIconLarge />;
    case "AccordionArrow":
      return <AccordionArrow />;
    case "TickIconCircle":
      return <TickIconCircle />;
    case "EditIconLined":
      return <EditIconLined />;
    case "TickIconWhite":
      return <TickIconWhite />;
    case "PlusIcon":
      return <PlusIcon />;
    case "SearchIcon":
      return <SearchIcon />;
    case "SortingIcon":
      return <SortingIcon />;
    case "SortingIconDSC":
      return <SortingIconDSC />;
    case "DatePicker":
      return <DatePicker />;
    case "CameraIcon":
      return <CameraIcon />;
    case "DownloadCSVIcon":
      return <DownloadCSVIcon />;
    case "SendCSVIcon":
      return <SendCSVIcon />;
    default:
      break;
  }
};

/**
 * Primary UI component for user interaction
 *
 * Used SVG for Icons, All icons are exported in the following file path
 *
 * File Path : "src/components/core/Media/Icons/Icons.tsx"
 */
export const Icon = ({ iconType = "RightArrow" }: IconProps) => {
  return <>{handleIconTYpes(iconType)}</>;
};
