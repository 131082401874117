import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Header,
  HeaderFixed,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";
import { AddCreditOrDebitCardForm } from "./Form/AddCreditOrDebitCardForm";
import { CONSTANT_PAYMENT_STATUSES } from "src/shared/utilities/constants";
import { getAccountLinkUrl } from "src/helpers/helperRent";
import { useGetBalanceDetailsQuery } from "src/store/services/privateApi";
import { Box, CircularProgress } from "@mui/material";

export const RentLinkedAccountsCreateCardScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [isOnlyCard, setIsOnlyCard] = useState(true);

  const { isFromPayment } = location.state
    ? (location.state as { isFromPayment: boolean })
    : { isFromPayment: false };

  const {
    data: balanceData,
    isSuccess: isSuccessBalanceData,
    isLoading: isLoadingBalanceData,
  }: any = useGetBalanceDetailsQuery(null);

  useEffect(() => {
    if (isSuccessBalanceData && balanceData) {
      if (
        balanceData?.paymentStatus === CONSTANT_PAYMENT_STATUSES.DO_NOT_ACCEPT
      ) {
        navigate(getAccountLinkUrl(balanceData?.paymentStatus), {
          state: {
            isOnlyCard:
              balanceData?.paymentStatus ===
              CONSTANT_PAYMENT_STATUSES.CERTIFIED_FUNDS,
          },
        });
      }

      if (
        balanceData?.paymentStatus === CONSTANT_PAYMENT_STATUSES.CERTIFIED_FUNDS
      ) {
        setIsOnlyCard(true);
      }

      if (balanceData?.paymentStatus === CONSTANT_PAYMENT_STATUSES.ACCEPT) {
        setIsOnlyCard(false);
      }
    }
  }, [balanceData, isSuccessBalanceData, navigate]);

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible
          paddingX={0}
          headerText={t("rent.common.linkedListHeading")}
          withCenteredHeaderText
          onBackButtonClick={() =>
            navigate(isFromPayment ? "/rent/payment" : "/rent/linked-accounts")
          }
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        {isLoadingBalanceData && (
          <Box
            sx={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: isLoadingBalanceData
                ? "rgba(255, 255, 255, 0.5)"
                : "rgba(255, 255, 255, 0.4)",
              borderRadius: "8px",
            }}
          >
            <CircularProgress
              color="primary"
              size={"40px"}
              sx={{
                position: "absolute",
              }}
            />
          </Box>
        )}

        {!isLoadingBalanceData && (
          <WrpperAuthorizedContainer>
            <AddCreditOrDebitCardForm
              navigate={navigate}
              t={t}
              isFromPayment={isFromPayment}
              isOnlyCard={isOnlyCard}
            />
          </WrpperAuthorizedContainer>
        )}
      </Wrapper>
    </>
  );
};
