import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Box, Typography } from "@mui/material";

import { FormInput, FormTextArea, ShadowCard } from "src/components";
import { updateBindingsChange } from "src/shared/utilities/formUtilities";
import {
  PayoutAmountOption,
  PayoutAmountOptionTop,
  PayoutAmountOptionBottom,
} from "./PayoutAmountFormFields.style";
import { handleTransactionAmount } from "src/helpers/helperRent";

export interface PayoutAmountFormFieldsProps {
  balanceAmount?: string;
  register: any;
  control: any;
  setValue: any;
  errors: any;
  setPaymentWay: any;
  paymentWay: "fixed" | "custom";
  isPaymentOptionEnable: boolean;
  isCustomPaymentOptionEnable?: boolean;
}

export const PayoutAmountFormFields = ({
  balanceAmount,
  register,
  control,
  errors,
  setValue,
  setPaymentWay,
  paymentWay,
  isCustomPaymentOptionEnable,
  isPaymentOptionEnable,
}: PayoutAmountFormFieldsProps) => {
  const onClickSlection = (type: "fixed" | "custom") => {
    setPaymentWay(type);
  };

  const [amount, plusValue] = handleTransactionAmount(balanceAmount);

  useEffect(() => {
    if (!plusValue && isCustomPaymentOptionEnable) {
      setPaymentWay("custom");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plusValue, isCustomPaymentOptionEnable]);
  const isFixedPayBalanceOptionDisabled = !isPaymentOptionEnable || !plusValue;

  return (
    <ShadowCard padding={4} marginBottom={6}>
      <Box display={"flex"} flexDirection={"column"}>
        <Typography
          component="p"
          variant="h2Bold"
          textTransform={"uppercase"}
          sx={{
            marginBottom: 6,
            display: "inline-flex",
            alignItems: "center",
            color: "gray.500",
          }}
        >
          {"payment amount"}
        </Typography>
        <Typography
          component="p"
          variant="h2"
          textTransform={"capitalize"}
          sx={{
            marginBottom: 6,
            display: "inline-flex",
            alignItems: "center",
            color: "gray.700",
          }}
        >
          {"How much would you like to pay?"}
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection={"column"}>
        <PayoutAmountOption
          component={"div"}
          onClick={() => onClickSlection("fixed")}
          marginBottom={isCustomPaymentOptionEnable ? 4 : 1}
          sx={{
            pointerEvents:
              !plusValue && isCustomPaymentOptionEnable ? "none" : "auto",
            opacity: !plusValue && isCustomPaymentOptionEnable ? "0.6" : "1",
          }}
        >
          <PayoutAmountOptionTop
            className={
              paymentWay === "fixed"
                ? isFixedPayBalanceOptionDisabled
                  ? "not_selected"
                  : "selected"
                : "not_selected"
            }
          >
            <Box component="div" display={"flex"} alignItems={"center"}>
              <Box className="option-indicator" component="span" />
              <Typography
                component="span"
                textTransform={"capitalize"}
                variant="h2Bold"
                color={
                  paymentWay === "fixed"
                    ? isFixedPayBalanceOptionDisabled
                      ? "gray.600"
                      : "primary"
                    : "gray.600"
                }
              >
                {"Pay Balance"}
              </Typography>
            </Box>
            <Typography
              variant="h2Bold"
              color={
                paymentWay === "fixed"
                  ? isFixedPayBalanceOptionDisabled
                    ? "gray.600"
                    : "primary"
                  : "gray.900"
              }
              component="span"
              textTransform={"capitalize"}
            >
              {amount}
            </Typography>
          </PayoutAmountOptionTop>
        </PayoutAmountOption>

        {isCustomPaymentOptionEnable && (
          <PayoutAmountOption
            component={"div"}
            onClick={() => onClickSlection("custom")}
            marginBottom={paymentWay === "custom" ? 0 : 3}
          >
            <PayoutAmountOptionTop
              marginBottom={paymentWay === "custom" ? 5 : 0}
              className={paymentWay === "custom" ? "selected" : "not_selected"}
            >
              <Box component="div" display={"flex"} alignItems={"center"}>
                <Box className="option-indicator" component="span" />
                <Typography
                  component="span"
                  textTransform={"capitalize"}
                  variant="h2Bold"
                  color={paymentWay === "custom" ? "primary" : "gray.600"}
                >
                  {"Custom Amount"}
                </Typography>
              </Box>

              <Controller
                control={control}
                name="amount"
                render={({ field: { value } }) => (
                  <FormInput
                    id={"amount"}
                    placeholder={""}
                    {...register("amount")}
                    error={errors?.amount?.message}
                    onChange={(event: any) => {
                      updateBindingsChange(event, "amount", setValue);
                    }}
                    onKeyPress={(event: any) => {
                      if (!/^[-+]?\d*\.?\d*$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    isErrorMessageVisible={false}
                    inputMode={"decimal"}
                    value={value}
                    isAmountField
                    disableCopyPaste={true}
                  />
                )}
              />
            </PayoutAmountOptionTop>
            {paymentWay === "custom" && (
              <PayoutAmountOptionBottom
                className={
                  paymentWay === "custom" ? "selected" : "not_selected"
                }
              >
                <Controller
                  control={control}
                  name="memo"
                  render={({ field: { value } }) => {
                    return (
                      <FormTextArea
                        id={"memo"}
                        {...register("memo")}
                        placeholder={"Memo"}
                        error={errors?.memo?.message}
                        onChange={(event: any) => {
                          updateBindingsChange(event, "memo", setValue);
                        }}
                        value={value}
                        size="small"
                      />
                    );
                  }}
                />
              </PayoutAmountOptionBottom>
            )}
          </PayoutAmountOption>
        )}
      </Box>
    </ShadowCard>
  );
};
