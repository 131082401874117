import { Box, Typography } from "@mui/material";
import { Modal } from "../Modal/Modal";
import { WelcomeMessageModalProps } from "src/shared/models/component/base.model";
import { FaqBlock } from "src/components/CommonNestedBlocks/FaqBlock/FaqBlock";
import { NoteText } from "src/components/Notes/NoteText/NoteText";

export const WelcomeMessageModal = ({
  closeIdModalEvent,
  ResidentName,
  t,
}: WelcomeMessageModalProps) => {
  // handle close event __
  const handleCloseEvent = () => {
    closeIdModalEvent && closeIdModalEvent();
  };

  return (
    <>
      <Modal
        onCloseEvent={handleCloseEvent}
        isMobileBottomFixed={false}
        additionalStyles={{
          width: "calc(100vw - 40px) !important",
          maxWidth: "400px !important",
          paddingTop: 10,
          position: "relative",
          background: "#fff !important",
        }}
      >
        <Box>
          <Box
            sx={{ marginBottom: 6, display: "flex", flexDirection: "column" }}
          >
            <Typography variant="h6" textAlign={"center"} marginBottom={6}>
              {`Howdy ${ResidentName ? ResidentName : ""}!`}
            </Typography>
            <Typography
              variant="h3"
              color={"#4E4F5E"}
              marginBottom={0}
              textAlign={"left"}
              lineHeight={"24px"}
            >
              {t("home.welcomMessageModal.content")}
            </Typography>
          </Box>
          <Box marginBottom={4}>
            <FaqBlock
              heading={t("home.welcomMessageModal.block1.heading")}
              content={t("home.welcomMessageModal.block1.content")}
              iconType="MakeAPayment"
            />
            <FaqBlock
              heading={t("home.welcomMessageModal.block2.heading")}
              content={t("home.welcomMessageModal.block2.content")}
              iconType="MaintenanceRequest"
            />
            <FaqBlock
              heading={t("home.welcomMessageModal.block3.heading")}
              content={t("home.welcomMessageModal.block3.content")}
              iconType="RentReporting"
            />
          </Box>
        </Box>
        <Box marginBottom={0} paddingTop={4}>
          <NoteText iconType="InfoRoundedIcon" t={t} />
        </Box>
      </Modal>
    </>
  );
};
