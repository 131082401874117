import { Box } from "@mui/material";
import { StatusChips } from "../StatusChips/StatusChips";
import { useComponentVisible } from "src/hooks";
import {
  StatusChipsType,
  StatusDropDownProps,
} from "src/shared/models/component/base.model";
import { ShadowCard } from "src/components/CommonNestedStyles/FormWrapper.style";

export const StatusDropDown = ({
  defaultValue,
  options,
  optionOnClickEvent,
}: StatusDropDownProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleOptionOnCLickEvent = (item: any) => {
    optionOnClickEvent && optionOnClickEvent(item);
    setIsComponentVisible(false);
  };

  return (
    <Box ref={ref} position={"relative"}>
      <Box onClick={() => setIsComponentVisible(true)}>
        <StatusChips type={defaultValue} isArrowIconVisible />
      </Box>
      {isComponentVisible && (
        <ShadowCard
          pt={2}
          px={2}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            position: "absolute",
            top: "100%",
            left: "0",
          }}
        >
          {options &&
            options.map((item: { type: StatusChipsType }, index: any) => (
              <Box
                width={"100%"}
                mb={2}
                sx={{ cursor: "pointer" }}
                onClick={() => handleOptionOnCLickEvent(item)}
                key={index}
              >
                <StatusChips type={item.type} />
              </Box>
            ))}
        </ShadowCard>
      )}
    </Box>
  );
};
