// import { styled } from "@mui/material/styles";
import { Theme, styled } from "@mui/system";

export const MenuItem = styled("li", {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{ theme?: Theme; disabled?: boolean }>(
  ({ theme, disabled }) => `
  width: auto;
  display: inline-flex;
  align-items: center;
  list-style: none;

  a {
    pointer-events: ${disabled ? "none" : "inherit"};
    opacity: ${disabled ? 0.3 : 1};
    &.active {
      div {
        color: ${theme.palette.primary.main};
      }
    }
  }


  @media(min-width: ${theme.breakpoints.values.sm}px){
   margin-left: ${theme.spacing(8)};
   .meun_icon {
    display: none;
   }
  }

  @media(max-width: ${theme.breakpoints.values.sm}px){
   justify-content: center;
   align-items: center;
   width: 25%;

   div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   }

   .meun_icon {
    margin-bottom: ${theme.spacing(3)};
   }
  }
`
);

export const MenuNav = styled("nav")(
  ({ theme }) => `
  display: flex;
  align-items: center;
  width: 100%;

 ul {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;

  &.invoice_tab_active .invoices {
    div {
      color: ${theme.palette.primary.main};
     }
  }
  
  &.tran_tab_active .finances {
    div {
      color: ${theme.palette.primary.main};
     }
  }
 }

 @media(min-width: ${theme.breakpoints.values.sm}px){
  ul {
   justify-content: flex-end;
   display: flex;
  }
 }

 @media(max-width: ${theme.breakpoints.values.sm}px){
  position: fixed;
  bottom: 0;
  height: 72px;
  left: 0;
  right: 0;
  background: ${theme.palette.white};
  z-index: 2;
  box-shadow: 0px -1px 0px rgba(0,0,0,0.1);
 }
 `
);
