import React from "react";
import { PaymentTableWrapper } from "./TableWrapper.style";

export interface PaymentTableProps {
  children?: React.ReactNode;
  isToggle?: boolean;
  tabType?: "submitted" | "paid" | "approved" | "transactions";
  onClick?: any;
}

export const TableWrapper = ({
  children,
  isToggle,
  tabType,
  ...rest
}: PaymentTableProps) => {
  return (
    <>
      {isToggle && (
        <PaymentTableWrapper
          {...rest}
          className={
            tabType === "submitted" ? "table_submitted" : "payment_table"
          }
        >
          {children}
        </PaymentTableWrapper>
      )}
    </>
  );
};
