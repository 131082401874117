import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, createSearchParams } from "react-router-dom";

import {
  DataGridWithToolBarCard,
  Header,
  HeaderFixed,
  ManualLoader,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";
import {
  set_old_date_for_rent_reporting_history,
  sortingArrayByDateForRentStatement,
} from "src/helpers/helperRent";
import { todayDateYYYYMMDD } from "src/shared/utilities/dateConvertions";
import { useGetReportingStatementsQuery } from "src/store/services/privateApi";

export const RentReportingListScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState<string>("");
  const [filterSchema, setFilterSchema] = useState({
    startDate: null,
    endDate: null,
  });
  const [gridData, setGridData] = useState<any[]>([]);

  const { data, isSuccess, isLoading, isFetching }: any =
    useGetReportingStatementsQuery({
      startDate: filterSchema.startDate
        ? filterSchema.startDate
        : set_old_date_for_rent_reporting_history,
      endDate: filterSchema.endDate
        ? filterSchema.endDate
        : todayDateYYYYMMDD(),
    });

  const handleSearch = (event: { target: { value: any } }) => {
    setSearchText(event.target.value);
  };

  const handleResetSearch = () => {
    setSearchText("");
  };

  useEffect(() => {
    if (isSuccess && data && data.length > 0) {
      const sortedArray = sortingArrayByDateForRentStatement(data, true);
      setGridData(sortedArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess]);

  return (
    <>
      {(isLoading || isFetching) && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible
          paddingX={0}
          headerText={t("rentReporting.header")}
          onBackButtonClick={() => navigate("/rent/reporting/home")}
          withCenteredHeaderText
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          {isSuccess && (
            <DataGridWithToolBarCard
              t={t}
              dataObject={gridData}
              dataGridCardTitle={t("rentReporting.reportingHistory.title")}
              iconArrowVisible={false}
              isHandleArrowClickable={false}
              navigate={navigate}
              routeState={{ backRouteUrl: "/rent/reporting/list" }}
              onHandleResetSearch={handleResetSearch}
              onHandleSearch={handleSearch}
              searchText={searchText}
              isTooltipInfoEnabled
              tooltipContent={t(
                "rentReporting.reportingHistory.listTooltipContent"
              )}
              itemCount={data ? `${data?.length}` : "0"}
              setFilterSchema={setFilterSchema}
              createSearchParams={createSearchParams}
              isDataFetching={isFetching}
              gridType="reporting_history"
              searchInputPlaceHolder={t(
                "rentReporting.reportingHistory.searchStatements"
              )}
            />
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
