import React, { useEffect } from "react";
import { usePaymentInputs } from "react-payment-inputs";
import classNames from "classnames";

import { Box, FormLabel, Grid } from "@mui/material";

import { FormInputErrorMessage } from "../FormInputErrorMessage/FormInputErrorMessage";
import {
  PaymentCardFieldsInputContainer,
  CardIcon,
} from "./PaymentCardFields.style";

import { ERROR_MESSAGES_CARD_COMPONENT } from "src/shared/utilities/validationMessages";
import {
  AmexIcon,
  DinersclubIcon,
  DiscoverIcon,
  HipercardIcon,
  JcbIcon,
  UnionpayIcon,
  MastercardIcon,
  GenericCardIcon,
  VisaCardIcon,
} from "src/components/Media/Icon/Icons/cards";

export interface PaymentCardFieldsProps {
  setValue: any;
  errors: any;
  setIsCardDetailsAdded: any;
  cardNumber?: number;
  setCardNumber: any;
  cardCVC?: any;
  setCardCVC?: any;
  cardExpiryDate?: any;
  setCardExpiryDate?: any;
}

export const PaymentCardFields = ({
  setValue,
  errors,
  setIsCardDetailsAdded,
  cardNumber,
  setCardNumber,
  cardCVC,
  setCardCVC,
  cardExpiryDate,
  setCardExpiryDate,
}: PaymentCardFieldsProps) => {
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs({
      errorMessages: ERROR_MESSAGES_CARD_COMPONENT,
    });

  const { erroredInputs, touchedInputs } = meta;

  const handleChangeExpiryMonthYear = (event: any) => {
    setCardExpiryDate(event.target.value);
    const getExpDateLength = event.target.value.length;
    if (getExpDateLength === 7) {
      setValue("cardExpiryYear", event.target.value);
    }
  };

  const handleChangeCVC = (event: any) => {
    setCardCVC(event.target.value);
    setValue("cardCVC", event.target.value);
  };

  const handleChangeCardNumber = (event: any) => {
    setCardNumber(event.target.value);
    setValue("cardNumber", event.target.value);
  };

  /**
   * Handle in the initial load
   */
  useEffect(() => {
    if (meta.cardType) {
      const getCardName =
        meta.cardType.displayName === "American Express"
          ? "Amex"
          : meta.cardType.displayName === "Mastercard"
          ? "MasterCard"
          : meta.cardType.displayName;
      setValue("cardType", getCardName);
    }

    if (meta.error === undefined) {
      setIsCardDetailsAdded(true);
    } else {
      setIsCardDetailsAdded(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.cardType, meta.error]);

  const cardNumberError = touchedInputs.cardNumber
    ? erroredInputs.cardNumber
    : !touchedInputs.cardNumber && errors?.cardNumber?.message
    ? errors?.cardNumber?.message
    : null;

  const expiryDateError = touchedInputs.expiryDate
    ? erroredInputs.expiryDate
    : !touchedInputs.expiryDate && errors?.cardExpiryYear?.message
    ? errors?.cardExpiryYear?.message
    : null;

  const cvcError =
    touchedInputs.cvc && erroredInputs.cvc
      ? erroredInputs.cvc
      : !touchedInputs.cvc && errors?.cardCVC?.message
      ? errors?.cardCVC?.message
      : null;

  const handleCardImage = (type: string) => {
    switch (type) {
      case "amex":
        return <AmexIcon />;
      case "dinersclub":
        return <DinersclubIcon />;
      case "discover":
        return <DiscoverIcon />;
      case "hipercard":
        return <HipercardIcon />;
      case "jcb":
        return <JcbIcon />;
      case "unionpay":
        return <UnionpayIcon />;
      case "mastercard":
        return <MastercardIcon />;
      case "placeholder":
        return <GenericCardIcon />;
      case "visa":
        return <VisaCardIcon />;
      default:
        break;
    }
  };

  return (
    <Box>
      <PaymentCardFieldsInputContainer
        className={classNames([
          cardNumberError ? "error" : "normal",
          cardNumber ? "focused" : "",
        ])}
      >
        <input
          {...getCardNumberProps({ onChange: handleChangeCardNumber })}
          value={cardNumber}
          autoComplete="off"
          placeholder="XXXX XXXX XXXX XXXX"
        />
        {meta.cardType && cardNumber && !cardNumberError && (
          <CardIcon>{handleCardImage(meta.cardType.type)}</CardIcon>
        )}
        <FormLabel>{"Card Number"}</FormLabel>

        {cardNumberError && (
          <FormInputErrorMessage>{cardNumberError}</FormInputErrorMessage>
        )}
      </PaymentCardFieldsInputContainer>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <PaymentCardFieldsInputContainer
            className={classNames([
              expiryDateError ? "error" : "normal",
              cardExpiryDate ? "focused" : "",
            ])}
          >
            <input
              {...getExpiryDateProps({ onChange: handleChangeExpiryMonthYear })}
              value={cardExpiryDate}
            />
            <FormLabel>{"Expiration"}</FormLabel>
            {expiryDateError && (
              <FormInputErrorMessage>{expiryDateError}</FormInputErrorMessage>
            )}
          </PaymentCardFieldsInputContainer>
        </Grid>
        <Grid item xs={6}>
          <PaymentCardFieldsInputContainer
            className={classNames([
              cvcError ? "error" : "normal",
              cardCVC ? "focused" : "",
            ])}
          >
            <input
              {...getCVCProps({ onChange: handleChangeCVC })}
              value={cardCVC}
              placeholder="CVV"
            />
            <FormLabel>{"CVV"}</FormLabel>
            {cvcError && (
              <FormInputErrorMessage>{cvcError}</FormInputErrorMessage>
            )}
          </PaymentCardFieldsInputContainer>
        </Grid>
      </Grid>
    </Box>
  );
};
