import * as React from "react";
import {
  FormSelectInputContainer,
  FormSelectInputWrapper,
} from "./FromAutoCompleteSelect.style";
import { Autocomplete, TextField } from "@mui/material";
import { DropdownData as dropdownData } from "./FromAutoCompleteSelect.data";
import { FormInputErrorMessage } from "../FormInputErrorMessage/FormInputErrorMessage";
import { FromAutoCompleteSelectProps } from "src/shared/models/component/base.model";
import classNames from "classnames";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const FromAutoCompleteSelect: React.FC<FromAutoCompleteSelectProps> =
  React.forwardRef<HTMLSelectElement, FromAutoCompleteSelectProps>(
    (
      {
        id,
        label = "State",
        error,
        isValid = false,
        maxWidth = "100%",
        options = dropdownData,
        readonly,
        inputValue,
        onChange,
        inputFocus,
        type = "default",
      },
      ...props
    ) => {
      const handleInputClasses =
        error && !isValid
          ? "error_input"
          : isValid
          ? "valid_input"
          : "normal_input";

      let selectedStateVal = inputValue
        ? options.find(({ label }: any) => label === inputValue)
        : null;
      selectedStateVal = selectedStateVal || null;

      return (
        <FormSelectInputWrapper
          style={{ maxWidth: maxWidth }}
          className={classNames([handleInputClasses, type])}
        >
          <FormSelectInputContainer>
            <Autocomplete
              disablePortal
              id={id}
              options={options ?? []}
              onChange={onChange}
              fullWidth
              disabled={readonly}
              value={selectedStateVal && selectedStateVal}
              onBlur={inputFocus && inputFocus}
              popupIcon={
                type === "shadowed" ? (
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: 24, color: "#BABDC2" }}
                  />
                ) : (
                  <ArrowDropDownIcon />
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  error={error?.length > 0}
                  focused={inputValue ? true : false}
                />
              )}
            />
          </FormSelectInputContainer>
          {error && !isValid && (
            <FormInputErrorMessage>{error}</FormInputErrorMessage>
          )}
        </FormSelectInputWrapper>
      );
    }
  );
