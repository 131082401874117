/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  Wrapper,
  Header,
  ManualLoader,
  Messages,
  HeaderFixed,
} from "src/components";

import { theme } from "src/shared/theme/theme";
import { IMAGES } from "src/shared/assets/images";
import {
  LandingBannerContainer,
  LandingBanner,
  // DefaultBanner,
} from "./Landing.style";
import {
  PropertySearchRouteInits,
  PropertySearchRouteParams,
} from "src/shared/models/containers/account.model";
import { useGetPropertiesMutation } from "src/store/services/publicApi";

export const handleTheLandingBannerContainerHeight = (
  errorMessage: any,
  isDefaultBannerVisible: boolean
) => {
  const height =
    errorMessage.variant !== "" && isDefaultBannerVisible
      ? "calc((var(--vh, 1vh) * 100) - 400px)"
      : errorMessage.variant === "status" && !isDefaultBannerVisible
      ? "calc((var(--vh, 1vh) * 100) - 390px)"
      : errorMessage.variant === "invalid" && !isDefaultBannerVisible
      ? "calc((var(--vh, 1vh) * 100) - 390px)"
      : errorMessage.variant === "" && isDefaultBannerVisible
      ? "calc((var(--vh, 1vh) * 100) - 330px)"
      : "calc((var(--vh, 1vh) * 100) - 310px)";

  return height;
};

export const PropertyLandingScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { abbreviationCode } = useParams();

  const { loginWithRedirect, isLoading } = useAuth0();

  const [errorMessage, setErrorMessage] = useState<{
    message: string;
    heading: string;
    variant: "status" | "invalid" | "";
  }>({
    message: "",
    heading: "",
    variant: "",
  });
  const [propertyDetails, setPropertydetails] =
    useState<PropertySearchRouteParams>(PropertySearchRouteInits);
  const [isDefaultBannerVisible, setIsDefaultBannerVisible] =
    useState<boolean>(false);

  const [
    getPropertiesForCodeMutation,
    { isLoading: isLoadingGetPropertiesForCode },
  ] = useGetPropertiesMutation();

  /**
   * Handle single property details for propertycode
   */
  const getSingleProperty = async () => {
    const singlePropertyResponse: any = await getPropertiesForCodeMutation(
      abbreviationCode ?? ""
    );

    if (singlePropertyResponse && singlePropertyResponse?.data) {
      const singlePropertyResponseDataObj = singlePropertyResponse?.data;
      const propertyCode = singlePropertyResponseDataObj?.abbreviation;
      const propertyId = singlePropertyResponseDataObj?.id;
      const propertyIsActive = singlePropertyResponseDataObj?.isActive;
      const propertyName = singlePropertyResponseDataObj?.name;
      const propertyCity = singlePropertyResponseDataObj?.city;
      const propertyState = singlePropertyResponseDataObj?.state;

      setPropertydetails({
        ...propertyDetails,
        propertyCodeFPL: propertyCode ?? "",
        propertyIdFPL: propertyId,
        propertyIsActiveFPL: propertyIsActive,
        propertyNameFPL: propertyName,
        propertyCityFPL: propertyCity ?? "",
        getPropertyStateFPL: propertyState ?? "",
      });

      if (!propertyIsActive) {
        setErrorMessage({
          heading: "Inactive property",
          message: `Sorry, the property ${propertyName} is inactive.`,
          variant: "status",
        });
      }
    }

    if (singlePropertyResponse && singlePropertyResponse.error) {
      setErrorMessage({
        heading: "Invalid Property Code",
        message: `Sorry, unbable to find the property for property code ${abbreviationCode}.`,
        variant: "invalid",
      });
    }
  };

  useEffect(() => {
    if (abbreviationCode) {
      getSingleProperty();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Email sig in click
   */
  const webAuthnSignIn = async () => {
    await loginWithRedirect({
      authorizationParams: {
        responseType: "code",
        redirectUri: `${window.location.origin}`,
        mode: "login",
      },
      appState: {
        returnTo: "/",
      },
    });
  };

  /**
   * signup page redirection
   */
  const webAuthnSignUpRedirection = () => {
    navigate("/invitation", {
      state: {
        ...propertyDetails,
        isFromePropertyLanding: true,
      },
    });
  };

  const handleBottomContentVisiblity =
    errorMessage && errorMessage.variant !== "invalid";
  const getPropertyName =
    propertyDetails && propertyDetails?.propertyNameFPL
      ? propertyDetails?.propertyNameFPL
      : "";
  const getPropertyCity =
    propertyDetails && propertyDetails?.propertyCityFPL
      ? propertyDetails?.propertyCityFPL
      : "";
  const getPropertyState =
    propertyDetails && propertyDetails?.getPropertyStateFPL
      ? propertyDetails?.getPropertyStateFPL
      : "";

  return (
    <>
      {(isLoading || isLoadingGetPropertiesForCode) && <ManualLoader />}
      <Wrapper>
        <HeaderFixed
          component={"div"}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              paddingX: 3,
            },
            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              paddingX: 0,
            },
          }}
        >
          <Header
            paddingX={0}
            isBottomContentVisible={handleBottomContentVisiblity}
            bottomHighlightContent={
              handleBottomContentVisiblity
                ? `${getPropertyName} ${
                    getPropertyCity !== "" ? "|" : ""
                  } ${getPropertyCity}${
                    getPropertyState ? "," : ""
                  } ${getPropertyState}`
                : ""
            }
            isHeaderBottomFixedInDesktopView
          />
        </HeaderFixed>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
            paddingX: 4,
            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              flex: 1,
            },
          }}
        >
          <LandingBannerContainer
            sx={{
              [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                height: handleTheLandingBannerContainerHeight(
                  errorMessage,
                  isDefaultBannerVisible
                ),
                marginTop:
                  errorMessage.variant !== ""
                    ? 4
                    : isDefaultBannerVisible
                    ? 8 // 8
                    : 6,
              },
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                height: errorMessage.variant === "" ? "460px" : "400px",
                marginTop: errorMessage.variant !== "" ? 4 : 10,
              },
            }}
          >
            {isDefaultBannerVisible && (
              <LandingBanner>
                <img src={IMAGES.LANDING_BANNER} alt="Landing Banner" />
              </LandingBanner>
            )}
            {!isDefaultBannerVisible && (
              <LandingBanner>
                <img
                  src={`https://d7kjalqldbemw.cloudfront.net/banners/${abbreviationCode}.png`}
                  alt={propertyDetails?.propertyNameFPL}
                  onError={() => setIsDefaultBannerVisible(true)}
                />
              </LandingBanner>
              // <DefaultBanner>
              //   <img
              //     src={`https://d7kjalqldbemw.cloudfront.net/${abbreviationCode}.png`}
              //     alt={propertyDetails?.propertyNameFPL}
              //     onError={() => setIsDefaultBannerVisible(true)}
              //   />
              // </DefaultBanner>
            )}
          </LandingBannerContainer>
          {errorMessage.message !== "" && (
            <Messages
              messageHeading={errorMessage.heading}
              messageContent={errorMessage.message}
              closeEvent={() =>
                setErrorMessage({
                  message: "",
                  heading: "",
                  variant: "",
                })
              }
              hideCloseIcon
              topMargin={1}
              bottomMargin={1}
            />
          )}

          <Button
            variant="containedLarge"
            color="primary"
            onClick={webAuthnSignUpRedirection}
            fullWidth
            sx={{ marginBottom: 4, marginTop: 4 }}
            id={"SignUp"}
          >
            {t("buttonTexts.signupButtonText")}
          </Button>
          <Button
            variant="outlinedLarge"
            color="primary"
            onClick={webAuthnSignIn}
            fullWidth
            id={"Login"}
          >
            {t("buttonTexts.loginButtonText")}
          </Button>
        </Box>
      </Wrapper>
    </>
  );
};
