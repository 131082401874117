import { Box, CircularProgress, Typography } from "@mui/material";
import { ShadowCard } from "src/components/CommonNestedStyles/FormWrapper.style";
import { TooltipInfo } from "src/components/OtherUtilities/TooltipInfo/TooltipInfo";
import { WrapperCardProps } from "src/shared/models/component/base.model";

export const WrapperCard = ({
  type,
  pbWrapper = 4,
  ptWrapper = 4,
  plWrapper = 4,
  prWrapper = 4,
  mbWrapper = 6,
  mtWrapper = 0,
  minHeightWrapper = 100,
  title,
  tooltipContent,
  pbTitle = 0,
  ptTitle = 0,
  plTitle = 0,
  prTitle = 0,
  mbTitle = 0,
  mtTitle = 0,
  colorTitle = "gray.500",
  variantTitle = "h2Bold",
  textTransformTitle = "uppercase",
  subTitle,
  pbSubTitle = 0,
  ptSubTitle = 0,
  plSubTitle = 0,
  prSubTitle = 0,
  mbSubTitle = 0,
  mtSubTitle = 0,
  colorSubTitle = "gray.900",
  variantSubTitle = "h3",
  textTransformSubTitle = "none",
  fwSubTitle = 400,
  isInnerLoaderEnabled = false,
  isLoadingFadeEnabled = false,
  children,
  childElementsFlexDirection = "column",
}: WrapperCardProps) => {
  return (
    <ShadowCard
      paddingBottom={pbWrapper}
      paddingTop={ptWrapper}
      paddingLeft={plWrapper}
      paddingRight={prWrapper}
      marginBottom={mbWrapper}
      marginTop={mtWrapper}
      className={type ? type : ""}
      position={"relative"}
      minHeight={minHeightWrapper}
    >
      {title && (
        <Typography
          component="p"
          variant={variantTitle}
          textTransform={textTransformTitle}
          sx={{
            marginBottom: mbTitle,
            marginTop: mtTitle,
            display: "inline-flex",
            alignItems: "center",
            color: colorTitle,
            paddingLeft: plTitle,
            paddingRight: prTitle,
            paddingTop: ptTitle,
            paddingBottom: pbTitle,
            width: "100%",
          }}
        >
          {title}
          {tooltipContent && (
            <TooltipInfo>
              <Box width={"154px"} sx={{ textTransform: "initial" }}>
                {tooltipContent}
              </Box>
            </TooltipInfo>
          )}
        </Typography>
      )}
      {subTitle && (
        <Typography
          component="p"
          variant={variantSubTitle}
          textTransform={textTransformSubTitle}
          sx={{
            marginBottom: mbSubTitle,
            marginTop: mtSubTitle,
            display: "inline-flex",
            alignItems: "center",
            color: colorSubTitle,
            paddingLeft: plSubTitle,
            paddingRight: prSubTitle,
            paddingTop: ptSubTitle,
            paddingBottom: pbSubTitle,
            fontWeight: fwSubTitle,
            width: "100%",
          }}
        >
          {subTitle}
        </Typography>
      )}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: childElementsFlexDirection,
        }}
      >
        {children}
      </Box>
      {(isInnerLoaderEnabled || isLoadingFadeEnabled) && (
        <Box
          sx={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: isLoadingFadeEnabled
              ? "rgba(255, 255, 255, 0.5)"
              : "rgba(255, 255, 255, 0.4)",
            borderRadius: "8px",
          }}
        >
          {!isLoadingFadeEnabled && (
            <CircularProgress
              color="primary"
              size={"40px"}
              sx={{
                position: "absolute",
              }}
            />
          )}
        </Box>
      )}
    </ShadowCard>
  );
};
