import moment from "moment";
import { DOB } from "../models/component/base.model";

export const updateBindingsChange = (event: any, feild: any, setValue: any) => {
  setValue(feild, event.target.value);
};

export const phoneOnChangeHandler = (
  event: any,
  setValue: any,
  btnSubmitted: boolean,
  valueText = "phone"
) => {
  setValue(valueText, event, { shouldValidate: btnSubmitted && true });
};

export const removeSpaceAndSymbols = (val: any) => {
  return val?.replace(/\D/g, "");
};

export const phoneNumberMask = (val: any) => {
  let num = "";
  if (val) {
    val = removeSpaceAndSymbols(val);
    if (val.length < 3) {
      num = `(${val.substring(0, 3)}`;
    } else {
      num = `(${val.substring(0, 3)}) ${val.substring(3, 6)}-${val.substring(
        6,
        10
      )}`;
    }
  }
  return (num = num.trim());
};

export const phoneMaskWithoutCountryCode = (val: any) => {
  let num = "";
  if (val) {
    val = removeSpaceAndSymbols(val);
    if (val.length === 11) {
      val = val.slice(1, val.length);
      num = phoneNumberMask(val);
    } else {
      num = phoneNumberMask(val);
    }
  }
  return (num = num.trim());
};

export const getOnlyPhoneDigits = (val: any) => {
  let num = "";
  if (val) {
    val = removeSpaceAndSymbols(val);
    if (val.length === 11) {
      num = val.slice(1, val.length);
    } else {
      num = val;
    }
  }
  return (num = num.trim());
};

export const isValidPhoneFormatOrNot = (val: any): boolean => {
  let isValid = true;

  if (val) {
    const valSymbolRemoved = removeSpaceAndSymbols(val);
    const checkFirstLetterIsUSCountryCodeOrNot =
      valSymbolRemoved.slice(0, 1) === "1";

    if (valSymbolRemoved.length > 11) {
      isValid = false;
    } else if (
      valSymbolRemoved.length === 11 &&
      !checkFirstLetterIsUSCountryCodeOrNot
    ) {
      isValid = false;
    } else if (val.length < 10) {
      isValid = false;
    }
  }

  return isValid;
};

export const isNullDOB = (dob: DOB) => {
  return !dob.day || !dob.month || !dob.year;
};

export const onChangeHandlerDateInput = (
  val: any,
  setValue: any,
  setErrorManualDateType: any
) => {
  if (val) {
    const dateMoment = moment(val);
    const toDate = moment(val).toDate();
    const dateWithFormat = moment(toDate).format("MM/DD/yyyy");
    if (dateWithFormat === "Invalid date") {
      setErrorManualDateType(true);
    }
    if (
      !dateMoment?.isValid() ||
      !toDate?.getFullYear() ||
      toDate?.getFullYear() < 1900 ||
      (toDate?.getFullYear() > new Date().getFullYear() && val.length !== 10)
    ) {
      setErrorManualDateType(false);
    } else {
      const formattedDate = moment(toDate).format("MM/DD/yyyy");
      const years = moment().diff(formattedDate, "years", true);
      if (years < 18) {
        val.length === 10
          ? setErrorManualDateType(true)
          : setErrorManualDateType(false);
      } else {
        val.length < 9
          ? setErrorManualDateType(false)
          : setErrorManualDateType(false);
      }
    }
  }
  setValue("dob", val); // Set value
};

export const isNot18 = (val: any) => {
  const toDate = moment(val).toDate();
  const dateWithFormat = moment(toDate).format("MM/DD/yyyy");
  const years = moment().diff(dateWithFormat, "years", true);
  if (years < 18) {
    return val.length === 10 ? false : true;
  }
  return true;
};

export const isValidDOB = (value: any) => {
  const dateMoment = moment(value);
  return dateMoment?.isValid();
};

export const ssnNumberMask = (val: any) => {
  let num = "";
  if (val) {
    val = removeSpaceAndSymbols(val);
    if (val.length === 9) {
      num = `${val.substring(0, 3)}-${val.substring(3, 5)}-${val.substring(
        5,
        10
      )}`;
    }
  }
  return (num = num.trim());
};

export const validationMessageForeResponse = (
  response: any,
  setErrorMessage: any,
  customMessage: string,
  onSubmitSuccessEvent?: any
) => {
  if (response.error) {
    if (response.error.length > 0) {
      setErrorMessage(customMessage);
    } else {
      setErrorMessage(
        response?.error?.data?.err ??
          response?.error?.data?.message ??
          response?.error?.data?.error ??
          response?.error?.data
      );
    }
  } else {
    setErrorMessage("");
    onSubmitSuccessEvent && onSubmitSuccessEvent();
  }
};
