export const rentEnTranslation = {
  rentReporting: {
    header: "Rent Reporting",
    notReadyMessageContent:
      "To view your most recent score, turn on rent reporting. This will help build your credit score as well.",
    creditScore: {
      title: "Credit Score",
      subTitleWithScore: "Your credit is in excellent shape!",
      subTitleWithoutScore:
        "Your credit score will show up here in a few days after rent reporting is active.",
      bottomText: "VantageScore 3.0 credit score by equifax",
    },
    scoreProgress: {
      title: "Score Progress",
      tooltipContent:
        "Score Progress shows your historical movement of credit score since tracking began on CredBuild",
    },
    reportingHistory: {
      title: "Reporting History",
      tooltipContent:
        "Reporting History shows a report for each month your rent was reported by CredBuild to the credit bureaus. You can see the month, amount and the date when reporting was done.",
      emptyStateContent:
        "Once reporting begins, each month's report will show up in this space.",
      listTooltipContent:
        "This list shows each payment reported to the credit bureaus on your behalf.",
      searchStatements: "Search for statements",
    },
    reportingConfirmation: {
      title: "Great, you're all signed up!",
      title1:
        "Sit back and relax as we do the reporting and help you achieve your credit goals.",
    },
    reportingInfo: {
      informationContent: "Your information is secure and encrypted",
      informationSubContent: "We do not do a credit check or hard pulls, ever.",
    },
    creditScoreModal: {
      title: "View My Credit Score",
      subtitle: "Report your monthly rent & bills to build credit",
      description1:
        "By Clicking the button below, I agree to the conditions mentioned in the",
      description2:
        "including providing consent to CredBuild to display my credit score.",
      description3: "CredBuild will never do a credit check.",
      termsOfService: "Terms of Service",
    },
  },
};
