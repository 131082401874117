import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Button } from "@mui/material";

import { Modal } from "../Modal/Modal";

import { AccountInfoUpdateFormModalProps } from "src/shared/models/component/base.model";
import {
  FormAuthorizedWrapper,
  ShadowCard,
} from "../../CommonNestedStyles/FormWrapper.style";
import {
  phoneNumberMask,
  phoneOnChangeHandler,
  removeSpaceAndSymbols,
  validationMessageForeResponse,
} from "src/shared/utilities/formUtilities";
import { FormInputPhoneNumber } from "src/components/FormElements/FormInputPhoneNumber/FormInputPhoneNumber";
import { FormInput } from "src/components/FormElements/FormInput/FormInput";
import { AccountUpdateFormParams } from "src/shared/models/containers/account.model";
import { ERROR_MESSAGES } from "src/shared/utilities/validationMessages";
import { useUpdateUserAccountMutation } from "src/store/services/privateApi";
import { ManualLoader } from "src/components/OtherUtilities/Loader/ManualLoader";
import { theme } from "src/shared/theme/theme";
import { FormInPutChangePassword } from "src/components/FormElements/FormInPutChangePassword/FormInPutChangePassword";

export const AccountInfoUpdateFormModal = ({
  closeIdModalEvent,
  userData,
  isLoadingUserDataSuccess,
  t,
  setErrorMessage,
}: AccountInfoUpdateFormModalProps) => {
  // handle close event __
  const handleCloseEvent = () => {
    closeIdModalEvent && closeIdModalEvent();
  };

  const [btnSubmitted, setBtnSubmitted] = useState(false);

  const [updateUserAccountMutation, { isLoading: isLoadingUpdateUser }] =
    useUpdateUserAccountMutation();

  useEffect(() => {
    if (isLoadingUserDataSuccess && userData && userData.user) {
      const personObject = userData?.user?.person;
      const fullName = `${
        personObject?.FirstName ? personObject?.FirstName : ""
      } ${personObject?.LastName ? personObject?.LastName : ""}`;
      const phoneNumber = userData?.user?.person?.MobilePhone;
      const homePhoneNumber = userData?.user?.person?.HomePhone;
      setValue("fullName", fullName);
      setValue("phone", phoneNumber ? phoneNumberMask(phoneNumber) : "");
      setValue("firstName", personObject?.FirstName);
      setValue("lastName", personObject?.LastName);
      setValue("homePhone", homePhoneNumber ? homePhoneNumber : "");
      setValue("email", userData?.user?.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUserDataSuccess]);

  const revieInfoSchema = yup.object({
    phone: yup
      .string()
      .required(ERROR_MESSAGES.REQUIRED)
      .min(14, ERROR_MESSAGES.PHONE_NUMBER_INVALID),
  });

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    // getValues,
    formState: { errors },
  } = useForm<AccountUpdateFormParams>({
    reValidateMode: "onBlur",
    mode: "onSubmit",
    resolver: yupResolver(revieInfoSchema),
  });

  const onAppFormSubmit = handleSubmit(
    async (data: AccountUpdateFormParams) => {
      const accountData = {
        mobilePhone: removeSpaceAndSymbols(data.phone),
        firstName: data.firstName,
        lastName: data.lastName,
        homePhone: data.homePhone ? data.homePhone : "",
      };
      const accountResponse: any = await updateUserAccountMutation(accountData);

      if (accountResponse) {
        validationMessageForeResponse(
          accountResponse,
          setErrorMessage,
          "Unable to complete this process, please check the details",
          handleCloseEvent()
        );
      }
    }
  );

  return (
    <>
      {isLoadingUpdateUser && <ManualLoader />}
      <Modal
        onCloseEvent={handleCloseEvent}
        modalTitle={"Edit Account Information"}
      >
        <FormAuthorizedWrapper onSubmit={onAppFormSubmit}>
          <ShadowCard paddingX={4} paddingTop={6} paddingBottom={1}>
            <Controller
              control={control}
              name="fullName"
              render={({ field: { value } }) => (
                <FormInput
                  label={t("formInput.fullName")}
                  id={"fullName"}
                  placeholder={""}
                  {...register("fullName")}
                  inputFocus={(e: any) => {
                    trigger("fullName");
                  }}
                  value={value}
                  readonly
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={({ field: { value } }) => (
                <FormInput
                  label={t("formInput.email")}
                  id={"email"}
                  placeholder={""}
                  {...register("email")}
                  inputFocus={(e: any) => {
                    trigger("email");
                  }}
                  value={value}
                  readonly
                />
              )}
            />
            <Controller
              control={control}
              name="phone"
              render={({ field: { value } }) => (
                <FormInputPhoneNumber
                  label={t("formInput.phone")}
                  id={"phone"}
                  placeholder={"(123) 543-3454"}
                  {...register("phone")}
                  inputError={errors.phone?.message}
                  onChange={(e: any) => {
                    phoneOnChangeHandler(e, setValue, btnSubmitted);
                  }}
                  inputMode="numeric"
                  value={value}
                />
              )}
            />
            <FormInPutChangePassword setErrorMessage={setErrorMessage} />
          </ShadowCard>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              left: 0,
              right: 0,
              bottom: 0,
              top: "auto",
              paddingBottom: 0,
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                paddingTop: 6,
              },
              [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                position: "absolute",
              },
            }}
            component={"div"}
          >
            <Button
              variant="containedLarge"
              color="primary"
              type="submit"
              fullWidth
              sx={{ overflow: "hidden" }}
              onClick={() => {
                setBtnSubmitted(true);
              }}
            >
              {t("buttonTexts.updateButton")}
            </Button>
          </Box>
        </FormAuthorizedWrapper>
      </Modal>
    </>
  );
};
