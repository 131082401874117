import { styled } from "@mui/material/styles";

export const Thead = styled("div")(
  ({ theme }) => `
  display: flex;
  width: 100%;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    display: none;
  }
`
);

export const TheadTr = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TheadInfoIconWrapper = styled("div")`
  display: inline-flex;
`;

export const TheadTh = styled("div")(
  ({ theme }) => `
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${theme.palette.gray[500]};

  span {
    font-weight: 700;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: ${theme.palette.gray[500]};
  }

  svg {
    display: none;
  }

  @media(min-width: ${theme.breakpoints.values.sm}px) {
    &.first {
      max-width: calc(100% - 320px);

      &.icon_arrow_hide {
        width: calc(100% - 265px);
        max-width: calc(100% - 300px);
      }
    }

    &.second {
      max-width: 124px;
      display: flex;
    }

    &.third {
      max-width: 160px;

      &.icon_arrow_visible {
        align-items: baseline;
      }

      &.icon_arrow_hide {
        justify-content: space-between;
        min-width: 100px;
        text-align: left;
      }
    }
  }
`
);

/**
 * Table body section
 */

export const TbodyWrapper = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;

  .no_cursor {
    pointer-events: none;
  }
`;

export const TbodyContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  width: 100%;
  position: relative;

  .label_bulk_edit {
    width: 16px;
    height: 16px;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 4px;
    background: #fff;
    border: 1px solid ${theme.palette.gray[300]};
    transition: o.4s ease all;
    cursor: pointer;
    svg {
      display: none;
    }
  }

  .input_bulk_edit {
    height: 0;
    width: 0;
    overflow: hidden;
    visbility: hidden;
    opacity: 0;
    position: absolute;
    left: 0;

    &:checked {
      + .label_bulk_edit {
        background: ${theme.palette.primary.main};
        align-items: center;
        justify-content: center;
        border: 1px solid ${theme.palette.primary.main};
        svg {
          display: block;
        }
      }
      ~ .payout_item {
        pointer-events: none;
      }
    }
  }
`
);

export const Tbody = styled("div")(
  ({ theme }) => `
  display: flex;
  width: 100%;
  background-color: transparent;

  transition: 0.4s ease all;

  * {
    transition: 0.4s ease all;
  }

  &.odd {
    background: #f8f8f8;
  }

  .is_cursor {
    cursor: pointer;
  }

  .updated_terms {
    > * {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: '';
      display: block;
      top: 0;
      bottom: 0;
      position: absolute;
      background: rgba(241, 241, 241, 0.5);
      z-index: 1;
    }
  }

  &.is_old {
    &.is_editable {
      .payout_item_tr {
        padding-left: 24px;
      }
    }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    .updated_terms {
      &:before {
        left: -10px;
        right: -10px;
      }
    }

    &.is_editable {
      .edit_tag.current {
        margin-right: -20px;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    .updated_terms {
      &:before {
        left: 0px;
        right: 0px;
      }
    }
  }
`
);

export const TbodyTr = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TbodyTd = styled("div")(
  ({ theme }) => `
  padding: 10px 0;
  width: 100%;
  display: flex;

  .coupa_invoice {
    color: ${theme.palette.primary.main};
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    &.date_val {
      color: ${theme.palette.gray[500]};
      font-weight: 400;
    }
  }

  &.upcomingTran {
    pointer-events: none;
  }

  &.second {
    justify-content: flex-start;
    padding-right: ${theme.spacing(4)};
  }

  &.first {
    padding-right: ${theme.spacing(4)};

    div {
      width: 100%;
    }

    span.first-link-text-transactions {
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 18px;
      text-transform: capitalize;
    }
  }

  &.third {
    position: relative;

    svg {
      position: relative;
      right: 0;
    }

    color: ${theme.palette.gray[800]};

    &.icon_arrow_visible {
      padding-right: 18px;

      .sub-cell {
        &-third {
          right: 0;
          top: 0;
          bottom: 0;
          position: absolute;
          margin-top: auto;
          margin-bottom: auto;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  @media(max-width:${theme.breakpoints.values.sm}px) {
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
  
      &.date_val {
        font-size: 12px;
      }
    }

    &.first {
      max-width: calc(100% - 80px);
      min-width: calc(100% - 100px);
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;
    }

    &.second {
      display: none;
    }

    &.third {
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 0;

      svg {
        width: 7.5px;
      }
    }
  }

  @media(min-width: ${theme.breakpoints.values.sm}px) {
    &.first {
      max-width: calc(100% - 320px);
      padding-right: 30px;

      &.icon_arrow_hide {
        width: calc(100% - 265px);
        max-width: calc(100% - 300px);
      }
    }

    &.second {
      max-width: 124px;
      display: flex;
    }

    &.third {
      max-width: 200px;

      &.icon_arrow_visible {
        max-width: 200px;
      }

      &.icon_arrow_hide {
        justify-content: space-between;
        width: 205px;
        text-align: left;
      }

      svg {
        width: 10px;
      }
    }
  }
`
);

export const TdContainer = styled("div")(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;

  .date_val {
    margin-top: 4px;
  }

  .property-location {
    opacity: 0.5;
    margin-top: 4px;
  }

  .invoice-number-wrapper {
    padding-top: 1px;
  }

  .long-invoice-number-wrapper {
    padding-top: 1px;
  }

  @media(max-width: ${theme.breakpoints.values.sm}px) {
    .invoice-number-wrapper {
      padding-top: 1px;
      position: absolute;
    }

    .long-invoice-number-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`
);

export const TagWrapper = styled("div")(
  ({ theme }) => `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;


  &.edit_tag {
    transition: none;

    span,
    svg {
      transition: none;
    }
  }

  &.new_term {
    .percentage_tag {
      display: none;
    }
  }

  span {
    font-weight: 400;
    display: flex;
    align-items: center;
    transition: 0.4s ease all;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: ${theme.palette.primary.main};

    svg {
      margin-left: 2.5px;
      transition: 0.1s ease all;
    }
  }


  &.current {
    background: ${theme.palette.blue[50]};

    span {
      justify-content: center;
    }

    &:focus,
    &.term_clicked {
      background: ${theme.palette.primary.main};

      span {
        color: ${theme.palette.white};
      }
    }

    &.paid {
      background: #EBEEF1;
      span {
        color: ${theme.palette.gray[900]};
      }
    }
    
    &.span-delinquent {
      background: ${theme.palette.yellow[75]};
      
      span {
        color: ${theme.palette.gray[900]};
      }

      &.paid {
        background: #EBEEF1;
        span {
          color: ${theme.palette.gray[900]};
        }
      }
    }
  }

  &.old {
    background: ${theme.palette.yellow[75]};
    color: ${theme.palette.gray[900]};
    padding-right: 0px;
    pointer-events: auto;
    cursor: pointer;

    span {
      color: ${theme.palette.gray[900]};
      justify-content: center;
    }

    &:focus,
    &.term_clicked {
      background: ${theme.palette.primary.main};

      span {
        color: #fff;
      }
    }
  }

  .percentage_tag {
    top: 0;
    right: 0;
    position: absolute;
    display: inline-flex;
    

    span {
      position: relative;
      z-index: 1;
      color: ${theme.palette.white} !important;
      font-weight: 700;
      line-height: 1;
    }

    &:before {
      content: '';
      right: -20px;
      width: 20.21px;
      height: 60.46px;
      position: absolute;
      background: rgba(11, 24, 72, 0.8);
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-top: ${theme.spacing(1)};
    height: 14px;
    border-radius: 8px;

    &.current {
      .enable_edit {
        min-width: 68px;
      }
      
      .disable_edit {
        min-width: 62px;
      }

      .term_name {
        display: inline-flex;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        justify-content: center;
        padding: 0;
      }
    }

    &.old {
      .enable_edit {
        min-width: 67px;
      }

      .disable_edit {
        min-width: 56px;
      }
    }

    span {
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
    }

    .percentage_tag {
      span {
        font-size: 7px;
        line-height: 7px;
        font-weight: 700;
        top: 2px;
        right: 2px;
      }
  
      &:before {
        top: -15px;
        transform: rotate(-36deg);
      }
    }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    height: 20px;
    border-radius: 12px;

    &.current {
      .enable_edit {
        min-width: 78px;
      }

      .disable_edit {
        min-width: 68px;
      }

      .term_name {
        display: inline-flex;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        justify-content: center;
        padding: 0;
      }

      &.old_term {
        .enable_edit,
        .disable_edit {
          min-width: 68px;
        }
      }
    }

    &.old {
      .enable_edit {
        min-width: 78px;
      }

      .disable_edit {
        min-width: 68px;
      }
    }

    span {
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
    }

    .percentage_tag {
      span {
        font-size: 8px;
        line-height: 8px;
        font-weight: 700;
        top: 3px;
        right: 2px;
      }
  
      &:before {
        top: -10px;
        transform: rotate(-34deg);
      }
    }
  }
`
);

export const Separator = styled("span")(
  ({ theme }) => `
  &:before {
    content: '|';
    margin:  0 4px;
  }
`
);
