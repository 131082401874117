import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Header,
  HeaderFixed,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";
import { MaintenanceRequestForm } from "./From/Form";

export const MaintainanceRequestCreateScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const { isFromHome } = location.state
    ? (location.state as { isFromHome: boolean })
    : { isFromHome: false };

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible
          paddingX={0}
          headerText={t("requests.addOrUpdateRequest.newRequestTitle")}
          withCenteredHeaderText
          onBackButtonClick={() => navigate(isFromHome ? "/home" : "/requests")}
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          <MaintenanceRequestForm t={t} navigate={navigate} />
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
