import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Header,
  HeaderFixed,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";
import { AddBankAccountForm } from "./Form/AddBankAccountForm";
import { useGetBalanceDetailsQuery } from "src/store/services/privateApi";
import { CONSTANT_PAYMENT_STATUSES } from "src/shared/utilities/constants";
import { getAccountLinkUrl } from "src/helpers/helperRent";
import { Box, CircularProgress } from "@mui/material";

export const RentLinkedAccountsCreateBankScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [isOnlyCard, setIsOnlyCard] = useState(true);

  const { isFromPayment, isFromHome } = location.state
    ? (location.state as {
        isFromPayment: boolean;
        isFromHome: boolean;
      })
    : { isFromPayment: false, isFromHome: false };

  const {
    data: balanceData,
    isSuccess: isSuccessBalanceData,
    isLoading: isLoadingBalanceData,
  }: any = useGetBalanceDetailsQuery(null);

  useEffect(() => {
    if (isSuccessBalanceData && balanceData) {
      if (balanceData?.paymentStatus !== CONSTANT_PAYMENT_STATUSES.ACCEPT) {
        navigate(getAccountLinkUrl(balanceData?.paymentStatus), {
          state: {
            isOnlyCard:
              balanceData?.paymentStatus ===
              CONSTANT_PAYMENT_STATUSES.CERTIFIED_FUNDS,
          },
        });
      } else {
        setIsOnlyCard(false);
      }
    }
  }, [balanceData, isSuccessBalanceData, navigate]);

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible
          paddingX={0}
          headerText={t("rent.common.linkedListHeading")}
          withCenteredHeaderText
          onBackButtonClick={() =>
            navigate(
              isFromPayment
                ? "/rent/payment"
                : isFromHome
                ? "/home"
                : "/rent/linked-accounts"
            )
          }
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        {isLoadingBalanceData && (
          <Box
            sx={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: isLoadingBalanceData
                ? "rgba(255, 255, 255, 0.5)"
                : "rgba(255, 255, 255, 0.4)",
              borderRadius: "8px",
            }}
          >
            <CircularProgress
              color="primary"
              size={"40px"}
              sx={{
                position: "absolute",
              }}
            />
          </Box>
        )}

        {!isLoadingBalanceData && (
          <WrpperAuthorizedContainer>
            <AddBankAccountForm
              navigate={navigate}
              t={t}
              isFromPayment={isFromPayment}
              isOnlyCard={isOnlyCard}
            />
          </WrpperAuthorizedContainer>
        )}
      </Wrapper>
    </>
  );
};
