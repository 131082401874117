import { Typography } from "@mui/material";
import {
  InfoRoundedIcon,
  LockedIcon,
} from "src/components/Media/Icon/Icons/Icons";
import { NotTextWrapper } from "./NoteText.style";
import { NoteTextProps } from "src/shared/models/component/base.model";

export const NoteContent = ({ t }: any) => {
  return (
    <>
      {t("noteBlock.contentPrefix")}{" "}
      <a href="mailto:support@tenantport.com">support@tenantport.com</a>{" "}
      {t("noteBlock.contentPostfix")}
    </>
  );
};

export const NoteText = ({
  iconType = "InfoRoundedIcon",
  children,
  isBorder = true,
  t,
}: NoteTextProps) => {
  const handleIconTypes = (_iconType: string | undefined) => {
    switch (_iconType) {
      case "InfoRoundedIcon":
        return (
          <InfoRoundedIcon width={"16"} height={"16"} fillColor="#4E4F5E" />
        );
      case "LockedIcon":
        return <LockedIcon />;
      default:
        break;
    }
  };

  return (
    <NotTextWrapper className={`note ${!isBorder ? "no_border" : "is_border"}`}>
      <>
        <div className="icon_wrapper">{handleIconTypes(iconType)}</div>
        <Typography variant="h1" color={"gray.700"}>
          {children ? children : <NoteContent t={t} />}
        </Typography>
      </>
    </NotTextWrapper>
  );
};
