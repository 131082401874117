import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, createSearchParams } from "react-router-dom";

import {
  DataGridWithToolBarCard,
  Header,
  HeaderFixed,
  ManualLoader,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";
import { set_old_date_fortransaction_history } from "src/helpers/helperRent";
import { todayDateYYYYMMDD } from "src/shared/utilities/dateConvertions";
import { useGetTransactionsQuery } from "src/store/services/privateApi";

export const RentActivityListScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState<string>("");
  const [filterSchema, setFilterSchema] = useState({
    startDate: null,
    endDate: null,
  });

  const { data, isSuccess, isLoading, isFetching }: any =
    useGetTransactionsQuery({
      startDate: filterSchema.startDate
        ? filterSchema.startDate
        : set_old_date_fortransaction_history,
      endDate: filterSchema.endDate
        ? filterSchema.endDate
        : todayDateYYYYMMDD(),
    });

  const handleSearch = (event: { target: { value: any } }) => {
    setSearchText(event.target.value);
  };

  const handleResetSearch = () => {
    setSearchText("");
  };

  return (
    <>
      {(isLoading || isFetching) && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible
          paddingX={0}
          headerText={t("rent.rentActionCard.buttonThreeText")}
          onBackButtonClick={() => navigate("/rent")}
          withCenteredHeaderText
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          {isSuccess && (
            <DataGridWithToolBarCard
              t={t}
              dataObject={data?.transactions}
              dataGridCardTitle={t("rent.common.gridHeading")}
              iconArrowVisible={false}
              isHandleArrowClickable={false}
              navigate={navigate}
              routeState={{ backRouteUrl: "/rent/activity/list" }}
              onHandleResetSearch={handleResetSearch}
              onHandleSearch={handleSearch}
              searchText={searchText}
              isTooltipInfoEnabled
              tooltipContent={`Activity shows all charges to your account and payments made by you.`}
              itemCount={data ? `${data?.transactions.length}` : "0"}
              setFilterSchema={setFilterSchema}
              createSearchParams={createSearchParams}
              isDataFetching={isFetching}
              gridType="activity"
              searchInputPlaceHolder={"Search for activity"}
            />
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
