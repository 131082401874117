import { styled } from "@mui/material/styles";

export const ReviewCardWrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const StatusContainer = styled("div")`
  margin-right: 25px;
`;

export const ReviewCardTop = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
`;

export const ReviewCardContainer = styled("div")(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  
  .edit-empty {
    margin-top: -8px;
  }

  .ssn_provided_text_verified {
    .icon_wrapper {
      display: none;
    }

    * {
      font-size: 10px;
    }

    .verified,
    .pending {
      height: 16px;
      margin-left: 4px;
    }
  }

  .ssn_provided_text {
    display: flex;
    align-itmes: center;

    h1 {
      margin: 0;
    }

    .icon_wrapper {
      margin-left: 8px;

      svg {
        width: 14px;
        height: 14px;
        color: ${theme.palette.primary.main};
      }
    }
  }
`
);

export const EditIconWrapper = styled("div")(
  ({ theme }) => `
  width: 12px;
  height: 12px;
  right: 12px;
  top: 24px;
  position: absolute;
  cursor: pointer;
  
  svg * {
    fill: ${theme.palette.primary.main};
  }

  &.is_shadow_iconed {
    right: 0;
    top: 0;
  }
`
);
