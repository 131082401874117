import { Button, Typography } from "@mui/material";
import ReactDOM from "react-dom";
import {
  ModalWrapper,
  ModalOverlay,
  ModalContainer,
  YesOrNoConfirmationModalContainerBottom,
  YesOrNoConfirmationModalContainerTop,
} from "./YesOrNoConfirmationModal.style";
import classNames from "classnames";

const domNode: any = document.getElementById("app-root");

export interface YesOrNoConfirmationModalProps {
  onCloseEvent?: () => void;
  children?: React.ReactNode;
  hideClose?: boolean;
  isMobileBottomFixed?: boolean;
  modalTitle?: string;
  modalWrapperClass?: string;
  content?: string;
  onClickLeftBtnEvent?: () => void;
  onClickRightBtnEvent?: () => void;
  leftButtonText?: string;
  rightButtonText?: string;
  isRightButtonVisible?: boolean;
}

function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
}

window.addEventListener("resize", appHeight);
appHeight();

export const YesOrNoConfirmationModal = ({
  onCloseEvent,
  children,
  isMobileBottomFixed = true,
  modalTitle,
  modalWrapperClass,
  content,
  onClickLeftBtnEvent,
  onClickRightBtnEvent,
  leftButtonText = "Yes",
  rightButtonText = "No",
  isRightButtonVisible = true,
}: YesOrNoConfirmationModalProps) => {
  return ReactDOM.createPortal(
    <ModalWrapper>
      <ModalOverlay onClick={onCloseEvent} />
      <ModalContainer
        className={classNames([
          isMobileBottomFixed ? "is_fixed" : "mobile_bottom__not_fixed",
          modalWrapperClass ? `${modalWrapperClass}_container` : "",
        ])}
      >
        <YesOrNoConfirmationModalContainerTop>
          {modalTitle && (
            <Typography
              variant="h3"
              component={"p"}
              color={"gray.800"}
              textAlign={"center"}
              marginBottom={6}
            >
              {modalTitle}
            </Typography>
          )}
          {content && (
            <Typography
              variant="h2"
              component={"p"}
              color={"gray.700"}
              textAlign={"center"}
              marginBottom={4}
              fontWeight={300}
            >
              {content}
            </Typography>
          )}
        </YesOrNoConfirmationModalContainerTop>
        <YesOrNoConfirmationModalContainerBottom>
          <Button
            variant="text"
            sx={{
              borderRadius: "0px 0px 0px 14px",
              color: "gray.700",
            }}
            onClick={onClickLeftBtnEvent}
            type="button"
          >
            {leftButtonText}
          </Button>
          {isRightButtonVisible && (
            <Button
              variant="text"
              className="button_right"
              sx={{
                borderRadius: "0px 0px 14px 0px",
                color: "primary",
              }}
              onClick={onClickRightBtnEvent}
              type="button"
            >
              {rightButtonText}
            </Button>
          )}
        </YesOrNoConfirmationModalContainerBottom>
        {children}
      </ModalContainer>
    </ModalWrapper>,
    domNode
  );
};
