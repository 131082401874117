import { useState, useEffect, useRef } from "react";

export const useGetBlockHeight = () => {
  // This ref is connected to the list
  const elementRef: any = useRef();

  // The height of the block
  // It will be updated later
  const [height, setHeight]: any = useState();

  // This function calculates width and height of the list
  const getBlockHeight = () => {
    const newHeight: any = elementRef.current.clientHeight;

    if (newHeight) {
      setTimeout(() => {
        setHeight(newHeight);
      }, 50);
    }
  };

  useEffect(() => {
    getBlockHeight();
  }, []);

  return [elementRef, height];
};
