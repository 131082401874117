import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";

import {
  FormAuthorizedWrapper,
  ShadowCard,
  ButtonAuthorizedWrapper,
  FromAutoCompleteSelect,
  FormTextArea,
  FormInput,
  ManualLoader,
  Messages,
  ConfirmationModal,
} from "src/components";

import {
  CreateOrderBodyParams,
  MaintainanceRequestCreateParams,
} from "src/shared/models/containers/request.model";

import { ERROR_MESSAGES } from "src/shared/utilities/validationMessages";
import { updateBindingsChange } from "src/shared/utilities/formUtilities";
import {
  dateFormatYYYYspaceMMspaceDDspaceTime,
  dateFormatYYYYspaceMMspaceDDspaceTimeAddMoreDays,
} from "src/shared/utilities/dateConvertions";

import {
  useCreateWorkOrderMutation,
  useGetWorkOrderCategoriesMutation,
} from "src/store/services/privateApi";

import { resetTheCreateRequestFormFields } from "src/helpers/helperRequests";
import { areasListData } from "src/assets/workOrdersData";

import { MaintenanceRequestFormProps } from "src/shared/models/containers/request.model";

export const exceptionalCategory = "General";
export const MaintenanceRequestForm = ({
  t,
  navigate,
  isUpdateRequest,
  requestData,
  onSubmitSupportEvent,
}: MaintenanceRequestFormProps) => {
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [categoriesList, setCategoriesList] = useState<any>();
  const [issueTypeList, setIssueTypeList] = useState<any>();
  const [categoriesResponse, setCategoriesResponse] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isOtherissueTypeSelected, setIsOtherissueTypeSelected] =
    useState<boolean>(false);

  const [getCategoriesMutation, { isLoading: isLoadingGetCategories }] =
    useGetWorkOrderCategoriesMutation();
  const [
    createWorkOrderMutation,
    { isLoading: isLoadingCreateOrder, data: createOrderResponseData },
  ] = useCreateWorkOrderMutation();

  /**
   * function to get categories
   */
  const getCategoriesFunction = async () => {
    const response: any = await getCategoriesMutation(null);

    if (response && response?.data) {
      const apiCategoryResponse = response?.data;
      const responseForCategoryDropDown =
        apiCategoryResponse &&
        apiCategoryResponse.map((node: { name: string }) => ({
          label: node.name,
        }));
      setCategoriesResponse(apiCategoryResponse);
      setCategoriesList(responseForCategoryDropDown);

      if (isUpdateRequest && requestData) {
        const getCategoryFromData = requestData?.Category;
        const getIssuTypeFormData = requestData?.title;

        const selectedCategoryNode =
          apiCategoryResponse &&
          apiCategoryResponse.length > 0 &&
          apiCategoryResponse.filter(
            (node: { name: string }) => node.name === getCategoryFromData
          );

        if (selectedCategoryNode && selectedCategoryNode.length === 1) {
          setValue("categoryId", getCategoryFromData);

          const selectedSubCategories =
            selectedCategoryNode[0].subCategories ?? "";

          if (selectedSubCategories && selectedSubCategories.length > 0) {
            const responseForIssueTypeDropDown = selectedSubCategories.map(
              (node: string) => ({
                label: node,
              })
            );

            setIssueTypeList(responseForIssueTypeDropDown);
            if (
              responseForIssueTypeDropDown &&
              responseForIssueTypeDropDown.length > 0
            ) {
              const selectedIssueTypeNode = responseForIssueTypeDropDown.filter(
                (node: { label: string }) => node.label === getIssuTypeFormData
              );

              if (selectedIssueTypeNode && selectedIssueTypeNode.length === 1) {
                setValue("title", getIssuTypeFormData);
              } else {
                setValue("title", "Other");
                setIsOtherissueTypeSelected(true);
                setValue("optionalTitle", getIssuTypeFormData);
              }
            }
          }
        } else {
          setValue("categoryId", exceptionalCategory);
          setValue("title", "Other");
          setIsOtherissueTypeSelected(true); //
          setValue("optionalTitle", getIssuTypeFormData);
        }
      }
    } else {
      setErrorMessage(
        response?.error?.data?.error ?? response?.error?.data?.message
      );
    }
  };

  /**
   * getCategoryId for on CHange of category autocomplete component
   */
  const getSubCategoriesForOnChange = (selectedCategory: string = "") => {
    if (selectedCategory !== "") {
      if (categoriesResponse && categoriesResponse.length > 0) {
        const selectedCategoryNode = categoriesResponse.filter(
          (node: { name: string }) => node.name === selectedCategory
        );

        const selectedSubCategories =
          selectedCategoryNode[0].subCategories ?? "";

        if (selectedSubCategories && selectedSubCategories.length > 0) {
          const responseForIssueTypeDropDown = selectedSubCategories.map(
            (node: string) => ({
              label: node,
            })
          );
          setIssueTypeList(responseForIssueTypeDropDown);
        }
      }
    }
  };

  /**
   * Handle in the initial load
   */
  useEffect(() => {
    getCategoriesFunction();

    if (isUpdateRequest) {
      if (requestData) {
        setValue("areas", requestData?.Areas ? requestData?.Areas[0] : "");
        setValue("description", requestData?.description ?? "");
        setValue("status", requestData?.Status);

        if (requestData?.Appointment === "Permission Given") {
          setValue("appointment", requestData?.Appointment ?? "");
          setIsConsentChecked(true);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateRequest, requestData]);

  /**
   * define the validation schema for form validation
   */
  const maintainanceRequestCreateFormSchema = yup
    .object({
      areas: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED),
      title: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED)
        .max(150, ERROR_MESSAGES.TITLE_MAX_LIMIT),
      categoryId: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED),
      description: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED)
        .max(4000, ERROR_MESSAGES.DESCRIPTION_MAX_LIMIT),
      optionalTitle: yup.string().when("front", {
        is: () => {
          if (!isOtherissueTypeSelected) {
            return false;
          } else {
            return true;
          }
        },
        then: yup
          .string()
          .typeError(ERROR_MESSAGES.REQUIRED)
          .required(ERROR_MESSAGES.REQUIRED)
          .max(150, ERROR_MESSAGES.TITLE_MAX_LIMIT),
      }),
      status: yup.string().when("front", {
        is: () => {
          if (!isUpdateRequest) {
            return false;
          } else {
            return true;
          }
        },
        then: yup
          .string()
          .typeError(ERROR_MESSAGES.REQUIRED)
          .required(ERROR_MESSAGES.REQUIRED),
      }),
    })
    .required();

  /**
   * initialize the hook form
   */
  const {
    register,
    handleSubmit,
    setValue,
    // getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<MaintainanceRequestCreateParams>({
    resolver: yupResolver(maintainanceRequestCreateFormSchema),
  });

  /**
   * handle the create request form submition
   */
  const formSubmit = handleSubmit(async (data) => {
    const currentDate = dateFormatYYYYspaceMMspaceDDspaceTime(new Date());
    const setDueDate = dateFormatYYYYspaceMMspaceDDspaceTimeAddMoreDays(
      new Date(),
      4
    );

    let setCreateOrderBody: CreateOrderBodyParams = {
      reportedDate:
        isUpdateRequest && requestData ? requestData.ReportedDate : currentDate,
      title: !isOtherissueTypeSelected
        ? `${data.title}`
        : `${data.optionalTitle}`,
      categoryId: `${data.categoryId}`,
      areas: `${data.areas}`,
      description: `${data.description}`,
      priority: isUpdateRequest && requestData ? requestData.Priority : "High",
      dueDate:
        isUpdateRequest && requestData ? requestData.DueDate : setDueDate,
    };

    if (data.appointment === "Permission Given") {
      setCreateOrderBody = {
        ...setCreateOrderBody,
        appointment: data.appointment,
      };
    } else if (
      isUpdateRequest &&
      data.appointment !== "Permission Given" &&
      requestData
    ) {
      setCreateOrderBody = {
        ...setCreateOrderBody,
        appointment: requestData?.Appointment,
      };
    }

    if (isUpdateRequest && requestData) {
      setCreateOrderBody = {
        ...setCreateOrderBody,
        phone: requestData.Phone ?? "",
        status: data.status,
        workorderId: requestData?.WorkOrderID,
      };
    }

    const response: any = await createWorkOrderMutation(setCreateOrderBody);

    if (response && response?.data) {
      setIsConsentChecked(false);
      setConfirmationModalOpen(true);
      setIssueTypeList(null);
      resetTheCreateRequestFormFields(setValue);

      if (isOtherissueTypeSelected) {
        setValue("optionalTitle", "");
        setIsOtherissueTypeSelected(false);
      }
    } else {
      setErrorMessage(
        response?.error?.data?.error ??
          response?.error?.data?.message ??
          t("requests.addOrUpdateRequest.enterValidDetailMessage")
      );
    }
  });

  /**
   * Update onchange consent
   */
  const onChangeConsent = (event: any) => {
    const isConsentFieldChecked = event.target.checked;
    if (isConsentFieldChecked) {
      setValue("appointment", "Permission Given");
      setIsConsentChecked(true);
    } else {
      setIsConsentChecked(false);
      setValue("appointment", "");
    }
  };

  /**
   * Click event for confirmation modal submit button
   */
  const onSubmitConfirmationModalBtn = () => {
    if (isUpdateRequest) {
      onSubmitSupportEvent && onSubmitSupportEvent();
    } else {
      navigate("/requests");
    }
    setConfirmationModalOpen(false);
  };

  return (
    <>
      {(isLoadingGetCategories || isLoadingCreateOrder) && <ManualLoader />}
      <FormAuthorizedWrapper onSubmit={formSubmit}>
        <ShadowCard padding={4} marginBottom={6}>
          <Controller
            control={control}
            name="areas"
            render={({ field: { onChange, value } }) => {
              return (
                <FromAutoCompleteSelect
                  label={t("formInput.areas")}
                  id={"areas"}
                  {...register("areas")}
                  placeholder={t("placeHolder.areas")}
                  error={errors?.areas?.message}
                  inputValue={value || ""}
                  onChange={(_event: any, selectedOption: any) => {
                    if (selectedOption) {
                      onChange(selectedOption.label);
                    }
                  }}
                  options={areasListData}
                  type="shadowed"
                />
              );
            }}
          />
          {categoriesList && categoriesList.length > 0 && (
            <Controller
              control={control}
              name="categoryId"
              render={({ field: { onChange, value } }) => {
                return (
                  <FromAutoCompleteSelect
                    label={t("formInput.category")}
                    id={"categoryId"}
                    {...register("categoryId")}
                    placeholder={t("placeHolder.category")}
                    error={errors?.categoryId?.message}
                    inputValue={value || ""}
                    onChange={(_event: any, selectedOption: any) => {
                      if (selectedOption) {
                        onChange(selectedOption.label);
                        getSubCategoriesForOnChange(selectedOption.label);
                      }
                    }}
                    options={categoriesList}
                    type="shadowed"
                  />
                );
              }}
            />
          )}

          {issueTypeList && (
            <Controller
              control={control}
              name="title"
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    {issueTypeList.length === 0 && (
                      <FormInput
                        label={t("formInput.issueType")}
                        id={"title"}
                        placeholder={t("placeHolder.issueType")}
                        {...register("title")}
                        error={errors?.title?.message}
                        onChange={(event: any) => {
                          updateBindingsChange(event, "title", setValue);
                        }}
                        value={value}
                        inputFocus={(e: any) => {
                          trigger("title");
                        }}
                        inputVariant="shadowed"
                      />
                    )}

                    {issueTypeList.length > 0 && (
                      <FromAutoCompleteSelect
                        label={t("formInput.issueType")}
                        id={"title"}
                        {...register("title")}
                        placeholder={t("placeHolder.issueType")}
                        error={errors?.title?.message}
                        inputValue={value || ""}
                        onChange={(_event: any, selectedOption: any) => {
                          if (selectedOption) {
                            onChange(selectedOption.label);
                            if (selectedOption.label === "Other") {
                              setIsOtherissueTypeSelected(true);
                            } else {
                              setIsOtherissueTypeSelected(false);
                            }
                          } else {
                            setIsOtherissueTypeSelected(false);
                          }
                        }}
                        inputFocus={(e: any) => {
                          trigger("title");
                        }}
                        options={issueTypeList}
                        type="shadowed"
                      />
                    )}
                  </>
                );
              }}
            />
          )}

          {isOtherissueTypeSelected && (
            <Controller
              control={control}
              name="optionalTitle"
              render={({ field: { value } }) => {
                return (
                  <FormInput
                    label={""}
                    id={"optionalTitle"}
                    placeholder={t("placeHolder.otherIssueType")}
                    {...register("optionalTitle")}
                    error={errors?.optionalTitle?.message}
                    onChange={(event: any) => {
                      updateBindingsChange(event, "optionalTitle", setValue);
                    }}
                    value={value}
                    inputFocus={(e: any) => {
                      trigger("optionalTitle");
                    }}
                    inputVariant="shadowed"
                  />
                );
              }}
            />
          )}

          {isUpdateRequest && (
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => {
                return (
                  <FromAutoCompleteSelect
                    label={"Status"}
                    id={"status"}
                    {...register("status")}
                    placeholder={t("placeHolder.status")}
                    error={errors?.status?.message}
                    inputValue={value || ""}
                    onChange={(_event: any, selectedOption: any) => {
                      if (selectedOption) {
                        onChange(selectedOption.label);
                      }
                    }}
                    options={[{ label: "Cancelled" }, { label: "Submitted" }]}
                    type="shadowed"
                  />
                );
              }}
            />
          )}

          <Controller
            control={control}
            name="description"
            render={({ field: { value } }) => {
              return (
                <FormTextArea
                  label={t("formInput.description")}
                  id={"description"}
                  {...register("description")}
                  placeholder={t("placeHolder.description")}
                  error={errors?.description?.message}
                  onChange={(event: any) => {
                    updateBindingsChange(event, "description", setValue);
                  }}
                  inputFocus={(e: any) => {
                    trigger("description");
                  }}
                  value={value}
                />
              );
            }}
          />
          <FormControlLabel
            sx={{ marginBottom: 1 }}
            control={
              <Checkbox
                name="confirm_subscription"
                size="small"
                onChange={onChangeConsent}
                checked={isConsentChecked}
              />
            }
            label={
              <Typography variant="h2" color={"gray.700"}>
                {t("formInput.consentCheck")}
              </Typography>
            }
          />
          {errorMessage !== "" && (
            <Messages
              messageHeading={
                !isUpdateRequest
                  ? t("requests.addOrUpdateRequest.newRequestTitle")
                  : t("requests.addOrUpdateRequest.updateRequestTitle")
              }
              messageContent={errorMessage}
              closeEvent={() => setErrorMessage("")}
              bottomMargin={0}
              topMargin={4}
            />
          )}
        </ShadowCard>

        <ButtonAuthorizedWrapper>
          <Button
            variant="containedLarge"
            color="primary"
            fullWidth
            type="submit"
            sx={{ overflow: "hidden" }}
          >
            {!isUpdateRequest && t("buttonTexts.submitButton")}
            {isUpdateRequest && t("buttonTexts.updateButton")}
          </Button>
        </ButtonAuthorizedWrapper>
      </FormAuthorizedWrapper>
      {confirmationModalOpen && (
        <ConfirmationModal
          onFormSubmit={onSubmitConfirmationModalBtn}
          title={t("requests.addOrUpdateRequest.confirmationModalTitle")}
          content={
            isUpdateRequest
              ? t(
                  "requests.addOrUpdateRequest.confirmationModalContentForUpdate",
                  {
                    number: createOrderResponseData
                      ? createOrderResponseData[0]?.Number
                      : "",
                  }
                )
              : t(
                  "requests.addOrUpdateRequest.confirmationModalContentForNew",
                  {
                    number: createOrderResponseData
                      ? createOrderResponseData[0]?.Number
                      : "",
                  }
                )
          }
          btnTitle={t("buttonTexts.okButton")}
          hideClose={true}
        />
      )}
    </>
  );
};
