import { InputHTMLAttributes } from "react";
import { ButtonProps } from "@mui/material/Button";
import { TFunction } from "react-i18next";
import { NavigateFunction } from "react-router-dom";
import { SxProps, Theme } from "@mui/material";

export type textAlign = "center" | "left" | "right";
export type InputType = "text" | "email" | "date" | "password";

export type StatusChipsType =
  | "Submitted"
  | "Not Started"
  | "In Progress"
  | "Completed"
  | "Scheduled"
  | "Cancelled"
  | "Closed"
  | "On Hold"
  | "Payment"
  | "Deposit"
  | "Charge"
  | "On Time"
  | "Delayed"
  | "Delinquent";

export type ShadowCardTypes =
  | "is_transparent"
  | "no_shadow"
  | "remove_all_shadow"
  | "full_border"
  | "default";
export interface childrenInterface {
  children?: React.ReactNode;
}

export interface HeadingContentInterface {
  heading?: string;
  content?: any;
  contentNewLine?: any;
}

export interface NavigateMethodProps {
  navigate: NavigateFunction;
}

export interface TranslatemethodProps {
  t?: TFunction;
}

export interface HeadingSummaryBlockProps extends HeadingContentInterface {
  spacing?: number;
  headingAlignment?: textAlign;
  mobileHeadingAlignment?: textAlign;
  contentAlignment?: textAlign;
  mobileContentAlignment?: textAlign;
}

export interface FormEventsProps {
  onCancelButton?: () => void;
  onFormSubmit?: () => void;
  onBackButtonClick?: () => void;
}

export interface translationProps {
  t: TFunction;
}

export interface VerfyCodeInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  error?: any;
  onChange?: any;
  isVerified?: any;
  handleOnKeydown?: any;
  errorMsg?: string;
  isWrong?: boolean;
  coupaError?: boolean;
  isValid?: boolean;
  onClickResendOtp?: () => void;
  otp?: string | number;
}

export interface ProgreeBarProps {
  totalProgressSteps?: number;
  currentProgressStep?: number;
  isProgressInfoVisible?: boolean;
  progressValue?: string;
  progressLabel?: string;
  overallProgressValue?: string;
  overallProgressLabel?: string;
  progressBarHeight?: string | number;
  sxProgressValue?: any;
  sxProgressLabel?: any;
}

export interface ProgressCardProps extends ProgreeBarProps {
  title?: string;
  stepsContent?: string;
  isBorderCardEnabled?: boolean;
}

export interface LimitProgressCardProps extends ProgreeBarProps {
  title?: string;
  stepsContent?: string;
  isLeftIconEnabled?: boolean;
  icon?: string;
  marginBottom?: string | number;
}

export interface LogoProps {
  isLogoClickable?: boolean;
  logoMarginIndex?: string;
  isLogoOnlyForDesktop?: boolean;
}

export interface HeaderProps extends ProgreeBarProps, LogoProps {
  isBackButtonVisible?: boolean;
  isSkipButtonVisible?: boolean;
  isProgressBarVisible?: boolean;
  isLogoLeftAligned?: boolean;
  onBackButtonClick?: () => void;
  onSkipButtonClick?: () => void;
  headerHeight?: number | string;
  paddingX?: number;
  backButtonId?: string;
  isLogoVisible?: boolean;
  isLanguageSwitcher?: boolean;
  isMainMunuVisible?: boolean;
  isBackButtonHide?: boolean;
  isSkipButtonHide?: boolean;
  headerText?: string;
  withCenteredHeaderText?: boolean;
  headerPaddingTop?: string | number;
  headerPaddingBottom?: string | number;
  isEditIconVisible?: boolean;
  onClickEditIcon?: () => void;
  bottomHighlightContent?: string;
  isBottomContentVisible?: boolean;
  isHeaderBottomFixedInDesktopView?: boolean;
  isCompletedKYC?: boolean;
}

export interface ContentWrapperProps extends childrenInterface {
  heightForDeduction?: string | number;
  minHeight?: number;
}

export interface ContentContainerProps extends childrenInterface {
  heightForDeduction?: string | number;
  minHeight?: number;
  isDisabled?: boolean;
  buttonLablel?: any;
  isNoteTextVisible?: boolean;
  onButtonClick?: () => void;
  onFormSubmitClick?: () => void;
  autocomplete?: "on" | "off";
  isFullScreen?: boolean;
}

export interface FormWrapperProps extends childrenInterface {
  heightForDeduction?: string | number;
  minHeight?: number;
  isDisabled?: boolean;
  buttonLablel?: any;
  isNoteTextVisible?: boolean;
  onButtonClick?: () => void;
  onFormSubmit?: () => void;
  autocomplete?: "on" | "off";
  isFullScreen?: boolean;
  noValidate?: any;
}

export interface CommonStatusContainerProps
  extends ContentContainerProps,
    HeadingSummaryBlockProps {
  statusImagePath?: string;
  lottieAnimationPath?: any;
  loop?: boolean;
}

export interface WrapperProps extends childrenInterface {
  type?: "xs" | "md" | "sm" | "lg" | "xl" | "android" | "iPhone";
  isFullScreen?: boolean;
  isAuthorizedWrapper?: boolean;
  iAuthorizedHeaderDesktopFixed?: boolean;
  isTabNavigationAvailable?: boolean;
  isSettingDesktop?: boolean;
  idSelector?: string;
}

export interface InputProps {
  id: string;
  label?: string;
  type?: InputType;
  placeholder?: string;
  htmlFor?: string;
  error?: any;
  isValid?: boolean;
  highLightText?: string;
  maxWidth?: string;
  inputMode?:
    | "none"
    | "numeric"
    | "tel"
    | "decimal"
    | "email"
    | "url"
    | "search"
    | "text";
  value?: any;
  onChange?: any;
  register?: any;
  name?: string;
  maxLength?: number;
  onPaste?: any;
  onCopy?: any;
  onCut?: any;
  readonly?: boolean;
  autoFocus?: boolean;
  autoComplete?: "off" | "on";
  trackClicks?: boolean;
  isValidationIconVisible?: boolean;
  inputFocus?: any;
  disableCopyPaste?: boolean;
  onKeyPress?: any;
  isAmountField?: boolean;
  inputVariant?: "shadowed" | "default";
  isErrorMessageVisible?: boolean;
}

export interface MaskInputProps extends InputProps {
  inputError?: any;
  autoFocus?: boolean;
  isShrink?: boolean;
  replace?: boolean;
  inputFocus?: any;
}
export interface EINProps extends InputProps {
  inputError?: any;
  autoFocus?: boolean;
  isShrink?: boolean;
  replace?: boolean;
  setEnableEIN?: any;
  inputFocus?: any;
  inValidEIN?: boolean;
}

export interface SSNInputProps extends InputProps {
  inputError?: any;
  only4?: boolean;
  replace?: boolean;
  setEnableSSNUpdate?: any;
  ssnNotProvided?: boolean;
  ssnVerificationFailed?: boolean;
}

export type FormInputErrorMessageProps = childrenInterface;

export interface FloatingButtonProps extends childrenInterface, ButtonProps {
  isLogin?: boolean;
  constainerStyle?: any;
  paddingX?: number;
  paddingY?: number;
}

export interface MessagesProps {
  messageHeading?: string;
  messageContent?: string;
  messageLinkText?: string;
  messageLinkEvent?: any;
  closeEvent?: () => void;
  isDesktopView?: boolean;
  hideCloseIcon?: boolean;
  topMargin?: string | number;
  bottomMargin?: string | number;
  type?:
    | "neutral"
    | "warning"
    | "success"
    | "error"
    | "delinquent"
    | "default"
    | "info";
  isContentClickabale?: boolean;
  onClickContent?: () => void;
}

export type NotificationMessagesVariants =
  | "rent_reporting_disabled"
  | "no_payment_methods"
  | "missing_payment_status";

export interface NotificationMessagesProps extends MessagesProps {
  variant?: NotificationMessagesVariants;
  isMessageClickable?: boolean;
}

export interface NaviagtionCardsProps extends childrenInterface {
  isArrowVisible?: boolean;
  onClickEvent?: () => void;
  isIconVisible?: boolean;
}

export interface FaqBlockProps extends HeadingContentInterface {
  iconType?: "MaintenanceRequest" | "MakeAPayment" | "RentReporting";
}

export interface AccountVerificationStatussesProps {
  type?: "restricted" | "pending" | "verified" | "unverified" | any;
}

export interface ReviewCardProps extends AccountVerificationStatussesProps {
  title?: string;
  editIconClickEvent?: () => void;
  name?: string;
  children?: React.ReactNode;
  hideEdit?: boolean;
  isInnerTitle?: boolean;
  isShadowWrappedIcon?: boolean;
}

export interface TermsTextProps {
  mainContent?: string;
  subContent?: string;
  marginIndex?: string;
}

export interface PayModeNoteProps {
  content?: string;
  marginIndex?: string;
  iconType?: string;
}

export interface FormEditSubmitionprops
  extends FormEventsProps,
    AccountVerificationStatussesProps {
  title?: string;
  setResult?: any;
  result?: any;
  setUpdateData?: any;
  navigate?: any;
  isSettings?: boolean;
  isShadowCard?: boolean;
  isVerified?: boolean;
  onFormSubmit?: any;
  isFromFinance?: boolean;
  isSkipSSNflow?: boolean;
}

export interface TermCardProps {
  cost?: any;
  date?: any;
  net?: any;
  isActive?: boolean;
  selectCard?: (val: any) => void;
}

export interface PayModeCardProps {
  id: string;
  modeName: string;
  feePercentage: string;
  onCardClick?: (id: string) => void;
  isActive?: boolean;
  comingSoon?: boolean;
  timeDuration?: string;
  isIconVisible?: boolean;
  feePercentageTextAlignment?: "right" | "left";
}
export interface PayModesInterface {
  id: string;
  modeName: string;
  feePercentage: string;
  active: boolean;
  time?: string;
}

export interface VerifyImageContentBlockProps extends HeadingSummaryBlockProps {
  imagePath?: string;
  headingBoxStyle?: any;
}

export interface ApiFailMessageProps {
  message?: string;
}

export interface AddressFieldsProps {
  onClick?: () => void;
  isSelected?: boolean;
  isHover?: boolean;
  isIconEnabled?: boolean;
  isIndividual?: boolean;
  isValid?: boolean;
  maxWidth?: any;
  setAddressStates?: any;
  addressStates?: any;
  register?: any;
  errors?: any;
  control?: any;
  getValue?: any;
  setValue?: any;
  trigger?: any;
  isVerifiedOnlyForSetting?: boolean;
  isPOBoxTextVisible?: boolean;
  highLightText?: string;
}

export type AddressFormProps = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal_code: string;
};

export const AddressDefaultValues = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  postal_code: "",
};

export interface DropdownStateProps
  extends React.SelectHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  htmlFor?: string;
  error?: any;
  isValid?: boolean;
  highLightText?: React.ReactNode;
  maxWidth?: string;
  defaultText?: string;
  options?: any;
  useId?: boolean;
  onChange?: (val: any) => void;
  onInputChange?: (val: any) => void;
  selectedState?: string;
  inputValue?: any;
  inputFocus?: any;
  readonly?: boolean;
}

export interface GoogleSearchAutoCompleteProps
  extends React.SelectHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  htmlFor?: string;
  error?: any;
  isValid?: boolean;
  highLightText?: React.ReactNode;
  maxWidth?: string;
  defaultText?: string;
  options?: (string | number)[];
  useId?: boolean;
  onSelect: (val: any) => void;
  onInputChange?: any;
  defaultValue?: string;
  inputFocus?: any;
  readonly?: boolean;
  value?: any;
}

export interface SettingsItemProps {
  title: string;
  onClick?: any;
  data: any;
  icon: string;
  buttonEnable: boolean;
  color: string;
  buttonText: string;
  id: string;
  subTitle?: string;
}

export interface InformationListProps {
  title?: string;
  onClick?: any;
  data?: any;
  logoutEvent?: any;
  newUpdatedTerm?: any;
  navType?: "settings" | "manage-cards";
  cardMarginTop?: string | number;
  cardPaddingRight?: string | number;
  cardPaddingLeft?: string | number;
  cardPaddingBottom?: string | number;
  cardPaddingTop?: string | number;
}

export interface RequestDetailProps extends translationProps {
  data?: any;
  isEditIconVisible?: boolean;
  onClickEditIcon?: () => void;
}

export interface TimeLineItemProps extends translationProps {
  itemName?: string;
  dateText?: string;
  timeText?: string;
  position: number;
  isActive?: boolean;
  hideConnector?: boolean;
}

export interface TimeLineNoteItemProps {
  itemName: string;
  description?: string;
  dateText: string;
  timeText: string;
  position: number;
  hasNext?: boolean;
}

export interface PaginationProps {
  limit?: number;
  totalCount?: number;
  offset?: number;
  pageCount?: number;
  handleShowMore?(param: any): void;
  tabType?: "submitted" | "paid" | "approved" | "transactions";
  selectedRowCount: number;
  onRowCountChange?(param: number): void;
  showOffsetChangeButtons?: boolean;
}

export interface RowCountDropDownProps {
  selectedRowCount?: number;
  onRowCountChange?(param: number): void;
}

export interface ISearchVendoDetails {
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
  email: string | null;
  phone: string | null;
}

export interface IDetailsForPhoneVerification {
  type?: "company" | "individual";
  phoneVerified?: boolean;
  phoneWorkVerified?: boolean;
  additionalPhoneVerified?: boolean;
  phone?: string;
  phoneWork?: string;
  additionalPhone?: string;
}

export type typeVerificationCodeFrom =
  | "individual_info"
  | "individual_review"
  | "individual_setting"
  | "business_info"
  | "business_personal_info"
  | "business_review"
  | "business_setting";

export interface IconValuesProps {
  width?: string | number;
  height?: string | number;
  strokeColor?: string;
  strokeWidth?: string;
  fillColor?: string;
}

export interface IPayupCard {
  data?: any;
  isCardSwipable?: boolean;
  isNameVisible?: boolean;
  isCardExpiryVisible?: boolean;
  isCardShowNumberVisible?: boolean;
  isCardCopyVisible?: boolean;
  isCardDotsIconVisible?: boolean;
  logoWidth?: string | number;
  logoHeight?: string | number;
  cardType?: "default" | "onlyNumber";
  cardShowNumberText?: string;
}

export interface ITransactionSummaryCard extends translationProps {
  valueType?: string;
  valueAccount?: string;
  valueRouting?: string;
  valueMethod?: string;
  valueId?: string;
  valueDescription?: string;
  valueMethodContent?: string;
  valueDestination?: string;
  isIncomingPayment?: boolean;
}

export interface ManageCardInfoModalProps extends translationProps {
  closeIdModalEvent?: () => void;
  onAcceptButtonClick?: () => void;
}

export interface CardAccountInfoModalProps extends ManageCardInfoModalProps {
  cardData?: any;
}

export interface ManageCardLimitsModalProps extends ManageCardInfoModalProps {
  cardData?: any;
  totalSpentAmount: number;
}

export interface EnabledDiabledRentReportingModalProps
  extends ManageCardInfoModalProps,
    translationProps {
  onFreezeCardOnchange?: any;
  defaultChecked?: boolean;
  isFreezeModalButtonDisabled?: boolean;
}

export interface AccountInfoUpdateFormModalProps extends translationProps {
  userData?: any;
  closeIdModalEvent?: () => void;
  isLoadingUserDataSuccess?: boolean;
  setErrorMessage?: any;
}

export interface IFinanceMoveMoneySummaryCard extends translationProps {
  currentBalance?: string | number;
  isAmountBlockVisible?: boolean;
}

export interface FinancesTermsModalProps extends translationProps {
  closeIdModalEvent?: () => void;
  onAcceptButtonClick?: () => void;
}

export interface IFinanceMoveMoneySummaryDetailsCard extends translationProps {
  valueType?: string;
  valueDate?: string;
  valueDescriptorCode?: string;
  valueMethod?: string;
  valueNotes?: string;
}

export interface ManageVirtualCardProps extends translationProps {
  title: string;
  data?: any;
  isSuccess?: boolean;
  totalSpendAmount: number;
}

export interface DataSortingProps extends translationProps {
  handleOnChangeDate: any;
  selectedOptionDateSorting: any;
  tabType?: "submitted" | "paid" | "approved" | "transactions";
}

export interface FromAutoCompleteSelectProps
  extends React.SelectHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  htmlFor?: string;
  error?: any;
  isValid?: boolean;
  highLightText?: React.ReactNode;
  maxWidth?: string;
  defaultText?: string;
  options?: any;
  useId?: boolean;
  onChange?: (val: any, option?: any) => void;
  onInputChange?: (val: any) => void;
  selectedState?: string;
  inputValue?: any;
  inputFocus?: any;
  readonly?: boolean;
  type?: "shadowed" | "default";
}

export type ActionIconButtonType =
  | "MaintenanceRequest"
  | "GuestAccess"
  | "UpdateRoomMate"
  | "UpdatePets"
  | "MakeAPayment"
  | "LinkedAccounts"
  | "PaymentHistory"
  | "RentReporting"
  | "BankLinking"
  | "CreditOrDebitCard";

export interface ActionIconButtonProps {
  cardType?: ActionIconButtonType;
  btnTitle?: string;
  isDisabled?: boolean;
  onClickBtnEvent?: () => void;
  isActive?: boolean;
  paddingRight?: string | number;
}

export interface ActionItemCardProps
  extends NavigateMethodProps,
    translationProps {
  cardMarginBottom?: string | number;
  actionItemsData: any;
  cardTitle?: string;
  cardWrapperType?: ShadowCardTypes;
  paddingX?: number | string;
  paddingY?: number | string;
}

export interface TextAreaProps extends InputProps {
  isResizeEnabled?: boolean;
  size?: "small" | "large" | "medium";
}

export type DataGridTypes = "request" | "activity" | "reporting_history";
export interface DataGridProps extends translationProps {
  dataObject?: any;
  isHandleArrowClickable?: boolean;
  isToggle?: boolean;
  searchInProgress?: boolean;
  hideHeader?: boolean;
  iconArrowVisible?: boolean;
  searchText?: string;
  limit?: number;
  routeState?: any;
  gridType: DataGridTypes;
}

export interface TooltipInfoProps {
  children?: React.ReactNode;
  aligned?: "right" | "left" | "mRight" | "mLeft" | "topLeft";
}

export interface DataGridEmptyCardProps {
  title?: string;
  imagePath?: string;
  emptyStateTitle?: string;
  emptyStateContent?: string;
  isTooltipInfoEnabled?: boolean;
  tooltipContent?: string;
  tooltipContentAligned?: "right" | "left" | "mRight" | "mLeft" | "topLeft";
}

export interface searchBarProps extends translationProps {
  onchangeHandler?: any;
  isSearchIconVisible?: boolean;
  onResetSearch?: () => void;
  searchText?: string;
  isFilterVisible?: boolean;
  onClickFilterModalOpen?: any;
  isFilterApplied?: boolean;
  searchInputPlaceHolder?: string;
}

export interface StatusChipsProps {
  type?: StatusChipsType;
  isResponsive?: boolean;
  isArrowIconVisible?: boolean;
  chipWidth?: string;
  chipWidthMobile?: string;
  textValue?: string;
  subType?: "text12s";
}

export interface StatusDropDownProps {
  defaultValue?: any;
  options?: any;
  optionOnClickEvent?: any;
}

export interface CustomSelectionProps extends StatusDropDownProps {
  sxTag?: SxProps<Theme>;
  theme?: any;
}

export interface FilterModalProps extends translationProps {
  onModalClose?: any;
  onFilterReset?: any;
  onClickApplyFilter?: any;
  resetFilter?: any;
  onChangeDateFilter?: any;
  selectedDateFilterOption?: string;
  onChangeDateCustomEvent?: any;
  selectedCustomDateRange?: any;
  onCancelDateCustomEvent?: any;
  handleDateSorting?: any;
  selectedDateSortingOption?: string;
}

export interface DataGridCardProps
  extends DataGridProps,
    NavigateMethodProps,
    TooltipInfoProps {
  isRecentWorkOrders?: boolean;
  dataGridCardTitle?: string;
  isCardLinkAvailable?: boolean;
  onHandleResetSearch?: any;
  onHandleSearch?: any;
  isTooltipInfoEnabled?: boolean;
  tooltipContent?: string;
  itemCount?: string | number;
  setFilterSchema?: any;
  createSearchParams?: any;
  isDataFetching?: boolean;
  viewAllLink?: string;
  searchInputPlaceHolder?: string;
}

export interface IFilterValues {
  dateSorting: string;
  dateFilterSelected?: string;
  selectedCustomDateRange?: any;
}

export interface AddBankAccountFormProps
  extends NavigateMethodProps,
    translationProps {
  isFromPayment: boolean;
  isOnlyCard: boolean;
}

export interface AddCreditOrDebitCardFormProps
  extends NavigateMethodProps,
    translationProps {
  isFromPayment: boolean;
  isOnlyCard: boolean;
}

export interface GridEmptyCardProps {
  type?: DataGridTypes;
  title?: string;
  emptyStateContent?: string;
  isTooltipInfoEnabled?: boolean;
  tooltipContent?: string;
  tooltipContentAligned?: "right" | "left" | "mRight" | "mLeft" | "topLeft";
  onClickBtn?: () => void;
  isButtonVisible?: boolean;
  buttonText?: string;
  isEmptyCardDataVisble?: boolean;
  isLoadingFadeEnabled?: boolean;
  isInnerLoaderEnabled?: boolean;
  mbWrapper?: string | number;
}

export interface ImageBannerProps {
  imgPath: string;
  imgAlt: string;
  imgWidth?: string;
  imgHeight?: string;
  sxImgContainer?: SxProps<Theme>;
  sxImageWrapper?: SxProps<Theme>;
}

export interface DOB {
  day?: string;
  month?: string;
  year?: string;
}

export interface WrapperCardProps extends childrenInterface {
  type?:
    | "default"
    | "is_transparent"
    | "no_shadow"
    | "remove_all_shadow"
    | "full_border";
  pbWrapper?: string | number;
  ptWrapper?: string | number;
  plWrapper?: string | number;
  prWrapper?: string | number;
  mbWrapper?: string | number;
  mtWrapper?: string | number;
  minHeightWrapper?: string | number;
  subTitle?: string;
  pbTitle?: string | number;
  ptTitle?: string | number;
  plTitle?: string | number;
  prTitle?: string | number;
  mbTitle?: string | number;
  mtTitle?: string | number;
  colorTitle?: string;
  variantTitle?: "h2Bold" | "h3SemiBold" | "h3" | "h2";
  textTransformTitle?: "uppercase" | "capitalize" | "none";
  title?: string;
  tooltipContent?: string;
  pbSubTitle?: string | number;
  ptSubTitle?: string | number;
  plSubTitle?: string | number;
  prSubTitle?: string | number;
  mbSubTitle?: string | number;
  mtSubTitle?: string | number;
  colorSubTitle?: string;
  variantSubTitle?: "h2Bold" | "h3SemiBold" | "h3" | "h2";
  textTransformSubTitle?: "uppercase" | "capitalize" | "none";
  fwSubTitle?: 400 | 500 | 600 | 700;
  isInnerLoaderEnabled?: boolean;
  isLoadingFadeEnabled?: boolean;
  childElementsFlexDirection?: "column" | "row";
}

export interface LandingBalanceCardProps extends translationProps {
  balanceDetails: any;
  autoPayDetails: any;
  onclickPayNowButton?: () => void;
  onclickScheduleButton?: () => void;
  isBalanceDetailLoadingSuccess: boolean;
  isAutoPayDetailLoadingSuccess: boolean;
  isInnerLoaderEnabled?: boolean;
  isLoadingFadeEnabled?: boolean;
}

export interface WelcomeMessageModalProps extends translationProps {
  closeIdModalEvent?: () => void;
  ResidentName?: string;
}

export interface NoteTextProps extends translationProps {
  iconType?: "InfoRoundedIcon" | "LockedIcon";
  children?: React.ReactNode;
  isBorder?: boolean;
}

export interface createErrorDetailsParamsProps {
  errorStatus: string;
  errorMessage: string;
  targetAPI: string;
  endpointName: string;
  method: string;
}
