import { Box, Typography } from "@mui/material";
import { MiddleRow } from "./RequestDetailMiddle.style";

import {
  dateFormatWithTimeZoneForEarning,
  timeFormatWithoutTimeZoneForEarning,
} from "src/shared/utilities/dateConvertions";
import { TimelineItem, ShadowCard } from "src/components";
import { RequestDetailProps } from "src/shared/models/component/base.model";
import { handleCompletedDate } from "src/helpers/helperRequests";

export const RequestDetailMiddle = ({ data, t }: RequestDetailProps) => {
  const [completedDate, isDue] = handleCompletedDate(data);
  const isCompletedTImelineVisible =
    data?.Status !== "Closed" && data?.Status !== "Cancelled";
  const isCompletedTimelineActive =
    data?.Status === "Completed" || data?.CompletedDate !== undefined;

  return (
    <ShadowCard paddingX={4} marginBottom={8} paddingTop={4} paddingBottom={4}>
      <MiddleRow className="header">
        <Box className="header_left">
          <Typography variant="h1Bold" color={"gray.500"}>
            {t("requests.requestDetail.timlineCard.title")}
          </Typography>
        </Box>
      </MiddleRow>
      <TimelineItem
        itemName={t("requests.requestDetail.timlineCard.timelineOneTitle")}
        dateText={dateFormatWithTimeZoneForEarning(data?.ReportedDate)}
        position={1}
        isActive={true}
        t={t}
      />

      {data.StartedDate !== undefined && (
        <TimelineItem
          itemName={t("requests.requestDetail.timlineCard.timelineTwoTitle")}
          dateText={dateFormatWithTimeZoneForEarning(
            data?.invoice_submitted_date
          )}
          timeText={timeFormatWithoutTimeZoneForEarning(
            data?.invoice_submitted_date
          )}
          position={2}
          isActive={true}
          t={t}
        />
      )}

      {/* <TimelineItem
        itemName={t('requests.requestDetail.timlineCard.timelineThreeTitle')}
        dateText={dateFormatWithTimeZoneForEarning(
          data?.LastModified
        )}
        timeText={timeFormatWithoutTimeZoneForEarning(
          data?.LastModified
        )}
        position={3}
        isActive={data?.Status === 'Scheduled' ?? false}
        t={t}
      /> */}

      {isCompletedTImelineVisible && (
        <TimelineItem
          itemName={t("requests.requestDetail.timlineCard.timelineFourTitle")}
          dateText={dateFormatWithTimeZoneForEarning(completedDate)}
          timeText={
            !isDue
              ? timeFormatWithoutTimeZoneForEarning(completedDate)
              : undefined
          }
          position={4}
          isActive={isCompletedTimelineActive}
          t={t}
        />
      )}

      {data?.Status === "Closed" && (
        <TimelineItem
          itemName={t("requests.requestDetail.timlineCard.timelineFiveTitle")}
          dateText={dateFormatWithTimeZoneForEarning(data?.LastModified)}
          timeText={timeFormatWithoutTimeZoneForEarning(data?.LastModified)}
          position={4}
          isActive={true}
          t={t}
        />
      )}

      {data?.Status === "Cancelled" && (
        <TimelineItem
          itemName={t("requests.requestDetail.timlineCard.timelineSixTitle")}
          dateText={dateFormatWithTimeZoneForEarning(data?.LastModified)}
          timeText={timeFormatWithoutTimeZoneForEarning(data?.LastModified)}
          position={5}
          isActive={true}
          t={t}
        />
      )}
    </ShadowCard>
  );
};
