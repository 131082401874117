import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Error404ScreenContainer } from "src/containers";

export const Error404Screen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/404");
  }, [navigate]);

  return <Error404ScreenContainer />;
};
