export const identityTranslation = {
  identity: {
    verificationTitle: "Verificación de identidad",
    noteContent1: "¡Hola vecinos! Sólo un rápido aviso:",
    noteContent2:
      "Como parte de una actualización de seguridad de TenantPort, necesitamos verificar su identidad.",
    noteContent3:
      "Su información KYC (Conozca a su cliente) nos ayuda a verificar su identidad y garantizar que todos los que usan la aplicación sean quienes dicen ser. Esto ayuda a prevenir el fraude y el acceso no autorizado a su cuenta y mantiene a nuestra comunidad como un espacio confiable para todos. Además, el mapeo KYC preciso garantiza que sus pagos siempre se acrediten en la cuenta correcta, ¡sin confusiones ni demoras!",
    verifyIdentityTitle: "Verificar identidad",
    verifyIdentityContent:
      "Complete su verificación para acceder a todas las funciones de TenantPort",
    rentReporting: {
      description:
        "Confirme su identidad para ayudarnos a informar su alquiler con precisión.",
    },
    errorMessage: "Unable to complete this process, please check the details",
  },
};
