import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const FormInputWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 ${theme.spacing(4.5)}; 

    &.icon_enabled {
      input {
        padding-right: 40px;
      }
    }

    input {
      border: none !important; 
      color: ${theme.palette.gray[900]}; 
      font-size: 14px;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${theme.palette.gray[500]}; 
        opacity: 1; /* Firefox */
      }
  
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${theme.palette.gray[500]}; 
      }
  
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${theme.palette.gray[500]}; 
      }

      background: ${theme.palette.white} !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.gray[100]};
      border-radius: ${theme.shape.borderRadius}px;
    }

    &.valid_input {
      label {
        color: ${theme.palette.success.main} !important;
      }
      
      input {
        color: ${theme.palette.gray[900]}; 
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.success.main} !important;
        * {
          color: ${theme.palette.primary.main};
        }
      }
    }

    .Mui-error,
    &.error_input {
      label {
        color: ${theme.palette.error.main} !important;
      }

      input {
        color: ${theme.palette.gray[900]}; 
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.error.main} !important;
        * {
          color: ${theme.palette.error.main};
        }
      }
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.gray[100]};
      border-width: 1px;
    }

    .MuiInputBase-input.MuiOutlinedInput-input {
      line-height: 16px;
      padding: 11px 14px;
    }

    &.default {
      label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined {
        top: -2px;
      }

      .MuiInputBase-input.MuiOutlinedInput-input {
        padding: 11px 14px;
      }

      .MuiFormLabel-root.Mui-focused {
        color: ${theme.palette.gray[600]};
      }
    }

    &.shadowed {
      label {
        color: ${theme.palette.primary.main};
        font-size: 16px;
        font-weight: 600; 
      }

      &.valid_input {
        label {
          color: ${theme.palette.primary.main};
        }
      }

      .Mui-error,
      &.error_input {
        label {
          color: ${theme.palette.error.main};
        }
      }

      label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined {
        top: 3px;
      }

      .MuiOutlinedInput-notchedOutline {
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.1);
      }

      .MuiInputBase-root.MuiInputBase-formControl.MuiAutocomplete-inputRoot {
        padding-left: 15px;
      }

      .MuiFormLabel-root.Mui-focused{
        color: ${theme.palette.primary.main};
      }
    }

    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined{
      line-height: 16px;

      &.MuiInputLabel-shrink.MuiFormLabel-root {
        font-size: 12px;
        transform: translate(14px, -9px) scale(1);
        background: transparent;
        padding-right: 2px;
        top: 0;
        left: -4px;
      }
    }
`
);

export const FormInputContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  width: 100%;
  position: relative;

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 50px white inset;
    }

    background: ${theme.palette.white} !important;
  }

  .validation_icons {
    right: 19px;
    top: 12px;
    position: absolute;
  }

  &.left-icon-enabled {
    .MuiInputBase-input.MuiOutlinedInput-input {
      padding-left: 33px;
    }

    .MuiInputLabel-root.MuiInputLabel-formControl {
      &:not(.Mui-focused) {
        left: 20px;
      }
    }
  }
`
);

export const HighLightText = styled(Box)`
  margin: 8px 0 0;
  color: var(--black100);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;
