import React from "react";
import { ReviewAddress } from "./ReviewAddress";
import {
  RegisterReviewSection,
  RegisterReviewSectionContent,
  RegisterReviewSectionTitle,
} from "./Register.style";

export const ReviewInformation = (props: any) => {
  // Styled components

  const Section = RegisterReviewSection;
  const SectionTitle = RegisterReviewSectionTitle;
  const SectionContent = RegisterReviewSectionContent;
  const { formData, adminFee, applicationFee, leaseId, houseHoldMembers } =
    props?.options || {};
  console.log(JSON.stringify(houseHoldMembers));
  const {
    vehicle,
    pet,
    emergencyContact,
    currentEmployment,
    otherIncome,
    currentAddress,
    applicantInfo,
    misc,
    otherApplicants,
  } = formData || {};
  return (
    <>
      <Section>
        <SectionTitle>Applicant Information</SectionTitle>
        <SectionContent>
          <p>Name: {applicantInfo?.name}</p>
          <p>Email: {applicantInfo?.email}</p>
          <p>Phone: {applicantInfo?.phone}</p>
          <p>Birthdate: {applicantInfo?.birthdate}</p>
          <h3>Identification</h3>
          {applicantInfo?.identification?.notApplicable ? (
            <p>Not Applicable</p>
          ) : (
            <>
              <p>
                US Citizen:{" "}
                {applicantInfo?.identification?.usCitizen ? "Yes" : "No"}
              </p>
              <p>Type: {applicantInfo?.identification?.type}</p>
              <p>Number: {applicantInfo?.identification?.number}</p>
            </>
          )}
          <h3>Driver's License</h3>
          {applicantInfo?.dvLicense?.notApplicable ? (
            <p>Not Applicable</p>
          ) : (
            <>
              <p>Number: {applicantInfo?.dvLicense?.number}</p>
              <p>State: {applicantInfo?.dvLicense?.state}</p>
            </>
          )}
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>Current Address</SectionTitle>
        <ReviewAddress address={currentAddress?.address} />
        <p>Move-in Date: {currentAddress?.moveInDate}</p>
        <p>Reason for Leaving: {currentAddress?.reasonForLeaving}</p>
        <p>Rented: {currentAddress?.rented ? "Yes" : "No"}</p>
        <p>Landlord: {currentAddress?.landlord}</p>
        <p>Landlord Contact: {currentAddress?.landlordContact}</p>
        <p>Landlord Phone: {currentAddress?.landlordPhone}</p>
      </Section>
      <Section>
        <SectionTitle>Current Employment</SectionTitle>
        <SectionContent>
          <p>
            Not Applicable: {currentEmployment?.notApplicable ? "Yes" : "No"}
          </p>
          <p>Industry: {currentEmployment?.industry}</p>
          <p>Employer: {currentEmployment?.employer}</p>
          <p>Title: {currentEmployment?.title}</p>
          <p>Start Date: {currentEmployment?.dateFrom}</p>
          <p>Monthly Income: ${currentEmployment?.monthlyIncome}</p>
          <p>Supervisor: {currentEmployment?.superVisorName}</p>
          <p>Supervisor Phone: {currentEmployment?.superVisorPhone}</p>
          <p>Supervisor Email: {currentEmployment?.email}</p>
          <ReviewAddress address={currentEmployment?.address} />

          <h2>Other Income</h2>
          {otherIncome?.map((income: any, index: number) => (
            <div key={index}>
              <p>Type: {income?.incomeType}</p>
              <p>Contact: {income?.contactName}</p>
              <p>Monthly Income: ${income?.monthlyIncome}</p>
              <p>Effective Date: {income?.effectiveDate}</p>
              <ReviewAddress address={income?.address} />
            </div>
          ))}
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>Emergency Contact</SectionTitle>
        <SectionContent>
          <p>Name: {emergencyContact?.name}</p>
          <p>Relationship: {emergencyContact?.relationship}</p>
          <p>Phone: {emergencyContact?.phone}</p>
          <p>Email: {emergencyContact?.email}</p>
          <ReviewAddress address={emergencyContact?.address} />
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>Pets</SectionTitle>
        <SectionContent>
          {pet?.noPets && <p>No Pets</p>}
          {!pet?.noPets &&
            pet?.pet?.map((p: any, index: number) => (
              <div key={index}>
                <h4>Name: {p?.name}</h4>
                <p>Type: {p?.type}</p>
                <p>Breed: {p?.breed}</p>
                <p>Color: {p?.color}</p>
                <p>Age: {p?.age}</p>
                <p>Weight: {p?.weight} lbs</p>
                <p>
                  Registration: {p?.registration?.type} |{" "}
                  {p?.registration?.issuer} | {p?.registration?.number}
                </p>
                <p>Assistant: {p?.assistant ? "Yes" : "No"}</p>
              </div>
            ))}
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>Vehicle Information</SectionTitle>
        <SectionContent>
          {vehicle?.noVehicles && <p>No Vehicles</p>}
          {!vehicle?.noVehicles &&
            vehicle?.vehicle?.map((v: any, index: number) => (
              <div key={index}>
                <p>Year: {v?.year}</p>
                <p>Make: {v?.make}</p>
                <p>Model: {v?.model}</p>
                <p>Color: {v?.color}</p>
                <p>
                  License Plate: {v?.licensePlateNumber} | (
                  {v?.licensePlateState})
                </p>
              </div>
            ))}
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>HouseHold Members</SectionTitle>
        <SectionContent>
          {!otherApplicants?.noOthers &&
            !leaseId &&
            otherApplicants?.otherApplicants?.map((v: any, index: number) => (
              <>
                <p>---</p>
                <div key={index}>
                  <p>Name: {v?.name}</p>
                  <p>Relationship: {v?.householdStatus}</p>
                  {v?.householdStatus !== "Dependent" && (
                    <p>Email: {v?.email}</p>
                  )}
                  {v?.householdStatus === "Dependent" && (
                    <p>Birth Date: {v?.birthDate}</p>
                  )}
                </div>
              </>
            ))}
          {houseHoldMembers?.map((v: any, index: number) => (
            <>
              <p>---</p>
              <div key={index}>
                <p>Name: {`${v?.person?.FirstName} ${v?.person?.LastName}`}</p>
                <p>Relationship: {v?.relation}</p>
                {v?.person?.Email && <p>Email: {v?.person?.Email}</p>}
                {v?.person?.birthDate && (
                  <p>Birth Date: {v?.person?.birthDate}</p>
                )}
              </div>
            </>
          ))}
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>Miscellaneous</SectionTitle>
        <SectionContent>
          <p>How did you hear about us: {misc?.referral?.source}</p>
          <p>Referring Agent: {misc?.referral?.agent}</p>
          <p>Referring agency: {misc?.referral?.agency}</p>
          <p>Other Comments: {misc?.referral?.comments}</p>
          <p>Reason for Moving: {misc?.movingReason}</p>
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>Fee And Deposit</SectionTitle>
        <SectionContent>
          <p>
            Please review the fees below.You will be redirected to a page to
            complete your payment
          </p>
          {applicationFee > 0 && (
            <p>
              Application Fee&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {applicationFee}{" "}
            </p>
          )}
          {!leaseId && adminFee > 0 && (
            <p>Unit Fee&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{adminFee} </p>
          )}
        </SectionContent>
      </Section>
    </>
  );
};
