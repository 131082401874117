import { useTranslation } from "react-i18next";
import { ConditionalText } from "../ConditionalText/ConditionalText";

export const FooterNote = ({
  buttonText = "Submit",
}: {
  buttonText?: String;
}) => {
  const { i18n } = useTranslation();
  return (
    <>
      {i18n.resolvedLanguage === "en" && (
        <ConditionalText>
          By clicking {buttonText}, I agree to TenantPort's{" "}
          <a
            target="_blank"
            href="https://tenantport.s3.amazonaws.com/documents/TermsConditions-TenantPort.pdf"
            rel="noreferrer"
          >
            Terms Of Services
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href="https://tenantport.s3.amazonaws.com/documents/PrivacyPolicy-TenantPort.pdf"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          {"; "}
          <a
            target="_blank"
            href="https://tenantport.s3.amazonaws.com/documents/CredBuild+ToS+-+2024_03_08.docx.pdf"
            rel="noreferrer"
          >
            terms
          </a>
          {" and "}
          <a
            target="_blank"
            href="https://tenantport.s3.amazonaws.com/documents/CredBuild+-+PrivacyPolicy+(gray+draft+2-20-24).docx.pdf"
            rel="noreferrer"
          >
            policies
          </a>{" "}
          for our partner CredBuild and terms and conditions for our linking
          partner{" "}
          <a
            target="_blank"
            href="https://plaid.com/legal/#consumers"
            rel="noreferrer"
          >
            Plaid
          </a>{" "}
          as well as receiving automated notifications.
        </ConditionalText>
      )}

      {i18n.resolvedLanguage === "es" && (
        <ConditionalText>
          Al hacer clic en Enviar, acepto las condiciones de TenantPort{" "}
          <a
            target="_blank"
            href="https://tenantport.s3.amazonaws.com/documents/TermsConditions-TenantPort.pdf"
            rel="noreferrer"
          >
            Términos de servicios
          </a>{" "}
          y{" "}
          <a
            target="_blank"
            href="https://tenantport.s3.amazonaws.com/documents/PrivacyPolicy-TenantPort.pdf"
            rel="noreferrer"
          >
            Política de privacidad
          </a>{" "}
          así como recibir notificaciones automáticas.
        </ConditionalText>
      )}
    </>
  );
};
