import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Box, Button, RadioGroup } from "@mui/material";

import {
  FormAuthorizedWrapper,
  FormInput,
  ButtonAuthorizedWrapper,
  Messages,
  AddressFields,
  PaymentCardFields,
  FormRadioButton,
  FormInputErrorMessage,
  ManualLoader,
} from "src/components";
import {
  AddressDefaultValues,
  AddressFormProps,
} from "src/shared/models/component/base.model";
import { cardPaymentFormSchemaObj } from "src/shared/utilities/validationSchemas";

import {
  PaymentCardFieldsProps,
  summaryDetailsParams,
} from "../../../shared/models/containers/rent.model";
import { useTranslation } from "react-i18next";
import { DropdownData } from "../../../components/FormElements/AddressFields/DropdownState/DropdownState.data";
import { PaymentSummaryModal } from "../../../containers/Rent/Payment/PaymentSummaryModal/PaymentSummaryModal";
import {
  useGetFeeStructureMutation,
  usePostCardPaymentMutation,
} from "../../../store/services/privateApi";

export const PayCreditOrDebitCard = ({
  amount,
  leaseId,
  onPaymentSuccess,
}: {
  amount: number;
  leaseId: string;
  onPaymentSuccess: Function;
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [addressStates, setAddressStates] =
    useState<AddressFormProps>(AddressDefaultValues);

  const [isCardDetailsAdded, setIsCardDetailsAdded] = useState(false);
  const [cardNumber, setCardNumber] = useState<any>();
  const [cardCVC, setCardCVC] = useState<any>();
  const [cardExpiryDate, setCardExpiryDate] = useState<any>();
  const [namesAdded, setNamesAdded] = useState<
    "none" | "added" | "worng_entry"
  >("none");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [paymentSummaryModalOpen, setPaymentSummaryModalOpen] = useState(false);
  const [summaryDetails, setSummaryDetails] = useState<summaryDetailsParams>({
    paymentMethodType: "",
    fee: 0,
    amount: 0,
    totalamount: 0,
    notes: "",
    feepercentage: "",
  });
  const [formData, setFormData] = useState<any>({});
  const [getFeeStructure, { isLoading: isLoadingGetFeeStructure }] =
    useGetFeeStructureMutation();
  const [paymentMutation, { isLoading: isLoadingPayment }] =
    usePostCardPaymentMutation();

  useEffect(() => {}, []);

  const resolver = yup.object(cardPaymentFormSchemaObj).required();
  /**
   * initialize the hook form
   */
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<PaymentCardFieldsProps>({
    resolver: yupResolver(resolver),
  });

  const appSubmit = handleSubmit(async (data) => {
    setErrorMessage("");
    if (isCardDetailsAdded) {
      const setBillingStreetAddress = `${data.line1}${data.line2 ? "," : ""} ${
        data.line2 ?? ""
      }`;

      const getCardNumber: string = data.cardNumber ? `${data.cardNumber}` : "";

      const getExpiryDate = data.cardExpiryYear ? `${data.cardExpiryYear}` : "";
      const getMonth = getExpiryDate.slice(0, 2);
      const getYear = getExpiryDate.slice(-2);

      const getState: any =
        DropdownData &&
        DropdownData.filter(
          (state: { label: string; abbreviation: string }) =>
            state.label === data?.state
        );

      const payloadParams: any = {
        PayerFirstName: data.payerFirstName,
        PayerLastName: data.payerLastName,
        CreditCardType: data.cardType, //'Visa', 'MasterCard', 'Discover', 'Amex'
        CreditCardNumber: getCardNumber.replace(/\s+/g, ""),
        CreditCardExpMonth: getMonth,
        CreditCardExpYear: getYear,
        CreditCardCvv2: data.cardCVC,
        BillingFirstName: data.payerFirstName,
        BillingLastName: data.payerLastName,
        BillingStreetAddress: setBillingStreetAddress,
        BillingCity: data?.city,
        BillingState: getState ? getState[0].abbreviation : "",
        BillingCountry: "US",
        BillingZip: data?.postal_code,
        IsDebitCard: data.addCard === "Yes" ? "Yes" : "No",
        leaseId: leaseId,
        TotalAmount: amount,
      };
      setFormData(payloadParams);
      const getFeeAmount: any = await getFeeStructure(amount);
      if (getFeeAmount && getFeeAmount.error) {
        setErrorMessage("Unable to load the fee structure");
      } else {
        const getCreditCardFee = getFeeAmount.data.creditCard.fee;
        const getDebitCardFee = getFeeAmount.data.debitCardFee;

        const setFeeForPaymentMethodType =
          data.addCard === "Yes" ? getDebitCardFee : getCreditCardFee;

        const setFeePercentage =
          data.addCard === "No"
            ? `${getFeeAmount.data.creditCard.percentage}%`
            : "";

        const getTotalAmount: number = amount + setFeeForPaymentMethodType;

        setSummaryDetails({
          paymentMethodType: "1",
          fee: setFeeForPaymentMethodType,
          amount: amount,
          totalamount: getTotalAmount,
          notes: "Application Fee",
          feepercentage: setFeePercentage,
        });

        setPaymentSummaryModalOpen(true);
      }
    }
  });

  /**
   * used to get firstname last name, when input onchange
   * @param event Returning input events when onchange
   */
  const updateBindingsChangeGetNames = (event: any) => {
    const getValue = event.target.value;
    const getNames = getValue.split(" ");

    setValue("cardHolderName", event.target.value);

    if (getNames && getNames.length > 1) {
      const getFirstName = getNames[0];
      const getLastName = getNames[getNames.length - 1];

      if (getLastName && getLastName.length > 0) {
        setNamesAdded("added");
        setValue("payerFirstName", getFirstName);
        setValue("payerLastName", getLastName);
      } else {
        setNamesAdded("worng_entry");
      }
    } else {
      setValue("payerFirstName", "");
      setValue("payerLastName", "");

      if (getValue.length > 0) {
        setNamesAdded("worng_entry");
      } else {
        setNamesAdded("none");
      }
    }
  };

  const onPayamentSummaryModalCloseEvent = () => {
    setPaymentSummaryModalOpen(false);
  };

  const onPayamentSummaryBtnClickEvent = async () => {
    setPaymentSummaryModalOpen(false);
    const response: any = await paymentMutation(formData);
    if (response.error) {
      setErrorMessage(
        response?.error?.data?.error ??
          response?.error?.data?.message?.Message ??
          response?.error?.data?.message?._text ??
          "Payment Failed."
      );
    } else {
      onPaymentSuccess();
    }
  };

  return (
    <>
      {(isLoadingPayment || isLoadingGetFeeStructure) && <ManualLoader />}
      <FormAuthorizedWrapper autoComplete="off" onSubmit={appSubmit}>
        <Box>
          <Controller
            control={control}
            name="addCard"
            render={({ field: { onChange, value } }) => (
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ marginBottom: errors?.cardType?.message ? 0 : 3 }}
                >
                  <FormRadioButton
                    label={"Debit Card"}
                    value={value ?? ""}
                    id={"Yes"}
                    checked={value === "Yes"}
                    {...register("addCard")}
                    handleChange={() => setValue("addCard", "Yes")}
                    labelFontVariant="h2"
                    marginRight={4}
                  />
                  <FormRadioButton
                    label={"Credit Card"}
                    value={value ?? ""}
                    id={"No"}
                    checked={value === "No"}
                    {...register("addCard")}
                    handleChange={() => setValue("addCard", "No")}
                    labelFontVariant="h2"
                    marginRight={4}
                  />
                </RadioGroup>
                {errors?.cardType?.message && (
                  <Box sx={{ paddingBottom: 5 }} display={"flex"}>
                    <FormInputErrorMessage>
                      {errors?.cardType?.message}
                    </FormInputErrorMessage>
                  </Box>
                )}
              </Box>
            )}
          />
          <Controller
            control={control}
            name="cardHolderName"
            render={({ field: { value } }) => (
              <FormInput
                label={"Card Holder Name"}
                id={"cardHolderName"}
                placeholder={""}
                {...register("cardHolderName")}
                error={
                  namesAdded === "none" && errors?.cardHolderName?.message
                    ? errors?.cardHolderName?.message
                    : namesAdded === "worng_entry" &&
                      errors?.payerLastName?.message
                    ? errors?.payerLastName?.message
                    : ""
                }
                onChange={(event: any) => {
                  updateBindingsChangeGetNames(event);
                }}
                inputFocus={(e: any) => {
                  trigger("cardHolderName");
                }}
                value={value}
              />
            )}
          />

          <PaymentCardFields
            setValue={setValue}
            errors={errors}
            setIsCardDetailsAdded={setIsCardDetailsAdded}
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
            cardCVC={cardCVC}
            setCardCVC={setCardCVC}
            cardExpiryDate={cardExpiryDate}
            setCardExpiryDate={setCardExpiryDate}
          />

          <AddressFields
            register={register}
            control={control}
            getValue={getValues}
            setValue={setValue}
            setAddressStates={setAddressStates}
            addressStates={addressStates}
            trigger={trigger}
            errors={errors}
            isPOBoxTextVisible={false}
          />
        </Box>

        {errorMessage !== "" && (
          <Messages
            messageHeading={"Card Payment"}
            messageContent={errorMessage}
            closeEvent={() => setErrorMessage("")}
            bottomMargin={4}
            topMargin={4}
          />
        )}
        <ButtonAuthorizedWrapper>
          <Button
            variant="containedLarge"
            color="primary"
            fullWidth
            type="submit"
            sx={{ overflow: "hidden" }}
          >
            {t("buttonTexts.submitButton")}
          </Button>
        </ButtonAuthorizedWrapper>
      </FormAuthorizedWrapper>

      {paymentSummaryModalOpen && (
        <PaymentSummaryModal
          summaryDetails={summaryDetails}
          t={t}
          onPayamentSummaryBtnClickEvent={onPayamentSummaryBtnClickEvent}
          onPayamentSummaryModalCloseEvent={onPayamentSummaryModalCloseEvent}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};
