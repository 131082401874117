import React from "react";
import { WarningIcon } from "../../Media/Icon/Icons/Icons";
import { ErrorMessageWrapper } from "./FormInputErrorMessage.style";
import { FormInputErrorMessageProps } from "../../../shared/models/component/base.model";

export const FormInputErrorMessage = ({
  children,
}: FormInputErrorMessageProps) => {
  return (
    <ErrorMessageWrapper>
      <div>
        <WarningIcon />
      </div>
      <span className="error_content">{children}</span>
    </ErrorMessageWrapper>
  );
};
