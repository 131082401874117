export const IMAGES = {
  LANDING_BANNER: require("../../assets/images/landing-banner.png"),
  SIGNUP_SUCCESS: require("../../assets/images/landing-banner.png"),
  TP_LOGO: require("../../assets/images/tp-logo.png"),
  CREDIT_BUILDER: require("../../assets/images/creditbuilder.png"),
};

export const EmptyStates = {
  aprroved: require("../../assets/images/status/empty-states/aprroved.png"),
};

export const RENT_IMAGES = {
  INFO_BANNER: require("../../assets/images/rent/info_banner.png"),
  CONFIRMATION_BANNER: require("../../assets/images/rent/confirmation_banner.png"),
};

export const IDENTITY_IMAGES = {
  APPROVED: require("../../assets/images/identity/identity_approved.png"),
  FAILED: require("../../assets/images/identity/identity_failed.png"),
  VERIFY: require("../../assets/images/identity/verify_identity.png"),
};
