import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Typography,
} from "@mui/material";

import {
  FormAuthorizedWrapper,
  ShadowCard,
  ActionItemCard,
  FormInput,
  ManualLoader,
  ButtonAuthorizedWrapper,
  Messages,
  FormRadioButton,
  FormInputErrorMessage,
  ConfirmationModal,
} from "src/components";

import { createBankAccountActionCardButtonItems } from "src/shared/assets/data";
import { AddBankAccountFormProps } from "src/shared/models/component/base.model";
import {
  AddBankAccountFormParams,
  addBankAccountParamsProps,
} from "src/shared/models/containers/rent.model";
import { updateBindingsChange } from "src/shared/utilities/formUtilities";

import {
  useAddBankAccountMutation,
  useGetPaymentMethodsQuery,
  useUpdateDefaultBankAccountMutation,
} from "src/store/services/privateApi";
import {
  checkDefaultPaymentMethod,
  resetTheAddBankAccountFormFields,
} from "src/helpers/helperRent";
import { addBankAccountFormSchemaObj } from "src/shared/utilities/validationSchemas";

export const AddBankAccountForm = ({
  navigate,
  t,
  isFromPayment,
  isOnlyCard,
}: AddBankAccountFormProps) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isMarkedAsDefaultPaymentMethod, setIsMarkedAsDefaultPaymentMethod] =
    useState<boolean>(true);
  const [
    isDefaultPaymentOptionUnEditable,
    setIsDefaultPaymentOptionUnEditable,
  ] = useState<boolean>(true);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [namesAdded, setNamesAdded] = useState<
    "none" | "added" | "worng_entry"
  >("none");

  const [
    addBankAccount,
    { isLoading: isLoadingAddBankAccount, error: addBankAccountError },
  ] = useAddBankAccountMutation();
  const [updateDefaultBankAccount, { isLoading: isLoadingUpdateBA }] =
    useUpdateDefaultBankAccountMutation();

  const { data: paymentMethods, isSuccess } = useGetPaymentMethodsQuery(null);

  useEffect(() => {
    const [autoEnableDefaultUdate] = checkDefaultPaymentMethod(
      isSuccess,
      paymentMethods
    );
    if (autoEnableDefaultUdate) {
      setIsMarkedAsDefaultPaymentMethod(true);
      setIsDefaultPaymentOptionUnEditable(true);
    } else {
      setIsDefaultPaymentOptionUnEditable(false);
    }
  }, [isSuccess, paymentMethods]);

  const addBankAccountFormSchema = yup
    .object(addBankAccountFormSchemaObj)
    .required();

  /**
   * initialize the hook form
   */
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<AddBankAccountFormParams>({
    resolver: yupResolver(addBankAccountFormSchema),
  });

  useEffect(() => {
    resetTheAddBankAccountFormFields(setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formSubmit = handleSubmit(async (data: AddBankAccountFormParams) => {
    const setAccountType =
      data.account_type === "checking"
        ? "Checking"
        : data.account_type === "savings"
        ? "Savings"
        : data.account_type === "business_checking"
        ? "Business Checking"
        : "";

    const payloadParams: addBankAccountParamsProps = {
      PayerFirstName: data.payerFirstName,
      PayerLastName: data.payerLastName,
      AccountType: setAccountType, // "Checking", "Business Checking", "Savings"
      AccountFullName: data.accountHolderFullName,
      RoutingNumber: data.routing_number,
      AccountNumber: data.account_number,
      CurrencyCode: "USD",
    };

    const response: any = await addBankAccount(payloadParams);

    if (response) {
      if (response.error) {
        setErrorMessage(
          response?.error?.data?.error ??
            response?.error?.data?.message ??
            "Unable to create the bank account."
        );
      } else {
        if (isMarkedAsDefaultPaymentMethod && response?.data?.paymentMethodId) {
          const updateDefaultResponse: any = await updateDefaultBankAccount({
            id: response?.data?.paymentMethodId,
          });

          if (updateDefaultResponse && updateDefaultResponse.error) {
            setErrorMessage(
              response?.error?.data?.error ??
                response?.error?.data?.message ??
                "Unable to mark as default, but account has been created successfully."
            );
          } else {
            setErrorMessage("");
            setIsMarkedAsDefaultPaymentMethod(false);
            resetTheAddBankAccountFormFields(setValue);
            setConfirmationModalOpen(true);
          }
        } else {
          setErrorMessage("");
          resetTheAddBankAccountFormFields(setValue);
          setConfirmationModalOpen(true);
          setIsMarkedAsDefaultPaymentMethod(false);
        }
      }
    }
  });

  const isValidAccNumConf = () => {
    if (addBankAccountError) {
      return false;
    }
    if (errors?.account_number_conf?.message) {
      return false;
    }

    const accNum = getValues("account_number");
    const accNumConf = getValues("account_number_conf");
    if (!accNum || !accNumConf) {
      return false;
    }
    if (accNum !== accNumConf) {
      return false;
    }

    return true;
  };

  const isValidAccNum = () => {
    if (addBankAccountError) {
      return false;
    }
    if (errors?.account_number?.message) {
      return false;
    }

    const accNum = getValues("account_number") ?? "";
    if (accNum.length < 5 || accNum.length > 17) {
      return false;
    }

    return true;
  };

  const onChangeMarkedAsDefault = (event: any) => {
    const isDefaultChecked = event.target.checked;
    if (isDefaultChecked) {
      setIsMarkedAsDefaultPaymentMethod(true);
    } else {
      setIsMarkedAsDefaultPaymentMethod(false);
    }
  };

  const onSubmitConfirmationModalBtn = () => {
    setConfirmationModalOpen(false);
    if (isFromPayment) {
      navigate("/rent/payment");
    } else {
      navigate("/rent/linked-accounts");
    }
  };

  let actionItemsData = createBankAccountActionCardButtonItems;

  if (isFromPayment) {
    actionItemsData.map((x) => (x.isFromPayment = true));
  }

  /**
   * used to get firstname last name, when input onchange
   * @param event Returning input events when onchange
   */
  const updateBindingsChangeGetNames = (event: any) => {
    const getValue = event.target.value;
    const getNames = getValue.split(" ");

    setValue("accountHolderName", event.target.value);

    if (getNames && getNames.length > 1) {
      const getFirstName = getNames[0];
      const getLastName = getNames[getNames.length - 1];

      if (getLastName && getLastName.length > 0) {
        setNamesAdded("added");
        setValue("payerFirstName", getFirstName);
        setValue("payerLastName", getLastName);
      } else {
        setNamesAdded("worng_entry");
      }
    } else {
      setValue("payerFirstName", "");
      setValue("payerLastName", "");

      if (getValue.length > 0) {
        setNamesAdded("worng_entry");
      } else {
        setNamesAdded("none");
      }
    }
  };

  return (
    <>
      {(isLoadingAddBankAccount || isLoadingUpdateBA) && <ManualLoader />}
      <FormAuthorizedWrapper onSubmit={formSubmit}>
        <ShadowCard padding={4} marginBottom={6}>
          {!isOnlyCard && (
            <ActionItemCard
              navigate={navigate}
              t={t}
              actionItemsData={actionItemsData}
              cardWrapperType="no_shadow"
              paddingX={0}
              paddingY={0}
              cardMarginBottom={4}
            />
          )}
          <Controller
            control={control}
            name="account_type"
            render={({ field: { onChange, value } }) => (
              <Box>
                <FormLabel id="demo-radio-buttons-group-label">
                  {t("formInput.accountType.label")}
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ marginBottom: errors?.account_type?.message ? 0 : 3 }}
                >
                  <FormRadioButton
                    label={t("formInput.accountType.option1")}
                    value={value ?? ""}
                    id={"checking"}
                    checked={value === "checking"}
                    {...register("account_type")}
                    handleChange={() => setValue("account_type", "checking")}
                    labelFontVariant="h2"
                    marginRight={"10px"}
                  />
                  <FormRadioButton
                    label={t("formInput.accountType.option2")}
                    value={value ?? ""}
                    id={"savings"}
                    checked={value === "savings"}
                    {...register("account_type")}
                    handleChange={() => setValue("account_type", "savings")}
                    labelFontVariant="h2"
                    marginRight={"10px"}
                  />
                  <FormRadioButton
                    label={t("formInput.accountType.option3")}
                    value={value ?? ""}
                    checked={value === "business_checking"}
                    id={"business_checking"}
                    {...register("account_type")}
                    handleChange={() =>
                      setValue("account_type", "business_checking")
                    }
                    labelFontVariant="h2"
                    marginRight={"0"}
                  />
                </RadioGroup>
                {errors?.account_type?.message && (
                  <Box sx={{ paddingBottom: 5 }} display={"flex"}>
                    <FormInputErrorMessage>
                      {errors?.account_type?.message}
                    </FormInputErrorMessage>
                  </Box>
                )}
              </Box>
            )}
          />
          <Box>
            <Controller
              control={control}
              name="accountHolderName"
              render={({ field: { value } }) => (
                <FormInput
                  label={t("formInput.accountName")}
                  id={"accountHolderName"}
                  placeholder={""}
                  {...register("accountHolderName")}
                  error={
                    namesAdded === "none" && errors?.accountHolderName?.message
                      ? errors?.accountHolderName?.message
                      : namesAdded === "worng_entry" &&
                        errors?.payerLastName?.message
                      ? errors?.payerLastName?.message
                      : ""
                  }
                  onChange={(event: any) => {
                    updateBindingsChangeGetNames(event);
                  }}
                  inputFocus={(e: any) => {
                    trigger("accountHolderName");
                  }}
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              name="account_number"
              render={({ field: { onChange, value } }) => (
                <FormInput
                  label={t("formInput.accountNumber")}
                  id={"accountNumber"}
                  placeholder={"XXXXXXXXX"}
                  {...register("account_number")}
                  isValidationIconVisible
                  inputMode="numeric"
                  onChange={(e: any) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    onChange(e);
                  }}
                  error={errors?.account_number?.message}
                  isValid={isValidAccNum()}
                  maxLength={17}
                  value={value}
                  inputFocus={(e: any) => {
                    trigger("account_number");
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="account_number_conf"
              render={({ field: { onChange, value } }) => (
                <FormInput
                  label={t("formInput.confirmAccountNumber")}
                  id={"confirmAccountNumber"}
                  placeholder={"XXXXXXXXX"}
                  {...register("account_number_conf")}
                  onChange={(e: any) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    onChange(e);
                  }}
                  isValidationIconVisible
                  inputMode="numeric"
                  error={errors?.account_number_conf?.message}
                  isValid={isValidAccNumConf()}
                  maxLength={17}
                  disableCopyPaste={true}
                  value={value}
                  inputFocus={(e: any) => {
                    trigger("account_number_conf");
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="routing_number"
              render={({ field: { onChange, value } }) => (
                <FormInput
                  label={t("formInput.routingNumber")}
                  id={"routingNumber"}
                  placeholder={"XXXXXXXXX"}
                  {...register("routing_number")}
                  inputMode="numeric"
                  error={errors?.routing_number?.message}
                  maxLength={9}
                  onChange={(e: any) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    onChange(e);
                  }}
                  value={value}
                  inputFocus={(e: any) => {
                    trigger("routing_number");
                  }}
                />
              )}
            />
            <FormControlLabel
              sx={{
                marginBottom: 3,
                marginTop: -1,
                pointerEvents: isDefaultPaymentOptionUnEditable
                  ? "none"
                  : "auto",
                opacity: isDefaultPaymentOptionUnEditable ? "0.6" : "1",
              }}
              control={
                <Checkbox
                  name="marked_as_default"
                  size="small"
                  onChange={onChangeMarkedAsDefault}
                  checked={isMarkedAsDefaultPaymentMethod}
                />
              }
              label={
                <Typography variant="h2" color={"gray.700"}>
                  {"Make this my default payment method"}
                </Typography>
              }
            />
          </Box>
          <Controller
            control={control}
            name="accountHolderFullName"
            render={({ field: { value } }) => (
              <FormInput
                label={"Payment Label"}
                id={"accountHolderFullName"}
                placeholder={"Assign a Nickname to this method"}
                {...register("accountHolderFullName")}
                error={errors?.accountHolderFullName?.message}
                onChange={(event: any) => {
                  event.target.value = event.target.value.replace("  ", " ");
                  updateBindingsChange(
                    event,
                    "accountHolderFullName",
                    setValue
                  );
                }}
                value={value}
                inputFocus={(e: any) => {
                  trigger("accountHolderFullName");
                }}
                highLightText={"Assign a Nickname to this method"}
              />
            )}
          />

          {errorMessage !== "" && (
            <Messages
              messageHeading={"Bank Linking"}
              messageContent={errorMessage}
              closeEvent={() => setErrorMessage("")}
              bottomMargin={4}
              topMargin={4}
            />
          )}
        </ShadowCard>
        <ButtonAuthorizedWrapper>
          <Button
            variant="containedLarge"
            color="primary"
            fullWidth
            type="submit"
            sx={{ overflow: "hidden" }}
          >
            {t("buttonTexts.addButton")}
          </Button>
        </ButtonAuthorizedWrapper>
      </FormAuthorizedWrapper>

      {confirmationModalOpen && (
        <ConfirmationModal
          onFormSubmit={onSubmitConfirmationModalBtn}
          title={t("requests.addOrUpdateRequest.confirmationModalTitle")}
          content={"Your bank account has successfully been added."}
          btnTitle={t("buttonTexts.okButton")}
          hideClose={true}
        />
      )}
    </>
  );
};
