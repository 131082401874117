import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

export const ResendCodeWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: ${theme.spacing(10)};
`
);

export const SendAgainText = styled(Box)(
  ({ theme }) => `
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  color: ${theme.palette.gray[900]};

  &.resend{
    color: ${theme.palette.gray[300]};
     cursor: default;
      }

  &.refresh{
    color: ${theme.palette.primary.main};
      }
  
  span {
    font-weight: medium;
    font-size: 16px;
    margin-left: ${theme.spacing(2)};
    color: ${theme.palette.primary.main};
    &.resend{
    cursor: default;
    color: ${theme.palette.gray[300]};
      }
    &.refresh{
    color: ${theme.palette.primary.main};
      }
  }
  svg {
    width: 20px;
  }
`
);
