import { Chart } from "react-google-charts";

import { Box } from "@mui/material";
import { WrapperCard } from "../WrapperCard/WrapperCard";
import { theme } from "src/shared/theme/theme";
import { CreditBuildScore } from "src/shared/models/containers/rent.model";
import { useMemo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

export interface ScoreProgressChartCardProps {
  creditScoreData?: CreditBuildScore[];
  isSuccessLoadData?: boolean;
  isLoadingFadeEnabled?: boolean;
}

export const ScoreProgressChartCard = ({
  creditScoreData,
  isSuccessLoadData,
  isLoadingFadeEnabled,
}: ScoreProgressChartCardProps) => {
  const { t } = useTranslation();
  const hasScores = creditScoreData?.length;

  const data = useMemo(() => {
    const chatData: (string | number)[][] = [["Month", "Score"]];

    if (hasScores && isSuccessLoadData) {
      creditScoreData?.forEach((creditScore) => {
        chatData.push([
          moment(creditScore.createdDate).format("MMM"),
          Math.round(creditScore.score),
        ]);
      });
    } else {
      for (let i = -2; i <= 2; i++) {
        chatData.push([moment().add(i, "month").format("MMM"), 700]);
      }
    }

    return chatData;
  }, [creditScoreData, hasScores, isSuccessLoadData]);

  const options = {
    curveType: "function",
    lineWidth: 1,
    intervals:
      isSuccessLoadData && hasScores
        ? { barWidth: 5 }
        : { lineWidth: 1, barWidth: 1, style: "boxes" },
    legend: "none",
    series:
      isSuccessLoadData && hasScores
        ? [{ color: "#387C88", lineWidth: 3 }]
        : [{ color: "#BABDC2", lineDashStyle: [1, 1] }],
    vAxis: {
      viewWindowMode: "explicit",
      textPosition: "out",
      viewWindow: {
        max: 850,
        min: 300,
      },
      textStyle: {
        fontSize: 13,
        color: "#91919F",
        marginRIght: 0,
        width: "auto",
        fontName: "'Public Sans', sans-serif",
      },
      gridlines: {
        color: "transparent",
      },
      ticks: [350, 450, 550, 650, 750, 850],
    },
    hAxis: {
      textStyle: {
        fontSize: 13,
        color: "#91919F",
        marginRIght: 0,
        width: "auto",
        fontName: "'Public Sans', sans-serif",
      },
      gridlines: {
        color: "transparent",
      },
    },
    chartArea: { width: "83%", height: "83%" },
    isStacked: true,
  };

  return (
    <WrapperCard
      title={t("rentReporting.scoreProgress.title")}
      tooltipContent={t("rentReporting.scoreProgress.tooltipContent")}
      plWrapper={0}
      prWrapper={0}
      plTitle={4}
      prTitle={4}
      minHeightWrapper={311}
      isLoadingFadeEnabled={isLoadingFadeEnabled}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          paddingLeft: "16px",
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            maxWidth: "440px",
          },
        }}
      >
        <Chart
          chartType="LineChart"
          width={"100%"}
          height={260}
          data={data}
          options={options}
          style={{
            padding: 0,
            margin: 0,
            minHeight: 260,
            position: "relative",
          }}
          legendToggle
        />
      </Box>
    </WrapperCard>
  );
};
