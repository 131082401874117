import { Box, Chip, Typography } from "@mui/material";

import { ShadowCard } from "../..";
import { DataGrid } from "src/components/Grids/DataGrid/DataGrid";
import { SearchBarForGrid } from "./SearchBar/SearchBar";
import { TooltipInfo } from "src/components/OtherUtilities/TooltipInfo/TooltipInfo";
import { FilterModal } from "./Filter/FilterModal/FilterModal";

import { configs } from "src/shared/config/config";
import { DataGridCardProps } from "src/shared/models/component/base.model";

import { handleFilter } from "src/helpers/helperFilter";

/**
 * Primary UI component for user interaction
 */
export const DataGridWithToolBarCard = ({
  dataObject,
  dataGridCardTitle = "",
  isRecentWorkOrders,
  isCardLinkAvailable,
  t,
  navigate,
  routeState = { backRouteUrl: "/requests" },
  searchText,
  onHandleResetSearch,
  onHandleSearch,
  isTooltipInfoEnabled,
  aligned = "right",
  tooltipContent,
  itemCount,
  setFilterSchema,
  createSearchParams,
  isDataFetching,
  gridType,
  isHandleArrowClickable,
  iconArrowVisible,
  searchInputPlaceHolder,
}: DataGridCardProps) => {
  const [
    onClickModalOpen,
    onClickModalClose,
    filterModalOpen,
    onChangeDateFilter,
    onChangeDateCustomEvent,
    onCancelDateCustomEvent,
    onClickApplyFilter,
    onClickResetFilter,
    onChangeDateSorting,
    filterValues,
  ]: any = handleFilter(setFilterSchema, createSearchParams, navigate);

  /**
   * Set the data asc and desc order
   */
  let updatedArray = dataObject ? [...dataObject] : [];

  if (filterValues && filterValues.dateSorting === "reported_asc") {
    updatedArray = dataObject ? [...dataObject].reverse() : [];
  }

  return (
    <>
      {gridType !== "reporting_history" && (
        <SearchBarForGrid
          onchangeHandler={onHandleSearch}
          isSearchIconVisible={searchText === "" || searchText === undefined}
          onResetSearch={onHandleResetSearch}
          searchText={searchText}
          t={t}
          isFilterVisible
          onClickFilterModalOpen={onClickModalOpen}
          isFilterApplied={window.location.search ? true : false}
          searchInputPlaceHolder={searchInputPlaceHolder}
        />
      )}
      {filterModalOpen && (
        <FilterModal
          t={t}
          onModalClose={onClickModalClose}
          onClickApplyFilter={onClickApplyFilter}
          onFilterReset={onClickResetFilter}
          onChangeDateFilter={onChangeDateFilter}
          onChangeDateCustomEvent={onChangeDateCustomEvent}
          onCancelDateCustomEvent={onCancelDateCustomEvent}
          selectedDateFilterOption={
            filterValues && filterValues.dateFilterSelected
              ? filterValues.dateFilterSelected
              : "all"
          }
          selectedCustomDateRange={
            filterValues && filterValues.selectedCustomDateRange
              ? filterValues.selectedCustomDateRange
              : null
          }
          handleDateSorting={onChangeDateSorting}
          selectedDateSortingOption={
            filterValues && filterValues.dateSorting
              ? filterValues.dateSorting
              : ""
          }
        />
      )}
      {!isDataFetching && (
        <ShadowCard
          className={`card-wrapper-home-main`}
          padding={4}
          marginBottom={6}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: isCardLinkAvailable
                ? "space-between"
                : "flex-start",
              marginBottom: 3,
              width: "100%",
            }}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Typography
                component="p"
                variant="h3SemiBold"
                textTransform={"capitalize"}
                sx={{
                  marginBottom: 1,
                  display: "inline-flex",
                  alignItems: "center",
                  color: "gray.800",
                }}
              >
                {dataGridCardTitle}
              </Typography>
              {isTooltipInfoEnabled && (
                <TooltipInfo aligned={aligned}>
                  <Box width={"150px"}>{tooltipContent}</Box>
                </TooltipInfo>
              )}
              {itemCount && (
                <Chip
                  label={itemCount === "0" ? "0" : itemCount}
                  size="small"
                  sx={{
                    fontSize: "10px",
                    color: "gray.700",
                    fontWeight: 600,
                    height: "18px",
                    paddingX: 0.5,
                    marginLeft: 4,
                    backgroundColor: "gray.100",
                  }}
                />
              )}
            </Box>
            {isCardLinkAvailable && (
              <Typography
                component="span"
                variant="h1Bold"
                textTransform={"capitalize"}
                sx={{
                  marginBottom: 1,
                  display: "inline-flex",
                  alignItems: "center",
                  color: "primary.main",
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/requests/maintenance/list")}
              >
                {t("buttonTexts.linkViewAll")}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <>
              <DataGrid
                isToggle={true}
                dataObject={updatedArray}
                searchInProgress={false}
                iconArrowVisible={iconArrowVisible}
                isHandleArrowClickable={isHandleArrowClickable}
                limit={isRecentWorkOrders ? 3 : configs.grid_data_limit}
                routeState={routeState}
                searchText={searchText}
                t={t}
                gridType={gridType}
              />
            </>
          </Box>
        </ShadowCard>
      )}
    </>
  );
};
