/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";

import { Autocomplete, TextField } from "@mui/material";

import { FormInputErrorMessage } from "../../FormInputErrorMessage/FormInputErrorMessage";
import {
  GoogleSearchSelectInputContainer,
  GoogleSearchSelectInputWrapper,
} from "./GoogleSearchAutoComplete.style";

import { GoogleSearchAutoCompleteProps } from "src/shared/models/component/base.model";
import classNames from "classnames";

export const GoogleSearchAutoComplete: React.FC<GoogleSearchAutoCompleteProps> =
  React.forwardRef<HTMLSelectElement, GoogleSearchAutoCompleteProps>(
    (
      {
        id,
        label = "",
        error,
        isValid = false,
        maxWidth = "100%",
        onSelect,
        onInputChange,
        readonly,
        value,
        inputFocus,
        highLightText,
      },
      ...props
    ) => {
      const {
        ready,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
      } = usePlacesAutocomplete({
        requestOptions: {
          componentRestrictions: { country: "us" },
        },
        debounce: 300,
      });

      const handleInputClasses =
        error && !isValid
          ? "error_input"
          : isValid
          ? "valid_input"
          : "normal_input";

      // eslint-disable-next-line
      return (
        <GoogleSearchSelectInputWrapper
          style={{ maxWidth: maxWidth }}
          className={classNames([handleInputClasses])}
        >
          <GoogleSearchSelectInputContainer>
            <Autocomplete
              freeSolo
              disablePortal
              id={id}
              onInputChange={(event, value: any) => {
                setValue(value);
                onInputChange(value);
              }}
              disabled={readonly}
              onChange={(event, value1: any) => {
                if (!value1) {
                  const addressObj = {
                    line1: "",
                    city: "",
                    state: "",
                    zip: "",
                    value: value1,
                  };
                  onSelect(addressObj);
                  return;
                }

                const parameter = {
                  placeId: value1.place_id,
                };

                getDetails(parameter).then((details: any) => {
                  const { address_components } = details;
                  const addressLines = value1.description.split(",");
                  const line1 = addressLines && addressLines[0];
                  const getLongName = (type = "") => {
                    return address_components.find(
                      (c: any) => c.types.indexOf(type) >= 0
                    )?.long_name;
                  };
                  const city = getLongName("locality");
                  const state = getLongName("administrative_area_level_1");
                  const zip = getLongName("postal_code");

                  const addressObj = {
                    line1,
                    city,
                    state,
                    zip,
                    value: value1,
                  };

                  onSelect(addressObj);
                });
              }}
              fullWidth
              options={data}
              onBlur={inputFocus && inputFocus}
              renderInput={(params) => <TextField {...params} label={label} />}
              getOptionLabel={(option: any) =>
                option?.structured_formatting?.main_text ?? value
              }
              renderOption={(props, option, index) => {
                return (
                  <li {...props} key={option.description}>
                    {option?.description}
                  </li>
                );
              }}
              value={value}
            />
          </GoogleSearchSelectInputContainer>
          {error && !isValid && (
            <FormInputErrorMessage>{error}</FormInputErrorMessage>
          )}
        </GoogleSearchSelectInputWrapper>
      );
    }
  );
