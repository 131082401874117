/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Qs from "qs";
import * as FullStory from "@fullstory/browser";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Wrapper, Header, ManualLoader, Messages } from "../../../components";

import { theme } from "../../../shared/theme/theme";
import { IMAGES } from "src/shared/assets/images";
import { LandingBannerContainer, LandingBanner } from "./Landing.style";
import {
  useCreateUserMutation,
  useLazyGetUserQuery,
} from "src/store/services/privateApi";

export const LandingScreenContainer = () => {
  const navigate = useNavigate(); // get navigate method from useNavigate hook
  const { t } = useTranslation(); // get t - translation method from useTranslation hook
  const location = useLocation(); // get location method from useLocation hook
  const { tpEnableFullstoryConfigs } = useFlags(); // get tpEnableFullstoryConfigs from useFlags hook

  // get auth0 related parameters and methods from useAuth0 hook
  const { user, loginWithRedirect, isAuthenticated, isLoading, logout } =
    useAuth0();

  // get users data via api (it's lazy loading)
  const [trigger, { data, isSuccess, isFetching, isError, error }]: any =
    useLazyGetUserQuery();
  // api for create_user (register)
  const [createUserMutation, { isLoading: isLoadingCreateUser }] =
    useCreateUserMutation();

  // set state to handle the error messages
  const [errorMessage, setErrorMessage] = useState<string>("");

  /**
   * function to handle the signin event
   * used loginWithRedirect method handler from useAuth0
   */
  const webAuthnSignIn = async () => {
    await loginWithRedirect({
      authorizationParams: {
        responseType: "code",
        redirectUri: `${window.location.origin}`,
        mode: "login",
      },
      appState: {
        returnTo: "/",
      },
    });
  };

  /**
   * create account button click event
   */
  const webAuthnSignUpRedirection = () => {
    navigate("/invitation"); // signup page redirection
  };

  /**
   * function to handle the user registration
   * registration will happen if gerSearchParams parameter is not empty string
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const registerUser = async (gerSearchParams: any) => {
    let _phone = undefined;
    let _propertyCode = undefined;
    let _unit = undefined;
    let _invitationId = undefined;

    if (gerSearchParams !== "") {
      // set values from search params __
      _phone = `${Qs.parse(gerSearchParams.substring(1))?.phone}`;
      _propertyCode = Qs.parse(gerSearchParams.substring(1))?.propertyCode;
      _unit = Qs.parse(gerSearchParams.substring(1))?.unit;
      _invitationId =
        Qs.parse(gerSearchParams.substring(1))?.invitationId ?? null;

      // create the object to send to BE for create user from the portal __
      let registerParams: any = {
        phone: _phone?.replace(/ /g, ""),
        propertyCode: _propertyCode,
        unit: _unit,
      };

      // if nvitation id is available update the params __
      if (_invitationId !== null) {
        registerParams = {
          ...registerParams,
          invitationId: _invitationId,
        };
      }

      // call the mutation __
      const createuserResponse: any = await createUserMutation(registerParams);

      // handle the response __
      if (createuserResponse && createuserResponse.data) {
        navigate("/unverified-email", {
          state: { isAuthorizedAccess: true },
        });
      } else {
        setErrorMessage(
          createuserResponse?.error?.data?.error ??
            createuserResponse?.error?.data?.message ??
            createuserResponse?.error?.data?.err
        );
      }
    }
  };

  /**
   * Render in intial load based on the dependency array valuses changed
   */
  useEffect(() => {
    // check if user isAuthenticated & signup email is verified
    if (isAuthenticated && user && user.email_verified && errorMessage === "") {
      // if signup email verification is done, then trigger the get_uer api
      trigger(null).then(() => {
        // if fetching return false
        if (isFetching) {
          return;
        }

        // if error display the errorMessage
        if (isError) {
          setErrorMessage(error?.data ?? "Unable to fetch the user details");
        }

        // check user property is null or not
        if (data && !data.user && isSuccess) {
          // if null navigate to verified email screen
          navigate("/verified-email", {
            state: {
              isAuthorizedAccess: true,
            },
          });
        } else {
          // if not null navigate to home screen
          navigate("/home");
        }

        // Fullstory Identifying users
        if (data && data?.user && tpEnableFullstoryConfigs) {
          if (data?.user?.id) {
            FullStory.identify(data?.user?.id);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    isAuthenticated,
    isFetching,
    isSuccess,
    navigate,
    tpEnableFullstoryConfigs,
    trigger,
    user,
    errorMessage,
  ]);

  /**
   * Render in intial load based on the dependency array valuses changed
   */
  useEffect(() => {
    const gerSearchParams = location.search ?? "";

    if (isAuthenticated && user && !user.email_verified && gerSearchParams) {
      registerUser(gerSearchParams);
    } else if (
      isAuthenticated &&
      user &&
      !user.email_verified &&
      gerSearchParams === ""
    ) {
      navigate("/unverified-email", {
        state: { isAuthorizedAccess: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  /**
   * UI for if user is not authenticated and auth0 details are already loaded
   */
  if (!isAuthenticated && !isLoading && errorMessage === "") {
    return (
      <Wrapper>
        <Box
          component={"div"}
          sx={{
            paddingX: 4,
          }}
        >
          <Header
            isBackButtonVisible={false}
            paddingX={0}
            isLanguageSwitcher
            isLogoLeftAligned
          />
        </Box>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
            paddingX: 4,
            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              flex: 1,
            },
          }}
        >
          <LandingBannerContainer>
            <LandingBanner>
              <img src={IMAGES.LANDING_BANNER} alt="Landing Banner" />
            </LandingBanner>
          </LandingBannerContainer>

          <Button
            variant="containedLarge"
            color="primary"
            onClick={webAuthnSignUpRedirection}
            fullWidth
            sx={{ marginBottom: 4, marginTop: 4 }}
            id={"SignUp"}
          >
            {t("buttonTexts.signupButtonText")}
          </Button>
          <Button
            variant="outlinedLarge"
            color="primary"
            onClick={webAuthnSignIn}
            fullWidth
            id={"Login"}
          >
            {t("buttonTexts.loginButtonText")}
          </Button>
        </Box>
      </Wrapper>
    );
  }

  /**
   * UI for if loading user and auth0 details
   */
  if ((isLoading || isLoadingCreateUser) && errorMessage === "") {
    return <ManualLoader />;
  }

  /**
   * UI for if error message is not empty string
   */
  if (errorMessage !== "") {
    return (
      <Wrapper>
        <Box
          component={"div"}
          sx={{
            paddingX: 4,
          }}
        >
          <Header
            isBackButtonVisible={false}
            paddingX={0}
            isLanguageSwitcher
            isLogoLeftAligned
          />
        </Box>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
            paddingX: 4,
            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              flex: 1,
            },
          }}
        >
          <Box
            component={"div"}
            sx={{
              marginBottom: 12,
            }}
          >
            <Messages
              messageHeading="Register User Details"
              messageContent={errorMessage}
              hideCloseIcon
            />
          </Box>
          <Button
            variant="containedLarge"
            color="primary"
            onClick={() => {
              navigate("/");
              setErrorMessage("");
            }}
            fullWidth
            id={"Go back"}
            sx={{
              marginBottom: 6,
            }}
          >
            {t("buttonTexts.goBackButton")}
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              logout({ logoutParams: { returnTo: window.location.origin } });
              setErrorMessage("");
            }}
            fullWidth
            id={"Log Out"}
          >
            {"Log Out"}
          </Button>
        </Box>
      </Wrapper>
    );
  }

  /**
   * UI for default state
   */
  return <ManualLoader />;
};
