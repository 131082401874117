import * as React from "react";
import { Autocomplete, TextField } from "@mui/material";

import { FormInputErrorMessage } from "../../FormInputErrorMessage/FormInputErrorMessage";
import {
  FormSelectInputContainer,
  FormSelectInputWrapper,
} from "./DropdownState.style";

import { DropdownData as dropdownData } from "./DropdownState.data";
import { DropdownStateProps } from "src/shared/models/component/base.model";
import classNames from "classnames";

export const DropdownState: React.FC<DropdownStateProps> = React.forwardRef<
  HTMLSelectElement,
  DropdownStateProps
>(
  (
    {
      id,
      label = "State",
      error,
      isValid = false,
      maxWidth = "100%",
      options = dropdownData,
      readonly,
      inputValue,
      onChange,
      inputFocus,
    },
    ...props
  ) => {
    const handleInputClasses =
      error && !isValid
        ? "error_input"
        : isValid
        ? "valid_input"
        : "normal_input";

    let selectedStateVal = inputValue
      ? options.find(({ label }: any) => label === inputValue)
      : null;
    selectedStateVal = selectedStateVal || null;

    return (
      <FormSelectInputWrapper
        style={{ maxWidth: maxWidth }}
        className={classNames([handleInputClasses, "default"])}
      >
        <FormSelectInputContainer>
          <Autocomplete
            disablePortal
            id={id}
            options={options && options}
            onChange={onChange}
            fullWidth
            disabled={readonly}
            value={selectedStateVal && selectedStateVal}
            onBlur={inputFocus && inputFocus}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={error?.length > 0}
                focused={inputValue ? true : false}
              />
            )}
          />
        </FormSelectInputContainer>
        {error && !isValid && (
          <FormInputErrorMessage>{error}</FormInputErrorMessage>
        )}
      </FormSelectInputWrapper>
    );
  }
);
