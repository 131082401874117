export const homeEnTranslation = {
  home: {
    welcomeText: "Welcome,",
    missingPaymentMethodsMessage: {
      heading: "Add a payment method",
      content:
        "We need a payment method on file to process your rent. You can add a card or link your bank.",
      paymentScreenContent:
        "We need a payment method on file to process your rent. You can add a card or link your bank. You also can configure the AutoPay option, once you add your default payment method.",
    },
    isNotAcceptPaymentStatus: {
      heading: "Unable to process the payment",
      content:
        "At this moment, we are unable to accept online payments for your balance. Your account is only able to receive payment in full via certified funds. Please contact the property team if you have any questions.",
    },
    welcomMessageModal: {
      content: "Thanks for signing up on TenantPort. You now have access to:",
      block1: {
        heading: "Hassle-Free Payments",
        content: `No more checks, no more stamps. Pay your rent at your convenience from anywhere and at any time. Or leave it on AutoPay so you don't miss a payment.`,
      },
      block2: {
        heading: "Quick Maintenance Requests",
        content: `Encounter an issue? Submit a maintenance request with just a few taps with a photo, and our team will be on it in no time.`,
      },
      block3: {
        heading: "Rent Reporting",
        content: `Boost your credit score by simply paying your rent. With our new app, your on-time payments can now reflect positively on your credit report.`,
      },
    },
  },
};
