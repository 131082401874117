export const requestsEsTranslation = {
  requests: {
    common: {
      heading: "Peticiones",
      tooltipContent:
        "Todos los tickets de emergencia y mantenimiento que envíe se denominan solicitudes.",
      emptyStateTitle: "Aún no hay solicitudes",
      emptyStateContent: "Sus solicitudes más recientes aparecerán aquí.",
    },
    requestActionCard: {
      title: "¿Tienes una solicitud? Haznos saber",
      buttonOneText: "Peticion de mantenimiento",
      buttonTwoText: "Acceso de invitado",
      buttonThreeText: "Agregar/quitar compañera",
      buttonFourText: "Agregar/quitar mascotas",
      inProgressCardTitle: "Solicitudes en proceso",
      emptyCardContent: "No tienes solicitudes actuales",
      emptyCardContent2: "No tienes solicitudes recientes",
      emptyCardBtntext: "Nueva solicitud",
    },
    addOrUpdateRequest: {
      newRequestTitle: "Nuevas solicitudes",
      updateRequestTitle: "Solicitudes de actualización",
      enterValidDetailMessage: "Por favor, introduzca los datos válidos",
      enterMissingDetailMessage: "Por favor agregue los detalles que faltan",
      confirmationModalTitle: "Éxito !",
      confirmationModalContentForUpdate:
        "Tu petición {{number}} ha sido actualizado con éxito.",
      confirmationModalContentForNew:
        "Tu petición {{number}} ha sido enviado con éxito.",
    },
    requestDetail: {
      titlePrefix: "Pedido",
      timlineCard: {
        title: "LÍNEA DE TIEMPO",
        timelineOneTitle: "Solicitud enviada",
        timelineTwoTitle: "En cola para el trabajo",
        timelineThreeTitle: "Mantenimiento programado",
        timelineFourTitle: `Solicitud completada`,
        timelineFiveTitle: `Solicitud cerrada`,
        timelineSixTitle: `Solicitud cancelada`,
      },
      statusUpdateConfirmationMessage:
        "¿Estás segura de que deseas cancelar esta solicitud?",
    },
    requestGrid: {
      coloumnOneName: "TÍTULO",
      coloumnTwoName: "FECHA",
      coloumnThreeName: "#NÚMERO",
    },
  },
};
