import { Box, Card, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ImageBanner } from "src/components";
import { IDENTITY_IMAGES } from "src/shared/assets/images";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

export const VerifyIdentityScreen = ({
  onNextScreen,
}: {
  onNextScreen: VoidFunction;
}) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        backgroundColor: "yellow.50",
        pl: 4,
        pr: 8,
        py: 5.5,
        my: 5,
        position: "relative",
        boxShadow:
          "0px 4px 12px 0px rgba(0, 0, 0, 0.10), 0px 0px 3px 0px rgba(0, 0, 0, 0.06)",
        cursor: "pointer",
      }}
      onClick={onNextScreen}
    >
      <Stack flexDirection="row" alignItems="center" gap={3}>
        <ImageBanner
          imgAlt="Identity Failed"
          imgPath={IDENTITY_IMAGES.VERIFY}
          imgWidth="40px"
          sxImageWrapper={{ marginBottom: 0, width: "40px" }}
        />
        <Box>
          <Typography variant="h2" gutterBottom sx={{ fontWeight: "600" }}>
            {t("identity.verifyIdentityTitle")}
          </Typography>
          <Typography variant="h1">
            {t("identity.verifyIdentityContent")}
          </Typography>
        </Box>
      </Stack>
      <KeyboardArrowRightOutlinedIcon
        sx={{
          position: "absolute",
          color: "gray.300",
          fontSize: 20,
          top: "50%",
          right: 16,
          transform: "translateY(-50%)",
        }}
      />
    </Card>
  );
};
