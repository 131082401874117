const getApiUrl = () => {
  const { host } = window.location;
  if (host.indexOf("localhost") > -1 || host.indexOf(".qa") > -1) {
    return "https://api.qa.tenantport.com";
  } else {
    return "https://api.tenantport.com";
  }
};
export const configs = {
  auth0_domain: "rp-resident-portal.us.auth0.com",
  api_audience: "http://resident-portal-API",
  clientId: "8Saw8xEXqQ9UezAv7Ff2wQD8g2nzCQKQ",
  base_url: getApiUrl(),
  grid_data_limit: 2000,
  BASE_CURRENCY: "$",
  FULLSTORY_ORG_ID: "o-1CZ4ZR-na1",
};
