export const ERROR_MESSAGES = {
  REQUIRED: "Please complete this field",
  EMAIL_INVALID: "Please enter a valid email address",
  PHONE_NUMBER_INVALID: "Phone number required 10 digits",
  DESCRIPTION_MAX_LIMIT: "Description length should not exceed 4000 characters",
  TITLE_MAX_LIMIT: "Title length should not exceed 150 characters",
  ACCOUNT_NUMBER_INVALID: "Input required between 5 and 17 digits",
  ACCOUNTS_NOT_MATCH: "Accounts do not match",
  SSN_INVALID_FULL: "Input required only 9 digits",
  SELECT_ACCOUNT_TYPE: "Please select the account type",
  ZIPCODE_INVALID: "Input required only 5 digits",
  SELECT_CARD_TYPE: "Please select the card type",
  NAMES_REQUIRED: "Please enter the last name.",
  SSN_INVALID_LAST4: "Input required only 4 digits",
  DATE_INVALID: "Incorrect date format, please check.",
  DATE_INVALID_LESS_THAN_18: "It seems your age is less than 18",
};

export const ERROR_MESSAGES_CARD_COMPONENT = {
  emptyCardNumber: "Please enter a valid card number",
  invalidCardNumber: "The card number is invalid",
  emptyExpiryDate: "Please enter a valid expiration date",
  monthOutOfRange: "The expiration month must be between 01 and 12",
  yearOutOfRange: "The expiration year cannot be in the past",
  dateOutOfRange: "Expiration date cannot be in the past",
  invalidExpiryDate: "The expiration date is invalid",
  emptyCVC: "Please enter a valid security code",
  invalidCVC: "The security code is invalid",
};
