import React from "react";
import { useTranslation } from "react-i18next";

import { commonSpacing, theme } from "src/shared/theme/theme";

import { Box, Button, Typography } from "@mui/material";
import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  ContentContainerBottom,
  DivWrapper,
} from "../../../components";
import { WeNotFoundMatch } from "src/components/Media/Icon/Icons/Icons";

export interface ISearchProperty {
  onBackButtonClick?: () => void;
  customer?: string;
}

export const SearchPropertyFailiureContainer = ({
  onBackButtonClick,
  customer,
}: ISearchProperty) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <DivWrapper className="no_fullscreen">
        <Box
          component={"div"}
          className={"container_content"}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: 400 + commonSpacing.desktopPaddingB * 4,
            },
          }}
        >
          <Header
            isBackButtonVisible={true}
            onBackButtonClick={onBackButtonClick}
            paddingX={0}
          />
          <Box sx={{ marginBottom: 16 }}>
            <HeadingSummaryBlock
              heading={t("headingContent.statusses.weNotFoundVendor.heading")}
              headingAlignment="left"
              content={t("headingContent.statusses.weNotFoundVendor.content1", {
                customer: customer,
              })}
              contentAlignment="left"
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",

              flexDirection: "column",
              marginBottom: 3,
            }}
          >
            <Box
              sx={{
                width: "105px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                marginBottom: 16,
              }}
            >
              <WeNotFoundMatch />
            </Box>

            <Typography
              variant="body2"
              sx={{
                textAlign: "left",
              }}
            >
              {t("headingContent.statusses.weNotFoundVendor.content2", {
                customer: customer,
              })}
            </Typography>
          </Box>
        </Box>

        <ContentContainerBottom className={"no_fullscreen"}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            onClick={onBackButtonClick}
            fullWidth
            sx={{ overflow: "hidden" }}
          >
            {t("buttonTexts.goBackButton")}
          </Button>
        </ContentContainerBottom>
      </DivWrapper>
    </Wrapper>
  );
};
