import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  ActionItemCard,
  DataGridCard,
  GridEmptyCard,
  Header,
  HeaderFixed,
  LandingBalanceCard,
  ManualLoader,
  Messages,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";

import { set_old_date_fortransaction_history } from "src/helpers/helperRent";
import { handleRentActionCardButtonItems } from "src/shared/assets/data";
import { todayDateYYYYMMDD } from "src/shared/utilities/dateConvertions";

import {
  useGetAutoPayQuery,
  useGetBalanceDetailsQuery,
  useGetTransactionsQuery,
  useGetUserQuery,
} from "src/store/services/privateApi";

export const RentLandingScreenContainer = () => {
  const navigate = useNavigate(); // init navigate from useNavigate hook
  const { t } = useTranslation(); // init t from useTranslation hook
  const { tpRentReportingFeature } = useFlags(); // get tpRentReportingFeature flag from useFlags hook

  const [errorMessage, setErrorMessage] = useState<string>(""); // state used to set the error message

  // get the account-balance api details
  const {
    data: balanceData,
    isSuccess: isSuccessBalanceData,
    isLoading: isLoadingBalanceData,
    isError: isErrorBalanceData,
  }: any = useGetBalanceDetailsQuery(null);

  // get the transaction list vai api
  const {
    data: transactionData,
    isSuccess: isSuccessTransactionData,
    isLoading: isLoadingTransactionData,
    isError: isErrorTransactionData,
  }: any = useGetTransactionsQuery({
    startDate: set_old_date_fortransaction_history,
    endDate: todayDateYYYYMMDD(),
  });

  // get the auto-pay api details
  const {
    data: autoPayData,
    isSuccess: isSuccessAutoPay,
    isLoading: isLoadingAutoPay,
    isError: isErrorAutoPay,
  } = useGetAutoPayQuery(null);

  // get the user api details
  const {
    data: userData,
    isSuccess: isLoadingUserDataSuccess,
    isLoading: isLoadingUserData,
  } = useGetUserQuery(null);

  /**
   * Handle the error based on the dependency change
   */
  useEffect(() => {
    if (isErrorBalanceData && !isSuccessBalanceData) {
      setErrorMessage("Unable to fetch the balance data");
    } else if (isErrorTransactionData && !isSuccessTransactionData) {
      setErrorMessage("Unable to fetch the transaction history");
    } else if (isErrorAutoPay && !isSuccessAutoPay) {
      setErrorMessage("Unable to fetch the autopay data");
    } else {
      setErrorMessage("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isErrorBalanceData,
    isErrorTransactionData,
    isErrorAutoPay,
    isSuccessBalanceData,
    isSuccessTransactionData,
    isSuccessAutoPay,
  ]);

  // check is credit reporting is enabled or not ___
  const isCreditReportingReady =
    isLoadingUserDataSuccess &&
    userData &&
    userData?.user?.isCreditReportingReady;

  // get the top nav menu items, and pass the isCreditReportingReady constant as a parameter ___
  const [rentActionCardButtonItems] = handleRentActionCardButtonItems(
    isCreditReportingReady,
    !tpRentReportingFeature
  );

  return (
    <>
      {isLoadingUserData && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide
          paddingX={0}
          headerText={t("rent.common.heading")}
          withCenteredHeaderText
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          <ActionItemCard
            navigate={navigate}
            t={t}
            actionItemsData={rentActionCardButtonItems}
            cardTitle={t("rent.rentActionCard.title")}
            cardMarginBottom={6}
          />

          <LandingBalanceCard
            balanceDetails={balanceData}
            autoPayDetails={autoPayData}
            onclickPayNowButton={() =>
              navigate("/rent/payment", { state: { isFromHome: false } })
            }
            onclickScheduleButton={() =>
              navigate("/rent/payment", { state: { isFromHome: false } })
            }
            isBalanceDetailLoadingSuccess={isSuccessBalanceData && balanceData}
            isAutoPayDetailLoadingSuccess={isSuccessAutoPay && autoPayData}
            isLoadingFadeEnabled={isLoadingAutoPay || isLoadingBalanceData}
            t={t}
          />

          {
            /**
             * display DataGridCard component with transaction data
             * If transaction data length is more than 0 &&  api data loading is done
             */
            isSuccessTransactionData &&
              transactionData &&
              transactionData?.transactions.length > 0 &&
              !isLoadingTransactionData && (
                <DataGridCard
                  t={t}
                  dataObject={transactionData?.transactions}
                  dataGridCardTitle={t("rent.common.gridHeading")}
                  isRecentWorkOrders
                  isCardLinkAvailable
                  navigate={navigate}
                  isTooltipInfoEnabled
                  tooltipContent={`Activity shows all charges to your account and payments made by you.`}
                  gridType="activity"
                  viewAllLink="/rent/activity/list"
                  routeState={{ backRouteUrl: "/home" }}
                  iconArrowVisible={false}
                  isHandleArrowClickable={false}
                />
              )
          }

          {
            /**
             * display GridEmptyCard component for empty transaction data with empty state content
             * this component will display after api data loading is done
             */
            isSuccessTransactionData &&
              transactionData &&
              transactionData?.transactions.length === 0 &&
              !isLoadingTransactionData && (
                <GridEmptyCard
                  title={t("rent.common.gridHeading")}
                  isTooltipInfoEnabled
                  tooltipContent={`Activity shows all charges to your account and payments made by you.`}
                  emptyStateContent={"You have no current activities"}
                  type="activity"
                  isButtonVisible={false}
                />
              )
          }

          {
            /**
             * display GridEmptyCard component without empty state content
             * this component will display while api data loading
             */
            isLoadingTransactionData && !isSuccessTransactionData && (
              <GridEmptyCard
                title={t("rent.common.gridHeading")}
                isTooltipInfoEnabled
                tooltipContent={`Activity shows all charges to your account and payments made by you.`}
                isEmptyCardDataVisble={false}
                isLoadingFadeEnabled={isLoadingUserData}
                isInnerLoaderEnabled={
                  !isLoadingUserData && isLoadingTransactionData
                }
              />
            )
          }

          {errorMessage !== "" && (
            <Messages
              topMargin={0}
              messageHeading={t("rent.common.heading")}
              messageContent={errorMessage}
              closeEvent={() => setErrorMessage("")}
            />
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
