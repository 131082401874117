import React from "react";
import ReactDOM from "react-dom";

import {
  ModalWrapper,
  ModalOverlay,
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalButton,
  ModalClose,
  ModalResetAll,
} from "./FilterModal.style";
import { Icon } from "src/components/Media/Icon/Icon";
import { Button, Typography } from "@mui/material";
import { ShadowCard } from "../../../..";
import { DateFilter } from "../FilterRows";
import { FilterModalProps } from "src/shared/models/component/base.model";
import { DataSorting } from "../DataSorting/DataSorting";

const domNode: any = document.getElementById("app-root");

export const FilterModal = ({
  onModalClose,
  onFilterReset,
  onClickApplyFilter,
  resetFilter,
  onChangeDateFilter,
  selectedDateFilterOption,
  onChangeDateCustomEvent,
  selectedCustomDateRange,
  onCancelDateCustomEvent,
  handleDateSorting,
  selectedDateSortingOption,
  t,
}: FilterModalProps) => {
  return ReactDOM.createPortal(
    <>
      <ModalWrapper>
        <ModalOverlay onClick={onModalClose} />
        <ModalContainer className="is_fixed">
          <ModalResetAll onClick={onFilterReset}>
            {" "}
            {t("dataGrid.filter.resetAll")}
          </ModalResetAll>
          <ModalClose onClick={onModalClose}>
            <Icon iconType="CloseIcon" />
          </ModalClose>
          <ModalHeader>
            <Typography
              variant="h3Bold"
              color="gray.800"
              component={"p"}
              textAlign="center"
            >
              {t("dataGrid.filter.title")}
            </Typography>
          </ModalHeader>
          <ModalContent>
            <ShadowCard paddingY={4}>
              <DateFilter
                onChangeDate={onChangeDateFilter}
                selectedDateOption={selectedDateFilterOption}
                onChangeDateCustomEvent={onChangeDateCustomEvent}
                onCancelDateCustomEvent={onCancelDateCustomEvent}
                selectedDateRange={selectedCustomDateRange}
                resetFilter={resetFilter}
                isRangeFilterActive={
                  selectedCustomDateRange && selectedCustomDateRange.length > 0
                }
              />
              <DataSorting
                handleOnChangeDate={handleDateSorting}
                selectedOptionDateSorting={selectedDateSortingOption}
                t={t}
              />
            </ShadowCard>
          </ModalContent>
          <ModalButton>
            <Button
              variant="containedLarge"
              color="primary"
              type="button"
              fullWidth
              onClick={onClickApplyFilter}
              sx={{ overflow: "hidden" }}
            >
              {t("dataGrid.filter.applyBtn")}
            </Button>
          </ModalButton>
        </ModalContainer>
      </ModalWrapper>
    </>,
    domNode
  );
};
