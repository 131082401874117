import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { LogoImage } from "./LogoImage";
import { LogoWrapper } from "./Logo.style";

import { LogoProps } from "../../../shared/models/component/base.model";

/**
 * Primary UI component for user interaction
 */
export const Logo = ({
  logoMarginIndex = "0",
  isLogoClickable,
  isLogoOnlyForDesktop = false,
}: LogoProps) => {
  const navigate = useNavigate();

  const handleOnClick = () => navigate("/earnings/approved");

  return (
    <>
      {isLogoClickable ? (
        <LogoWrapper
          style={{ margin: logoMarginIndex, cursor: "pointer" }}
          onClick={handleOnClick}
          className={classNames([
            "logo_wrapper",
            isLogoOnlyForDesktop ? "logo-for-desktop" : "",
          ])}
        >
          <LogoImage />
        </LogoWrapper>
      ) : (
        <LogoWrapper
          style={{ margin: logoMarginIndex }}
          className={classNames([
            "logo_wrapper",
            isLogoOnlyForDesktop ? "logo-for-desktop" : "",
          ])}
        >
          <LogoImage />
        </LogoWrapper>
      )}
    </>
  );
};
