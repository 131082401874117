import { styled } from "@mui/material/styles";

export const LanguageSwitchWrapper = styled("div")(
  ({ theme }) => `
  display: inline-flex;
  flex-direction: column;
  position: relative;
`
);

export const LanguageSwitchSelectedVal = styled("div")(
  ({ theme }) => `
 display: flex;
 align-items: center;
 font-weight: 600;
 font-size: 12px;
 line-height: 14px;
 letter-spacing: 0.24px;
  text-transform: uppercase;
  color: ${theme.palette.gray[800]};
  cursor: pointer;
`
);

export const LanguageSwitchDropdown = styled("div")(
  ({ theme }) => `
 display: flex;
 flex-direction: column;
 position: absolute;
 top: 100%;
 padding: 12px 25px 12px 20px;
 background-color: var(--white);
 box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
 z-index: 1;

 &.left_laigned {
  left: 0;
  border-radius: 0px 10px 10px 10px;
 }

 &.right_aligned {
  right: 0;
  border-radius: 10px 0px 10px 10px;
}


 .language_option {
   font-weight: 600;
   font-size: 12px;
   line-height: 14px;
   letter-spacing: 0.24px;
   text-transform: uppercase;
   color: ${theme.palette.gray[800]};
   cursor: pointer;
 }
`
);

export const DownArrow = styled("div")(
  ({ theme }) => `
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.palette.gray[800]} !important;

  svg {
   width: 18px;
  }
`
);

export const LanguageSwitchCardWrapper = styled("div")(
  ({ theme }) => `
  width: 100%;
  height: auto;
  
  .language_option_card {
    display: flex;
    width: 100%;
    height: 52px;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${theme.palette.gray[50]};

    &:first-of-type {
      border-top: none;
    }
    

    &.active {
      pointer-events: none;
    }

    &.in-active {
      cursor: pointer;
    }
  }

  .tick-icon {
    transform: rotate(10deg);
    * {
      stroke: ${theme.palette.primary.main};
      stroke-width: 2;
    }
  }
`
);
