import { Box, Typography } from "@mui/material";
import { ShadowCard } from "src/components/CommonNestedStyles/FormWrapper.style";
import { ImageBanner } from "src/components/OtherUtilities/ImageBanner/ImageBanner";
import { OrderListNumbers } from "src/components/OtherUtilities/OrderLIstNumbers/OrderLIstNumbers";
import { RENT_IMAGES } from "src/shared/assets/images";

export const RentReportingInfoCard = () => {
  return (
    <>
      <ShadowCard
        paddingX={4}
        paddingTop={5}
        paddingBottom={6}
        marginBottom={6}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          marginBottom={6}
          justifyContent={"center"}
        >
          <Typography
            variant="h6Bold"
            color={"gray.700"}
            marginBottom={4}
            textAlign={"center"}
          >
            {"Pay Rent, Build Credit"}
          </Typography>
          <Typography
            variant="h2"
            color={"gray.700"}
            marginBottom={0}
            textAlign={"center"}
          >
            {"Report your monthly rent & bills to build credit"}
          </Typography>
        </Box>
        <ImageBanner
          imgAlt=""
          imgPath={RENT_IMAGES.INFO_BANNER}
          imgWidth="335px"
        />
        <Box>
          <Typography variant="h2" color={"gray.700"} marginBottom={4}>
            {"Here’s how it works:"}
          </Typography>
          <Box paddingBottom={0}>
            <OrderListNumbers
              options={[
                { label: "Confirm your identity to begin reporting" },
                { label: "Pay your rent on time - AutoPay helps here!" },
                { label: "We take care of reporting for you" },
              ]}
            />
          </Box>
          <Typography
            variant="h2"
            color={"gray.700"}
            paddingLeft={9}
            marginBottom={0}
          >
            {"Sit back and see your credit score rise!"}
            <Typography
              component={"span"}
              fontSize={"11px"}
              marginLeft={1}
              top={"-2px"}
              position={"relative"}
            >
              ⤴️
            </Typography>
          </Typography>
        </Box>
      </ShadowCard>
    </>
  );
};
