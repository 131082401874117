import React from "react";
import { useTranslation } from "react-i18next";

import { commonSpacing, theme } from "src/shared/theme/theme";

import { Box, Button, Typography } from "@mui/material";
import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  ContentContainerBottom,
  DivWrapper,
  FooterNote,
} from "../../../components";
import { WeFoundMatchIcon } from "src/components/Media/Icon/Icons/Icons";
import { useFlags } from "launchdarkly-react-client-sdk";

export interface ISearchProperty {
  onBackButtonClick?: () => void;
  customer?: string;
  property?: string;
  onSubmitEvent?: () => void;
}

export const SearchPropertySuccessContainer = ({
  onBackButtonClick,
  customer,
  property,
  onSubmitEvent,
}: ISearchProperty) => {
  const { t } = useTranslation();
  const { tpSignupIdentityVerification } = useFlags();

  return (
    <Wrapper>
      <DivWrapper className="no_fullscreen">
        <Box
          component={"div"}
          className={"container_content  with_note bottom_terms_padding"}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: 400 + commonSpacing.desktopPaddingB * 4,
            },
          }}
        >
          <Header
            isBackButtonVisible={true}
            onBackButtonClick={onBackButtonClick}
            paddingX={0}
          />
          <Box sx={{ marginBottom: 14 }}>
            <HeadingSummaryBlock
              heading={t("headingContent.statusses.weFoundVendor.heading")}
              headingAlignment="left"
              content={t("headingContent.statusses.weFoundVendor.content1", {
                property: property,
                customer: customer,
              })}
              contentAlignment="left"
            />
            <Typography
              variant="body2"
              sx={{
                textAlign: "left",
                marginTop: 3,
              }}
            >
              {!tpSignupIdentityVerification
                ? t("headingContent.statusses.weFoundVendor.content2")
                : t("headingContent.statusses.weFoundVendor.content3")}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: 4,
              color: "primary.main",
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                marginBottom: 12,
              },
            }}
          >
            <WeFoundMatchIcon />
          </Box>
          <Box component={"div"} className="container_content_bottom ">
            <FooterNote />
          </Box>
        </Box>

        <ContentContainerBottom className={"no_fullscreen"}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            onClick={onSubmitEvent}
            fullWidth
            sx={{ overflow: "hidden" }}
          >
            {t("buttonTexts.continueButton")}
          </Button>
        </ContentContainerBottom>
      </DivWrapper>
    </Wrapper>
  );
};
