import { Box, Button, Typography } from "@mui/material";
import {
  GuestAccessIcon,
  MaintenanceRequestIcon,
  UpdatePetsIcon,
  UpdateRoomMateIcon,
  Paymenticon,
  BankIcon,
  TimerIcon,
  ReportingIcon,
  CardIcon,
} from "src/components/Media/Icon/Icons/Icons";
import {
  ActionIconButtonProps,
  ActionIconButtonType,
} from "src/shared/models/component/base.model";

export const handleRequestIconButtonIcon = (
  _cardType: ActionIconButtonType = "MaintenanceRequest"
) => {
  switch (_cardType) {
    case "MaintenanceRequest":
      return <MaintenanceRequestIcon />;
    case "GuestAccess":
      return <GuestAccessIcon />;
    case "UpdateRoomMate":
      return <UpdateRoomMateIcon />;
    case "UpdatePets":
      return <UpdatePetsIcon />;
    case "MakeAPayment":
      return <Paymenticon />;
    case "LinkedAccounts":
      return <BankIcon />;
    case "PaymentHistory":
      return <TimerIcon />;
    case "RentReporting":
      return <ReportingIcon />;
    case "BankLinking":
      return <BankIcon />;
    case "CreditOrDebitCard":
      return <CardIcon width="16" height="14" fillColor="white" />;
    default:
      break;
  }
};

//MaintenanceRequestIcon
export const ActionIconButton = ({
  cardType,
  btnTitle,
  isDisabled,
  onClickBtnEvent,
  isActive = true,
  paddingRight = 4,
}: ActionIconButtonProps) => {
  return (
    <Button
      variant="containedLarge"
      color="primary"
      type="button"
      fullWidth
      disabled={isDisabled}
      sx={{
        paddingLeft: 3,
        paddingRight: paddingRight,
        justifyContent: "flex-start",
        opacity: isActive && !isDisabled ? 1 : 0.38,
      }}
      onClick={onClickBtnEvent}
    >
      <Box width={"20px"} mr={2} height={"20px"} display={"inline-flex"}>
        {handleRequestIconButtonIcon(cardType)}
      </Box>
      <Typography
        component="span"
        variant="h3Bold"
        textAlign={"left"}
        sx={{ fontWeight: 500, fontSize: "16px" }}
      >
        {btnTitle}
      </Typography>
    </Button>
  );
};
