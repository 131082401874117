/* eslint-disable react-hooks/rules-of-hooks */
import { useCurrencyFormat } from "src/hooks";
import { configs } from "src/shared/config/config";
import { bankDetailsParams } from "src/shared/models/containers/rent.model";
import {
  CONSTANT_PAYMENT_METHOD_STATUSES,
  CONSTANT_PAYMENT_STATUSES,
} from "src/shared/utilities/constants";

/** used to set old date to set default dateFrom in transaction history */
export const set_old_date_fortransaction_history = "2010-04-03";
export const set_old_date_for_rent_reporting_history = "2023-04-01";

/**
 * functio used to handle transaction amount "-" values with Symbol
 * @param transactionAmount
 * @returns
 */
export const handleTransactionAmount = (transactionAmount?: any) => {
  let amount;
  let plusValue = false;

  if (transactionAmount) {
    if (transactionAmount > 0) {
      amount = `${useCurrencyFormat(transactionAmount)}`;
      plusValue = true;
    } else {
      const convertAMountToPositiove = Math.abs(transactionAmount);
      amount = `${transactionAmount < 0 ? "-" : ""}${useCurrencyFormat(
        convertAMountToPositiove
      )}`;
      plusValue = false;
    }
  } else {
    amount = useCurrencyFormat(0);
  }

  return [amount, plusValue];
};

/**
 * function used to reset the create bank account form values
 * @param setValue this is a method from hookform
 */
export const resetTheAddBankAccountFormFields = (setValue: any) => {
  setValue("payerFirstName", "");
  setValue("payerLastName", "");
  setValue("account_number", "");
  setValue("account_number_conf", "");
  setValue("routing_number", "");
  setValue("account_type", "");
  setValue("accountHolderFullName", "");
};

/**
 * function used to reset the create card form values
 * @param setValue this is a method from hookform
 */
export const resetTheAddCardFormFields = (setValue: any) => {
  setValue("cardHolderName", "");
  setValue("payerFirstName", "");
  setValue("payerLastName", "");
  setValue("label", "");
  setValue("line1", "");
  setValue("line2", "");
  setValue("city", "");
  setValue("state", "");
  setValue("postal_code", "");
  setValue("cardNumber", "");
  setValue("cardCVC", "");
  setValue("cardExpiryYear", "");
  setValue("cardType", "");
  setValue("addCard", "");
};

/**
 * function used to get last four digits from bankdetails object.
 * @param bankDetails
 * @returns last four digits
 */
export const getLastFourDigits = (bankDetails: any) => {
  let lastFout;

  if (bankDetails?.type === "BANK" && bankDetails?.bankAccountNo) {
    lastFout = bankDetails?.bankAccountNo.slice(-4);
  }

  if (bankDetails?.type === "CARD" && bankDetails?.lastFourDigits) {
    lastFout = bankDetails?.lastFourDigits;
  }

  return lastFout;
};

/**
 * function used to get the amount integer or floating value.
 * @param isSuccessBalanceData it's aboolean value coming from, it'll true when balance details get loaded from api
 * @param balanceData balance details from api
 * @param paymentWay wich payment waay used to do this payment, fixed, or custom
 * @param data returning from form, it's contain amount value fro the amount field (applicable if user choosed the custom payment way)
 * @returns
 */
export const getPaymentAmount = (
  isSuccessBalanceData: boolean,
  balanceData: any,
  paymentWay: "fixed" | "custom",
  data: any
) => {
  const getFullAmount =
    isSuccessBalanceData && balanceData ? balanceData?.balance : null;

  let amount = 0;

  if (getFullAmount && paymentWay === "fixed") {
    amount = getFullAmount;
  }

  if (paymentWay === "custom") {
    amount = parseFloat(data.amount);
  }

  return [amount];
};

/**
 * function used to detect the paying method type it can be 'credit_card' | 'debit_card' | 'bank'
 * @param type this is returning from selected payment method, it can be "CARD" | "BANK"
 * @param isDebitCard this is returning from selected payment method, this is used to conform, it's a debit card or not (applicable only for CARD type)
 * @returns
 */
export const getPaymentMethodType = (
  type: "CARD" | "BANK",
  isDebitCard: boolean
) => {
  let methodType = "";
  let methodTitle = "";

  if (type === "CARD" && isDebitCard) {
    methodType = "debit_card";
    methodTitle = "Debit card";
  } else if (type === "CARD" && !isDebitCard) {
    methodType = "credit_card";
    methodTitle = "Credit card";
  } else if (type === "BANK") {
    methodType = "bank";
    methodTitle = "Bank";
  }

  return [methodType, methodTitle];
};

/**
 * function used return the amount falue with currency symbol
 * @param amountValue
 * @returns
 */
export const handleAmount = (amountValue: number) => {
  let amount = "";

  if (amountValue === 0) {
    amount = `${configs.BASE_CURRENCY}0`;
  } else if (amountValue) {
    if (Math.floor(amountValue) === amountValue) {
      amount = `${useCurrencyFormat(amountValue)}`;
    } else {
      amount = `${useCurrencyFormat(amountValue)}`;
    }
  } else {
    amount = "----";
  }

  return amount;
};

export const checkDefaultPaymentMethod = (isSuccess: boolean, data: any) => {
  let getDefaultActivePaymentMethod = [];
  let autoEnableDefaultUdate = false;

  if (isSuccess && data?.paymentMethods && data?.paymentMethods.length > 0) {
    getDefaultActivePaymentMethod = data?.paymentMethods?.filter(
      (bankItem: bankDetailsParams) =>
        bankItem.status === CONSTANT_PAYMENT_METHOD_STATUSES.ACTIVE &&
        bankItem.default
    );

    if (
      getDefaultActivePaymentMethod &&
      getDefaultActivePaymentMethod.length === 0
    ) {
      autoEnableDefaultUdate = true;
    } else {
      autoEnableDefaultUdate = false;
    }
  } else {
    autoEnableDefaultUdate = true;
  }

  return [autoEnableDefaultUdate];
};

export const handleThePosition = (
  index: any,
  item: number,
  totalCount: number,
  getMinVal: number,
  scoreDifference: number
) => {
  let left: string | number = `${
    ((item - getMinVal) / scoreDifference) * 100
  }%`;
  let right: string | number = "auto";

  if (totalCount - 1 === index) {
    left = "auto";
    right = 0;
  }

  if (index === 0) {
    left = 0;
    right = "auto";
  }

  return [left, right];
};

export const sortingArrayByDateForRentStatement = (data: any, isDesc: true) => {
  const array = [...data];
  const sortedArray = array.sort((a: any, b: any) => {
    const aDate: any = new Date(a.statementDate);
    const bDate: any = new Date(b.statementDate);

    return isDesc ? bDate - aDate : aDate - bDate;
  });

  return sortedArray;
};

export const handleTheScoreScalesStatuses = (
  isSuccessLoadData: boolean,
  getLastMonthScoreFromData: number
) => {
  let scale = "NA";

  if (isSuccessLoadData && getLastMonthScoreFromData > 300) {
    if (getLastMonthScoreFromData > 300 && getLastMonthScoreFromData < 350) {
      scale = "BAD";
    } else if (
      getLastMonthScoreFromData > 350 &&
      getLastMonthScoreFromData < 580
    ) {
      scale = "POOR";
    } else if (
      getLastMonthScoreFromData > 580 &&
      getLastMonthScoreFromData < 670
    ) {
      scale = "Fair";
    } else if (
      getLastMonthScoreFromData > 670 &&
      getLastMonthScoreFromData < 740
    ) {
      scale = "GOOD";
    } else if (
      getLastMonthScoreFromData > 740 &&
      getLastMonthScoreFromData < 800
    ) {
      scale = "VERY GOOD";
    } else if (getLastMonthScoreFromData > 800) {
      scale = "EXCELENT";
    }
  }

  return [scale];
};

/**
 * Get active payment methods
 */
export const getActivePaymentMethods = (
  bankData: any,
  isCheckonlyAvailability: boolean
) => {
  let activePaymentMethods = [];
  let isActivePaymentMethodsAvailable = false;

  if (bankData && bankData.length > 0) {
    activePaymentMethods = bankData.filter(
      (bankItem: bankDetailsParams) =>
        bankItem.status === CONSTANT_PAYMENT_METHOD_STATUSES.ACTIVE
    );

    if (activePaymentMethods && activePaymentMethods?.length > 0) {
      isActivePaymentMethodsAvailable = true;
    } else {
      isActivePaymentMethodsAvailable = false;
    }
  }

  if (isCheckonlyAvailability) {
    return [isActivePaymentMethodsAvailable];
  }

  return [activePaymentMethods, isActivePaymentMethodsAvailable];
};

export const getAccountLinkUrl = (paymentStatus: string) => {
  if (paymentStatus === CONSTANT_PAYMENT_STATUSES.ACCEPT) {
    return "/rent/create/bank-account";
  }

  if (paymentStatus === CONSTANT_PAYMENT_STATUSES.CERTIFIED_FUNDS) {
    return "/rent/create/card";
  }

  return "/";
};
