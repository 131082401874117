import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { FormInput } from "../FormInput/FormInput";
import { useResetPasswordEmailMutation } from "src/store/services/privateApi";
import { ManualLoader } from "src/components/OtherUtilities/Loader/ManualLoader";
import { ConfirmationModal } from "src/components";

export interface IFormInPutChangePassword {
  email?: string;
  setErrorMessage?: any;
}
export const FormInPutChangePassword = ({
  email = "",
  setErrorMessage,
}: IFormInPutChangePassword) => {
  const { t } = useTranslation();
  const [resetPasswordEmailSendMutation, { isLoading: isLoadingRPE }] =
    useResetPasswordEmailMutation();
  const [resendConfirmationModalVisible, setResendConfirmationModalVisible] =
    useState(false);

  const handleConfirmationModalClose = () => {
    setResendConfirmationModalVisible(false);
  };

  const handleChangePasswordClick = async () => {
    const response: any = await resetPasswordEmailSendMutation(null);
    if (response) {
      if (response.error) {
        handleChangePasswordClick();
        setErrorMessage(
          response?.error?.data?.error ?? response?.error?.data?.message
        );
      } else {
        setResendConfirmationModalVisible(true);
        setErrorMessage("");
      }
    }
  };

  return (
    <>
      {isLoadingRPE && <ManualLoader />}
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <FormInput
            label={t("formInput.password")}
            id={"password"}
            type="password"
            readonly={true}
            value={"•••••••••••••••"}
          />
        </Box>
        <Typography
          sx={{
            fontSize: 10,
            fontWeight: "medium",
            position: "absolute",
            right: "12px",
            top: 16,
            zIndex: 2,
            cursor: "pointer",
          }}
          color={"blue.400"}
          onClick={handleChangePasswordClick}
        >
          {t("formInput.changePasswordText")}
        </Typography>
      </Box>

      {resendConfirmationModalVisible && (
        <ConfirmationModal
          onCloseEvent={handleConfirmationModalClose}
          onFormSubmit={handleConfirmationModalClose}
          title={t("settings.ChangePasswordConfirmation.title")}
          content={t("settings.ChangePasswordConfirmation.content")}
          btnTitle={t("buttonTexts.okButton")}
        />
      )}
    </>
  );
};
