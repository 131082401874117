import { useState } from "react";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { ErrorCard, Header, ManualLoader, Wrapper } from "src/components";
import { useAuth0 } from "@auth0/auth0-react";

export const Error401ScreenContainer = () => {
  const { loginWithRedirect } = useAuth0();

  const [isLoader, setIsLoader] = useState(false); // handle login page visibility throgh the components __

  const webAuthnSignIn = async () => {
    setIsLoader(true);

    await loginWithRedirect({
      authorizationParams: {
        responseType: "code",
        redirectUri: `${window.location.origin}`,
        mode: "login",
      },
    });

    setTimeout(function () {
      setIsLoader(false);
    }, 1200);
  };

  return (
    <Wrapper>
      {isLoader && <ManualLoader />}
      <Header isBackButtonVisible={false} />
      <Box paddingTop={6} paddingX={10} sx={{ textAlign: "center" }}>
        <ErrorCard>
          <Typography
            variant="h3"
            component={"p"}
            color={"gray.600"}
            textAlign={"center"}
            marginBottom={6}
          >
            {"Sorry, you have been logged out! Please log in again."}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            type="button"
            sx={{ width: "100px", marginX: "auto" }}
            onClick={webAuthnSignIn}
          >
            {"Sign In"}
          </Button>
        </ErrorCard>
      </Box>
    </Wrapper>
  );
};
