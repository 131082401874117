import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

export const StylesFormControlLabel = styled(FormControlLabel)<{
  includechilds: "true" | "false";
}>(({ theme, includechilds }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: 0,
  marginRight: 0,
  marginBottom: theme.spacing(includechilds === "true" ? 1 : 6),
}));

export const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",

    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
      "&.Mui-disabled  .MuiSwitch-thumb": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="9" width="10" viewBox="0 0 12 16"><path fill="${encodeURIComponent(
          `${theme.palette.gray[400]}`
        )}" d="M9.52381 6.66667V4C9.52381 1.79133 7.81778 0 5.71429 0C3.61079 0 1.90476 1.79133 1.90476 4V6.66667H0V16H11.4286V6.66667H9.52381ZM3.1746 6.66667V4C3.1746 2.52933 4.31365 1.33333 5.71429 1.33333C7.11492 1.33333 8.25397 2.52933 8.25397 4V6.66667H3.1746Z"/></svg>')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center left 4px",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.white,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.gray[300],
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
