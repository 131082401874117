import { styled } from "@mui/material/styles";

export const OrderList = styled("ol")(
  ({ theme }) => `
  list-style: none;
  counter-reset: item;
  padding: ${theme.spacing(1)} 0 0;
  margin: 0;
`
);

export const OrderListItem = styled("li")(
  ({ theme }) => `
 counter-increment: item;
 margin-bottom: ${theme.spacing(6)};
 color: ${theme.palette.gray[700]};
 font-size: 14px;
 font-weight: 400;
 position: relative;
 padding-left:  ${theme.spacing(9)};

 &:before {
   content: counter(item);
   width: 24px;
   height: 24px;
   border-radius: 24px;
   left: 0;
   top: -4px;
   position: absolute;
   background: ${theme.palette.primary.main};
   color: ${theme.palette.white};
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14px;
 }
`
);
