import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const FormAddressWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    width: 100%;
`
);

export const FormAddressContainer = styled(Box)`
  display: flex;
  width: 100%;
`;

export const HighLightText = styled(Box)`
  margin: 4px 0 16px;
  color: var(--black100);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  &.no-mb {
    margin-bottom: 0;
  }
`;
