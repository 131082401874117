import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import { Box, Button } from "@mui/material";

import {
  ButtonAuthorizedWrapper,
  FooterNote,
  FormAuthorizedWrapper,
  FormInput,
  FormInputDate,
  FormInputPhoneNumber,
  FormInputSSN,
  Header,
  HeaderFixed,
  HeadingSummaryBlock,
  ManualLoader,
  Messages,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";

import {
  isNot18,
  isValidDOB,
  onChangeHandlerDateInput,
  phoneNumberMask,
  phoneOnChangeHandler,
  ssnNumberMask,
} from "src/shared/utilities/formUtilities";
import { ERROR_MESSAGES } from "src/shared/utilities/validationMessages";
import { IdVerificationFormParams } from "src/shared/models/containers/rent.model";

import { useGetBlockHeight } from "src/hooks";
import { commonSpacing, theme } from "src/shared/theme/theme";
import { IDVerificationParams } from "src/shared/models/containers/account.model";
import {
  useGetIDVerificationMutation,
  usePostIDVerificationMutation,
} from "src/store/services/publicApi";
import { getFirstLastName } from "src/helpers/helperSettings";
import { delayApi } from "src/helpers/helperRequests";

interface IIdentityVerification {
  onBackButtonClick?: VoidFunction;
  onSubmitEvent?: (status: string) => void;
  phoneNumber: string;
  email: string;
  fullName?: string;
  personId?: string;
}

export const IdentityVerification = ({
  fullName,
  phoneNumber,
  email,
  personId,
  onBackButtonClick,
  onSubmitEvent,
}: IIdentityVerification) => {
  const { t } = useTranslation();
  const [enableSSNUpdate, setEnableSSNUpdate]: any = useState(true);
  const [errorManualDate, setErrorManualDate] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [elementRef, height] = useGetBlockHeight();
  const [isLoading, setLoading] = useState(false);
  const [postIDVerificationMutation] = usePostIDVerificationMutation();
  const [getIDVerification] = useGetIDVerificationMutation();

  const revieInfoSchema = yup.object({
    dob: yup
      .string()
      .required(ERROR_MESSAGES.REQUIRED)
      .test(
        "len",
        errorManualDate
          ? ERROR_MESSAGES.DATE_INVALID_LESS_THAN_18
          : ERROR_MESSAGES.DATE_INVALID,
        (value) => {
          if (
            value &&
            value?.length === 10 &&
            isNot18(value) &&
            isValidDOB(value)
          )
            return true;
          else return false;
        }
      ),
    ssn: yup
      .string()
      .test("required", ERROR_MESSAGES.REQUIRED, (value) => {
        if (value) return true;
        return false;
      })
      .test("min", ERROR_MESSAGES.SSN_INVALID_FULL, (value) => {
        if (value?.length === 9) return true;
        return false;
      }),
    fullName: yup
      .string()
      .test("required", ERROR_MESSAGES.REQUIRED, (value) => {
        if (value) return true;
        return false;
      })
      .test("required", ERROR_MESSAGES.NAMES_REQUIRED, (value) => {
        if (value) {
          const { firstName, lastName } = getFirstLastName(value);
          if (firstName && lastName) return true;
        }
        return false;
      }),
  });

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    control,
    formState: { errors },
  } = useForm<IdVerificationFormParams>({
    reValidateMode: "onBlur",
    mode: "onSubmit",
    resolver: yupResolver(revieInfoSchema),
  });

  useEffect(() => {
    if (phoneNumber) {
      setValue("phone", phoneNumberMask(phoneNumber ?? ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  useEffect(() => {
    if (fullName) {
      setValue("fullName", fullName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullName]);

  const updateBindingsChange = (event: any, feild: any) => {
    setEnableSSNUpdate(true);
    setValue(feild, event.target.value, {
      shouldValidate: true,
    });
  };

  const onChangeHandlerDate = (event: any) => {
    onChangeHandlerDateInput(event, setValue, setErrorManualDate);
  };

  const onAppFormSubmit = handleSubmit(
    async (data: IdVerificationFormParams) => {
      const fullName = getValues("fullName");
      const formData: IDVerificationParams = {
        ssn: ssnNumberMask(data?.ssn),
        dob: moment(data?.dob).format("YYYY-MM-DD"),
        phone: getValues("phone"),
        email,
        user: getFirstLastName(fullName),
        personId,
      };
      console.log("formData", formData);
      try {
        setLoading(true);
        const response: any = await postIDVerificationMutation(formData);
        if (response.error) {
          setErrorMessage(
            response.error?.data?.err ||
              "There is an error on verification. Try again."
          );
          setLoading(false);
        } else if (response.data) {
          const idvId = response.data.id;
          let status = response.data.status?.toLowerCase();
          while (status === "active") {
            const res: any = await getIDVerification(idvId);
            if (res.data) {
              status = res.data.status?.toLowerCase();
              await delayApi(1000);
            } else if (res.error) {
              setErrorMessage(
                response.error?.data?.err ||
                  "There is an error on verification. Try again."
              );
              status = "error";
            }
          }
          setLoading(false);
          onSubmitEvent?.(status);
        }
      } catch (err: any) {
        setErrorMessage(
          err?.message || "There is an error on verification. Try again."
        );
        onSubmitEvent?.("failed");
      } finally {
        setLoading(false);
      }
    }
  );

  return (
    <>
      {isLoading && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isBackButtonVisible={Boolean(onBackButtonClick)}
          onBackButtonClick={onBackButtonClick}
          paddingX={0}
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          <FormAuthorizedWrapper onSubmit={onAppFormSubmit}>
            <Box
              component={"div"}
              ref={elementRef}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: height,
                  paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
                },
              }}
            >
              <Box sx={{ marginBottom: 6 }}>
                <HeadingSummaryBlock
                  heading={t("headingContent.commonSteps.identity.heading")}
                  headingAlignment="left"
                  content={t("headingContent.commonSteps.identity.content")}
                  contentAlignment="left"
                />
              </Box>
              <Controller
                control={control}
                name="fullName"
                render={({ field: { value } }) => (
                  <FormInput
                    label={t("formInput.fullName")}
                    id={"fullName"}
                    placeholder={""}
                    {...register("fullName")}
                    error={errors.fullName?.message}
                    inputFocus={(e: any) => {
                      trigger("fullName");
                    }}
                    value={value}
                    onChange={(event: any) => {
                      setValue("fullName", event.target.value);
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="dob"
                render={({ field: { ref, value, ...rest } }) => (
                  <FormInputDate
                    label={t("formInput.dob")}
                    id={"dob"}
                    {...register("dob")}
                    inputError={errors.dob?.message}
                    onChange={(event: any) => {
                      onChangeHandlerDate(event);
                    }}
                    inputMode="numeric"
                    inputFocus={(e: any) => {
                      trigger("dob");
                    }}
                    value={value || ""}
                  />
                )}
              />
              <Controller
                control={control}
                name="ssn"
                render={({ field: { value, ref } }) => (
                  <FormInputSSN
                    label={t("formInput.ssn")}
                    id={"securityNumber"}
                    only4={false}
                    {...register("ssn")}
                    error={errors?.ssn?.message}
                    inputError={errors?.ssn?.message}
                    onChange={(event: any) => {
                      updateBindingsChange(event, "ssn");
                    }}
                    inputFocus={(e: any) => {
                      trigger("ssn");
                    }}
                    replace={enableSSNUpdate}
                    setEnableSSNUpdate={setEnableSSNUpdate}
                  />
                )}
              />
              <Controller
                control={control}
                name="phone"
                render={({ field: { value } }) => (
                  <FormInputPhoneNumber
                    label={t("formInput.phone")}
                    id={"phone"}
                    placeholder={"(123) 543-3454"}
                    {...register("phone")}
                    onChange={(e: any) => {
                      phoneOnChangeHandler(e, setValue, false);
                    }}
                    inputMode="numeric"
                    value={value}
                  />
                )}
              />
              {errorMessage !== "" && (
                <Messages
                  messageHeading={"Identity Verification"}
                  messageContent={errorMessage}
                  closeEvent={() => setErrorMessage("")}
                  bottomMargin={4}
                  topMargin={4}
                />
              )}
            </Box>
            <Box
              component={"div"}
              className="container_content_bottom"
              sx={{ mb: 2 }}
            >
              <FooterNote buttonText="Continue" />
            </Box>
            <ButtonAuthorizedWrapper>
              <Button
                variant="containedLarge"
                color="primary"
                fullWidth
                type="submit"
                sx={{ overflow: "hidden" }}
              >
                {t("buttonTexts.continueButton")}
              </Button>
            </ButtonAuthorizedWrapper>
          </FormAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
