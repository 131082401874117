import { Typography } from "@mui/material";
import {
  ConnectorLine,
  BottomSection,
  Container,
  SecondRow,
  DetailsSection,
} from "./TimelineItem.style";
import {
  QuedIcon,
  RequestCOmpletedIcon,
  MaintenanceSceduledIcon,
  SubmittedIcon,
  CancelAccountIcon,
} from "src/components/Media/Icon/Icons/Icons";
import { ReactNode } from "react";
import { TimeLineItemProps } from "src/shared/models/component/base.model";

export const TimelineItem = ({
  itemName,
  dateText,
  timeText,
  position,
  isActive = false,
  hideConnector = false,
  t,
}: TimeLineItemProps) => {
  const getIcon = (): ReactNode => {
    switch (position) {
      case 1:
        return <SubmittedIcon isActive={isActive} />;
      case 2:
        return <QuedIcon isActive={isActive} />;
      case 3:
        return <MaintenanceSceduledIcon isActive={isActive} />;
      case 4:
        return <RequestCOmpletedIcon isActive={isActive} />;
      case 5:
        return <CancelAccountIcon />;
      default:
        return <SubmittedIcon isActive={isActive} />;
    }
  };

  return (
    <Container>
      {position === 1 || hideConnector ? null : (
        <ConnectorLine isActive={isActive} />
      )}
      <BottomSection>
        {getIcon()}
        <DetailsSection>
          <Typography
            variant="h2Bold"
            component={"p"}
            color={"gray.700"}
            sx={{ marginBottom: 1, opacity: isActive ? 1 : 0.6 }}
          >
            {itemName}
          </Typography>
          <SecondRow>
            <Typography
              variant="h1"
              color={"gray.500"}
              sx={{ opacity: isActive ? 1 : 0.6 }}
            >
              {dateText}
            </Typography>
            {timeText ? (
              <Typography
                variant="h1"
                component={"p"}
                color={"gray.500"}
                sx={{ marginLeft: 0.75, opacity: isActive ? 1 : 0.6 }}
              >
                <span>• </span>
                {isActive ? timeText : "Estimated"}
              </Typography>
            ) : null}
          </SecondRow>
        </DetailsSection>
      </BottomSection>
    </Container>
  );
};
