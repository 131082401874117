import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import {
  DataGridCard,
  GridEmptyCard,
  Header,
  HeaderFixed,
  LandingBalanceCard,
  ManualLoader,
  Messages,
  NotificationMessage,
  WelcomeMessageModal,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";

import { theme } from "src/shared/theme/theme";

import {
  useGetAutoPayQuery,
  useGetBalanceDetailsQuery,
  useGetPaymentMethodsQuery,
  useGetUserQuery,
  useGetWorkOrdersQuery,
  useLazyGetUserQuery,
} from "src/store/services/privateApi";
import {
  CONSTANT_PAYMENT_METHOD_STATUSES,
  CONSTANT_PAYMENT_STATUSES,
} from "src/shared/utilities/constants";
import { getAccountLinkUrl } from "src/helpers/helperRent";
import { IdentityNote } from "../Identity/IdentityNote";
import { IDENTITY_VERIFICATION_SCREEN } from "src/shared/models/containers/identity.model";
import { VerifyIdentityScreen } from "../Identity/VerifyIdentity";
import { IdentityVerification } from "../Account/Reporting/Identity";
import { IdentityApproved } from "../Account/Reporting/Approved";
import { IdentityFailed } from "../Account/Reporting/Failed";
import { getFullName } from "src/helpers/helperSettings";
import { useFlags } from "launchdarkly-react-client-sdk";

export const HomeScreenContainer = () => {
  const navigate = useNavigate(); // init navigate from useNavigate hook
  const { t } = useTranslation(); // init t from useTranslation hook
  const location = useLocation();
  const { tpExistingUserIdentityVerification } = useFlags();

  const [errorMessage, setErrorMessage] = useState<string>(""); // state used to set the error message
  const [inProgressRequests, setInProgressRequests] = useState<any>([]); // state used to set onlythe inprogress status contain requests
  const [isActivePaymentMethodsAvailable, setIsActivePaymentMethodsAvailable] =
    useState(true); // state used to check active payment methods available or not
  const [welcomeMessageModalVisible, setWelcomeMessageModalVisible] =
    useState(false); // set state to handle the welcome modal visibility
  const [idvScreen, setIdvScreen] = useState<IDENTITY_VERIFICATION_SCREEN>(
    IDENTITY_VERIFICATION_SCREEN.NONE
  );
  const [isCompletedKYC, setIsCompletedKYC] = useState(false);

  const { isFromVerifiedEmail } = location.state
    ? (location.state as { isFromVerifiedEmail: boolean })
    : { isFromVerifiedEmail: false };

  // get the account-balance api details
  const {
    data: balanceData,
    isSuccess: isSuccessBalanceData,
    isLoading: isLoadingBalanceData,
    isError: isErrorBalanceData,
  }: any = useGetBalanceDetailsQuery(null);

  // get the work orders (requests) list from api
  const { data, isSuccess, isLoading, isError }: any = useGetWorkOrdersQuery({
    isDateFilterAvailable: false,
  });
  console.log("HomeScreenContainer");
  // get the user api details
  const {
    data: userData,
    isSuccess: isSuccessUserData,
    isLoading: isLoadingUserData,
    isError: isErrorUserData,
  }: any = useGetUserQuery(null);
  const [getUserLazyRequest]: any = useLazyGetUserQuery();

  let currentLease = userData?.user?.person?.leaseLinks?.find((link: any) => {
    return link.lease.Status === "Current";
  });
  if (!currentLease && userData?.user?.person?.leaseLinks) {
    currentLease = userData?.user?.person?.leaseLinks[0];
  }

  // get the auto-pay api details
  const {
    data: autoPayData,
    isSuccess: isSuccessAutoPay,
    isLoading: isLoadingAutoPay,
    isError: isErrorAutoPay,
  } = useGetAutoPayQuery(null);

  // get the payment method api details
  const { data: paymentMethodsData, isSuccess: isSuccessPaymentMethods } =
    useGetPaymentMethodsQuery(null);

  /**
   * Handle the error based on the dependency change
   */
  useEffect(() => {
    if (isSuccess && data && data.length > 0) {
      const getInProgressRequests = data.filter(
        (item: any) => item.Status === "In Progress"
      );

      if (getInProgressRequests && getInProgressRequests.length > 0) {
        setInProgressRequests(getInProgressRequests);
      } else {
        setInProgressRequests([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess]);

  /**
   * Check the active payment methods available or not
   */
  useEffect(() => {
    let activePaymentMethods = [];

    if (
      isSuccessPaymentMethods &&
      paymentMethodsData &&
      paymentMethodsData?.paymentMethods?.length > 0
    ) {
      activePaymentMethods = paymentMethodsData?.paymentMethods.filter(
        (item: { status: string }) =>
          item.status === CONSTANT_PAYMENT_METHOD_STATUSES.ACTIVE
      );
      if (activePaymentMethods && activePaymentMethods.length > 0) {
        setIsActivePaymentMethodsAvailable(true);
      } else {
        setIsActivePaymentMethodsAvailable(false);
      }
    }

    if (
      isSuccessPaymentMethods &&
      paymentMethodsData &&
      paymentMethodsData?.paymentMethods?.length === 0
    ) {
      setIsActivePaymentMethodsAvailable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodsData, isSuccessPaymentMethods]);

  /**
   * Handle the welcome message modal if user navigate from registration flow
   */
  useEffect(() => {
    if (isFromVerifiedEmail && isSuccessUserData) {
      if (userData && userData?.user) {
        setWelcomeMessageModalVisible(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUserData, userData, isFromVerifiedEmail]);

  useEffect(() => {
    const isKycSucces = userData?.user?.isKycSuccess;
    if (isKycSucces) {
      setIdvScreen(IDENTITY_VERIFICATION_SCREEN.NONE);
    } else {
      setIdvScreen(IDENTITY_VERIFICATION_SCREEN.MESSAGE);
    }
    setIsCompletedKYC(isKycSucces);
    localStorage.setItem("isKycSucces", String(isKycSucces));
  }, [userData?.user?.isKycSuccess]);

  /**
   * Welcome message modal close event
   */

  const onWelcomeMessageModelCloseEvent = () => {
    setWelcomeMessageModalVisible(true);
    navigate("/home", { replace: true });
  };

  /**
   * Close Identity Verification Message
   */
  const handleShowNormalHomePage = () => {
    setIdvScreen(IDENTITY_VERIFICATION_SCREEN.NONE);
  };

  const handleIdentityApproved = async () => {
    const { data: updatedUserData } = await getUserLazyRequest();
    setIsCompletedKYC(updatedUserData?.user?.isKycSuccess);
    localStorage.setItem("isKycSucces", updatedUserData?.user?.isKycSuccess);
    setIdvScreen(IDENTITY_VERIFICATION_SCREEN.NONE);
  };

  /**
   * Click verify now on identity verification message
   */
  const onVerifyNow = () => {
    setIdvScreen(IDENTITY_VERIFICATION_SCREEN.Identity_Verification);
  };

  const onVerifyIdentityStart = () => {
    setIdvScreen(IDENTITY_VERIFICATION_SCREEN.Identity_Verification);
  };

  const onIdentityVerified = (status: string) => {
    if (status === "success")
      setIdvScreen(IDENTITY_VERIFICATION_SCREEN.IDENTITY_APPROVED);
    else setIdvScreen(IDENTITY_VERIFICATION_SCREEN.IDENTITY_FAILED);
  };

  /**
   * Handle the Errors
   */
  useEffect(() => {
    if (isErrorUserData && !isSuccessUserData) {
      setErrorMessage("Unable to fetch the user information");
    } else if (isErrorBalanceData && !isSuccessBalanceData) {
      setErrorMessage("Unable to fetch the balance data");
    } else if (isError && !isSuccess) {
      setErrorMessage("Unable to fetch the work orders");
    } else if (isErrorAutoPay && !isSuccessAutoPay) {
      setErrorMessage("Unable to fetch the autopay data");
    } else {
      setErrorMessage("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isErrorBalanceData,
    isError,
    isErrorAutoPay,
    isSuccessBalanceData,
    isSuccess,
    isErrorUserData,
    isSuccessUserData,
    isSuccessAutoPay,
  ]);

  const setBottomHeaderContent =
    isSuccess && userData
      ? `${currentLease?.lease?.property?.name} | Unit ${currentLease?.lease?.Unit}`
      : "";
  const residentName =
    isSuccessUserData && userData ? userData?.user?.person?.FirstName : "";

  if (idvScreen === IDENTITY_VERIFICATION_SCREEN.Identity_Verification) {
    return (
      <IdentityVerification
        phoneNumber={userData?.user?.phone}
        email={userData?.user?.email}
        fullName={getFullName(
          userData?.user?.person?.FirstName,
          userData?.user?.person?.LastName
        )}
        personId={userData?.user?.person?.id}
        onSubmitEvent={onIdentityVerified}
        onBackButtonClick={handleShowNormalHomePage}
      />
    );
  }

  if (idvScreen === IDENTITY_VERIFICATION_SCREEN.IDENTITY_APPROVED) {
    return <IdentityApproved onSubmitEvent={handleIdentityApproved} />;
  }

  if (idvScreen === IDENTITY_VERIFICATION_SCREEN.IDENTITY_FAILED) {
    return (
      <IdentityFailed
        onSubmitEvent={onVerifyIdentityStart}
        submitButtonText={t("buttonTexts.goBackButton")}
      />
    );
  }

  return (
    <>
      {isLoadingUserData && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop" flexDirection={"column"}>
        <Header
          isMainMunuVisible
          isBackButtonHide
          paddingX={0}
          isBottomContentVisible
          bottomHighlightContent={setBottomHeaderContent}
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        {isSuccessUserData &&
        tpExistingUserIdentityVerification &&
        idvScreen === IDENTITY_VERIFICATION_SCREEN.MESSAGE ? (
          <IdentityNote
            onVerifyNow={onVerifyNow}
            onCloseNote={handleShowNormalHomePage}
          />
        ) : (
          <WrpperAuthorizedContainer style={{ marginBottom: 40 }}>
            <Box minHeight={54} sx={{ display: "flex", width: "100%" }}>
              <Typography
                component="h1"
                variant="h6"
                textTransform={"capitalize"}
                sx={{
                  marginBottom: 3,
                  display: "inline-flex",
                  alignItems: "center",
                  color: "gray.900",
                  fontWeight: 300,
                  [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                    paddingTop: 6,
                  },
                  [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                    paddingTop: 8,
                  },
                }}
              >
                {`${!isLoadingUserData ? t("home.welcomeText") : ""} ${
                  residentName ?? ""
                }`}
              </Typography>
            </Box>

            {isSuccessUserData &&
              !isCompletedKYC &&
              tpExistingUserIdentityVerification && (
                <VerifyIdentityScreen onNextScreen={onVerifyIdentityStart} />
              )}

            <Box sx={{ position: "relative" }}>
              {isSuccessUserData &&
                !isCompletedKYC &&
                tpExistingUserIdentityVerification && (
                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "calc(100% - 24px)",
                      background: "#D9D9D9",
                      opacity: 0.5,
                      zIndex: 1,
                    }}
                  />
                )}

              <LandingBalanceCard
                balanceDetails={balanceData}
                autoPayDetails={autoPayData}
                onclickPayNowButton={() =>
                  navigate("/rent/payment", { state: { isFromHome: true } })
                }
                onclickScheduleButton={() =>
                  navigate("/rent/payment#auto-pay", {
                    state: { isFromHome: true },
                  })
                }
                isBalanceDetailLoadingSuccess={
                  isSuccessBalanceData && balanceData
                }
                isAutoPayDetailLoadingSuccess={isSuccessAutoPay && autoPayData}
                isLoadingFadeEnabled={isLoadingAutoPay || isLoadingBalanceData}
                t={t}
              />

              {isSuccessPaymentMethods && !isActivePaymentMethodsAvailable && (
                <NotificationMessage
                  messageHeading={t(
                    "home.missingPaymentMethodsMessage.heading"
                  )}
                  messageContent={t(
                    "home.missingPaymentMethodsMessage.content"
                  )}
                  messageLinkEvent={() =>
                    navigate(getAccountLinkUrl(balanceData?.paymentStatus), {
                      state: {
                        isFromHome: true,
                        isOnlyCard:
                          balanceData?.paymentStatus ===
                          CONSTANT_PAYMENT_STATUSES.CERTIFIED_FUNDS,
                      },
                    })
                  }
                  variant="no_payment_methods"
                  topMargin={0}
                />
              )}

              {
                /**
                 * display DataGridCard component with inprogress-requests data
                 * If transaction data length is more than 0 &&  api data loading is done
                 */
                isSuccess &&
                  inProgressRequests &&
                  inProgressRequests.length > 0 &&
                  !isLoading && (
                    <DataGridCard
                      t={t}
                      dataObject={inProgressRequests}
                      dataGridCardTitle={t(
                        "requests.requestActionCard.inProgressCardTitle"
                      )}
                      isRecentWorkOrders
                      isCardLinkAvailable
                      navigate={navigate}
                      isTooltipInfoEnabled
                      tooltipContent={t("requests.common.tooltipContent")}
                      gridType="request"
                      iconArrowVisible={true}
                      isHandleArrowClickable={true}
                    />
                  )
              }

              {
                /**
                 * display GridEmptyCard component for empty inprogress-requests data with empty state content
                 * this component will display after api data loading is done
                 */
                isSuccess &&
                  inProgressRequests &&
                  inProgressRequests.length === 0 &&
                  !isLoading && (
                    <GridEmptyCard
                      title={t(
                        "requests.requestActionCard.inProgressCardTitle"
                      )}
                      emptyStateContent={t(
                        "requests.requestActionCard.emptyCardContent"
                      )}
                      isTooltipInfoEnabled
                      tooltipContent={t("requests.common.tooltipContent")}
                      onClickBtn={() =>
                        navigate("/requests/maintenance/create", {
                          state: { isFromHome: true },
                        })
                      }
                      buttonText={t(
                        "requests.requestActionCard.emptyCardBtntext"
                      )}
                    />
                  )
              }

              {
                /**
                 * display GridEmptyCard component without empty state content
                 * this component will display while api data loading
                 */
                isLoading && !isSuccess && (
                  <GridEmptyCard
                    title={t("requests.requestActionCard.inProgressCardTitle")}
                    isEmptyCardDataVisble={false}
                    isTooltipInfoEnabled
                    tooltipContent={t("requests.common.tooltipContent")}
                    isLoadingFadeEnabled={isLoadingUserData}
                    isInnerLoaderEnabled={!isLoadingUserData && isLoading}
                  />
                )
              }

              {errorMessage !== "" && (
                <Messages
                  topMargin={6}
                  messageContent={errorMessage}
                  closeEvent={() => setErrorMessage("")}
                />
              )}
            </Box>
          </WrpperAuthorizedContainer>
        )}
      </Wrapper>

      {welcomeMessageModalVisible && isFromVerifiedEmail && (
        <WelcomeMessageModal
          t={t}
          ResidentName={residentName}
          closeIdModalEvent={onWelcomeMessageModelCloseEvent}
        />
      )}
    </>
  );
};
