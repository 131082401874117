import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MenuItem, MenuNav } from "./MainMenu.style";
import { Typography } from "@mui/material";
import {
  LargeDollarIcon,
  HomeIcon,
  MenuIconRequests,
  MenuIconAccount,
} from "../../Media/Icon/Icons/Icons";
import { useFlags } from "launchdarkly-react-client-sdk";

export const MainMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { tpExistingUserIdentityVerification } = useFlags();
  const isCompletedKYC = localStorage.getItem("isKycSucces");

  const NavData = [
    {
      title: t("menu.dashboard"),
      urlPath: "/home",
      iconType: "dashboard",
    },
    { title: t("menu.rent"), urlPath: "/rent", iconType: "rent" },
    { title: t("menu.requests"), urlPath: "/requests", iconType: "invoices" },
    { title: t("menu.settings"), urlPath: "/settings", iconType: "settings" },
  ];

  /**
   * used to handle the icons for mobile menu
   * @param _title this will retrive from NavData json
   * @returns Icon type
   */
  const handleIcon = (_title?: string) => {
    switch (_title) {
      case t("menu.dashboard"):
        return <HomeIcon />;
      case t("menu.requests"):
        return <MenuIconRequests />;
      case t("menu.rent"):
        return <LargeDollarIcon />;
      case t("menu.payments"):
        return <LargeDollarIcon />;
      case t("menu.settings"):
        return <MenuIconAccount />;
      default:
        break;
    }
  };

  /**
   * used to hand the Invoices menu item active
   * @returns class name of invoices menu item active
   */
  const handleInvoiceTabActive = () => {
    let classValue = "menu_list";

    if (
      location.pathname === "/earnings/approved" ||
      location.pathname === "/earnings/paid" ||
      location.pathname.includes("payout/")
    ) {
      classValue = "invoice_tab_active";
    }

    if (location.pathname.includes("transfer/")) {
      classValue = "tran_tab_active";
    }

    return classValue;
  };

  return (
    <MenuNav>
      <ul className={handleInvoiceTabActive()}>
        {NavData &&
          NavData.map((navitem: any, index: any) => {
            return (
              <MenuItem
                key={index}
                disabled={
                  isCompletedKYC === "false" &&
                  tpExistingUserIdentityVerification &&
                  navitem.iconType === "rent"
                }
              >
                <NavLink to={navitem.urlPath} className={navitem.iconType}>
                  <Typography component={"div"} variant={"h2"} color="gray.900">
                    <div className="meun_icon">{handleIcon(navitem.title)}</div>
                    <div className="meun_text">{navitem.title}</div>
                  </Typography>
                </NavLink>
              </MenuItem>
            );
          })}
      </ul>
    </MenuNav>
  );
};
