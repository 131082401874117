export const rentEsTranslation = {
  rentReporting: {
    header: "Informes de alquiler",
    notReadyMessageContent:
      "Para ver su puntuación más reciente, active los informes de alquiler. Esto también ayudará a desarrollar su puntaje crediticio.",
    creditScore: {
      title: "Puntaje de crédito",
      subTitleWithScore: "Su crédito está en excelente forma!",
      subTitleWithoutScore:
        "Su puntaje de crédito aparecerá aquí unos días después de que el informe de alquiler esté activo.",
      bottomText: "Puntaje de crédito VantageScore 3.0 de equifax",
    },
    scoreProgress: {
      title: "Progreso de la puntuación",
      tooltipContent:
        "Score Progress muestra su movimiento histórico de puntaje crediticio desde que comenzó el seguimiento en CredBuild",
    },
    reportingHistory: {
      title: "Historial de informes",
      tooltipContent:
        "El historial de informes muestra un informe por cada mes en que CredBuild informó su alquiler a las agencias de crédito. Puede ver el mes, el monto y la fecha en que se realizó el informe.",
      emptyStateContent:
        "Una vez que comiencen los informes, el informe de cada mes aparecerá en este espacio.",
      listTooltipContent:
        "Esta lista muestra cada pago reportado a las agencias de crédito en su nombre.",
      searchStatements: "Buscar declaraciones",
    },
    reportingConfirmation: {
      title: "Genial, ya estáis todos registrados!",
      title1:
        "Siéntese y relájese mientras elaboramos los informes y le ayudamos a alcanzar sus objetivos crediticios.",
    },
    reportingInfo: {
      informationContent: "Tu información está segura y encriptada",
      informationSubContent:
        "Nunca hacemos una verificación de crédito ni tiradas duras.",
    },
    creditScoreModal: {
      title: "Ver mi puntaje de crédito",
      subtitle:
        "Informe su alquiler mensual y sus facturas para generar crédito",
      description1:
        "Al hacer clic en el botón a continuación, acepto las condiciones mencionadas en los",
      description2:
        "incluido el consentimiento a CredBuild para mostrar mi puntaje crediticio.",
      description3: "CredBuild nunca realizará una verificación de crédito.",
      termsOfService: "Términos de servicios",
    },
  },
};
