import {
  ActivityGridColoumns,
  ReportingHistoryGridColoumns,
  RequestGridColoumns,
} from "src/components/Grids/DataGrid/Columns/Columns";
import { DataGridTypes } from "src/shared/models/component/base.model";

export const handleTheadColumnClassName = (
  _index?: any,
  _iconArrowVisible: boolean = true
) => {
  let setClassName = "";

  if (_index === 0) {
    setClassName = `first ${
      !_iconArrowVisible ? "icon_arrow_hide" : "icon_arrow_visible"
    }`;
  }

  if (_index === 1) {
    setClassName = `second ${
      !_iconArrowVisible ? "icon_arrow_hide" : "icon_arrow_visible"
    }`;
  }

  //need to show date column in old invoices
  if (_index === 2) {
    setClassName = `third hidden-mobile ${
      !_iconArrowVisible ? "icon_arrow_hide" : "icon_arrow_visible"
    }`;
  }

  return setClassName;
};

export const handleColumnTooltipContent = (_index: any) => {
  let tooltipContent = "";

  if (_index === 0) {
    tooltipContent = "Job and invoice details pulled from the business";
  }

  if (_index === 1) {
    tooltipContent = "Estimated Payout amount based on selected Net X terms";
  }

  if (_index === 2) {
    tooltipContent = "This is the date on which the invoice was submitted.";
  }

  return tooltipContent;
};

export const handleTbodyColumnClassName = (
  _index: any,
  _isHandleArrowClickable?: boolean,
  _iconArrowVisible: boolean = true
) => {
  let setClassName = "";
  const handleCursorClass = _isHandleArrowClickable ? "is_cursor" : "no_cursor";

  if (_index === 0) {
    setClassName = `first ${handleCursorClass} ${handleCursorClass} ${
      !_iconArrowVisible ? "icon_arrow_hide" : "icon_arrow_visible"
    }`;
  }

  if (_index === 1) {
    setClassName = `second ${handleCursorClass} ${
      !_iconArrowVisible ? "icon_arrow_hide" : "icon_arrow_visible"
    }`;
  }

  if (_index === 2) {
    setClassName = `third ${handleCursorClass} ${
      !_iconArrowVisible ? "icon_arrow_hide" : "icon_arrow_visible"
    }`;
  }

  return setClassName;
};

export const handleColumns = (gridType: DataGridTypes, t: any) => {
  switch (gridType) {
    case "request":
      return RequestGridColoumns(t);
    case "activity":
      return ActivityGridColoumns(t);
    case "reporting_history":
      return ReportingHistoryGridColoumns(t);
    default:
      break;
  }
};
