/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Button, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import { WrapperCard } from "src/components";
import { PayNowIcon } from "src/components/Media/Icon/Icons/Icons";

import { handleTransactionAmount } from "src/helpers/helperRent";
import { LandingBalanceCardProps } from "src/shared/models/component/base.model";
import { theme } from "src/shared/theme/theme";
import { dateFormatWithYear } from "src/shared/utilities/dateConvertions";

export const LandingBalanceCard = ({
  balanceDetails,
  autoPayDetails,
  onclickPayNowButton,
  onclickScheduleButton,
  isBalanceDetailLoadingSuccess,
  isAutoPayDetailLoadingSuccess,
  isInnerLoaderEnabled,
  isLoadingFadeEnabled,
  t,
}: LandingBalanceCardProps) => {
  const { tpAutopayFeature } = useFlags(); // get tpAutopayFeature flag from useFlags hook
  const [amount] = handleTransactionAmount(balanceDetails?.balance); // set the amount with + or - values

  // set the autopay details visbility in to constant (type boolean)
  const checkIsAutoPayDetailsAreVisible =
    isAutoPayDetailLoadingSuccess &&
    autoPayDetails?.data?.isActive &&
    tpAutopayFeature;

  return (
    <WrapperCard
      mbWrapper={6}
      plWrapper={4}
      prWrapper={4}
      ptWrapper={4}
      pbWrapper={4}
      minHeightWrapper={"169px"}
      isInnerLoaderEnabled={isInnerLoaderEnabled}
      isLoadingFadeEnabled={isLoadingFadeEnabled}
    >
      <Box display={"flex"} justifyContent={"space-between"} marginBottom={3}>
        <Typography variant="h4" color={"gray.800"} fontWeight={600}>
          {t("landingBanlceCard.row1Title")}
        </Typography>

        <Typography variant="h4" color={"gray.800"} fontWeight={600}>
          {isBalanceDetailLoadingSuccess && balanceDetails?.balance
            ? amount
            : "$0.00"}
        </Typography>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"} marginBottom={3}>
        <Typography variant="h3" color={"gray.600"} fontWeight={400}>
          {t("landingBanlceCard.row2Title")}
        </Typography>

        <Typography variant="h3" color={"gray.800"} fontWeight={400}>
          {isBalanceDetailLoadingSuccess && balanceDetails?.payment_due_by
            ? dateFormatWithYear(balanceDetails?.payment_due_by)
            : "---"}
        </Typography>
      </Box>

      {checkIsAutoPayDetailsAreVisible && (
        <Box display={"flex"} justifyContent={"space-between"} marginBottom={3}>
          <Typography variant="h3" color={"gray.600"} fontWeight={400}>
            {t("landingBanlceCard.row3Title")}
          </Typography>
          <Typography variant="h3" color={"gray.800"} fontWeight={400}>
            {dateFormatWithYear(autoPayDetails?.data?.nextPaymentDate)}
          </Typography>
        </Box>
      )}

      <Box
        display={"flex"}
        justifyContent={"center"}
        marginTop={4}
        paddingTop={4}
        sx={{
          borderTop: `1px solid ${theme.palette.gray[100]}`,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ width: "100%", maxWidth: "188px" }}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              height: "40px",
              fontWeight: 500,
              borderRadius: "20px",
              width: "100%",
              maxWidth: "180px",
              paddingLeft: 2,
              paddingRight: 2,
            }}
            onClick={onclickPayNowButton}
          >
            <PayNowIcon />
            <Typography
              component={"span"}
              marginLeft={1}
              marginRight={1}
              color={"inherit"}
              fontSize={"inherit"}
              fontWeight={"inherit"}
            >
              {t("landingBanlceCard.button1Text")}
            </Typography>
          </Button>
        </Box>
      </Box>
    </WrapperCard>
  );
};
