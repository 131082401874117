import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { RefreshIcon } from "src/components/Media/Icon/Icons/Icons";
import { ResendCodeWrapper, SendAgainText } from "./ResendCode.style";

interface ResendCodeProps {
  onClick?: () => void;
  isResend?: boolean;
  isEmailVerification?: boolean;
}

export const ResendCode = ({
  onClick,
  isResend = false,
  isEmailVerification = false,
}: ResendCodeProps) => {
  const { t } = useTranslation();

  return (
    <ResendCodeWrapper>
      {!isEmailVerification ? (
        <Typography variant="h3" textAlign={"center"} sx={{ marginBottom: 2 }}>
          {t("verifyCode.notRecivedText")}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          sx={{
            textAlign: "left",
            marginBottom: 2,
          }}
        >
          {t("headingContent.unVerifiedEmail.additionalContent")}
        </Typography>
      )}
      <SendAgainText
        onClick={onClick}
        className={isResend ? "resend" : "refresh"}
      >
        <RefreshIcon />
        <span className={isResend ? "resend" : "refresh"}>
          {t("linkTexts.sendAgainLink")}
        </span>
      </SendAgainText>
    </ResendCodeWrapper>
  );
};
