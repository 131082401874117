import { OrderList, OrderListItem } from "./OrderLIstNumbers.style";

export interface OrderListNumbersProps {
  options?: any[];
}

export const OrderListNumbers = ({ options }: OrderListNumbersProps) => {
  return (
    <>
      <OrderList>
        {options &&
          options.map((item: { label: string }, index: string | number) => (
            <OrderListItem key={index}>{item.label}</OrderListItem>
          ))}
      </OrderList>
    </>
  );
};
