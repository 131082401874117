import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
}

window.addEventListener("resize", appHeight);
appHeight();

const spacingContainer = {
  modalHeightDesktop: "100vh",
  modalHeightMobile: "calc(var(--vh, 1vh) * 100)",
};

export const ModalWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1301;
  justify-content: center;
  align-items: center;
  
  @media (min-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
    align-items: center;
    height: ${spacingContainer.modalHeightDesktop};
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
    align-items: center;
    height: ${spacingContainer.modalHeightMobile};
    height: -webkit-fill-available;
  }
`
);

export const ModalOverlay = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(26, 31, 54, 0.6);
  z-index: 1;
`;

export const ModalTitle = styled("div")(
  ({ theme }) => `
  width: 100%;
  display: flex;
  margin-bottom: ${theme.spacing(8)};
  text-align: center;
  justify-content: center;
`
);

export const ModalContainer = styled(Box)(
  ({ theme }) => `
  background: ${theme.palette.white};
  border: 1px solid rgba(241, 241, 241, 0.25);
  padding: 16px 0 0;
  border-radius: 8px;
  z-index: 2;
  width: calc(100% - 46px);
  max-width: 270px;
  height: auto;
`
);

export const YesOrNoConfirmationModalContainerBottom = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  border-top: 1px solid ${theme.palette.gray[100]};

  button {
   width: 50%;
   height: 50px;
   font-size: 16px;
   font-weight: 400;
   text-align: center;
   border: none;

   &.button_right {
    border-left: 1px solid ${theme.palette.gray[100]};
   }
  }

    `
);

export const YesOrNoConfirmationModalContainerTop = styled(Box)(
  ({ theme }) => `
 display: flex;
 flex-direction: column;
 padding: 0 ${theme.spacing(4)};

   `
);
