import { Accordion } from "src/components/OtherUtilities/Accordion/Accordion";
import { FilterTag } from "../FilterTag/FilterTag";
import { FilterContentWrapper } from "../FilterModal/FilterModal.style";
import { DataSortingProps } from "src/shared/models/component/base.model";

export const DataSorting = ({
  handleOnChangeDate,
  selectedOptionDateSorting,
  t,
}: DataSortingProps) => {
  return (
    <Accordion
      title={t("dataGrid.filter.sortFilter.title")}
      isFilterActive={selectedOptionDateSorting !== ""}
      isNoBorder={true}
    >
      <FilterContentWrapper>
        <FilterTag
          inputName={"sort"}
          title={t("dataGrid.filter.sortFilter.date")}
          idValue={"reported_desc"}
          iconType="SortingIcon"
          inputValue={`reported_desc`}
          handleOnChange={handleOnChangeDate}
          className={"sorting_tag"}
          selectedOption={selectedOptionDateSorting}
        />
        <FilterTag
          inputName={"sort"}
          title={t("dataGrid.filter.sortFilter.date")}
          inputValue={`reported_asc`}
          idValue={"reported_asc"}
          iconType="SortingIconDSC"
          handleOnChange={handleOnChangeDate}
          className={"sorting_tag"}
          selectedOption={selectedOptionDateSorting}
        />
      </FilterContentWrapper>
    </Accordion>
  );
};
