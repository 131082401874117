export const homeEsTranslation = {
  home: {
    welcomeText: "Welcome,",
    missingPaymentMethodsMessage: {
      heading: "Agregar un método de pago",
      content:
        "Necesitamos un método de pago registrado para procesar su alquiler. Puede agregar una tarjeta o vincular su banco.",
      paymentScreenContent:
        "Necesitamos un método de pago registrado para procesar su alquiler. Puede agregar una tarjeta o vincular su banco. También puede configurar la opción de Pago automático, una vez que agregue su método de pago predeterminado.",
    },
    isNotAcceptPaymentStatus: {
      heading: "no se puede procesar el pago",
      content:
        "En este momento, no podemos aceptar pagos en línea de su saldo. Su cuenta solo puede recibir el pago completo a través de fondos certificados. Comuníquese con el equipo de la propiedad si tiene alguna pregunta.",
    },
    welcomMessageModal: {
      content: "Gracias por registrarse en TenantPort. Ahora tienes acceso a:",
      block1: {
        heading: "Pagos sin complicaciones",
        content: `No más cheques, no más sellos. Paga tu alquiler a tu conveniencia desde cualquier lugar y en cualquier momento. O déjelo en AutoPago para no perder ningún pago.`,
      },
      block2: {
        heading: "Solicitudes de mantenimiento rápido",
        content: `¿Encontró un problema? Envíe una solicitud de mantenimiento con solo unos pocos toques con una foto y nuestro equipo estará disponible en poco tiempo.`,
      },
      block3: {
        heading: "Informes de alquiler",
        content: `Aumente su puntaje crediticio simplemente pagando el alquiler. Con nuestra nueva aplicación, sus pagos puntuales ahora pueden reflejarse positivamente en su informe crediticio.`,
      },
    },
  },
};
