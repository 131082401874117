import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

import {
  ButtonAuthorizedWrapper,
  DivAuthorizedWrapper,
  Header,
  HeaderFixed,
  ImageBanner,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";

import { RENT_IMAGES } from "src/shared/assets/images";

export const RentReportingConfirmationScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide
          paddingX={0}
          headerText={"Rent Reporting"}
          withCenteredHeaderText
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          <DivAuthorizedWrapper>
            <Box sx={{ width: "100%", marginBottom: 6 }}>
              <ImageBanner
                imgAlt=""
                imgPath={RENT_IMAGES.CONFIRMATION_BANNER}
                imgWidth="352px"
              />
              <Box sx={{ width: "100%" }}>
                <Typography
                  variant="h3"
                  color={"#4E4F5E"}
                  marginBottom={0}
                  textAlign={"center"}
                  lineHeight={"24px"}
                  fontSize={"17px"}
                >
                  {t("rentReporting.reportingConfirmation.title")}
                </Typography>
                <Typography
                  variant="h3"
                  color={"#4E4F5E"}
                  marginBottom={0}
                  textAlign={"center"}
                  lineHeight={"24px"}
                  fontSize={"17px"}
                >
                  {t("rentReporting.reportingConfirmation.title1")}
                </Typography>
              </Box>
            </Box>
            <ButtonAuthorizedWrapper>
              <Button
                variant="containedLarge"
                color="primary"
                fullWidth
                type="button"
                sx={{ overflow: "hidden" }}
                onClick={() => navigate("/rent/reporting/home")}
              >
                {t("buttonTexts.continueButton")}
              </Button>
            </ButtonAuthorizedWrapper>
          </DivAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
