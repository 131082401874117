/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import moment from "moment";
import Qs from "qs";

import { IFilterValues } from "src/shared/models/component/base.model";

export const initialFilterValues = {
  dateSorting: "",
  dateFilterSelected: "all",
  selectedCustomDateRange: null,
};

export const handleFilter = (
  setFilterSchema: any,
  createSearchParams: any,
  navigate: any
) => {
  const [filterModalOpen, setFilterModalOpen] = useState(false); // initialize state for modal open and close
  const [filterValues, setFilterValues] =
    useState<IFilterValues>(initialFilterValues); // initialize state to handle filter values updates, and related values chages

  /**
   * render in the initila load,
   * also applied filters state will returen in the page refresh
   */
  useEffect(() => {
    const locationSearchparams = window.location.search; // get location search params

    // if location searc params available
    if (locationSearchparams) {
      const endDate = `${Qs.parse(locationSearchparams.substring(1))?.endDate}`; // get endDate date from search param
      const startDate = `${
        Qs.parse(locationSearchparams.substring(1))?.startDate
      }`; // get startDate date from search param
      const dateFilter = `${
        Qs.parse(locationSearchparams.substring(1))?.dateFilter
      }`; // get dateFilter date from search param
      const dateSorting = `${
        Qs.parse(locationSearchparams.substring(1))?.dateSorting
      }`; // get dateSorting date from search param

      // if endDate and startDate is available
      if (endDate !== "undefined" && startDate !== "undefined") {
        setFilterValues({
          dateSorting: dateSorting !== "undefined" ? dateSorting : "",
          dateFilterSelected: `${
            Qs.parse(locationSearchparams.substring(1))?.dateFilter
          }`,
          selectedCustomDateRange:
            dateFilter === "date"
              ? [new Date(startDate), new Date(endDate)]
              : null,
        });
      } // if endDate and startDate is unavailable, but sorting got applied
      else if (
        endDate === "undefined" &&
        startDate === "undefined" &&
        dateSorting !== "undefined"
      ) {
        setFilterValues({
          dateSorting: dateSorting,
          dateFilterSelected: "all",
          selectedCustomDateRange: null,
        });
      }

      // if endDate and startDate is available, set filter schema into the state
      if (endDate !== "undefined" && startDate !== "undefined") {
        setFilterSchema({ endDate: endDate, startDate: startDate });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * onclick event to open the filter modal
   */
  const onClickModalOpen = () => {
    setFilterModalOpen(true);
  };

  /**
   * onclick event to close the filter modal
   */
  const onClickModalClose = (): void => {
    setFilterModalOpen(false);
  };

  /**
   * Handle Date filteration buttons onChange
   */
  const onChangeDateFilter = (event: any) => {
    setFilterValues((filterValues: IFilterValues) => ({
      ...filterValues,
      dateFilterSelected: event.target.id,
      selectedCustomDateRange: null,
    }));
  };

  /**
   * Handle custom date filteration for datepicker change
   */

  const onChangeDateCustomEvent = (dates: any) => {
    setFilterValues((filterValues: IFilterValues) => ({
      ...filterValues,
      dateFilterSelected: "date",
      selectedCustomDateRange: dates,
    }));
  };

  /**
   * Handle custom date picker close
   */
  const onCancelDateCustomEvent = () => {
    const locationSearchparams = window.location.search;
    const dateFilter = `${
      Qs.parse(locationSearchparams.substring(1))?.dateFilter
    }`;
    if (dateFilter !== "date") {
      setFilterValues((filterValues: IFilterValues) => ({
        ...filterValues,
        dateFilterSelected: "all",
        selectedCustomDateRange: null,
      }));
    }
  };

  /**
   * onChange handle date sorting
   */
  const onChangeDateSorting = (event: any) => {
    setFilterValues((filterValues: IFilterValues) => ({
      ...filterValues,
      dateSorting: event.target.id,
    }));
  };

  /**
   * onClick event to apply the filter
   */
  const onClickApplyFilter = (): void => {
    const getfilterValuesState = filterValues; // get filterValues state
    const rangeSelector: any = getfilterValuesState.dateFilterSelected; //get dateFilterSelected value from getfilterValuesState
    const customeRanges: any = getfilterValuesState.selectedCustomDateRange; //get dateFilterSelected value from selectedCustomDateRange

    // initialize the start date and end date
    let endDate: any = moment()
      .add(0, "day")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DD");
    let startDate: any = moment()
      .subtract(rangeSelector, "day")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DD");

    // if rangeSelector is equal to date - It's mean the custom date range got applied for the filteration
    if (rangeSelector === "date") {
      endDate = moment(customeRanges[1])
        .add(0, "day")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("YYYY-MM-DD");
      startDate = moment(customeRanges[0])
        .subtract(0, "day")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("YYYY-MM-DD");
    } // if rangeSelector is equal to all - It's mean the all option selected for the filteration
    else if (rangeSelector === "all") {
      endDate = null;
      startDate = null;
    }

    // add boolen constatnt isStartAndEndDateAvailable to check is endDate and startDate is avilable or not
    const isStartAndEndDateAvailable = endDate && startDate;

    // if endDate and startDate is available
    if (isStartAndEndDateAvailable) {
      let searchParams: any = {
        endDate: endDate,
        startDate: startDate,
        dateFilter: rangeSelector,
      };

      //  if data sorting applied update the searchParams opject
      if (filterValues.dateSorting !== "") {
        searchParams = {
          ...searchParams,
          dateSorting: filterValues.dateSorting,
        };
      }

      // options object create to use in navigate() method
      const options = {
        pathname: window.location.pathname,
        search: `?${createSearchParams(searchParams)}`,
      };

      // apply navigate() method, and create search params to handle the url refresh
      navigate(options, { replace: true });
    } // if endDate and startDate is unavailable, but sorting got applied
    else if (filterValues.dateSorting !== "" && !isStartAndEndDateAvailable) {
      const searchParams: any = {
        dateSorting: filterValues.dateSorting,
      };

      // options object create to use in navigate() method
      const options = {
        pathname: window.location.pathname,
        search: `?${createSearchParams(searchParams)}`,
      };

      // apply navigate() method, and create search params to handle the url refresh
      navigate(options, { replace: true });
    } else {
      // apply navigate() method , remove the search param and redirect only thr path
      navigate(window.location.pathname, { replace: true });
    }

    // set filter schema into the state
    setFilterSchema({ endDate: endDate, startDate: startDate });

    // close the flter modal after filter applied
    onClickModalClose();
  };

  /**
   * onClick reset the filter
   */
  const onClickResetFilter = (): void => {
    setFilterSchema({
      endDate: null,
      startDate: null,
    });
    setFilterValues(initialFilterValues);
    navigate(window.location.pathname, { replace: true });
    onClickModalClose();
  };

  /**
   * Define the return values
   */
  return [
    onClickModalOpen,
    onClickModalClose,
    filterModalOpen,
    onChangeDateFilter,
    onChangeDateCustomEvent,
    onCancelDateCustomEvent,
    onClickApplyFilter,
    onClickResetFilter,
    onChangeDateSorting,
    filterValues,
  ];
};
