import React from "react";
import { ConditionalTextWrapper } from "./ConditionalText.style";

export interface ConditionalTextProps {
  children?: React.ReactNode;
}

export const ConditionalText = ({ children }: ConditionalTextProps) => {
  return (
    <ConditionalTextWrapper>
      <p>{children}</p>
    </ConditionalTextWrapper>
  );
};
