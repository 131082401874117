import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IMAGES } from "src/shared/assets/images";

interface Props {
  handleCloseEvent: VoidFunction;
}

export const ViewMyCreditScoreModal = ({ handleCloseEvent }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={handleCloseEvent}
      PaperProps={{
        sx: {
          margin: 4,
          width: "calc(100% - 32px)",
        },
      }}
    >
      <DialogContent sx={{ px: 4, py: 6, textAlign: "center" }}>
        <Stack gap={4}>
          <Typography variant="h6" color="gray.700">
            {t("rentReporting.creditScoreModal.title")}
          </Typography>
          <Typography variant="body1" color="gray.700" sx={{ fontWeight: 300 }}>
            {t("rentReporting.creditScoreModal.subtitle")}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="img"
              src={IMAGES.CREDIT_BUILDER}
              alt="credit builder"
              width="239px"
            />
          </Box>
          <Typography
            variant="body1"
            color="gray.700"
            sx={{
              textAlign: "left",
              fontWeight: 300,
              lineHeight: "20px",
              "& a": { color: "gray.700", textDecoration: "underline" },
            }}
          >
            {t("rentReporting.creditScoreModal.description1")}{" "}
            <a
              target="_blank"
              href="https://tenantport.s3.amazonaws.com/documents/CredBuild+ToS+-+2024_03_08.docx.pdf"
              rel="noreferrer"
            >
              {t("rentReporting.creditScoreModal.termsOfService")}
            </a>
            {", "}
            {t("rentReporting.creditScoreModal.description2")}{" "}
            {t("rentReporting.creditScoreModal.description3")}
          </Typography>
          <Button variant="contained" onClick={handleCloseEvent}>
            View My Credit Score
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
