import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@mui/material/styles";
import i18n from "./locale/i18n";
import { I18nextProvider } from "react-i18next";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalStyle } from "./shared/styles/globalStyles";
import { theme } from "./shared/theme/theme";
import { Auth0ProviderWithNavigate } from "./shared/providers/Auth0ProviderWithNavigate";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

(async () => {
  // define LDP provider
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_KEY ?? "",
    user: { key: "anon-user", anonymous: true },
  });

  root.render(
    <HelmetProvider>
      <GlobalStyle />
      <LDProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Auth0ProviderWithNavigate>
              <I18nextProvider i18n={i18n}>
                <React.StrictMode>
                  <App />
                </React.StrictMode>
              </I18nextProvider>
            </Auth0ProviderWithNavigate>
          </ThemeProvider>
        </BrowserRouter>
      </LDProvider>
    </HelmetProvider>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
