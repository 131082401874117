import { localeType } from "./locale.type";

import { homeEsTranslation } from "./Home/es";
import { requestsEsTranslation } from "./Requests/es";
import { identityTranslation } from "./Identity/es";
import { rentEsTranslation } from "./Rent/es";

export const es: localeType = {
  ...homeEsTranslation,
  ...requestsEsTranslation,
  ...identityTranslation,
  ...rentEsTranslation,
  formInput: {
    firstName: "Nombre",
    lastName: "Apellido",
    comapanyName: "Nombre de la empresa",
    companyContactName: "Nombre de contacto de la empresa",
    email: "Correo electrónico",
    phone: "Número de teléfono",
    phone2: "Número de teléfono 2",
    ssn: "Número de Seguro Social",
    ssnInfo: "Ingrese los últimos 4 dígitos de su SSN",
    ssnLast4: "Número de Seguro Social",
    ssnReplaceButtonText: "SSN proporcionado",
    ssnNotProvidedText: "SSN no proporcionado",
    ssnNotVerifiedText: "SSN no verificado",
    dob: "Fecha de nacimiento (mm/dd/aaaa) ",
    addressLine1: "Dirección",
    addressLine1HiglightText:
      "Su dirección debe ser una dirección física válida y no puede ser un P.O. Caja. Esta dirección no se comparte públicamente y puede ser su dirección personal.",
    addressLine2: "Línea de dirección 2",
    city: "Ciudad",
    state: "Estado",
    zipCode: "Código postal",
    accountName: "Titular de la cuenta",
    accountNumber: "Cuenta número",
    confirmAccountNumber: "Confirmar cuenta número",
    routingNumber: "Enrutamiento número",
    ein: "Número de identificación del empleador (EIN)",
    einReplaceButtonText: "Identificación fiscal proporcionada",
    jobTitle: "Título",
    sameBillingAddressCheckbox: "Igual que la dirección de la empresa",
    ownerLabel: "Dueño",
    executiveLabel: "Ejecutivo",
    executiveConfirmInput: "Confirmo que poseo más del 25% de la empresa",
    required: "obligatorio",
    dobDefault: "01/31/2000",
    businessName: "Nombre comercial (si está registrado como una organización)",
    fullName: "Nombre completo",
    password: "Contraseña",
    changePasswordText: "Cambiar la contraseña",
    accountType: {
      label: "Tipo de cuenta",
      option1: "Comprobación",
      option2: "Ahorros",
      option3: "Negocio Comprobación",
    },
    businessType: {
      label: "Tipo de negocio",
      option1: "Individual",
      option2: "Organización",
    },
    amountRequired: "Cantidad requerida)",
    addNote: "Añadir la nota",
    nickName: "Apodo",
    recipientName: "Nombre de la destinataria",
    recipientNameFieldNote:
      "Utilice la dirección donde recibe el correo postal",
    property: "Propiedad",
    unit: "Unidad",
    areas: "Área",
    description: "Descripción",
    category: "Categoría",
    issueType: "Tipo de problema",
    consentCheck:
      "Doy mi consentimiento para que el equipo de administración de la propiedad ingrese a mi unidad mientras estoy fuera.",
    homePhone: "Teléfono de casa",
    mobilePhone: "Teléfono móvil",
  },
  buttonTexts: {
    loginButtonText: "Inicia sesión",
    signupButtonText: "Crear cuenta gratuita ",
    buttonTextSignup: "Únete",
    buttonTextSignIn: "Inicia sesión",
    continueButton: "Continuar",
    addButton: "Agregar",
    inviteUserButton: "Invitar a uno más",
    doneButton: "Hecho",
    cancelButton: "Cancelar",
    updateButton: "Actualizar",
    submitButton: "Enviar",
    confirmButton: "Confirmar",
    nextButton: "Siguiente",
    followNextStepButton: "Siguiente",
    confirmTermsButton: "Confirmar los términos de pago ",
    saveContinueButton: "Guardar y continuar ",
    continuePayupButton: "Siguiente",
    enableNotificationButton: "Habilitar notificaciones",
    ShareLinkText: "Compartir enlace a un amigo",
    continueWithIdButton: "Continuar con ID",
    continueWithEINDocButton: "Continuar con documentos comerciales",
    reviwDataButton: "Revisar datos",
    goBackButton: "Regresa",
    acceptButton: "Aceptar",
    linkViewAll: "Ver todo",
    okButton: "De acuerdo",
    yesButton: "Sí",
  },
  linkTexts: {
    sendAgainLink: "Enviar de nuevo",
    skipLink: "Saltarse",
  },
  captions: {
    personalInfo: "INFORMACIÓN PERSONAL",
    businessDetails: "DETALLES DEL NEGOCIO",
    personalDetails: "DATOS PERSONALES",
    invitedText: "INVITADO",
    submittedText: "PRESENTADO",
    termDetails: "INFORMACIÓN DEL TÉRMINO",
    bankInfotext: "DETALLES DEL BANCO",
    editDetails: "Editar detalles",
    recipientInfo: "DATOS DEL DESTINATARIO",
  },
  headingContent: {
    verifyPhone: {
      heading: "Comencemos",
      content: "Usa tu número de teléfono para registrarte",
    },
    verifyCode: {
      heading: "Verifica tu número",
      content: "Por favor ingrese el código de verificación enviado al",
    },
    invitationExpired: {
      heading: "Invitación no válida o caducada",
      content: "El enlace a su invitación ha caducado o no es válido.",
      additionalContent: "",
    },
    unVerifiedEmail: {
      heading: "Verificar correo electrónico",
      content:
        "Busque en su bandeja de entrada un correo de TenantPort y haga clic en el enlace para verificar su correo electrónico.",
      additionalContent:
        "¿No recibiste un correo electrónico? Revisa tu correo no deseado",
    },
    verifiedEmail: {
      headingEmailVerified: "Correo Electrónico Verificado",
      headingLoginCompletion: "Inicio de sesión completo",
      headingAccessDenied: "Acceso denegado",
      content:
        "Su dirección de correo electrónico fue verificada con éxito. Continúe configurando su cuenta.",
    },
    verifyCodeSuccess: {
      loginComplete: "Inicio de sesión completo",
    },
    verifyCodeFailiure: {
      loginInComplete: "Inicio de sesión incompleto",
    },
    commonSteps: {
      searchVendor: {
        heading: "Empecemos",
        content:
          "Utilice el correo electrónico y el contacto que utilizó para firmar el contrato de arrendamiento.",
      },
      identity: {
        heading: "Verificación de identidad",
        content:
          "Confirme su identidad para ayudarnos a localizarlo en el contrato de arrendamiento correcto.",
      },
    },
    statusses: {
      verificationFailed: {
        heading: "Error de verificación",
        content:
          "No podemos verificar su SSN. Proporcione una identificación válida para continuar registrándose. No se preocupe, todos sus datos se almacenarán de forma segura.",
      },
      weFoundVendor: {
        heading: "¡Encontramos una coincidencia!",
        content1:
          "Pudimos encontrar una coincidencia en {{vendor}} bajo {{customer}}.",
        content2:
          "Establezca una contraseña para su cuenta en la siguiente pantalla.",
        content3:
          "Complete su verificación antes de configurar una cuenta TenantPort.",
      },
      weNotFoundVendor: {
        heading: "No pudimos localizar sus datos",
        content1:
          "Lo sentimos, no pudimos encontrar tus datos en {{customer}} con la dirección de correo electrónico, el nombre  proporcionados.",
        content2:
          "¿Tiene contacto alternativo? Comparta el correo electrónico, el nombre y el número que utilizó para registrarse con {{customer}}.",
      },
      accountALreadyAvailable: {
        heading: "Ya estás en TenantPort",
        content1:
          "Encontramos un perfil ya registrado en TenantPort con el {{numberOrEmail}}.",
        content2:
          "Inicie sesión en su perfil o regrese para crear uno nuevo con un número o correo electrónico diferente.",
      },
      identityApproved: {
        heading: "Aprobada",
        content:
          "¡La verificación fue exitosa! Permítanos configurar una cuenta para usted.",
      },
      identityFailed: {
        heading: "Error de verificación",
        content:
          "Uno o más de los campos ingresados eran incorrectos. Vuelva atrás y revise los datos antes de enviarlos nuevamente.",
      },
    },
    individual: {
      personalInfo: {
        heading: "Repasemos",
        content:
          "Por favor, verifique la información que proporcionó para asegurarse de que sea precisa.",
      },
    },
  },
  errorMessages: {
    verifyCodeError: {
      heading: "Error de verificación",
    },
    phoneUniqueError: {
      heading: "El número de teléfono debe ser único",
      content: "Debes usar un número de teléfono único para usar TenantPort.",
    },
    emailUniqueError: {
      heading: "El ID de correo electrónico debe ser único",
      content:
        "Debe usar una identificación de correo electrónico única para usar TenantPort.",
    },
    invalidAddressError: {
      heading: "Dirección inválida",
    },
  },
  verifyCode: {
    notRecivedText: "¿No recibiste un mensaje de texto?",
  },
  menu: {
    requests: "Peticiones",
    settings: "Cuenta",
    rent: "Alquilar",
    dashboard: "Hogar",
    notification: "Notificación",
    referFriend: "Recomendar amigas",
    transactions: "Actas",
    payments: "Ganancias",
  },
  placeHolder: {
    firstName: "Primer nombre",
    lastName: "Apellido",
    emailAddress: "Dirección de correo electrónico",
    comapnyName: "Squeaky Cleaners LLC",
    firstLineAddress: "Dirección Línea 1",
    secondLineAddress: "Línea de dirección 2",
    city: "Ciudad",
    state: "Estado",
    zipCode: "Código postal",
    accountName: "Nombre del titular de la cuenta",
    jobTitle: "Título",
    property: "Nombre de la propiedad",
    unit: "Unidad#",
    areas: "Área",
    description:
      "Por favor, describa su problema aquí en detalle. (Máximo 1000 palabras)",
    category: "Categoría",
    issueType: "Tipo de problema",
    otherIssueType: "Por favor mencione el problema",
    searchRequestInput: "Buscar solicitudes",
  },
  settings: {
    HeaderTitle: "Cuenta",
    AccountInformation: {
      title: "Información de la cuenta",
      buttonTextVerified: "Verificada",
      buttonTextPending: "Pendiente",
      buttonTextRestricted: "Restringida",
      buttonTextUnverified: "Inconfirmado",
    },
    ChangePasswordConfirmation: {
      title: "Enlace enviado",
      content:
        "Busque en su bandeja de entrada un correo de TenantPort y haga clic en el enlace para cambiar la contraseña.",
    },
    PayoutTerms: {
      title: "Condiciones de pago",
      buttonText: "Neta 7",
    },
    PayoutMode: {
      title: "Modo de pago",
    },
    LinkedBankAccount: {
      title: "Cuentas bancarias vinculadas",
      buttonText: "Añadir cuenta",
      remove: "Remover",
      default: "Hacer por defecto",
    },
    Notifications: {
      title: "Notificaciones",
      buttonText: "",
    },
    Customers: {
      title: "Clientes",
      buttonText: "",
    },
    RentReporting: {
      title: "Informes de alquiler",
      buttonText: "",
    },
    Languages: {
      title: "Idiomas",
      buttonText: "Español",
    },
    LogOut: {
      title: "Cerrar sesión",
      buttonText: "Verificada",
    },
    Referrals: {
      title: "Referencias",
      buttonText: "",
    },
  },
  dataGrid: {
    filter: {
      applyBtn: "Aplicar",
      title: "Filtrar y Ordenar",
      resetAll: "Resetear todo",
      amountFilter: {
        title: "Monto",
        from: "De",
        to: "a",
      },
      dateFilter: {
        title: "Fecha",
        last7: "Los últimos 7 días",
        last30: "Últimos 30 días",
        last12m: "últimos 12 meses",
        lastYear: "El año hasta la fecha",
        last90: "últimos 90 días",
        all: "Todos",
        custom: "Período personalizado",
        ok: "Ok",
        done: "Hecho",
        cancel: "Cancelar",
      },
      termFilter: {
        title: "Condiciones de pago",
        all: "Seleccionar todo",
      },
      sortFilter: {
        title: "Ordenar por ",
        date: "Fecha",
        amount: "Monto",
      },
    },
  },
  rent: {
    common: {
      heading: "Alquilar",
      gridHeading: "Actividad",
      linkedListHeading: "Opciones de pago",
      tooltipContent:
        "Todos los tickets de emergencia y mantenimiento que envíe se denominan solicitudes.",
      emptyStateTitle: "Aún no hay actividades",
      emptyStateContent: "Tus actividades más recientes aparecerán aquí.",
    },
    rentActionCard: {
      title: "Bienvenido a las opciones de alquiler y pago",
      buttonOneText: "Realizar un pago",
      buttonTwoText: "Opciones de pago",
      buttonThreeText: "historial de pagos",
      buttonFourText: "Informes de alquiler",
    },
  },
  linkedBankAccount: {
    blockTitle: "CUENTAS VINCULADAS",
    linkText: "Vincular nueva cuenta",
    emptyBlockContent: "Cree su primera cuenta vinculada.",
    removeLinkTxt: "Remover",
    defaultLinkTxt: "Hacer por defecto",
    createActionCard: {
      buttonOneText: "Vinculación bancaria",
      buttonTwoText: "Tarjeta de crédito o débito",
    },
  },
  landingBanlceCard: {
    row1Title: "Saldo actual",
    row2Title: "Debido por",
    row3Title: "Programado para",
    button1Text: "Pagar ahora",
    button2Text: "Pago automático",
  },
  noteBlock: {
    contentPrefix: "¿Necesitas ayuda? Contactanos en",
    contentPostfix: "o envíe una solicitud a través de la aplicación.",
  },
};
