import { useNavigate } from "react-router-dom";
import { datenormalFormating } from "src/shared/utilities/dateConvertions";

export const SecondCell = (props: any) => {
  const navigate = useNavigate();
  const getPropsOriginalData = props?.props?.row?.original;
  const { routeState } = props?.props;
  const { WorkOrderID, ReportedDate } = getPropsOriginalData;

  return (
    <>
      <span
        className="hidden-mobile date_val"
        onClick={() =>
          navigate(`/requests/maintenance/detail/${WorkOrderID}`, {
            state: routeState,
          })
        }
      >
        {datenormalFormating(ReportedDate)}
      </span>
    </>
  );
};
