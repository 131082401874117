import { FieldValues, UseFormSetValue } from "react-hook-form";
import { dateFormatYYYYspaceMMspaceDDspaceTimeAddMoreDays } from "src/shared/utilities/dateConvertions";

/**
 * function used to reset the create order form values
 * @param setValue this is a method from hookform
 */
export const resetTheCreateRequestFormFields = (
  setValue: UseFormSetValue<FieldValues>
) => {
  setValue("areas", "");
  setValue("title", "");
  setValue("description", "");
  setValue("categoryId", "");
  setValue("appointment", "");
};

/**
 * function used to handle the order completed date for single order
 * @param data order details response data
 * @returns completed date
 */
export const handleCompletedDate = (data: any) => {
  let completedDate = data?.DueDate;
  let isDue = true;

  if (data?.CompletedDate === undefined) {
    completedDate = data?.DueDate;
    isDue = false;
  } else if (data?.CompletedDate !== undefined) {
    completedDate = data?.CompletedDate;
    isDue = false;
  } else if (
    data?.CompletedDate !== undefined &&
    data?.StartedDate !== undefined &&
    data?.StartedDate > data?.DueDate
  ) {
    completedDate = dateFormatYYYYspaceMMspaceDDspaceTimeAddMoreDays(
      data?.StartedDate,
      4
    );
    completedDate = data?.CompletedDate;
    isDue = false;
  } else if (data?.DueDate === undefined) {
    completedDate = dateFormatYYYYspaceMMspaceDDspaceTimeAddMoreDays(
      data?.ReportedDate,
      4
    );
    completedDate = data?.CompletedDate;
    isDue = false;
  }

  return [completedDate, isDue];
};

export function delayApi(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
