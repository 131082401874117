import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const CustomerAdditionConfirmModalIconWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing(2)};

  svg {
   color: ${theme.palette.primary.main};
  }
`
);
