import { datenormalFormating } from "src/shared/utilities/dateConvertions";

export const SecondCell = (props: any) => {
  const getPropsOriginalData = props?.props?.row?.original;
  const { date } = getPropsOriginalData;

  return (
    <>
      <span className="hidden-mobile date_val">
        {datenormalFormating(date)}
      </span>
    </>
  );
};
