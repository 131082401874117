import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  ButtonAuthorizedWrapper,
  ConfirmationModal,
  FormAuthorizedWrapper,
  Header,
  HeaderFixed,
  LinkedAccountsCard,
  ManualLoader,
  Messages,
  NotificationMessage,
  Wrapper,
  WrapperCard,
  WrpperAuthorizedContainer,
} from "src/components";
import {
  bankDetailsParams,
  summaryDetailsParams,
} from "src/shared/models/containers/rent.model";

import {
  useGetBalanceDetailsQuery,
  useGetFeeStructureMutation,
  useGetPaymentMethodsQuery,
  useGetUserQuery,
  useLazyGetPaymentTransactionsQuery,
  useMakePaymentMutation,
  useUpdateDefaultBankAccountMutation,
} from "src/store/services/privateApi";
import { PayoutAmountFormFields } from "./PayoutAmountFormFields/PayoutAmountFormFields";
import { ERROR_MESSAGES } from "src/shared/utilities/validationMessages";
import { PaymentSummaryModal } from "./PaymentSummaryModal/PaymentSummaryModal";
import {
  getAccountLinkUrl,
  getActivePaymentMethods,
  getPaymentAmount,
  getPaymentMethodType,
} from "src/helpers/helperRent";
import { CONSTANT_PAYMENT_STATUSES } from "src/shared/utilities/constants";
import moment from "moment";

export const RentPaymentScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { tpCustomPaymentFeature } = useFlags();

  const { isFromHome } = location.state
    ? (location.state as { isFromHome: boolean })
    : { isFromHome: false };

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<{
    gatewayPayerId: string;
    id: string;
    type: string;
    methodTitle: string;
  }>({
    gatewayPayerId: "",
    id: "",
    type: "",
    methodTitle: "",
  });

  const [paymentWay, setPaymentWay] = useState<"fixed" | "custom">("fixed");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [paymentSummaryModalOpen, setPaymentSummaryModalOpen] = useState(false);
  const [confirmationModalContent, setConfirmationModalContent] = useState("");
  const [summaryDetails, setSummaryDetails] = useState<summaryDetailsParams>({
    paymentMethodType: "",
    fee: 0,
    amount: 0,
    totalamount: 0,
    notes: "",
    feepercentage: "",
  });
  const [formContinueButtonHide, setFormContinueButtonHide] = useState(true);
  const [isPaymentMethodUnAvailable, setIsPaymentMethodUnAvailable] = useState<
    boolean | undefined
  >(undefined);

  const makePaymentFormSchema = yup
    .object({
      amount: yup.string().when("front", {
        is: () => {
          if (paymentWay === "fixed") {
            return false;
          } else {
            return true;
          }
        },
        then: yup
          .string()
          .typeError(ERROR_MESSAGES.REQUIRED)
          .required(ERROR_MESSAGES.REQUIRED),
      }),
      memo: yup.string().when("front", {
        is: () => {
          if (paymentWay === "fixed") {
            return false;
          } else {
            return true;
          }
        },
        then: yup
          .string()
          .typeError(ERROR_MESSAGES.REQUIRED)
          .required(ERROR_MESSAGES.REQUIRED)
          .max(200, ERROR_MESSAGES.TITLE_MAX_LIMIT),
      }),
    })
    .required();

  const {
    handleSubmit,
    setValue,
    register,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(makePaymentFormSchema),
  });

  const {
    data,
    isSuccess,
    isLoading: isLoadingData,
    isFetching: isFetchingData,
    isError: isErrorFetchingData,
  } = useGetPaymentMethodsQuery(null);

  const {
    data: balanceData,
    isSuccess: isSuccessBalanceData,
    isLoading: isLoadingBalanceData,
    isFetching: isFetchingBalanceData,
    isError: isErrorBalanceData,
  }: any = useGetBalanceDetailsQuery(null);

  const {
    data: userData,
    isSuccess: isSuccessUserData,
    isLoading: isSuccessLoading,
  } = useGetUserQuery(null);

  const [getFeeStructure, { isLoading: isLoadingGetFeeStructure }] =
    useGetFeeStructureMutation();

  const [makePayment, { isLoading: isLoadingMakePayment }] =
    useMakePaymentMutation();

  const [updateDefaultBankAccount, { isLoading: isLoadingUpdateBA }] =
    useUpdateDefaultBankAccountMutation();

  const [getPaymentsTrigger] = useLazyGetPaymentTransactionsQuery({});

  const addLinkedBankAccount = () => {
    navigate(getAccountLinkUrl(balanceData?.paymentStatus), {
      state: {
        isFromPayment: true,
        isOnlyCard:
          balanceData?.paymentStatus ===
          CONSTANT_PAYMENT_STATUSES.CERTIFIED_FUNDS,
      },
    });
  };
  const isUserDetailsLoaded = isSuccessUserData && userData && userData?.user;
  const isCustomPaymentEnabled =
    isUserDetailsLoaded && userData?.user?.allowCustomPayment ? true : false;
  const isAcceptPaymentStatus =
    isSuccessBalanceData &&
    balanceData &&
    (balanceData?.paymentStatus === CONSTANT_PAYMENT_STATUSES.ACCEPT ||
      balanceData?.paymentStatus === CONSTANT_PAYMENT_STATUSES.CERTIFIED_FUNDS)
      ? true
      : false;

  /**
   * Handle the error based on the dependency change
   */
  useEffect(() => {
    if (isErrorBalanceData && !isSuccessBalanceData) {
      setErrorMessage("Unable to fetch the balance data");
    } else if (isErrorFetchingData && !isSuccess) {
      setErrorMessage("Unable to fetch the linked accounts");
    } else {
      setErrorMessage("");
    }
  }, [
    isErrorBalanceData,
    isErrorFetchingData,
    isSuccess,
    isSuccessBalanceData,
  ]);

  const updateDefaultAccount = async (id: any) => {
    setErrorMessage("");
    const updateResponse: any = await updateDefaultBankAccount({ id });
    if (updateResponse && updateResponse.error) {
      if (updateResponse.error.length > 0) {
        setErrorMessage("Unable to process this change.");
      } else {
        setErrorMessage(
          updateResponse?.error?.data?.message ??
            updateResponse?.error?.data?.error
        );
      }
    } else {
      setErrorMessage("");
    }
  };

  const onSelectPaymentMethod = (bank_details: bankDetailsParams) => {
    const [methodType, methodTitle] = getPaymentMethodType(
      bank_details.type,
      bank_details.isDebitCard
    );

    setSelectedPaymentMethod({
      gatewayPayerId: bank_details.gatewayPayerId,
      id: bank_details.id,
      type: methodType,
      methodTitle: methodTitle,
    });

    const [isActivePaymentMethodsAvailable] = getActivePaymentMethods(
      data?.paymentMethods,
      true
    );
    const methodAllowed =
      balanceData?.paymentStatus === CONSTANT_PAYMENT_STATUSES.ACCEPT ||
      (balanceData?.paymentStatus ===
        CONSTANT_PAYMENT_STATUSES.CERTIFIED_FUNDS &&
        methodType !== "bank");
    if (
      isAcceptPaymentStatus &&
      isActivePaymentMethodsAvailable &&
      methodAllowed
    ) {
      //avoid strange react crash
      setTimeout(() => {
        setFormContinueButtonHide(false);
      }, 100);
    } else {
      setFormContinueButtonHide(true);
    }
  };

  const onSelectDefaultPaymentMethod = () => {
    if (isSuccess && data && data?.paymentMethods.length > 0) {
      const getDefaultPaymentMethod = data?.paymentMethods.filter(
        (methodItem: bankDetailsParams) => methodItem.default === true
      );

      const getActivePaymentMethods = data?.paymentMethods.filter(
        (methodItem: bankDetailsParams) => methodItem.status === "ACTIVE"
      );

      if (getDefaultPaymentMethod && getDefaultPaymentMethod.length > 0) {
        const getDefaultPaymentMethodNode: bankDetailsParams =
          getDefaultPaymentMethod[0];

        if (getDefaultPaymentMethodNode) {
          onSelectPaymentMethod(getDefaultPaymentMethodNode);
        }
      } else {
        if (getActivePaymentMethods && getActivePaymentMethods.length > 0) {
          const getIdOfFirstActivePaymentMethod =
            getActivePaymentMethods[0]?.id;
          updateDefaultAccount(getIdOfFirstActivePaymentMethod);
        }
      }
    }
  };

  /**
   * Handle the default payment method in the initial load
   */
  useEffect(() => {
    onSelectDefaultPaymentMethod();

    // check if active payment methods available, if yes make auto pay section enabled
    if (isSuccess && data) {
      const [isActivePaymentMethodsAvailable] = getActivePaymentMethods(
        data?.paymentMethods,
        true
      );

      if (isActivePaymentMethodsAvailable) {
        setIsPaymentMethodUnAvailable(false);
      } else {
        setIsPaymentMethodUnAvailable(true);
      }

      const methodAllowed =
        balanceData?.paymentStatus === CONSTANT_PAYMENT_STATUSES.ACCEPT ||
        (balanceData?.paymentStatus ===
          CONSTANT_PAYMENT_STATUSES.CERTIFIED_FUNDS &&
          !data?.paymentMethods.find(
            (bankItem: bankDetailsParams) =>
              bankItem.type === "BANK" && bankItem.default
          ));
      if (
        balanceData?.balance !== 0 &&
        balanceData?.balance !== null &&
        balanceData?.balance !== undefined &&
        isAcceptPaymentStatus &&
        isActivePaymentMethodsAvailable &&
        methodAllowed
      ) {
        setFormContinueButtonHide(false);
      } else if (
        isCustomPaymentEnabled &&
        isAcceptPaymentStatus &&
        isActivePaymentMethodsAvailable
      ) {
        setFormContinueButtonHide(false);
      } else {
        setFormContinueButtonHide(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess, balanceData, isSuccessBalanceData]);

  const onResetFormFields = () => {
    onSelectDefaultPaymentMethod();
    setPaymentWay("fixed");
    setValue("amount", "");
    setValue("memo", "");
    setErrorMessage("");
  };

  const formSubmit = handleSubmit(
    async (data: { amount: any; memo: string }) => {
      setErrorMessage("");

      const [amount] = getPaymentAmount(
        isSuccessBalanceData,
        balanceData,
        paymentWay,
        data
      );

      if (selectedPaymentMethod.id === "") {
        setErrorMessage("Please select the payment method");
        setPaymentSummaryModalOpen(false);
      } else {
        const getFeeAmount: any = await getFeeStructure(amount);

        if (getFeeAmount && getFeeAmount.error) {
          setErrorMessage("Unable to load the fee structure");
        } else {
          const getBankFee = getFeeAmount.data.achFee;
          const getCreditCardFee = getFeeAmount.data.creditCard.fee;
          const getDebitCardFee = getFeeAmount.data.debitCardFee;

          const setFeeForPaymentMethodType =
            selectedPaymentMethod.type === "bank"
              ? getBankFee
              : selectedPaymentMethod.type === "debit_card"
              ? getDebitCardFee
              : selectedPaymentMethod.type === "credit_card"
              ? getCreditCardFee
              : 0;

          const setFeePercentage =
            selectedPaymentMethod.type === "credit_card"
              ? `${getFeeAmount.data.creditCard.percentage}%`
              : selectedPaymentMethod.type === "bank"
              ? "0%"
              : "";

          const getTotalAmount: number = amount + setFeeForPaymentMethodType;
          const { data } = await getPaymentsTrigger({});
          const warnDays = 5;
          const transactionInLast5Days = data.transactions?.find(
            (t: any) =>
              t.status !== "FAILED" &&
              moment().diff(moment(t.createdDate), "days") < warnDays
          );
          setSummaryDetails({
            paymentMethodType: selectedPaymentMethod.methodTitle,
            fee: setFeeForPaymentMethodType,
            amount: amount,
            totalamount: getTotalAmount,
            notes: data.memo,
            feepercentage: setFeePercentage,
            warningMessage: transactionInLast5Days
              ? `You already made a successful payment of ${transactionInLast5Days.totalAmount}$ in the last ${warnDays} days. Are you sure you want to do another?`
              : "",
          });

          setPaymentSummaryModalOpen(true);
        }
      }
    }
  );

  const onPayamentSummaryBtnClickEvent = async () => {
    let payloadParams: {
      totalAmount: number;
      paymentMethodId: string;
      feeAmount: number;
      notes?: string;
    } = {
      paymentMethodId: selectedPaymentMethod.id, // get this from the list payment methods API
      totalAmount: summaryDetails.totalamount,
      feeAmount: summaryDetails.fee,
    };

    if (summaryDetails.notes !== "") {
      payloadParams = {
        ...payloadParams,
        notes: summaryDetails.notes,
      };
    }

    const response: any = await makePayment(payloadParams);

    if (response) {
      if (response.error) {
        setErrorMessage(
          response.error?.data?.message?.Message ??
            "Unable to Process this payment request"
        );
      } else {
        setConfirmationModalOpen(true);
        setConfirmationModalContent(
          "Your payment has been successfully submitted."
        );
        onResetFormFields();
      }
    }
  };

  const onPayamentSummaryModalCloseEvent = () => {
    setPaymentSummaryModalOpen(false);
  };

  const onSubmitConfirmationModalBtn = () => {
    setConfirmationModalOpen(false);
    setConfirmationModalContent("");
    setPaymentSummaryModalOpen(false);
    window.location.reload();
  };

  const isLoaderEnable =
    isLoadingData ||
    isFetchingData ||
    isLoadingBalanceData ||
    isLoadingMakePayment ||
    isFetchingBalanceData ||
    isLoadingGetFeeStructure ||
    isLoadingUpdateBA ||
    isSuccessLoading;

  return (
    <>
      {isLoaderEnable && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible
          paddingX={0}
          headerText={"Make a Payment"}
          withCenteredHeaderText
          onBackButtonClick={() => navigate(isFromHome ? "/home" : "/rent")}
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          <FormAuthorizedWrapper onSubmit={formSubmit}>
            {isSuccessBalanceData && (
              <PayoutAmountFormFields
                balanceAmount={balanceData?.balance?.toString()}
                register={register}
                control={control}
                errors={errors}
                setValue={setValue}
                setPaymentWay={setPaymentWay}
                paymentWay={paymentWay}
                isCustomPaymentOptionEnable={
                  tpCustomPaymentFeature && isCustomPaymentEnabled
                }
                isPaymentOptionEnable={
                  data?.paymentMethods && data?.paymentMethods.length > 0
                }
              />
            )}

            {!isLoaderEnable && isPaymentMethodUnAvailable && (
              <NotificationMessage
                messageHeading={t("home.missingPaymentMethodsMessage.heading")}
                messageContent={t("home.missingPaymentMethodsMessage.content")}
                messageLinkEvent={addLinkedBankAccount}
                variant="no_payment_methods"
                topMargin={0}
              />
            )}

            {!isLoaderEnable &&
              balanceData?.paymentStatus !==
                CONSTANT_PAYMENT_STATUSES.ACCEPT && (
                <NotificationMessage
                  messageHeading={t("home.isNotAcceptPaymentStatus.heading")}
                  messageContent={t("home.isNotAcceptPaymentStatus.content")}
                  isMessageClickable={false}
                  variant="missing_payment_status"
                  topMargin={0}
                />
              )}

            <WrapperCard
              plWrapper={0}
              prWrapper={0}
              ptWrapper={5}
              pbWrapper={2}
            >
              {isSuccess && (
                <LinkedAccountsCard
                  blockTitle={"Payment Details"}
                  linkText={t("linkedBankAccount.linkText")}
                  onClickLink={addLinkedBankAccount}
                  bankData={data?.paymentMethods}
                  emptyBlockContent={t("linkedBankAccount.emptyBlockContent")}
                  isViewMoreDropDownVisible={false}
                  onClickBankCard={onSelectPaymentMethod}
                  selectedItemID={selectedPaymentMethod.id}
                  isCardClickable={true}
                  wrapperCardStyle={{
                    cardType: "remove_all_shadow",
                    paddingY: 0,
                  }}
                  isLastDigits
                />
              )}
            </WrapperCard>

            {errorMessage !== "" && (
              <Messages
                topMargin={0}
                messageHeading={"Make Payment"}
                messageContent={errorMessage}
                closeEvent={() => setErrorMessage("")}
              />
            )}

            {!formContinueButtonHide && (
              <ButtonAuthorizedWrapper>
                <Button
                  variant="containedLarge"
                  color="primary"
                  fullWidth
                  type="submit"
                  sx={{ overflow: "hidden" }}
                >
                  {t("buttonTexts.continueButton")}
                </Button>
              </ButtonAuthorizedWrapper>
            )}
          </FormAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>

      {confirmationModalOpen && !isLoaderEnable && (
        <ConfirmationModal
          onFormSubmit={onSubmitConfirmationModalBtn}
          title={t("requests.addOrUpdateRequest.confirmationModalTitle")}
          content={confirmationModalContent}
          btnTitle={t("buttonTexts.okButton")}
          hideClose={true}
        />
      )}

      {paymentSummaryModalOpen && (
        <PaymentSummaryModal
          summaryDetails={summaryDetails}
          t={t}
          onPayamentSummaryBtnClickEvent={onPayamentSummaryBtnClickEvent}
          onPayamentSummaryModalCloseEvent={onPayamentSummaryModalCloseEvent}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};
