import { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  ContentContainerBottom,
  DivWrapper,
  Header,
  HeadingSummaryBlock,
  ManualLoader,
  Wrapper,
} from "../../../components";
import { VerifyConfirmationIcon } from "src/components/Media/Icon/Icons/Icons";

import { commonSpacing, theme } from "src/shared/theme/theme";

export const VerifiedEmailScreenContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { user, isAuthenticated, logout, isLoading } = useAuth0();

  const { isAuthorizedAccess } =
    location?.state != null
      ? (location?.state as {
          isAuthorizedAccess: boolean;
        })
      : {
          isAuthorizedAccess: false,
        };

  /**
   * Render in intial load
   */
  useEffect(() => {
    if (!isAuthorizedAccess) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isAuthorizedAccess, navigate, user]);

  /**
   * Continue button click event
   */
  const onClickBtnEvent = async () => {
    navigate("/home", { state: { isFromVerifiedEmail: true } });
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      <Wrapper isFullScreen>
        <DivWrapper className="no_fullscreen">
          <Box
            component={"div"}
            className={"container_content"}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: 400 + commonSpacing.desktopPaddingB * 4,
              },
            }}
          >
            <Header
              isBackButtonVisible={true}
              onBackButtonClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
              paddingX={0}
            />
            <Box
              sx={{
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  marginBottom: 15,
                },
                [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                  marginBottom: 40,
                },
              }}
            >
              <HeadingSummaryBlock
                heading={t("headingContent.verifiedEmail.headingEmailVerified")}
                headingAlignment="center"
                mobileHeadingAlignment="center"
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginBottom: 3,
              }}
            >
              <Box
                sx={{
                  width: "60px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  marginBottom: 11,
                }}
              >
                <VerifyConfirmationIcon />
              </Box>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                }}
              >
                {t("headingContent.verifiedEmail.content")}
              </Typography>
            </Box>
          </Box>

          <ContentContainerBottom className={"no_fullscreen"}>
            <Button
              variant="containedLarge"
              color="primary"
              type="button"
              onClick={onClickBtnEvent}
              fullWidth
              sx={{ overflow: "hidden" }}
            >
              {t("buttonTexts.continueButton")}
            </Button>
          </ContentContainerBottom>
        </DivWrapper>
      </Wrapper>
    </>
  );
};
