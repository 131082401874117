import { Box, Link, SwitchProps, Typography } from "@mui/material";
import { IOSSwitch, StylesFormControlLabel } from "./FormSwitch.style";

export interface FormSwitchProps extends SwitchProps {
  label?: any;
  onChange?: any;
  checked?: boolean;
  labelLink?: { label: string; url: string };
}

export const FormSwitch = ({
  label,
  defaultChecked,
  disabled,
  onChange,
  checked,
  labelLink,
}: FormSwitchProps) => {
  return (
    <Box>
      <StylesFormControlLabel
        // @ts-ignore
        includechilds={"false"}
        control={
          <IOSSwitch defaultChecked={defaultChecked} checked={checked} />
        }
        onChange={(e) => {
          onChange(e);
        }}
        disabled={disabled}
        label={
          (!labelLink && (
            <Typography variant="h2Bold" color={"gray.600"}>
              {label}
            </Typography>
          )) ||
          (labelLink && (
            <Typography variant="h2Bold" color={"gray.600"}>
              {label}
              <Link target="_blank" href={labelLink.url} rel="noreferrer">
                {labelLink.label}
              </Link>
            </Typography>
          ))
        }
        labelPlacement="start"
      />
    </Box>
  );
};
