import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  createAction,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Reducer } from "redux";
import { reducers } from "./reducers";
import { privateApi } from "./services/privateApi";
import { publicApi } from "./services/publicApi";
import { createErrorDetailsParamsProps } from "src/shared/models/component/base.model";

export const RESET_STATE_ACTION_TYPE = "resetState";

export const resetStateAction = createAction(RESET_STATE_ACTION_TYPE, () => {
  // Error checking is unavailable for following routes
  const errorNoNeedToCheck =
    window.location.pathname !== "/" &&
    window.location.pathname !== "/unverified-email" &&
    window.location.pathname !== "/verified-email" &&
    window.location.pathname !== "/verify-login" &&
    window.location.pathname.includes("/invitation") &&
    window.location.pathname !== "/invitation" &&
    window.location.pathname !== "/401" &&
    window.location.pathname !== "/register" &&
    window.location.pathname !== "/404";

  if (typeof window !== "undefined" && errorNoNeedToCheck) {
    window.location.href = "/error/401";
  }

  return { payload: null };
});

export const resetStateAction404 = createAction(
  RESET_STATE_ACTION_TYPE,
  (errorDetails: createErrorDetailsParamsProps) => {
    // Error checking is unavailable for following routes
    const errorNoNeedToCheck = window.location.pathname === "/";

    // check error and passthrohgh the search params
    if (typeof window !== "undefined" && errorNoNeedToCheck) {
      window.location.href = `/error/api/404/?error=${JSON.stringify(
        errorDetails
      )}`;
    }

    return { payload: null };
  }
);

// set middleware for error handling
export const unauthenticatedMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 401) {
      dispatch(resetStateAction());
    } else if (isRejectedWithValue(action) && action.payload.status === 404) {
      // Create object to define the api error details ___
      const createErrorDetailsParam: createErrorDetailsParamsProps = {
        errorStatus: action.payload.status ?? "404",
        errorMessage:
          action.payload?.data?.err ??
          action.payload?.data?.error ??
          "404 api Error",
        targetAPI:
          action.meta.baseQueryMeta.request.url ??
          action.meta.baseQueryMeta.response.url ??
          "Unable to fetch the URL",
        endpointName: action.meta.arg.endpointName ?? "",
        method: action.meta.baseQueryMeta.request.method ?? "",
      };

      // dispatch the action ___
      dispatch(resetStateAction404(createErrorDetailsParam));
    }

    return next(action);
  };

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {} as RootState;
  }

  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      privateApi.middleware,
      publicApi.middleware,
      unauthenticatedMiddleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
