import { Box, IconButton, Snackbar, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import {
  FormSwitch,
  ManualLoader,
  ShadowCard,
  YesOrNoConfirmationModal,
} from "src/components";
import { CustomSelection } from "src/components/OtherUtilities/CustomSelection/CustomSelection";
import { autoPayMonthsOptions } from "src/shared/assets/data";
import { dateFormatWithYear } from "src/shared/utilities/dateConvertions";
import {
  useAddAutoPayMutation,
  useGetAutoPayQuery,
  useUpdateAutoPayMutation,
} from "src/store/services/privateApi";
import { theme } from "src/shared/theme/theme";
import { CONSTANT_ENABLED_FEATURES } from "src/shared/utilities/constants";
import CloseIcon from "@mui/icons-material/Close";
import PaymentsIcon from "@mui/icons-material/Payments";

export interface PamentSchedulerProps {
  setErrorMessage: any;
  isAutoPaySectionDisabled: boolean;
  wrapperCardStyle?: {
    cardType?: "default" | "remove_all_shadow";
    marginTop?: string | number;
    marginBottom?: string | number;
    paddingX?: string | number;
    paddingBottom?: string | number;
    paddingTop?: string | number;
  };
}

export const PamentSchedulerDefaultStateTag = ({
  content,
}: {
  content: string;
}) => {
  return (
    <Typography
      component={"div"}
      variant="h1Bold"
      color={"primary"}
      sx={{
        paddingY: "3px",
        paddingX: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `1px solid ${theme.palette.gray[500]}`,
        borderRadius: "30px",
        background: theme.palette.gray[50],
        color: theme.palette.gray[500],
      }}
    >
      {content}
    </Typography>
  );
};

export const PamentScheduler = ({
  setErrorMessage,
  isAutoPaySectionDisabled,
  wrapperCardStyle = {
    cardType: "remove_all_shadow",
    marginTop: -1,
    marginBottom: 0,
    paddingX: 4,
    paddingBottom: 0,
    paddingTop: 0,
  },
}: PamentSchedulerProps) => {
  const [isAutoPayOn, setIsAutoPayOn] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<
    { name: string; value: number } | undefined
  >(undefined);

  const [openToast, setOpenToast] = useState(false);
  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenToast(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );
  const message = (
    <Box sx={{ display: "flex", gap: 2 }}>
      <PaymentsIcon></PaymentsIcon>
      <Typography lineHeight={2}>AutoPay has been disabled</Typography>
    </Box>
  );

  const {
    data: autoPayData,
    isSuccess: isSuccessAutoPay,
    isError: isErrorAutoPay,
    isLoading: isLoadingAutoPay,
    isFetching: isFetchingAutoPay,
  } = useGetAutoPayQuery(null);

  const [addAutoPaymutation, { isLoading: isLoadingAddAutoPay }] =
    useAddAutoPayMutation();

  const [updateAutoPaymutation, { isLoading: isLoadingUpdateAutoPay }] =
    useUpdateAutoPayMutation();

  /**
   * Handle the error based on the dependency change
   */
  useEffect(() => {
    if (isErrorAutoPay && !isSuccessAutoPay) {
      setErrorMessage("Unable to fetch the autopay data");
    } else {
      setErrorMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorAutoPay, isSuccessAutoPay]);

  /**
   * Handle the selected month based on the dependency change
   */
  useEffect(() => {
    if (
      isSuccessAutoPay &&
      autoPayData &&
      autoPayData?.data !== null &&
      !isAutoPaySectionDisabled
    ) {
      const setAutoPaySelectedMonth: number | undefined =
        autoPayData?.data?.dayOfMonth;
      const setDefaultValue =
        autoPayMonthsOptions &&
        autoPayMonthsOptions.filter(
          (item: { value: number }) => item.value === setAutoPaySelectedMonth
        );
      const isAutoPayEnabled = autoPayData?.data?.isActive;
      setOpenToast(!isAutoPayEnabled);

      if (setDefaultValue && setDefaultValue.length > 0) {
        setSelectedMonth(setDefaultValue[0]);
      }

      setIsAutoPayOn(isAutoPayEnabled);
    } else {
      setSelectedMonth(undefined);
      setIsAutoPayOn(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAutoPay, autoPayData, selectedMonth, isAutoPaySectionDisabled]);

  const updateAutoPay = async (payload: any, customErrorMessage: string) => {
    const updateResponse: any = await updateAutoPaymutation(payload);

    if (updateResponse?.error) {
      if (updateResponse?.error.length > 0) {
        setErrorMessage(customErrorMessage);
      } else {
        setErrorMessage(
          updateResponse?.error?.data?.err ??
            updateResponse?.error?.data?.message
        );
      }
    } else {
      setErrorMessage("");
    }
  };

  const onChangeAutoPaySwitch = async (event: any) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setConfirmationModalOpen(false);

      if (isSuccessAutoPay && autoPayData && autoPayData?.data?.id === "") {
        setIsAutoPayOn(true);

        const addResponse: any = await addAutoPaymutation(null);

        if (addResponse?.error) {
          if (addResponse?.error.length > 0) {
            setErrorMessage("Unable to add AutoPay");
            setIsAutoPayOn(false);
          } else {
            setErrorMessage(
              addResponse?.error?.data?.err ?? addResponse?.error?.data?.message
            );
          }
        } else {
          setErrorMessage("");
        }
      } else {
        setIsAutoPayOn(true);
        updateAutoPay(
          {
            isAutoPayEnabled: true,
          },
          "Unable to update the AutoPay"
        );
      }
    } else {
      setIsAutoPayOn(false);
      setConfirmationModalOpen(true);
    }
  };

  const onClickConfirmBtnEvent = () => {
    setIsAutoPayOn(false);
    setConfirmationModalOpen(false);

    updateAutoPay(
      {
        isAutoPayEnabled: false,
      },
      "Unable to update the AutoPay"
    );
  };

  const onClickCancelBtnEvent = () => {
    setIsAutoPayOn(true);
    setConfirmationModalOpen(false);
  };

  const onMonthSelectionEvent = async (item: {
    value: number;
    name: string;
  }) => {
    if (item) {
      setSelectedMonth(item);

      updateAutoPay(
        {
          dayOfMonth: item.value,
        },
        "Unable to update the AutoPay date"
      );
    }
  };

  const isLoaderEnabled =
    isLoadingUpdateAutoPay ||
    isLoadingAutoPay ||
    isFetchingAutoPay ||
    isLoadingAddAutoPay;

  const handleTheDiabledStateStyle = isAutoPaySectionDisabled
    ? {
        opacity: 0.7,
        pointerEvents: "none",
      }
    : {
        opacity: 1,
      };

  return (
    <>
      {isLoaderEnabled && <ManualLoader />}
      {isSuccessAutoPay && (
        <ShadowCard
          paddingX={wrapperCardStyle.paddingX}
          marginBottom={wrapperCardStyle.marginBottom}
          marginTop={wrapperCardStyle.marginTop}
          paddingTop={wrapperCardStyle.paddingTop}
          paddingBottom={wrapperCardStyle.paddingBottom}
          className={wrapperCardStyle.cardType}
        >
          <Snackbar
            open={openToast}
            autoHideDuration={5000}
            message={message}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            ContentProps={{
              sx: {
                background: "#E8EAED",
                color: "#606266",
                border: "1px solid #E8EAED",
                px: 2,
                py: 0,
              },
            }}
            sx={{
              top: 50,
            }}
            onClose={() => {
              setOpenToast(false);
              console.log("closed");
            }}
            action={action}
          />
          <Box marginBottom={8} sx={handleTheDiabledStateStyle}>
            <FormSwitch
              onChange={onChangeAutoPaySwitch}
              label={"AutoPay"}
              checked={isAutoPayOn}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              component="p"
              variant="h2Bold"
              textTransform={"uppercase"}
              sx={{
                marginBottom: 5,
                display: "inline-flex",
                alignItems: "center",
                color: "gray.500",
              }}
            >
              {"Payment Date"}
            </Typography>
          </Box>
          <Box
            marginBottom={5}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={handleTheDiabledStateStyle}
          >
            <Typography variant="h2Bold" color={"gray.600"}>
              {"AutoPay Date"}
            </Typography>
            {selectedMonth !== undefined && isAutoPayOn ? (
              <CustomSelection
                options={autoPayMonthsOptions}
                defaultValue={selectedMonth?.name}
                optionOnClickEvent={onMonthSelectionEvent}
                theme={theme}
              />
            ) : (
              <PamentSchedulerDefaultStateTag content="AutoPay is off" />
            )}
          </Box>

          {CONSTANT_ENABLED_FEATURES.SCHEDULED_FOR && (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={handleTheDiabledStateStyle}
            >
              <Typography variant="h2Bold" color={"gray.600"}>
                {"Next Scheduled Date"}
              </Typography>

              {isAutoPayOn && (
                <Typography
                  component={"div"}
                  variant="h1Bold"
                  color={"primary"}
                  sx={{
                    paddingY: "3px",
                    display: "flex",
                    alignItems: "center",
                    width: "94px",
                    justifyContent: "center",
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "30px",
                    background: theme.palette.blue[50],
                  }}
                >
                  {autoPayData?.data?.nextPaymentDate !== null &&
                  autoPayData?.data?.nextPaymentDate !== ""
                    ? dateFormatWithYear(autoPayData?.data?.nextPaymentDate)
                    : "--"}
                </Typography>
              )}

              {!isAutoPayOn && (
                <PamentSchedulerDefaultStateTag content="No payment scheduled" />
              )}
            </Box>
          )}
        </ShadowCard>
      )}

      {confirmationModalOpen && (
        <YesOrNoConfirmationModal
          modalTitle={"AutoPay Setting"}
          content={"Do you want to turn off AutoPay for rent payments?"}
          onClickLeftBtnEvent={onClickConfirmBtnEvent}
          onClickRightBtnEvent={onClickCancelBtnEvent}
        />
      )}
    </>
  );
};
