import { styled } from "@mui/material/styles";

export const SearchBarWrapper = styled("div")(
  ({ theme }) => `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(4)};
`
);

export const SearchInputWrapper = styled("div")(
  ({ theme }) => `
  width: 100%;
  position: relative;
  .close_icon {
    cursor: pointer;
  }

  input,
  input:focus,
  input:hover {
    width: 100%;
    height: 32px;
    padding: 8px 16px 8px 36px;
    background: ${theme.palette.white};
    border-radius: 32px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border: none;
    color: ${theme.palette.gray[800]};
    border: 1px solid ${theme.palette.gray[100]};

    &::-webkit-input-placeholder {
      /* Edge */
      color: ${theme.palette.gray[500]};
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${theme.palette.gray[500]};
    }

    &::placeholder {
      color: ${theme.palette.gray[500]};
    }
  }

  svg {
    position: absolute;
    left: 18px;
    top: 10px;
    opacity: 0.5;
    fill: ${theme.palette.blue[500]};
  }
`
);

export const FilterActiveIndicator = styled("div")(
  ({ theme }) => `
  width: 12px;
  height: 12px;
  background: ${theme.palette.primary.main};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  position: absolute;
  right: -4px;
  top: 5px;
  padding: 2px;
`
);
