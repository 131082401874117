import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Header,
  HeaderFixed,
  LinkedAccountsCard,
  ManualLoader,
  Messages,
  NotificationMessage,
  ShadowCard,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";
import { getAccountLinkUrl } from "src/helpers/helperRent";
import { bankDetailsParams } from "src/shared/models/containers/rent.model";
import { CONSTANT_PAYMENT_STATUSES } from "src/shared/utilities/constants";

import {
  useDeleteBankAccountMutation,
  useGetBalanceDetailsQuery,
  useGetPaymentMethodsQuery,
  useUpdateDefaultBankAccountMutation,
} from "src/store/services/privateApi";
import { PamentScheduler } from "../Payment/PamentScheduler/PamentScheduler";
import { useFlags } from "launchdarkly-react-client-sdk";

export const RentLinkedAccountsListScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { tpAutopayFeature } = useFlags();
  const [isAutoPayFeatureDisabled, setIsAutoPayFeatureDisabled] =
    useState(true);
  const [isPaymentMethodUnAvailable, setIsPaymentMethodUnAvailable] = useState<
    boolean | undefined
  >(undefined);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    data,
    isSuccess,
    isLoading: isLoadingData,
    isFetching: isFetchingData,
    isError: isErrorFetchingData,
  } = useGetPaymentMethodsQuery(null);

  const {
    data: balanceData,
    isSuccess: isSuccessBalanceData,
    isLoading: isLoadingBalanceData,
    isFetching: isFetchingBalanceData,
    isError: isErrorBalanceData,
  }: any = useGetBalanceDetailsQuery(null);

  const [deleteBankAccount, { isLoading: isLoadingDeleteBA }] =
    useDeleteBankAccountMutation();
  const [updateDefaultBankAccount, { isLoading: isLoadingUpdateBA }] =
    useUpdateDefaultBankAccountMutation();

  const addLinkedBankAccount = () => {
    navigate(getAccountLinkUrl(balanceData?.paymentStatus), {
      state: {
        isOnlyCard:
          balanceData?.paymentStatus ===
          CONSTANT_PAYMENT_STATUSES.CERTIFIED_FUNDS,
      },
    });
  };

  const deleteAccount = async (id: any) => {
    setErrorMessage("");
    const deleteResponse: any = await deleteBankAccount({ id });
    if (deleteResponse && deleteResponse.error) {
      if (deleteResponse.error.length > 0) {
        setErrorMessage("Unable to process your delete request.");
      } else {
        setErrorMessage(
          deleteResponse?.error?.data?.err ??
            deleteResponse?.error?.data?.message ??
            deleteResponse?.error?.data?.error
        );
      }
    } else {
      setErrorMessage("");
    }
  };

  const updateDefaultAccount = async (id: any) => {
    setErrorMessage("");
    const updateResponse: any = await updateDefaultBankAccount({ id });
    if (updateResponse && updateResponse.error) {
      if (updateResponse.error.length > 0) {
        setErrorMessage("Unable to process this change.");
      } else {
        setErrorMessage(
          updateResponse?.error?.data?.message ??
            updateResponse?.error?.data?.error
        );
      }
    } else {
      setErrorMessage("");
    }
  };

  const onSetDefaultPaymentMethod = () => {
    if (isSuccess && data && data?.paymentMethods.length > 0) {
      setIsPaymentMethodUnAvailable(false);
      const getDefaultPaymentMethod = data?.paymentMethods.filter(
        (methodItem: bankDetailsParams) => methodItem.default === true
      );

      const getActivePaymentMethods = data?.paymentMethods.filter(
        (methodItem: bankDetailsParams) => methodItem.status === "ACTIVE"
      );

      if (getDefaultPaymentMethod && getDefaultPaymentMethod.length === 0) {
        if (getActivePaymentMethods && getActivePaymentMethods.length > 0) {
          const getIdOfFirstActivePaymentMethod =
            getActivePaymentMethods[0]?.id;
          updateDefaultAccount(getIdOfFirstActivePaymentMethod);
          if (
            balanceData?.paymentStatus !==
            CONSTANT_PAYMENT_STATUSES.DO_NOT_ACCEPT
          )
            setIsAutoPayFeatureDisabled(false);
        }
      } else if (
        getActivePaymentMethods &&
        getActivePaymentMethods.length > 0 &&
        balanceData?.paymentStatus !== CONSTANT_PAYMENT_STATUSES.DO_NOT_ACCEPT
      ) {
        setIsAutoPayFeatureDisabled(false);
      } else {
        setIsAutoPayFeatureDisabled(true);
      }
    } else {
      setIsPaymentMethodUnAvailable(true);
      setIsAutoPayFeatureDisabled(true);
    }
  };

  /**
   * Handle the default payment method in the initial load
   */
  useEffect(() => {
    // check if active payment methods available, if yes make auto pay section enabled
    if (isSuccess && data) {
      onSetDefaultPaymentMethod();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess]);

  /**
   * Handle the error based on the dependency change
   */
  useEffect(() => {
    if (isErrorFetchingData) {
      setErrorMessage("Unable to fetch the linked accounts");
    }
    if (isErrorBalanceData && !isSuccessBalanceData) {
      setErrorMessage("Unable to fetch the balance data");
    }
  }, [isErrorFetchingData, isErrorBalanceData, isSuccessBalanceData]);

  return (
    <>
      {(isLoadingData ||
        isFetchingData ||
        isLoadingUpdateBA ||
        isLoadingDeleteBA ||
        isFetchingBalanceData ||
        isLoadingBalanceData) && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible
          paddingX={0}
          headerText={t("rent.common.linkedListHeading")}
          withCenteredHeaderText
          onBackButtonClick={() => navigate("/rent")}
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          {isSuccess && (
            <LinkedAccountsCard
              blockTitle={t("linkedBankAccount.blockTitle")}
              linkText={t("linkedBankAccount.linkText")}
              onClickLink={addLinkedBankAccount}
              bankData={data?.paymentMethods}
              emptyBlockContent={t("linkedBankAccount.emptyBlockContent")}
              onDeleteAccount={deleteAccount}
              onUpdateDefaulAccount={updateDefaultAccount}
              isCardClickable={false}
              isLastDigits
            />
          )}
          {isPaymentMethodUnAvailable && (
            <NotificationMessage
              messageHeading={t("home.missingPaymentMethodsMessage.heading")}
              messageContent={t("home.missingPaymentMethodsMessage.content")}
              messageLinkEvent={addLinkedBankAccount}
              variant="no_payment_methods"
              topMargin={0}
            />
          )}

          {tpAutopayFeature && isSuccess && (
            <ShadowCard
              marginBottom={2}
              paddingY={6}
              id="auto-pay"
              sx={{ mt: isPaymentMethodUnAvailable ? 0 : 6 }}
            >
              <PamentScheduler
                setErrorMessage={setErrorMessage}
                isAutoPaySectionDisabled={isAutoPayFeatureDisabled}
              />
            </ShadowCard>
          )}

          {errorMessage !== "" && (
            <Messages
              topMargin={0}
              messageHeading={t("rent.common.linkedListHeading")}
              messageContent={errorMessage}
              closeEvent={() => setErrorMessage("")}
            />
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
