import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import {
  HeadingSummaryBlock,
  FormInput,
  Wrapper,
  // FromAutoCompleteSelect,
  // ManualLoader,
  FormInputPhoneNumber,
  FormWrapper,
  ContentContainerBottom,
  Header,
  Messages,
  FromAutoCompleteSelect,
  ManualLoader,
} from "../../../components";

import { RegisterFormParams } from "src/shared/models/containers/account.model";
import { ERROR_MESSAGES } from "src/shared/utilities/validationMessages";
import {
  phoneOnChangeHandler,
  updateBindingsChange,
} from "src/shared/utilities/formUtilities";
import { commonSpacing, theme } from "src/shared/theme/theme";
import {
  useGetAllPropertiesMutation,
  useGetPropertiesMutation,
  useGetUnitsMutation,
} from "src/store/services/publicApi";
import { useCreateUserMutation } from "src/store/services/privateApi";

export const AccountRegisterScreenContainer = () => {
  const [getAllPropertiesMutation, { isLoading: isLoadingPropertiesList }] =
    useGetAllPropertiesMutation();
  const [
    getPropertiesForCodeMutation,
    { isLoading: isLoadingGetPropertiesForCode },
  ] = useGetPropertiesMutation();
  const [getUnitsMutation, { isLoading }] = useGetUnitsMutation();
  const [createUserMutation, { isLoading: isLoadingCreateUser }] =
    useCreateUserMutation();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [unitsData, setUnitsData] = useState<any>();
  const [propertiesList, setPropertiesList] = useState<any>();
  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const [responseData, setResponseData] = useState({
    unit: "",
    property: "",
    email: "",
    phone: "",
    propertyCode: "",
    phoneWithoutMask: "",
  });

  /**
   * define the validation schema for form validation
   */
  const registerFormSchema = yup
    .object({
      unit: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED),
      propertyName: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED),
      phone: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(14, ERROR_MESSAGES.PHONE_NUMBER_INVALID),
    })
    .required();

  /**
   * initialize the hook form
   */
  const {
    register,
    handleSubmit,
    setValue,
    // getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<RegisterFormParams>({
    resolver: yupResolver(registerFormSchema),
  });

  /**
   * function to get All properties
   */
  const getAllProperties = async () => {
    const response: any = await getAllPropertiesMutation(null);

    if (response && response?.data) {
      const responseForDropDown =
        response?.data &&
        response?.data.map((node: { code: string; name: string }) => ({
          label: node.name,
          abbreviation: node.code,
        }));
      setPropertiesList(responseForDropDown);
    }
  };

  /**
   * function used to get unit list
   */
  const getUnitsOfTheProperty = async (_propertyCode: string = "") => {
    if (_propertyCode !== "") {
      const unitResponse: any = await getUnitsMutation(_propertyCode);

      if (unitResponse && unitResponse?.data) {
        const responseForDropDown =
          unitResponse?.data &&
          unitResponse?.data.map(
            (node: { id: string; unitNumber: string }) => ({
              label: node.unitNumber,
              abbreviation: node.id,
            })
          );
        setUnitsData(responseForDropDown);
      }
    }
  };

  const getPropertyCodeForOnChange = async (selectedProperty: string = "") => {
    if (selectedProperty !== "") {
      getAllProperties();

      if (propertiesList && propertiesList.length > 0) {
        const selectedPropertyNode = propertiesList.filter(
          (node: { label: string }) => node.label === selectedProperty
        );
        const selectedPropertyCode = selectedPropertyNode[0].abbreviation ?? "";

        if (selectedPropertyCode !== "") {
          setValue("propertyCode", selectedPropertyCode);

          const propertyDetailResponse: any =
            await getPropertiesForCodeMutation(selectedPropertyCode);

          if (propertyDetailResponse && propertyDetailResponse.data) {
            getUnitsOfTheProperty(propertyDetailResponse.data.id);
          }
        }
      }
    }
  };

  // render in the initial load
  useEffect(() => {
    const getLocalStoragePropertyDetails =
      localStorage.getItem("searched_property_details") ?? null;

    if (getLocalStoragePropertyDetails) {
      const parseLocalStoragePropertyDetails = JSON.parse(
        getLocalStoragePropertyDetails
      );
      setResponseData(parseLocalStoragePropertyDetails);

      setValue("propertyName", parseLocalStoragePropertyDetails.property ?? "");
      setValue(
        "propertyCode",
        parseLocalStoragePropertyDetails.propertyCode ?? ""
      );
      setValue("unit", parseLocalStoragePropertyDetails.unit ?? "");
      setValue(
        "phone",
        parseLocalStoragePropertyDetails.phoneWithoutMask ?? ""
      );
    }

    getAllProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appSubmit = handleSubmit(async (data) => {
    const registerParams = {
      phone: data.phone?.replace(/ /g, ""),
      propertyCode: data.propertyCode ?? "",
      unit: data.unit,
    };

    const createuserResponse: any = await createUserMutation(registerParams);

    if (createuserResponse && createuserResponse.data) {
      navigate("/home");
    } else {
      setErrorMessage(
        createuserResponse?.error?.data?.error ??
          createuserResponse?.error?.data?.message
      );
    }
  });

  const isUnitNumberAvailableInInvitation =
    responseData && responseData.unit !== "" ? true : false;

  return (
    <>
      {(isLoadingGetPropertiesForCode ||
        isLoadingPropertiesList ||
        isLoading ||
        isLoadingCreateUser) && <ManualLoader />}
      <Wrapper>
        <FormWrapper onSubmit={appSubmit} className="no_fullscreen">
          <Box
            component={"div"}
            className={"container_content"}
            // ref={elementRef}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: 400 + commonSpacing.desktopPaddingB * 4,
              },
            }}
          >
            <Header isBackButtonVisible={false} paddingX={0} />
            <Box sx={{ marginBottom: 6 }}>
              <HeadingSummaryBlock
                heading={t("headingContent.individual.personalInfo.heading")}
                headingAlignment="left"
                content={t("headingContent.individual.personalInfo.content")}
                contentAlignment="left"
              />
            </Box>
            <Box>
              {propertiesList && propertiesList.length > 0 && (
                <Controller
                  control={control}
                  name="propertyName"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <FromAutoCompleteSelect
                        label={t("formInput.property")}
                        id={"propertyName"}
                        {...register("propertyName")}
                        placeholder={t("placeHolder.property")}
                        error={errors?.propertyName?.message}
                        inputValue={value || ""}
                        onChange={(_event: any, selectedOption: any) => {
                          if (selectedOption) {
                            onChange(selectedOption.label);
                            getPropertyCodeForOnChange(selectedOption.label);
                          }
                        }}
                        inputFocus={(e: any) => {
                          trigger("propertyName");
                        }}
                        options={propertiesList}
                        readonly={
                          responseData && responseData.property ? true : false
                        }
                      />
                    );
                  }}
                />
              )}

              {!isUnitNumberAvailableInInvitation &&
                unitsData &&
                unitsData.length > 0 && (
                  <Controller
                    control={control}
                    name="unit"
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      return (
                        <FromAutoCompleteSelect
                          label={t("formInput.unit")}
                          id={"unit"}
                          {...register("unit")}
                          placeholder={t("placeHolder.unit")}
                          error={errors?.unit?.message}
                          inputValue={value || ""}
                          onChange={(_event: any, selectedOption: any) => {
                            if (selectedOption) {
                              onChange(selectedOption.label);
                            }
                          }}
                          inputFocus={(e: any) => {
                            trigger("unit");
                          }}
                          options={unitsData}
                        />
                      );
                    }}
                  />
                )}

              {isUnitNumberAvailableInInvitation && (
                <Controller
                  control={control}
                  name="unit"
                  render={({ field: { onChange, value } }) => (
                    <FormInput
                      label={t("formInput.unit")}
                      id={"unit"}
                      placeholder={t("placeHolder.unit")}
                      {...register("unit")}
                      error={errors?.unit?.message}
                      onChange={(event: any) => {
                        updateBindingsChange(event, "unit", setValue);
                      }}
                      value={value}
                      readonly
                      inputFocus={(e: any) => {
                        trigger("unit");
                      }}
                    />
                  )}
                />
              )}

              <Controller
                control={control}
                name="phone"
                render={({ field: { value } }) => (
                  <FormInputPhoneNumber
                    label={t("formInput.phone")}
                    id={"phone"}
                    placeholder={"(123) 543-3454"}
                    {...register("phone")}
                    inputError={errors.phone?.message}
                    onChange={(e: any) => {
                      phoneOnChangeHandler(e, setValue, btnSubmitted);
                    }}
                    inputMode="numeric"
                    value={value}
                  />
                )}
              />
            </Box>
            {errorMessage !== "" && (
              <Messages
                messageHeading="Property Search"
                messageContent={errorMessage}
                closeEvent={() => setErrorMessage("")}
              />
            )}
          </Box>

          <ContentContainerBottom className={"no_fullscreen"}>
            <Button
              variant="containedLarge"
              color="primary"
              type="submit"
              onClick={() => {
                setBtnSubmitted(true);
              }}
              fullWidth
              sx={{ overflow: "hidden" }}
            >
              {t("buttonTexts.continueButton")}
            </Button>
          </ContentContainerBottom>
        </FormWrapper>
      </Wrapper>
    </>
  );
};
