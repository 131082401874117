import { TdContainer } from "../../DataGrid.style";
import { Typography } from "@mui/material";
import { datenormalFormating } from "src/shared/utilities/dateConvertions";

export const FirstCell = (props: any) => {
  const getPropsOriginalData = props?.props?.row?.original;
  const { date, description } = getPropsOriginalData;

  return (
    <TdContainer>
      <Typography
        className={`first-link-text-transactions`}
        color={"gray.900"}
        component={"span"}
      >
        {description === " " || !description ? "----" : description}
      </Typography>
      <span className="date_val hidden-desktop">
        {datenormalFormating(date)}
      </span>
    </TdContainer>
  );
};
