import Radio, { RadioProps } from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { BpCheckedIcon, BpIcon } from "./FormRadioButton.style";
import { Typography } from "@mui/material";

export const BpRadio = (props: RadioProps) => {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export interface FormRadioButtonProps {
  value?: string;
  label?: string;
  disabled?: boolean;
  name?: string;
  id?: string;
  checked?: any;
  handleChange?: any;
  marginRight?: number | string;
  labelFontVariant?: "body1" | "h1" | "h2";
}

export const FormRadioButton = ({
  value = "value",
  label = "Radio Button",
  disabled = false,
  id,
  name = "name",
  checked,
  handleChange,
  marginRight = 8,
  labelFontVariant = "body1",
  ...props
}: FormRadioButtonProps) => {
  return (
    <FormControlLabel
      value={checked}
      name={name}
      id={id}
      disabled={disabled}
      control={<BpRadio onChange={handleChange} checked={checked} id={id} />}
      label={
        <Typography variant={labelFontVariant} color={"gray.700"}>
          {label}
        </Typography>
      }
      sx={{ marginRight: marginRight }}
      {...props}
    />
  );
};
