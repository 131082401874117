export const identityTranslation = {
  identity: {
    verificationTitle: "Identity Verification",
    noteContent1: "Hey neighbors! Just a quick heads-up:",
    noteContent2:
      "As part of a security update to TenantPort, we need to verify your identity.",
    noteContent3:
      "Your KYC (Know Your Customer) information helps us verify your identity and ensure everyone using the app is who they say they are. This helps prevent fraud, unauthorized access to your account, and keeps our community a trusted space for all. Plus, accurate KYC mapping ensures your payments are always credited to the right account, no mix-ups or delays!",
    verifyIdentityTitle: "Verify Identity",
    verifyIdentityContent:
      "Please complete your verification to access all features on TenantPort",
    rentReporting: {
      description:
        "Please confirm your identity to help us report your rent accurately",
    },
    errorMessage: "Unable to complete this process, please check the details",
  },
};
