import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  LanguageSwitchWrapper,
  LanguageSwitchSelectedVal,
  LanguageSwitchDropdown,
  DownArrow,
  LanguageSwitchCardWrapper,
} from "./LanguageSwitcher.style";

import { useComponentVisible } from "src/hooks";

import { ShadowCard } from "../../CommonNestedStyles/FormWrapper.style";
import { TickIcon } from "../../Media/Icon/Icons/Icons";
import { Typography } from "@mui/material";

export const lngs: any = {
  en: { nativeName: "English", subName: null },
  es: { nativeName: "Español", subName: "Spanish" },
};

export interface LanguageSwitcherProps {
  isLeftLaigned?: boolean;
  isDropDownType?: boolean;
  isBorderEnabled?: boolean;
}

export const LanguageSwitcher = ({
  isLeftLaigned = false,
  isDropDownType = true,
  isBorderEnabled = false,
}: LanguageSwitcherProps) => {
  const { i18n } = useTranslation();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleLanguageSwitch = (lng: any) => {
    i18n.changeLanguage(lng);
    setIsComponentVisible(false);
  };

  useEffect(() => {
    handleLanguageSwitch(i18n.resolvedLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.resolvedLanguage]);

  const handleLaguageSelection = async (lng: any) => {
    handleLanguageSwitch(lng);
  };

  return (
    <>
      {/* {isLoading && <ManualLoader />} */}
      {isDropDownType && (
        <LanguageSwitchWrapper ref={ref}>
          <LanguageSwitchSelectedVal
            onClick={() => {
              setIsComponentVisible(!isComponentVisible);
            }}
          >
            {i18n.resolvedLanguage}
            <DownArrow>
              <KeyboardArrowDownIcon color={"inherit"} />
            </DownArrow>
          </LanguageSwitchSelectedVal>
          {isComponentVisible && (
            <LanguageSwitchDropdown
              className={isLeftLaigned ? "left_laigned" : "right_aligned"}
            >
              {Object.keys(lngs).map(
                (lng: any) =>
                  i18n.resolvedLanguage !== lng && (
                    <div
                      key={lng}
                      onClick={() => handleLaguageSelection(lng)}
                      className={"language_option"}
                    >
                      {lng}
                    </div>
                  )
              )}
            </LanguageSwitchDropdown>
          )}
        </LanguageSwitchWrapper>
      )}

      {!isDropDownType && (
        <LanguageSwitchCardWrapper>
          <ShadowCard
            padding={isBorderEnabled ? 0 : 4}
            className={isBorderEnabled ? "remove_all_shadow" : "normal_shadow"}
          >
            {Object.keys(lngs).map((lng: any) => (
              <div
                key={lng}
                onClick={() => handleLaguageSelection(lng)}
                className={`language_option_card ${
                  i18n.resolvedLanguage === lng ? "active" : "in-active"
                }`}
              >
                <div className="language_option_card_left">
                  <Typography
                    variant="h2Bold"
                    color="gray.900"
                    component={"p"}
                    marginBottom={1}
                  >
                    {lngs[lng].nativeName}
                  </Typography>
                  <Typography variant="h1" fontSize={"10px"} color="gray.400">
                    {lngs[lng].subName}
                  </Typography>
                </div>
                {i18n.resolvedLanguage === lng && (
                  <div className="tick-icon">
                    <TickIcon />
                  </div>
                )}
              </div>
            ))}
          </ShadowCard>
        </LanguageSwitchCardWrapper>
      )}
      {/* <SnackBar
        messageHeading={responseType}
        type={responseType === 'error' ? 'error' : 'success'}
        onClose={() => setResponseType('')}
      /> */}
    </>
  );
};
