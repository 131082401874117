import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const PaymentCardFieldsInputContainer = styled(Box)(
  ({ theme }) => `
 display: flex;
 flex-direction: column;
 width: 100%;
 position: relative;
 margin: 0 0 ${theme.spacing(4.5)}; 
 position: relative;

 .MuiFormLabel-root {
  position: absolute;
  top: 11px;
  left: 15px;
  z-index: 1;
 }

 input {
   width: 100%;
   box-shadow: none;
   outline: none !important;
   color: ${theme.palette.gray[900]}; 
   font-size: 14px;
   text-indent: 15px;
   line-height: 1.4375em;
   position: relative;
  font-weight: 400;
  z-index: 2;
  height: 42px;
  border-radius: ${theme.shape.borderRadius}px;

   &:-webkit-autofill,
   &:-webkit-autofill:hover,
   &:-webkit-autofill:focus,
   &:-webkit-autofill:active {
     -webkit-box-shadow: 0 0 0 50px white inset;
   }

   
   background: transparent !important;
   &::placeholder {
     /* Chrome, Firefox, Opera, Safari 10.1+ */
     color: transparent; 
     font-size: 14px;
     opacity: 1; /* Firefox */
   }

   &:-ms-input-placeholder {
     /* Internet Explorer 10-11 */
     color: transparent; 
     font-size: 14px;
   }

   &::-ms-input-placeholder {
     /* Microsoft Edge */
     color: transparent; 
     font-size: 14px;
   }

   &:focus {
    background: ${theme.palette.white} !important;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${theme.palette.gray[500]};  
      font-size: 14px;
      opacity: 1; /* Firefox */
    }
 
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${theme.palette.gray[500]}; 
      font-size: 14px;
    }
 
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${theme.palette.gray[500]}; 
      font-size: 14px;
    }
   }
 }

 &.focused .MuiFormLabel-root,
 input:focus + .MuiFormLabel-root{
  font-size: 12px;
  padding-right: 2px;
  top: 0;
  left: -4px;
  top: -7px;
  left: 10px;
  background: ${theme.palette.white}; 
  z-index: 2;
 }

 &.normal {
  input {
    border: 1px solid ${theme.palette.gray[100]};
  }
 }

 &.error {
  input {
    border: 1px solid ${theme.palette.error.main};
    color: ${theme.palette.error.main};
  }

  .MuiFormLabel-root {
    color: ${theme.palette.error.main};
  }
 }

`
);

export const CardIcon = styled(Box)(
  ({ theme }) => `
  display: flex;
  position: absolute;
  right: 9px;
`
);
