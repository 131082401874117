import { ERROR_MESSAGES } from "./validationMessages";
import * as yup from "yup";

export const registerFormValidationSchemObj = {
  email: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .email(ERROR_MESSAGES.EMAIL_INVALID),
  unit: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
  propertyName: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
  phone: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(14, ERROR_MESSAGES.PHONE_NUMBER_INVALID),
};

export const addressFiledValidationSchemObj = {
  line1: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
  city: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
  postal_code: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .max(5, ERROR_MESSAGES.ZIPCODE_INVALID)
    .min(5, ERROR_MESSAGES.ZIPCODE_INVALID),
  state: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
};

export const addCrreditCardFormSchemaObj = {
  ...addressFiledValidationSchemObj,
  cardHolderName: yup.string().required(ERROR_MESSAGES.REQUIRED),
  payerLastName: yup.string().required(ERROR_MESSAGES.NAMES_REQUIRED),
  label: yup.string().required(ERROR_MESSAGES.REQUIRED),
  cardNumber: yup.string().required(ERROR_MESSAGES.REQUIRED),
  cardExpiryYear: yup.string().required(ERROR_MESSAGES.REQUIRED),
  cardCVC: yup.string().required(ERROR_MESSAGES.REQUIRED),
  addCard: yup.string().required(ERROR_MESSAGES.SELECT_CARD_TYPE),
};

export const cardPaymentFormSchemaObj = {
  ...addressFiledValidationSchemObj,
  cardHolderName: yup.string().required(ERROR_MESSAGES.REQUIRED),
  payerLastName: yup.string().required(ERROR_MESSAGES.NAMES_REQUIRED),
  cardNumber: yup.string().required(ERROR_MESSAGES.REQUIRED),
  cardExpiryYear: yup.string().required(ERROR_MESSAGES.REQUIRED),
  cardCVC: yup.string().required(ERROR_MESSAGES.REQUIRED),
  cardType: yup.string().required(ERROR_MESSAGES.SELECT_CARD_TYPE),
  addCard: yup.string().required(ERROR_MESSAGES.SELECT_CARD_TYPE),
};

export const addBankAccountFormSchemaObj = {
  accountHolderName: yup.string().required(ERROR_MESSAGES.REQUIRED),
  payerLastName: yup.string().required(ERROR_MESSAGES.NAMES_REQUIRED),
  account_number: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
    .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID),
  routing_number: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(9, ERROR_MESSAGES.SSN_INVALID_FULL)
    .max(9, ERROR_MESSAGES.SSN_INVALID_FULL),
  account_number_conf: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
    .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
    .oneOf([yup.ref("account_number")], ERROR_MESSAGES.ACCOUNTS_NOT_MATCH),
  account_type: yup.string().required(ERROR_MESSAGES.SELECT_ACCOUNT_TYPE),
  accountHolderFullName: yup.string().required(ERROR_MESSAGES.REQUIRED),
};

export const achFormSchemaObj = {
  accountHolderName: yup.string().required(ERROR_MESSAGES.REQUIRED),
  payerLastName: yup.string().required(ERROR_MESSAGES.NAMES_REQUIRED),
  account_number: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
    .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID),
  routing_number: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(9, ERROR_MESSAGES.SSN_INVALID_FULL)
    .max(9, ERROR_MESSAGES.SSN_INVALID_FULL),
  account_number_conf: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
    .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
    .oneOf([yup.ref("account_number")], ERROR_MESSAGES.ACCOUNTS_NOT_MATCH),
  account_type: yup.string().required(ERROR_MESSAGES.SELECT_ACCOUNT_TYPE),
};
