export const DEFAULT_BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 990,
  lg: 1024,
  xl: 1512,
  android: 360,
  iPhone: 392,
};

export const onDesktop = `@media(min-width: ${DEFAULT_BREAKPOINTS.sm}px)`;
