import { Box, Typography } from "@mui/material";
import { WrapperCard } from "../WrapperCard/WrapperCard";
import {
  CdWrapper,
  CdRow,
  CdScoreBar,
  CdScoreBarIntervals,
  CdScoreIndicator,
} from "./CreditScoreCard.style";
import {
  handleThePosition,
  handleTheScoreScalesStatuses,
} from "src/helpers/helperRent";
import { CreditBuildScore } from "src/shared/models/containers/rent.model";
import { useTranslation } from "react-i18next";

export interface CreditScoreCardprops {
  creditScroreIntervals: number[];
  creditScroreBgColor: any[];
  isInnerLoaderEnabled?: boolean;
  isLoadingFadeEnabled?: boolean;
  isSuccessLoadData?: boolean;
  statmentData?: CreditBuildScore[];
}

export const CreditScoreCard = ({
  creditScroreIntervals,
  creditScroreBgColor,
  isInnerLoaderEnabled,
  isLoadingFadeEnabled,
  isSuccessLoadData,
  statmentData,
}: CreditScoreCardprops) => {
  const { t } = useTranslation();
  const hasScores = statmentData?.length;
  const getMaxVal = creditScroreIntervals
    ? creditScroreIntervals[creditScroreIntervals.length - 1]
    : 850;
  const getMinVal = creditScroreIntervals ? creditScroreIntervals[0] : 300;
  const scoreDifference = getMaxVal - getMinVal;
  const getLastMonthScoreFromData: number = hasScores
    ? statmentData[statmentData.length - 1].score
    : 0;
  const barPosition: string | number = `${
    ((getLastMonthScoreFromData - getMinVal) / scoreDifference) * 100
  }%`;

  const [scale] = handleTheScoreScalesStatuses(
    isSuccessLoadData ?? false,
    getLastMonthScoreFromData
  );

  return (
    <WrapperCard
      title={t("rentReporting.creditScore.title")}
      plWrapper={4}
      prWrapper={4}
      mbTitle={4}
      minHeightWrapper={211}
      subTitle={
        hasScores
          ? t("rentReporting.creditScore.subTitleWithScore")
          : t("rentReporting.creditScore.subTitleWithoutScore")
      }
      mbSubTitle={4}
      isInnerLoaderEnabled={isInnerLoaderEnabled}
      isLoadingFadeEnabled={isLoadingFadeEnabled}
    >
      <CdWrapper>
        <CdRow
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={2.5}
          paddingX={1.5}
        >
          <Box>
            <Typography variant={"h1"} component={"span"} color={"primary"}>
              {isSuccessLoadData && hasScores
                ? Math.round(getLastMonthScoreFromData)
                : "NA"}
            </Typography>
            <Typography variant={"h1"} component={"span"} color={"#636982"}>
              {`/${getMaxVal}`}
            </Typography>
          </Box>
          <Typography
            variant={"h2Bold"}
            component={"span"}
            color={"primary"}
            marginBottom={0}
          >
            {scale}
          </Typography>
        </CdRow>
        <CdRow
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={3}
          flexDirection={"column"}
        >
          <CdScoreBar marginBottom={2}>
            {creditScroreBgColor &&
              creditScroreBgColor.map(
                (
                  item: {
                    color: string;
                    percentageValue: number;
                  },
                  index: any
                ) => {
                  return (
                    <Box
                      display={"flex"}
                      height={"22px"}
                      key={index}
                      sx={{
                        background: item.color,
                        width: `${item.percentageValue}%`,
                      }}
                    />
                  );
                }
              )}
            {isSuccessLoadData && <CdScoreIndicator left={barPosition} />}
          </CdScoreBar>
          <CdScoreBarIntervals>
            {creditScroreIntervals &&
              creditScroreIntervals.map((item: number, index: any) => {
                const [left, right] = handleThePosition(
                  index,
                  item,
                  creditScroreIntervals ? creditScroreIntervals.length : 0,
                  getMinVal,
                  scoreDifference
                );

                return (
                  <Typography
                    component="span"
                    variant="h1"
                    display={"flex"}
                    height={"22px"}
                    key={index}
                    color={"gray.300"}
                    sx={{
                      fontWeight: 400,
                      position: "absolute",
                      left: left,
                      right: right,
                    }}
                  >
                    {item}
                  </Typography>
                );
              })}
          </CdScoreBarIntervals>
        </CdRow>
        <CdRow justifyContent={"center"} alignItems={"center"} marginBottom={0}>
          <Typography
            component={"span"}
            color={"gray.400"}
            sx={{ fontWeight: 500, fontSize: "10px" }}
          >
            {t("rentReporting.creditScore.bottomText")}
          </Typography>
        </CdRow>
      </CdWrapper>
    </WrapperCard>
  );
};
