import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Qs from "qs";

import { ErrorCard, Header, Wrapper } from "src/components";
import { createErrorDetailsParamsProps } from "src/shared/models/component/base.model";

/**
 * define the errodetails rendering ui
 */
const ErrorDetailsRow = ({
  title,
  value,
  isLast,
}: {
  title: string;
  value: string;
  isLast?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      className="content"
      justifyContent={"flex-start"}
      display={"flex"}
      sx={{
        borderTop: `1px solid ${theme.palette.gray[100]}`,
        borderBottom: isLast ? `1px solid ${theme.palette.gray[100]}` : "none",
        py: 3,
      }}
    >
      <Box width={150} pr={2}>
        <Typography
          variant="h2Bold"
          component={"p"}
          color={"gray.600"}
          textAlign={"right"}
        >
          {`${title} :`}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="h2"
          component={"p"}
          color={"gray.600"}
          textAlign={"left"}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

/**
 * Primary error container screen
 */
export const Error404ApiScreenContainer = () => {
  const { logout, isAuthenticated, isLoading } = useAuth0();
  const location = useLocation(); // get location method from useLocation hook
  const [errorDetails, setErrorDetails] = useState<
    createErrorDetailsParamsProps | undefined
  >(undefined);

  /**
   * Render in intial load based on the dependency array valuses changed
   */
  useEffect(() => {
    // get search from location
    const gerSearchParams = location.search ?? "";

    if (gerSearchParams) {
      // set values from search params __
      const _error = `${Qs.parse(gerSearchParams.substring(1))?.error}`;

      if (_error) {
        setErrorDetails(JSON.parse(_error));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <Wrapper>
      <Header isBackButtonVisible={false} />
      <Box paddingTop={6} paddingX={10} sx={{ textAlign: "center" }}>
        <ErrorCard>
          <Typography
            variant="h3Bold"
            component={"p"}
            color={"primary"}
            textAlign={"center"}
            marginBottom={6}
          >
            {"Something went wrong. Here are the details information:"}
          </Typography>

          {/* Start . display the error details */}
          {errorDetails !== undefined && (
            <Box display={"flex"} flexDirection={"column"} marginBottom={6}>
              <ErrorDetailsRow
                title="Error Message"
                value={errorDetails.errorMessage}
              />
              {/* End . Row */}
              <ErrorDetailsRow
                title="Target API"
                value={errorDetails.targetAPI}
              />
              {/* End . Row */}
              <ErrorDetailsRow title="API Method" value={errorDetails.method} />
              {/* End . Row */}
              <ErrorDetailsRow
                title="RTK Endpoint Name"
                value={errorDetails.endpointName}
                isLast
              />
              {/* End . Row */}
            </Box>
          )}
          {/* End . display the error details */}

          <Button
            variant="contained"
            color="primary"
            type="button"
            sx={{ width: "100px", marginX: "auto" }}
            onClick={() => logout()}
          >
            {isAuthenticated && !isLoading && "Log Out"}
            {!isAuthenticated && !isLoading && "Home"}
          </Button>
        </ErrorCard>
      </Box>
    </Wrapper>
  );
};
