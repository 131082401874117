import React from "react";
import { FilterTagWrapper } from "./FilterTag.style";
import { Icon } from "src/components/Media/Icon/Icon";

export interface FilterTagProps {
  title?: string;
  inputName?: string;
  inputType?: "radio" | "checkbox";
  idValue?: string;
  iconType?: string;
  handleOnChange?: any;
  inputValue?: any;
  selectedOption?: string;
  className?: string;
}

export const FilterTag = ({
  title,
  inputName,
  inputType = "radio",
  idValue,
  iconType,
  handleOnChange,
  inputValue,
  selectedOption,
  className = "filter-tag-wrapper",
}: FilterTagProps) => {
  let inputProps = {
    type: "checkbox",
    id: idValue,
    name: inputName,
    onChange: handleOnChange,
    checked: inputValue,
  };

  return (
    <FilterTagWrapper className={className}>
      {inputType === "radio" && (
        <input
          type="radio"
          value={inputValue ? inputValue : title}
          id={idValue}
          name={inputName}
          onChange={handleOnChange}
          checked={selectedOption === inputValue ? true : false}
          readOnly={selectedOption === inputValue ? true : false}
        />
      )}
      {inputType === "checkbox" && <input {...inputProps} />}
      <label htmlFor={idValue}>
        {iconType && <Icon iconType={iconType} />}
        {title}
      </label>
    </FilterTagWrapper>
  );
};
