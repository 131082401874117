import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { WrpperAuthorizedContainer } from "src/components";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  onVerifyNow: VoidFunction;
  onCloseNote: VoidFunction;
}

export const IdentityNote = ({ onCloseNote, onVerifyNow }: Props) => {
  const { t } = useTranslation();

  return (
    <WrpperAuthorizedContainer>
      <Box
        sx={{
          backgroundColor: "primary.main",
          px: 4,
          pt: 6,
          pb: 10,
          borderRadius: 2,
          color: "white",
          position: "relative",
          zIndex: 20,
        }}
      >
        <CloseIcon
          sx={{
            position: "absolute",
            right: 16,
            top: 24,
            cursor: "pointer",
            fontSize: "24px",
          }}
          onClick={onCloseNote}
        />
        <Typography variant="h4" sx={{ mb: 8, textAlign: "center" }}>
          {t("identity.verificationTitle")}
        </Typography>
        <Typography variant="body2" color="white">
          {t("identity.noteContent1")}
        </Typography>
        <Typography variant="body2" color="white">
          {t("identity.noteContent2")}
        </Typography>
        <br />
        <Typography variant="body2" color="white">
          {t("identity.noteContent3")}
        </Typography>
        <Box sx={{ textAlign: "center", mt: 8 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ backgroundColor: "blue.50" }}
            onClick={onVerifyNow}
          >
            Verify Now
          </Button>
        </Box>
      </Box>
    </WrpperAuthorizedContainer>
  );
};
