import { localeType } from "./locale.type";

import { homeEnTranslation } from "./Home/en";
import { requestsEnTranslation } from "./Requests/en";
import { identityTranslation } from "./Identity/en";
import { rentEnTranslation } from "./Rent/en";

export const en: localeType = {
  ...homeEnTranslation,
  ...requestsEnTranslation,
  ...identityTranslation,
  ...rentEnTranslation,
  formInput: {
    firstName: "First name",
    lastName: "Last name",
    comapanyName: "Company name",
    companyContactName: "Company contact name",
    email: "Email",
    phone: "Phone number",
    phone2: "Phone number 2",
    ssn: "Social Security Number",
    ssnInfo: "Enter last 4 digits of your SSN",
    ssnLast4: "Social Security Number",
    ssnReplaceButtonText: "SSN Provided",
    ssnNotProvidedText: "SSN Not Provided",
    ssnNotVerifiedText: "SSN Not Verified",
    dob: "Date of birth  (mm/dd/yyyy) ",
    addressLine1: "Address Line 1",
    addressLine1HiglightText:
      "Your address must be a valid physical address and cannot be a P.O. Box. This address is not shared publicly and can be your personal address.",
    addressLine2: "Address Line 2 (Optional)",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
    accountName: "Account Holder",
    accountNumber: "Account Number",
    confirmAccountNumber: "Confirm Account Number",
    routingNumber: "Routing Number",
    ein: "Employer Identification Number (EIN)",
    einReplaceButtonText: "Tax ID Provided",
    jobTitle: "Title",
    sameBillingAddressCheckbox: "Same as business address",
    ownerLabel: "Owner",
    executiveLabel: "Executive",
    executiveConfirmInput: "I confirm that I own more than 25% of the company",
    required: "required",
    dobDefault: "01/31/2000",
    businessName: "Business Name (if registered as an org)",
    fullName: "Full name",
    password: "Password",
    changePasswordText: "Change password",
    accountType: {
      label: "Account Type",
      option1: "Checking",
      option2: "Savings",
      option3: "Business Checking",
    },
    businessType: {
      label: "Business Type",
      option1: "Individual",
      option2: "Organization",
    },
    amountRequired: "Amount (required)",
    addNote: "Add Note",
    nickName: "Nickname",
    recipientName: "Recipient Name",
    recipientNameFieldNote: "Use the address where you receive post mail",
    property: "Property",
    unit: "Unit",
    areas: "Area",
    description: "Description",
    category: "Category",
    issueType: "Issue Type",
    consentCheck:
      "I consent to entry in my unit by the property management team while I’m away.",
    homePhone: "Home phone",
    mobilePhone: "Mobile phone",
  },
  buttonTexts: {
    loginButtonText: "Log In",
    signupButtonText: "Create Free Account ",
    buttonTextSignup: "Sign Up",
    buttonTextSignIn: "Log In",
    continueButton: "Continue",
    addButton: "Add",
    inviteUserButton: "Invite one more",
    doneButton: "Done",
    cancelButton: "Cancel",
    updateButton: "Update",
    submitButton: "Submit",
    confirmButton: "Confirm",
    nextButton: "Next",
    followNextStepButton: "Next",
    confirmTermsButton: "Confirm Payout Terms ",
    saveContinueButton: "Save & Continue ",
    continuePayupButton: "Next",
    enableNotificationButton: "Enable Notifications",
    ShareLinkText: "Share link to friend",
    continueWithIdButton: "Submit With ID",
    continueWithEINDocButton: "Continue With Business Docs",
    reviwDataButton: "Review Data",
    goBackButton: "Go Back",
    acceptButton: "Accept",
    linkViewAll: "View All",
    okButton: "Ok",
    yesButton: "Yes",
  },
  linkTexts: {
    sendAgainLink: "Send Again",
    skipLink: "Skip",
  },
  captions: {
    personalInfo: "PERSONAL INFORMATION",
    businessDetails: "BUSINESS DETAILS",
    personalDetails: "PERSONAL DETAILS",
    invitedText: "INVITED",
    submittedText: "SUBMITTED",
    termDetails: "TERM INFORMATION",
    bankInfotext: "BANK DETAILS",
    editDetails: "Edit details",
    recipientInfo: "RECIPIENT DETAILS",
  },
  headingContent: {
    verifyPhone: {
      heading: "Let’s get started",
      content: "Use your phone number to sign up",
    },
    verifyCode: {
      heading: "Verify your number",
      content: "Please enter the verification code sent to",
    },
    invitationExpired: {
      heading: "Invalid or expired invitation",
      content: "The link to your invitation has expired or is not valid.",
      additionalContent: "",
    },
    unVerifiedEmail: {
      heading: "Verify Email",
      content:
        "Check your inbox for a mail from TenantPort and click the link to verify your email.",
      additionalContent: "Didn’t receive a email? Check your spam",
    },
    verifiedEmail: {
      headingEmailVerified: "Email verified",
      headingLoginCompletion: "Login Complete",
      headingAccessDenied: "Access Denied",
      content:
        "Your email address was successfully verified. Continue to set up your account.",
    },
    verifyCodeSuccess: {
      loginComplete: "Login Complete",
    },
    verifyCodeFailiure: {
      loginInComplete: "Login Incomplete",
    },
    commonSteps: {
      searchVendor: {
        heading: "Let’s get started",
        content:
          "Please use the email and contact you used to sign your lease.",
      },
      identity: {
        heading: "Identity Verification",
        content:
          "Please confirm your identity to help us map you to the correct lease.",
      },
    },
    statusses: {
      verificationFailed: {
        heading: "Verification Failed",
        content: `We can't verify your SSN. Please provide a valid ID to continue signing up. Don't worry, all your data will be stored securely.`,
      },
      weFoundVendor: {
        heading: "We found a match!",
        content1:
          "We were able to find a match at {{property}} under {{customer}}.",
        content2:
          "Please set a password for your account on the following screen.",
        content3:
          "Complete your verification before setting up a TenantPort account.",
      },
      weNotFoundVendor: {
        heading: "We could not locate your details",
        content1:
          "Sorry, we were unable to find your details in {{customer}} against the provided email address and unit",
        content2:
          "Do you have alternate contact? Please share the email and unit you used to sign up with {{customer}}.",
      },
      accountALreadyAvailable: {
        heading: "You’re already signed",
        content1:
          "We found a profile already registered on TenantPort with the {{numberOrEmail}}.",
        content2:
          "Log in to your profile or go back to create a new one with a different number or email.",
      },
      identityApproved: {
        heading: "Approved",
        content:
          "The verification was successful! \n Let us  set up an account for you.",
      },
      identityFailed: {
        heading: "Verification Failed",
        content:
          "One or more of the fields entered was incorrect. Please go back and review the data before submitting again.",
      },
    },
    individual: {
      personalInfo: {
        heading: "Let’s review",
        content:
          "Please double-check the info you provided to make sure it’s accurate.",
      },
    },
  },
  errorMessages: {
    verifyCodeError: {
      heading: "Verification failed",
    },
    phoneUniqueError: {
      heading: "Phone number must be unique",
      content: "You must use unique phone number  to use TenantPort.",
    },
    emailUniqueError: {
      heading: "Email ID must be unique",
      content: "You must use unique email-id to use TenantPort.",
    },
    invalidAddressError: {
      heading: "Invalid Address",
    },
  },
  verifyCode: {
    notRecivedText: "Didn’t receive a text?",
  },
  menu: {
    requests: "Requests",
    settings: "Account",
    rent: "Rent",
    dashboard: "Home",
    notification: "Notification",
    referFriend: "Refer Friends",
    transactions: "Transactions",
    payments: "Earnings",
  },
  placeHolder: {
    firstName: "First name",
    lastName: "Last name",
    emailAddress: "Email",
    comapnyName: "Squeaky Cleaners LLC",
    firstLineAddress: "Address",
    secondLineAddress: "Address line 2",
    city: "City",
    state: "State",
    zipCode: "Zip code",
    accountName: "Account holder",
    jobTitle: "Title",
    property: "Property Name",
    unit: "Unit#",
    areas: "Area",
    description: "Please describe your issue here in detail. (Max 1000 words)",
    category: "Category",
    issueType: "IssueType",
    otherIssueType: "Please mention the issue",
    searchRequestInput: "Search for requests",
  },
  settings: {
    HeaderTitle: "Account",
    AccountInformation: {
      title: "Account information",
      buttonTextVerified: "Verified",
      buttonTextPending: "Pending",
      buttonTextRestricted: "Restricted",
      buttonTextUnverified: "Unverified",
    },
    ChangePasswordConfirmation: {
      title: "Link Sent",
      content:
        "Check your inbox for a mail from TenantPort and click the link to change the password.",
    },
    PayoutTerms: {
      title: "Payout Terms",
      buttonText: "Net 7",
    },
    PayoutMode: {
      title: "Payout Mode",
    },
    LinkedBankAccount: {
      title: "Linked Bank Accounts",
      buttonText: "Add Account",
      remove: "Remove",
      default: "Make Default",
    },
    Notifications: {
      title: "Notifications",
      buttonText: "",
    },
    Customers: {
      title: "Customers",
      buttonText: "",
    },
    RentReporting: {
      title: "Rent Reporting",
      buttonText: "",
    },
    Languages: {
      title: "Languages",
      buttonText: "English",
    },
    LogOut: {
      title: "Log Out",
      buttonText: "Verified",
    },
    Referrals: {
      title: "Referrals",
      buttonText: "",
    },
  },
  dataGrid: {
    filter: {
      applyBtn: "Apply",
      title: "Filter and Sort",
      resetAll: "Reset All",
      amountFilter: {
        title: "Amount",
        from: "From",
        to: "to",
      },
      dateFilter: {
        title: "Date",
        last7: "Last 7 Days",
        last30: "Last 30 Days",
        last12m: "Last 12 months",
        lastYear: "Year To Date",
        last90: "Last 90 days",
        all: "All",
        custom: "Custom Period",
        ok: "Ok",
        done: "Done",
        cancel: "Cancel",
      },
      termFilter: {
        title: "Payout terms",
        all: "Select All",
      },
      sortFilter: {
        title: "Sort by ",
        date: "Date",
        amount: "Amount",
      },
    },
  },
  rent: {
    common: {
      heading: "Rent",
      gridHeading: "Activity",
      linkedListHeading: "Payment Settings",
      tooltipContent:
        "All emergency and maintenance tickets by you are referred to as requests.",
      emptyStateTitle: "No activities yet",
      emptyStateContent: "Your most recent activities will appear here.",
    },
    rentActionCard: {
      title: "Welcome to Rent & Payment options",
      buttonOneText: "Make a Payment",
      buttonTwoText: "Payment Settings",
      buttonThreeText: "Payment History",
      buttonFourText: "Rent Reporting",
    },
  },
  linkedBankAccount: {
    blockTitle: "LINKED ACCOUNTS",
    linkText: "Link New Account",
    emptyBlockContent: "Create your first Linked Account.",
    removeLinkTxt: "Remove",
    defaultLinkTxt: "Make Default",
    createActionCard: {
      buttonOneText: "Bank Linking",
      buttonTwoText: "Credit or Debit Card",
    },
  },
  landingBanlceCard: {
    row1Title: "Current Balance",
    row2Title: "Due By",
    row3Title: "Scheduled for",
    button1Text: "Pay Now",
    button2Text: "AutoPay",
  },
  noteBlock: {
    contentPrefix: "Need help? Contact us at",
    contentPostfix: "or submit a request through the app.",
  },
};
