import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const CdWrapper = styled(Box)(
  ({ theme }) => `
   width: 100%;
   display: flex;
   flex-direction: column;
 `
);

export const CdRow = styled(Box)(
  ({ theme }) => `
   width: 100%;
   display: flex;
 `
);

export const CdScoreBar = styled(Box)(
  ({ theme }) => `
  width: 100%;
  display: flex;
  border-radius: 26px;
  height: 22px;
  overflow: hidden;
`
);

export const CdScoreBarIntervals = styled(Box)(
  ({ theme }) => `
  width: 100%;
  display: flex;
  height: 22px;
  overflow: hidden;
  position: relative;
`
);

export const CdScoreIndicator = styled(Box)(
  ({ theme }) => `
  width: 1.5px;
  height: 22px;
  position: absolute;
  background: transparent;
  right: auto;

  &:before,
  &:after {
    width: 1.5px;
    content: '';
    display: block;
    position: absolute;
    background: ${theme.palette.white};
  }

  &:before {
    top: 0;
    bottom: 17px;
  }

  &:after {
    top: 10px;
    bottom: 4px;
  }
`
);
