import { Box } from "@mui/material";
import React from "react";
import { Icon } from "src/components/Media/Icon/Icon";
import { StatusChips } from "src/components/OtherUtilities/StatusChips/StatusChips";
import { handleTransactionAmount } from "src/helpers/helperRent";
import { theme } from "src/shared/theme/theme";

export const ThirdCell = (props: any) => {
  const { isHandleArrowClickable } = props?.props;
  const getPropsOriginalData = props?.props?.row?.original;

  const handleCursorClass = isHandleArrowClickable ? "is_cursor" : "no_cursor";
  const { payment, status } = getPropsOriginalData;

  const [amount] = handleTransactionAmount(payment);

  return (
    <>
      <Box
        display={"flex"}
        sx={{
          [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
            justifyContent: "space-between",
            width: 175,
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            flexDirection: "column",
            justifyContent: "flex-end",
          },
        }}
      >
        <Box
          component={"span"}
          display={"flex"}
          className={`${handleCursorClass} amount_cell sub-cell-second fs-mask plusValue`}
          sx={{
            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              flexDirection: "column",
              justifyContent: "flex-end",
              textAlign: "right",
              marginBottom: 1,
            },
          }}
        >
          {amount}
        </Box>
        <Box
          display={"flex"}
          sx={{
            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              justifyContent: "flex-end",
            },
          }}
        >
          {status ? <StatusChips type={status} isResponsive /> : "--"}
        </Box>
      </Box>
      {props?.props?.iconArrowVisible && (
        <div className="sub-cell-third">
          <Icon iconType="RightAlignedArrow" />
        </div>
      )}
    </>
  );
};
