import { Box, Button, Typography } from "@mui/material";

import { TooltipInfo } from "src/components/OtherUtilities/TooltipInfo/TooltipInfo";
import {
  MenuIconRequests,
  TimerIcon,
} from "src/components/Media/Icon/Icons/Icons";

import {
  DataGridTypes,
  GridEmptyCardProps,
} from "src/shared/models/component/base.model";
import { WrapperCard } from "../WrapperCard/WrapperCard";

export const GridEmptyCardIcons = (gridType: DataGridTypes) => {
  switch (gridType) {
    case "request":
      return (
        <MenuIconRequests width="100" height="100" strokeColor="#D9D9D9" />
      );
    case "activity":
      return <TimerIcon width="100" height="100" fillColor="#D9D9D9" />;
    default:
      break;
  }
};

export const GridEmptyCard = ({
  title,
  emptyStateContent,
  isTooltipInfoEnabled,
  tooltipContentAligned,
  tooltipContent,
  onClickBtn,
  type = "request",
  isButtonVisible = true,
  buttonText = "New Request",
  isEmptyCardDataVisble = true,
  isLoadingFadeEnabled,
  isInnerLoaderEnabled,
  mbWrapper = 6,
}: GridEmptyCardProps) => {
  return (
    <WrapperCard
      pbWrapper={4}
      ptWrapper={5}
      plWrapper={4}
      prWrapper={4}
      mbWrapper={mbWrapper}
      isLoadingFadeEnabled={isLoadingFadeEnabled}
      isInnerLoaderEnabled={isInnerLoaderEnabled}
      minHeightWrapper={type !== "reporting_history" ? "243px" : "150px"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: 3,
          cursor: "pointer",
          width: "100%",
        }}
      >
        <Typography
          component="p"
          variant="h3SemiBold"
          textTransform={"capitalize"}
          sx={{
            marginBottom: 1,
            display: "inline-flex",
            alignItems: "center",
            color: "gray.800",
          }}
        >
          {title}
        </Typography>
        {isTooltipInfoEnabled && (
          <TooltipInfo aligned={tooltipContentAligned}>
            <Box width={"150px"}>{tooltipContent}</Box>
          </TooltipInfo>
        )}
      </Box>

      {isEmptyCardDataVisble && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          paddingTop={3}
        >
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent="center"
            marginBottom={3}
          >
            {GridEmptyCardIcons(type)}
          </Box>
          <Typography
            variant="h2"
            color={"black"}
            component="p"
            textAlign={type !== "reporting_history" ? "center" : "left"}
            marginBottom={isButtonVisible ? 6 : 2}
          >
            {emptyStateContent}
          </Typography>

          {isButtonVisible && (
            <Box width={"100%"} display={"flex"} justifyContent="center">
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                sx={{
                  height: "32px",
                  fontWeight: 500,
                  borderRadius: "8px",
                  width: "164px",
                  marginBottom: 2,
                }}
                onClick={onClickBtn}
              >
                {buttonText}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </WrapperCard>
  );
};
