import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AfterBottomRow = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  padding-top: ${theme.spacing(3)};
   padding-bottom: ${theme.spacing(3)};

  .content {
   &.have_two_row {
    padding-bottom: ${theme.spacing(2)};
   }

   &.icon_available {
    align-items: center;
   }
  }
  
  .content {
   &_right {
    width: calc(100% - 100px);
    padding-left: 10px;

    &.full {
      width: 100% !important;
      padding-left: 0;
    }
   }
  }

  .content {
   &_left {
    min-width: 100px;
    max-width: 100px;
   }

   &.icon_available {
    .content_right {
     align-items: center;
     display: flex;
    }
   }
  }

  .payup_icon {
   margin-right: ${theme.spacing(2)};
  }
`
);

export const TransactionCardWrapper = styled(Box)(
  ({ theme }) => `
   width: 100%;
   display: flex;
   flex-direction: column;
   margin-bottom: ${theme.spacing(4)};
   background: ${theme.palette.white};
   box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 6px 16px rgba(0, 0, 0, 0.1);
   border-radius: 8px;
   cursor: pointer;

   @media(min-width: ${theme.breakpoints.values.sm}px){
     padding: ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(4)};
   }
   
   @media(max-width: ${theme.breakpoints.values.sm}px){
     padding: ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(2)};
     .invoices_table_head {
       display: none;
     }
   }
 `
);

export const TransactionRowIconArrow = styled("div")`
  display: inline-flex;
  width: 15px;
  overflow: hidden;
  cusrsor: pointer;

  svg {
    height: 22px;
    width: 22px;
  }
`;

export const TransactionSummaryUserIcon = styled(Box)(
  ({ theme }) => `
 display: inline-flex;
 align-items: center;
 justify-content: center;
 width: 28px;
 height: 28px;
 color: ${theme.palette.grey[900]};
 font-weight: 500;
 font-size: 12px;
 line-height: 16px;
 margin-right: ${theme.spacing(2)};
 background: ${theme.palette.green[50]};
 border-radius: 20px;

 &.bank-info {
  margin-right: 8px;
  text-transform: uppercase;
  background: ${theme.palette.darkBlue[50]};
 }
`
);

export const AccountIdentifyIcon = styled(Box)(
  ({ theme }) => `
 display: inline-flex;
 align-items: center;
 justify-content: center;
 width: 16px;
 height: 16px;
 margin-right: ${theme.spacing(2)};
`
);
