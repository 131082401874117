import { Box, Button } from "@mui/material";
import { ContentContainerBottom, DivWrapper, Wrapper } from "src/components";
import { commonSpacing, theme } from "src/shared/theme/theme";

interface ISearchPageContainer {
  disabledSubmitBtn?: boolean;
  onSubmitEvent?: VoidFunction;
  submitButtonText: string;
}

export const SearchPageContainer = ({
  submitButtonText,
  disabledSubmitBtn,
  onSubmitEvent,
  children,
}: React.PropsWithChildren<ISearchPageContainer>) => {
  return (
    <Wrapper>
      <DivWrapper className="no_fullscreen">
        <Box
          component={"div"}
          className={"container_content  with_note bottom_terms_padding"}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: 400 + commonSpacing.desktopPaddingB * 4,
            },
          }}
        >
          {children}
        </Box>
        <ContentContainerBottom className={"no_fullscreen"}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            onClick={onSubmitEvent}
            fullWidth
            disabled={disabledSubmitBtn}
            sx={{ overflow: "hidden" }}
          >
            {submitButtonText}
          </Button>
        </ContentContainerBottom>
      </DivWrapper>
    </Wrapper>
  );
};
