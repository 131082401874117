import { Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import {
  BankIcon,
  CardIcon,
  DeleteIcon,
  LinedTickIcon,
  MoreVerticalIcon,
} from "../../Media/Icon/Icons/Icons";
import { ShadowCard } from "../../CommonNestedStyles/FormWrapper.style";
import {
  BankInfoCardTop,
  BankInfoCardLeft,
  BankInfoCardRight,
  DefaultTag,
  ViewMoreDropDown,
  ViewMoreDropDownIcon,
  ViewMoreDropDownList,
  ViewMoreDropDownListItem,
} from "../../CommonNestedStyles/BankInfoCard.style";
import { useComponentVisible } from "src/hooks/useComponentVisible";
import { useTranslation } from "react-i18next";
import { AccountIdentifyIcon } from "../../CommonNestedStyles/CommonNestedStyles.style";
import { getLastFourDigits } from "src/helpers/helperRent";
import { bankDetailsParams } from "src/shared/models/containers/rent.model";

export const LinkBankInfoRow = ({
  bankDetails,
  deleteAccount,
  updateDefaultAccount,
  isSelected,
  onClickBankCard,
  isUpdateEditOptionAvailable = true,
  isCardClickable = true,
  accountNumberPrefixText = "Account",
  isDefaultTagVisible = true,
  isDefaultUpdateOptionAvailable = true,
  isViewMoreDropDownVisible,
  isLastDigits,
  isAutoPayEnabled = false,
}: {
  bankDetails: bankDetailsParams;
  deleteAccount?: any;
  updateDefaultAccount?: any;
  isSelected?: boolean;
  onClickBankCard?: any;
  isUpdateEditOptionAvailable?: boolean;
  isCardClickable?: boolean;
  accountNumberPrefixText?: string;
  isDefaultTagVisible?: boolean;
  isDefaultUpdateOptionAvailable?: boolean;
  isViewMoreDropDownVisible?: boolean;
  isLastDigits?: boolean;
  isAutoPayEnabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleDropdownClick = () => {
    onClickBankCard && onClickBankCard(bankDetails);
  };

  const lastFout = isLastDigits ? getLastFourDigits(bankDetails) : null;

  return (
    <ShadowCard
      marginBottom={0}
      className={"remove_all_shadow"}
      position={"relative"}
    >
      <BankInfoCardTop className={isSelected ? "selected" : "unselected"}>
        <BankInfoCardLeft
          onClick={() => handleDropdownClick()}
          sx={{
            pointerEvents: isCardClickable ? "auto" : "none",
          }}
        >
          <AccountIdentifyIcon>
            {bankDetails?.type === "BANK" && (
              <BankIcon
                width="16"
                height="16"
                fillColor={isSelected ? "#387C88" : "#000"}
              />
            )}
            {bankDetails?.type !== "BANK" && (
              <CardIcon fillColor={isSelected ? "#387C88" : "#000"} />
            )}
          </AccountIdentifyIcon>
          <div className="bank_details">
            <Typography
              variant="h2Bold"
              color={isSelected ? "primary" : "gray.600"}
              marginBottom={lastFout ? 1 : 0}
              component="p"
              textTransform={"capitalize"}
            >
              {bankDetails?.accountName ?? ""}
            </Typography>
            <Typography
              variant="h1"
              color="gray.600"
              marginBottom={lastFout ? 1 : 0}
            >
              {lastFout
                ? `${accountNumberPrefixText} • • • • • ${lastFout}`
                : ""}
            </Typography>
            {isAutoPayEnabled && (
              <Typography variant="h1" color="gray.600">
                {`Fee: ${bankDetails.feeLabel}`}
              </Typography>
            )}
          </div>
          {bankDetails?.default && isDefaultTagVisible && isCardClickable && (
            <DefaultTag>
              <Typography
                variant="h1"
                color="blue.300"
                sx={{ lineHeight: "12px" }}
              >
                {`Default`}
              </Typography>
            </DefaultTag>
          )}
        </BankInfoCardLeft>
      </BankInfoCardTop>
      <BankInfoCardRight>
        {bankDetails?.default && isDefaultTagVisible && !isCardClickable && (
          <DefaultTag sx={{ mt: 2 }}>
            <Typography
              variant="h1"
              color="blue.300"
              sx={{ lineHeight: "12px" }}
            >
              {`Default`}
            </Typography>
          </DefaultTag>
        )}
        {isSelected && (
          <DoneIcon
            sx={{ fontSize: "24px", marginRight: 1.5, mt: 2 }}
            color="primary"
          />
        )}
        {isViewMoreDropDownVisible && (
          <ViewMoreDropDown ref={ref}>
            {isUpdateEditOptionAvailable && (
              <ViewMoreDropDownIcon
                onClick={() => setIsComponentVisible(!isComponentVisible)}
              >
                <MoreVerticalIcon />
              </ViewMoreDropDownIcon>
            )}
            {isComponentVisible && (
              <ViewMoreDropDownList>
                <ViewMoreDropDownListItem className="remove-item">
                  <Typography
                    variant="h2"
                    color="red.500"
                    onClick={() => {
                      deleteAccount && deleteAccount(bankDetails.id);
                      setIsComponentVisible(false);
                    }}
                  >
                    <DeleteIcon />
                    {t("linkedBankAccount.removeLinkTxt")}
                  </Typography>
                </ViewMoreDropDownListItem>
                {isDefaultUpdateOptionAvailable && (
                  <ViewMoreDropDownListItem>
                    <Typography
                      variant="h2"
                      color="gray.900"
                      onClick={() => {
                        updateDefaultAccount &&
                          updateDefaultAccount(bankDetails.id);
                        setIsComponentVisible(false);
                      }}
                    >
                      <LinedTickIcon />
                      {t("linkedBankAccount.defaultLinkTxt")}
                    </Typography>
                  </ViewMoreDropDownListItem>
                )}
              </ViewMoreDropDownList>
            )}
          </ViewMoreDropDown>
        )}
      </BankInfoCardRight>
    </ShadowCard>
  );
};
