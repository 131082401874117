import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AvatarIcon } from "../../Media/Icon/Icons/Icons";

import { ManualLoader } from "../Loader/ManualLoader";

export const UserIdentitify = ({ data, isSuccess, isLoading }: any) => {
  const [userDetails, setUserDetails]: any = useState(null);

  useEffect(() => {
    if (isSuccess && data && data?.user) {
      const dataUser = data?.user;
      const createUserDetailsObj = {
        ...dataUser,
        first_name: "First",
        last_name: "Last",
      };

      setUserDetails(createUserDetailsObj);
    }
  }, [isSuccess, data]);

  // @ts-ignore
  const fullName = `${userDetails?.person?.FirstName ?? ""} ${
    userDetails?.person?.LastName ?? ""
  }`;
  // @ts-ignore
  const email = userDetails?.email ?? "";

  const checkFirstNameAvailableOrNot =
    userDetails && userDetails?.person?.FirstName;
  // @ts-ignore
  const avatarText =
    userDetails && userDetails?.person?.FirstName
      ? `${userDetails?.person?.FirstName?.charAt(
          0
        )}${userDetails?.person?.LastName?.charAt(0)}`
      : "";

  return (
    <>
      {isLoading && <ManualLoader />}
      {isSuccess && data && data?.user && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {userDetails && (
            <>
              {checkFirstNameAvailableOrNot && (
                <Box
                  sx={{
                    position: "relative",
                    width: "40px",
                    height: "40px",
                  }}
                >
                  <AvatarIcon />
                  <Box
                    sx={{
                      position: "absolute",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      left: "0",
                      top: "0",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="h2Bold700"
                      sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                      color="white"
                      component="span"
                    >
                      {avatarText.trim()}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {checkFirstNameAvailableOrNot && (
                  <Typography
                    variant="h3Bold"
                    sx={{ marginLeft: "10px", textTransform: "capitalize" }}
                    color="gray.900"
                  >
                    {fullName}
                  </Typography>
                )}
                <Typography
                  variant="h1"
                  sx={{ marginTop: "4px", marginLeft: "12px" }}
                  color="gray.700"
                >
                  {email}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};
