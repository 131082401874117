import { styled } from "@mui/material/styles";

export const NotTextWrapper = styled("div")(
  ({ theme }) => `
display: flex;
align-items: center;
width: 100%;
box-sizng: border-box;

&.is_border {
  border-top: 1px solid ${theme.palette.gray[100]};
  padding-top: 16px;
  // padding-left: 16px;
  // padding-right: 16px;
  margin-bottom: 16px;
}

&.no_border {
  margin-bottom: 16px;
}

a {
  font-size: 12px;
  font-weight: 400;
  color: ${theme.palette.gray[700]};
  text-decoration: underline;
}

&.not_note {
  visibility: hidden;
  pointer-events: none;
}

.icon_wrapper {
  width: 18px;
  margin-right: 8px;
}

@media (max-width: ${theme.breakpoints.values.sm}px) {
 justify-content: flex-start;
}
@media (min-width: ${theme.breakpoints.values.sm}px) {
 justify-content: center;
}
`
);
