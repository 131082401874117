import { Box, Chip, Typography } from "@mui/material";

import { ShadowCard } from "../..";
import { DataGrid } from "src/components/Grids/DataGrid/DataGrid";

import { configs } from "src/shared/config/config";

import { DataGridCardProps } from "src/shared/models/component/base.model";
import { TooltipInfo } from "src/components/OtherUtilities/TooltipInfo/TooltipInfo";

/**
 * Primary UI component for user interaction
 */
export const DataGridCard = ({
  dataObject,
  dataGridCardTitle = "",
  isRecentWorkOrders,
  isCardLinkAvailable,
  t,
  navigate,
  routeState = { backRouteUrl: "/requests" },
  isTooltipInfoEnabled,
  aligned = "right",
  tooltipContent,
  itemCount,
  viewAllLink = "/requests/maintenance/list",
  gridType,
  isHandleArrowClickable,
  iconArrowVisible,
}: DataGridCardProps) => {
  return (
    <ShadowCard
      className={`card-wrapper-home-main`}
      paddingBottom={4}
      paddingTop={5}
      paddingX={4}
      marginBottom={6}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isCardLinkAvailable ? "space-between" : "flex-start",
          marginBottom: 3,
          width: "100%",
        }}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Typography
            component="p"
            variant="h3SemiBold"
            textTransform={"capitalize"}
            sx={{
              marginBottom: 1,
              display: "inline-flex",
              alignItems: "center",
              color: "gray.800",
            }}
          >
            {dataGridCardTitle}
          </Typography>
          {isTooltipInfoEnabled && (
            <TooltipInfo aligned={aligned}>
              <Box width={"154px"}>{tooltipContent}</Box>
            </TooltipInfo>
          )}
          {itemCount && (
            <Chip
              label={itemCount}
              size="small"
              sx={{
                fontSize: "10px",
                color: "gray.700",
                fontWeight: 600,
                height: "18px",
                paddingX: 0.5,
                marginLeft: 4,
                backgroundColor: "gray.100",
              }}
            />
          )}
        </Box>
        {isCardLinkAvailable && (
          <Typography
            component="span"
            variant="h1Bold"
            textTransform={"capitalize"}
            sx={{
              marginBottom: 1,
              display: "inline-flex",
              alignItems: "center",
              color: "primary.main",
              fontWeight: 600,
              cursor: "pointer",
            }}
            onClick={() => navigate(viewAllLink)}
          >
            {t("buttonTexts.linkViewAll")}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {dataObject && dataObject.length > 0 && (
          <>
            <DataGrid
              isToggle={true}
              dataObject={dataObject}
              searchInProgress={false}
              iconArrowVisible={iconArrowVisible}
              isHandleArrowClickable={isHandleArrowClickable}
              limit={isRecentWorkOrders ? 3 : configs.grid_data_limit}
              routeState={routeState}
              gridType={gridType}
              t={t}
            />
          </>
        )}
      </Box>
    </ShadowCard>
  );
};
