import React from "react";
import { Icon } from "src/components/Media/Icon/Icon";
import { TooltipWrapper } from "./TooltipInfo.style";
import { TooltipInfoProps } from "src/shared/models/component/base.model";

export const TooltipInfo = ({
  children = "Is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown",
  aligned = "right",
}: TooltipInfoProps) => {
  return (
    <TooltipWrapper className={aligned}>
      <div className="tooltip-icon">
        <Icon iconType="InfoIcon" />
      </div>
      <div className="tooltip-content">{children}</div>
    </TooltipWrapper>
  );
};
