import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const PayoutAmountOption = styled(Box)(
  ({ theme }) => `
   display: flex; 
   flex-direction: column;
  `
);

export const PayoutAmountOptionTop = styled(Box)(
  ({ theme }) => `
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  align-itmes: center;

  .option-indicator {
   display: flex;
   align-itmes: center;
   justify-content: center;
   width: 13px;
   height: 13px;
   border-radius: 7px;
   margin-right: ${theme.spacing(2)};
   border: 1px solid ${theme.palette.gray[600]};

   &:before {
    content: '';
    display: flex;
    width: 7px;
    height: 7px;
    border-radius: 4px;
    margin-top: 2px;
    background: ${theme.palette.white};
   }
  }

  &.selected {
   .option-indicator {
    border: 1px solid ${theme.palette.primary.main};
 
    &:before {
     background: ${theme.palette.primary.main};
    }
   }
  }

  &.not_selected {
   .option-indicator {
    border: 1px solid ${theme.palette.gray[600]};
 
    &:before {
     background: ${theme.palette.white};
    }
   }
  }

  .normal_input,
  .error_input {
   width: 120px;
   margin-bottom: 0;

   .left-icon-enabled {
    > svg {
     left: 5px;
     top: 9px;
    }
   }

   .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 6px 5px 6px 20px !important;
    text-align: right;
    font-weight: 500;
   }
  }

  &.selected {
   .normal_input {
    .MuiOutlinedInput-notchedOutline {
     border-color: ${theme.palette.primary.main}
    }
   }
  }
 `
);

export const PayoutAmountOptionBottom = styled(Box)(
  ({ theme }) => `
  display: flex; 
  width: 100%;

  .normal_input,
  .error_input {
   margin-bottom: 0;
  }

  &.selected {
   .normal_input {
    textarea {
     border-color: ${theme.palette.primary.main}
    }
   }
  }
 `
);
