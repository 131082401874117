import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, createSearchParams } from "react-router-dom";

import {
  DataGridWithToolBarCard,
  Header,
  HeaderFixed,
  ManualLoader,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";
import { useGetWorkOrdersQuery } from "src/store/services/privateApi";

export const MaintainanceRequestListsScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState<string>("");
  const [filterSchema, setFilterSchema] = useState({
    endDate: null,
    startDate: null,
  });

  const { data, isSuccess, isLoading, isFetching } = useGetWorkOrdersQuery({
    isDateFilterAvailable: filterSchema.endDate && filterSchema.startDate,
    params: filterSchema,
  });

  const handleSearch = (event: { target: { value: any } }) => {
    setSearchText(event.target.value);
  };

  const handleResetSearch = () => {
    setSearchText("");
  };

  return (
    <>
      {(isLoading || isFetching) && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible
          paddingX={0}
          headerText={t("requests.common.heading")}
          onBackButtonClick={() => navigate("/requests")}
          withCenteredHeaderText
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          {isSuccess && (
            <DataGridWithToolBarCard
              t={t}
              dataObject={data}
              dataGridCardTitle={t("requests.common.heading")}
              navigate={navigate}
              routeState={{ backRouteUrl: "/requests/maintenance/list" }}
              onHandleResetSearch={handleResetSearch}
              onHandleSearch={handleSearch}
              searchText={searchText}
              isTooltipInfoEnabled
              tooltipContent={t("requests.common.tooltipContent")}
              itemCount={data ? `${data.length}` : "0"}
              setFilterSchema={setFilterSchema}
              createSearchParams={createSearchParams}
              isDataFetching={isFetching}
              gridType="request"
              searchInputPlaceHolder={t("placeHolder.searchRequestInput")}
              iconArrowVisible={true}
              isHandleArrowClickable={true}
            />
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
