import React from "react";

export const TermsAndConditionsWidget = (props: any) => {
  const { value } = props;

  return (
    <div>
      <div
        id="TermsAndConditions"
        style={{
          height: "400px",
          overflow: "auto",
          marginBottom: "10px",
          backgroundColor: "white",
          borderStyle: "solid",
        }}
      >
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            The application will be signed by you and all other applicants
            applying for the apartment prior to signing the lease agreement.
            While some of the items below may not apply to you, there are some
            items that may become applicable prior to signing a lease agreement.
            In order to continue with the application process, you will need to
            review the Application Agreement and Authorization carefully and
            acknowledge that you and all other applicants accept the terms.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            <strong>Apartment Lease Information. </strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            The lease contemplated by the parties will be the current TAA or FAA
            Lease. Special information and conditions must be explicitly noted
            on the Lease.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            <strong>If you fail to sign Lease after approval.</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            Unless we authorize otherwise in writing, you and all co-applicants
            must sign the Lease within 3 days after we give you our approval in
            person or by telephone or within 5 days after we mail you, our
            approval. If you or any co-applicant fails to sign as required, your
            application will be deemed withdrawn and we may keep the application
            deposit as liquidated damages and terminate all further obligations
            under this agreement.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            <strong>If you withdraw before approval.</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            If you or any co-applicant withdraws an Application or notifies us
            that you’ve changed your mind about renting the dwelling unit, we’ll
            be entitled to retain all application deposits as liquidated damages
            and the parties will then have no further obligation to each other.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            <strong>Approval/non-approval.</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            If we do not approve your Application within 7 days after the date
            we received a completed Application, your Application will be
            considered “disapproved.” Notification may be in person or mail or
            telephone unless you have requested that notification be by mail.
            You must not assume approval until you receive actual notice of
            approval. The 7-day time period may be changed only by separate
            written agreement.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            <strong>Refund after Non-Approval.</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            If you or any co-applicant is disapproved or deemed disapproved
            Under Paragraph 6, we’ll refund all application deposits within 30
            days of such disapproval. Refund checks may be made payable to all
            co-applicants and mailed to one applicant.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            <strong>Extension of deadlines.</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            If the deadline for approving or refunding under paragraphs 6 or 7
            falls on a Saturday, Sunday or a state or federal holiday, the
            deadline will extend to the end of the next business day.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            <strong>Keys or access devices.</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            We’ll furnish keys and/or access devices only after: (1) all parties
            have signed the Lease and other rental documents referred to in the
            Lease; and (2) all applicable rents and security deposits have been
            paid in full.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            <strong>Application submission.</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            Submission of an Application does not guarantee approval or
            acceptance. It does not bind us to accept the applicant or to sign a
            Lease. Images on our website may represent a sample of a unit and
            may not reflect specific details of any unit. For information not
            found on our website regarding unit availability, unit
            characteristics, pricing or other questions, please call or visit
            our office.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            <strong>Notice to or from co-applicants.</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12pt",
            }}
          >
            <span style={{ fontFamily: "arial, helvetica, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                Any notice we give you or your co-applicant is considered notice
                to all co-applicants; and any notice from you or your
                co-applicants is considered notice from all co-applicants.
              </span>
            </span>
            <br />
            <br />
            <strong
              style={{
                fontFamily: "arial, helvetica, sans-serif",
                fontSize: "12pt",
              }}
            >
              Notice of Income Verification
              <br />
              <br />
            </strong>
            <span style={{ fontFamily: "arial, helvetica, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                Income documents may be submitted through a third party
                verification source to detect potential fraudulent/forged
                documents. The landlord also has the right to contact the
                employer to verify employment.&nbsp;
              </span>
            </span>
            <br />
            <br />
            <strong
              style={{
                fontFamily: "arial, helvetica, sans-serif",
                fontSize: "12pt",
              }}
            >
              Notice of Rental Verification
              <br />
              <br />
            </strong>
            <span style={{ fontFamily: "arial, helvetica, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                Signing this application gives the landlord the right to contact
                former apartment communities or landlords to do a rental
                verification.&nbsp;
              </span>
            </span>
            <br />
            <br />
            <strong
              style={{
                fontFamily: "arial, helvetica, sans-serif",
                fontSize: "12pt",
              }}
            >
              Acknowledgement&nbsp;
              <br />
              <br />
            </strong>
            <span style={{ fontFamily: "arial, helvetica, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                You declare that all your statements in this Application are
                true and complete.&nbsp;
              </span>
            </span>
            <strong
              style={{
                fontFamily: "arial, helvetica, sans-serif",
                fontSize: "12pt",
              }}
            >
              Applicant's submission of this Application, including payment of
              any fees and deposits, is being done only after applicant has
              fully investigated, to its satisfaction, those facts which
              applicant deems material and necessary to the decision to apply
              for a rental unit.&nbsp;
            </strong>
            <span style={{ fontFamily: "arial, helvetica, sans-serif" }}>
              <span style={{ fontSize: "12pt" }}>
                You authorize us to verify your information through any means,
                including consumer-reporting agencies and other rental-housing
                owners, along with using third party fraudulent detection tools,
                and doing employment verifications.{" "}
              </span>
            </span>
            <strong
              style={{
                fontFamily: "arial, helvetica, sans-serif",
                fontSize: "12pt",
              }}
            >
              You acknowledge that you had an{" "}
            </strong>
            <span style={{ fontFamily: "arial, helvetica, sans-serif" }}>
              <span style={{ fontSize: "16px" }}>
                <strong>opportunity</strong>
              </span>
            </span>
            <strong
              style={{
                fontFamily: "arial, helvetica, sans-serif",
                fontSize: "12pt",
              }}
            >
              {" "}
              to review our rental-selection{" "}
            </strong>
            <span style={{ fontFamily: "arial, helvetica, sans-serif" }}>
              <span style={{ fontSize: "16px" }}>
                <strong>criteria</strong>
              </span>
            </span>
            <strong
              style={{
                fontFamily: "arial, helvetica, sans-serif",
                fontSize: "12pt",
              }}
            >
              , which include reasons your Application may be denied, such as
              criminal history, credit history, current income and rental
              history. You understand that if you do not meet our
              rental-selection criteria or if you fail to answer any question or
              give false information, we may reject the application, retain all
              application fees as liquidated damages for our time and expense,
              and terminate your right of occupancy.{" "}
            </strong>
            Giving false information is a serious criminal offense. In lawsuits
            relating to the Application or Lease, the prevailing party may
            recover from the non-prevailing party all attorney's fees and
            litigation costs. We may at any time furnish information to
            consumer-reporting agencies and other rental-housing owners
            regarding your performance of your legal obligations. Fax or
            electronic signatures are legally binding. You acknowledge that our
            privacy policy is available to you.&nbsp;
            <br />
            <br />
            <strong>
              This application and the Lease are binding documents when signed.
              Before submitting an application or signing a Lease, you may take
              a copy of these documents to review and/or consult an attorney.
              Additional provisions or changes may be made in the Lease if
              agreed to in writing by all parties.&nbsp;
            </strong>
            <br />
          </span>
        </p>
      </div>
    </div>
  );
};
