import { FaqBlockProps } from "src/shared/models/component/base.model";
import { FaqBlockWrapper, FaqBlockLeft, FaqBlockRight } from "./FaqBlock.style";
import { Typography } from "@mui/material";

export const FaqBlock = ({ heading, content, iconType }: FaqBlockProps) => {
  const handleFaqIconTypes = (_iconType: string | undefined) => {
    switch (_iconType) {
      case "MakeAPayment":
        return "🏠";
      case "MaintenanceRequest":
        return "🔧";
      case "RentReporting":
        return "📈";
      default:
        break;
    }
  };

  return (
    <FaqBlockWrapper>
      <FaqBlockLeft>{handleFaqIconTypes(iconType)}</FaqBlockLeft>
      <FaqBlockRight>
        {heading && (
          <Typography
            variant="h3Bold"
            color={"#4E4F5E"}
            sx={{ marginBottom: 2 }}
          >
            {heading}
          </Typography>
        )}
        {content && (
          <Typography variant="h2" color={"#4E4F5E"} lineHeight={"20px"}>
            {content}
          </Typography>
        )}
      </FaqBlockRight>
    </FaqBlockWrapper>
  );
};
