import moment from "moment-timezone";

export const dateFormatWithTimeZoneForEarning = (date: any) => {
  return moment(date).format("DD MMM YYYY");
};

export const dateFormatMMMSpaceDD = (date: any) => {
  return moment(date).format("MMM DD");
};

export const dateFormatMMMSpaceDDYYYY = (date: any) => {
  return moment(date).format("MMM DD, YYYY");
};

export const timeFormatWithoutTimeZoneForEarning = (date: any) => {
  return moment(date).format("hh:mm A");
};

export const dateFormatWithYear = (date: any) => {
  return moment(date).format("MMM DD, YYYY");
};

export const dateFormatWithMonth = (date: any) => {
  return moment(date).format("MMM D");
};

export const datenormalFormating = (date: any) => {
  return moment(date).format("MM/DD/YY");
};

export const dateGetMonth = (date: any) => {
  return moment(date).format("MMMM");
};

export const datenormalFormatingWithTimeZone = (date: any) => {
  return moment(date).tz("America/Chicago").format("MM/DD/YY");
};

export const todayDateYYYYMMDD = () => {
  return moment().add(1, "day").format("YYYY-MM-DD");
};

export const dateFormatYYYYspaceMMspaceDDspaceTime = (date: any) => {
  return moment(date).tz("America/Chicago").format();
};

export const dateFormatYYYYspaceMMspaceDDspaceTimeAddMoreDays = (
  date: Date,
  additionalDays: number
) => {
  return moment(date)
    .tz("America/Chicago")
    .add(additionalDays, "days")
    .format();
};

/**
 * Get Year to Today day count
 * @returns
 */
export const getYTD = () => {
  const now: any = new Date();
  const start: any = new Date(now.getFullYear(), 0, 0);
  const diff: any = now - start;
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);

  return day;
};

/**
 * used to convert a Date to a formatted string
 * @param date : target Date
 * @param format : target format
 * @returns a boolean
 */
export const getFormattedDate = (
  date: Date,
  format = "DD MMM, YYYY"
): string => {
  if (date) {
    return moment(date).format(format);
  }

  return "N/A";
};

/**
 *
 * @param _currentDate from wich date user need to check, for example user need to cross check with current date, user need to update YYYY-MM-DD format
 * @param _startDateCount It's a string value, start date value, for example if your date is 1st of every month you have to send value as '01' if it's 24th '24'
 * @param _endDateCount  It's a string value, end date value, for example if your date is 25hth of every month you have to send value as '25' if it's 30th '30',
 * if it's end of every month you just send month-end
 * @param isEndDateisMonthEnd confirm if end date is a month-end
 * @returns boolean value, confirmation for -> if expected date is availble between the reange or not
 */
export const checkIsDateBetweenTheTargetMonth = (
  _currentDate: string,
  _startDateCount: string,
  _endDateCount: string,
  isEndDateisMonthEnd: boolean
): boolean => {
  const currentDate: string = moment().format(_currentDate);
  const startDate: string = moment().format(`YYYY-MM-${_startDateCount}`);
  const endDate: string = isEndDateisMonthEnd
    ? moment().format("YYYY-MM-") + moment().daysInMonth()
    : moment().format(`YYYY-MM-${_endDateCount}`);

  const isBetweenTargetDate = moment(currentDate).isBetween(
    startDate,
    endDate,
    undefined,
    "[]"
  );

  return isBetweenTargetDate;
};

export const getPreviousSixMonths = () => {
  let n = 0;
  let arRet: any[] = [];

  for (; n < 7; n++) arRet.push(moment().subtract(n, "months").format("MMM")); // get last 7 months into array
  arRet.shift(); // remove the current month
  arRet.reverse(); // reverse the array
  arRet = arRet.map(function (x) {
    return x.toUpperCase();
  }); // capitalize the array strings

  return [arRet];
};
