import { ImageBannerProps } from "src/shared/models/component/base.model";
import { BannerImage, ImageContainer, ImageWrapper } from "./ImageBanner.style";

export const ImageBanner = ({
  imgPath,
  imgAlt,
  imgWidth,
  imgHeight = "auto",
  sxImgContainer = { justifyContent: "center" },
  sxImageWrapper = { marginBottom: 6 },
}: ImageBannerProps) => {
  return (
    <ImageWrapper sx={sxImageWrapper}>
      <ImageContainer sx={sxImgContainer}>
        <BannerImage
          src={imgPath}
          alt={imgAlt}
          width={imgWidth}
          height={imgHeight}
        />
      </ImageContainer>
    </ImageWrapper>
  );
};
