import React, { useState, useRef } from "react";
import {
  AccordionSection,
  AccordionButton,
  AccordionTitle,
  AccordionContent,
  FilterRowIndicator,
} from "./Accordion.style";
import { Icon } from "src/components/Media/Icon/Icon";
// import { FilterRowIndicator } from '../../../Grids/InvoiceGridElements/PaymentGrid/SearchBar/SearchBar.style';

export interface AccordionProps {
  title?: string;
  children?: React.ReactNode;
  isFilterActive?: boolean;
  isNoBorder?: boolean;
}

export const Accordion = ({
  title,
  children,
  isFilterActive,
  isNoBorder = false,
}: AccordionProps) => {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content: any = useRef(null);

  const toggleAccordion = () => {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  };

  return (
    <AccordionSection className="accordion__section">
      <AccordionButton
        className={`accordion ${setActive} ${isNoBorder ? "none_border" : ""}`}
        onClick={toggleAccordion}
      >
        <AccordionTitle>{title}</AccordionTitle>
        {isFilterActive && (
          <FilterRowIndicator>
            <Icon iconType="TickIconWhite" />
          </FilterRowIndicator>
        )}
        <div className={setRotate}>
          <Icon iconType="AccordionArrow" />
        </div>
      </AccordionButton>
      <AccordionContent
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        {children}
      </AccordionContent>
    </AccordionSection>
  );
};
