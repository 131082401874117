import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const LandingBannerContainer = styled(Box)(
  ({ theme }) => `
     display: flex;
     width: 100%;
     overflow: hidden;
     position: relative;
     justify-content: center;
     margin-bottom: ${theme.spacing(4)};
     border-radius: 8px;
`
);

export const LandingBanner = styled(Box)(
  ({ theme }) => `
     display: flex;
     width: 100%;
     overflow: hidden;
     position: relative;
     justify-content: center;
     border-radius: 8px;

     img {
      object-fit: cover;
      object-position: center;
      border-radius: 8px;
     }

     @media (max-width: ${theme.breakpoints.values.sm}px) {
      img {
        width: 100%;
        max-height: calc((var(--vh, 1vh) * 100) - 200px);
      }
     }
   
     @media (min-width: ${theme.breakpoints.values.sm}px) {
       img {
        width: 100%;
        max-width: 380px;
       }
     }
`
);

export const DefaultBanner = styled(Box)(
  ({ theme }) => `
     display: flex;
     width: 100%;
     overflow: hidden;
     position: relative;
     justify-content: center;
     align-items: center;
     border-radius: 8px;
     flex-direction: column;

     img {
      display: block;
      height: auto;
      max-width: 90%;
      height: auto;
      max-height: 180px;
     }
`
);

export const HeaderLogo = styled(Box)(
  ({ theme }) => `
     display: flex;
     width: 100%;
     max-width: 200px;
     height: 90px;
     overflow: hidden;
     position: absolute !important;
     justify-content: center;
     align-items: center;
     left: 0;
     right: 0;
     top: 9px;
     margin-left: auto;
     margin-right: auto;

     img {
      display: block;
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 90px;
     }
`
);
