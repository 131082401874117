import { Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  CardArrow,
  IconWrapper,
  MessageContent,
  NotificationMessageWrapper,
} from "./NotificationMessage.style";
import {
  NotificationMessagesProps,
  NotificationMessagesVariants,
} from "src/shared/models/component/base.model";
import {
  HomeIdentityIcon,
  HomeLinkBankIcon,
} from "src/components/Media/Icon/Icons/Icons";

export const handleRequestIconButtonIcon = (
  _cardType?: NotificationMessagesVariants
) => {
  switch (_cardType) {
    case "rent_reporting_disabled":
      return <HomeIdentityIcon />;
    case "no_payment_methods":
      return <HomeIdentityIcon />;
    case "missing_payment_status":
      return <HomeLinkBankIcon />;
    default:
      break;
  }
};

export const NotificationMessage = ({
  messageHeading,
  messageContent,
  messageLinkEvent,
  isDesktopView,
  topMargin = 6,
  bottomMargin = 6,
  type,
  variant,
  isMessageClickable = true,
}: NotificationMessagesProps) => {
  const renderMessageContent = () => {
    return (
      <>
        <IconWrapper>{handleRequestIconButtonIcon(variant)}</IconWrapper>
        <MessageContent>
          {messageHeading && (
            <Typography variant="h2Bold" color={"gray.900"} sx={{ margin: 0 }}>
              {messageHeading}
            </Typography>
          )}
          {messageContent && (
            <Typography
              variant="h1"
              color={"gray.900"}
              sx={{ marginTop: 1, marginBottom: 0 }}
            >
              {messageContent}
            </Typography>
          )}
        </MessageContent>
        {isMessageClickable && (
          <CardArrow>
            <KeyboardArrowRightIcon color={"inherit"} />
          </CardArrow>
        )}
      </>
    );
  };

  return (
    <>
      {isMessageClickable && (
        <NotificationMessageWrapper
          className={`${isDesktopView ? "is_desktop" : "is_mobile"}`}
          sx={{ marginTop: topMargin, marginBottom: bottomMargin }}
          onClick={messageLinkEvent}
        >
          {renderMessageContent()}
        </NotificationMessageWrapper>
      )}
      {!isMessageClickable && (
        <NotificationMessageWrapper
          className={`${isDesktopView ? "is_desktop" : "is_mobile"}`}
          sx={{
            marginTop: topMargin,
            marginBottom: bottomMargin,
            cursor: "default",
          }}
        >
          {renderMessageContent()}
        </NotificationMessageWrapper>
      )}
    </>
  );
};
