import React from "react";
import { Icon } from "src/components/Media/Icon/Icon";

import {
  SearchBarWrapper,
  SearchInputWrapper,
  FilterActiveIndicator,
} from "./SearchBar.style";
import { searchBarProps } from "src/shared/models/component/base.model";
import { FilterIcon } from "src/components/Media/Icon/Icons/Icons";
import { Box } from "@mui/material";

export const SearchBarForGrid = ({
  onchangeHandler,
  isSearchIconVisible,
  onResetSearch,
  searchText,
  t,
  isFilterVisible,
  onClickFilterModalOpen,
  isFilterApplied,
  searchInputPlaceHolder,
}: searchBarProps) => {
  return (
    <SearchBarWrapper>
      <SearchInputWrapper>
        <input
          type="text"
          placeholder={searchInputPlaceHolder}
          onChange={onchangeHandler}
          value={searchText}
        />
        {isSearchIconVisible && <Icon iconType="SearchIcon" />}
        {!isSearchIconVisible && (
          <span className="close_icon" onClick={onResetSearch}>
            <Icon iconType="CloseIcon" />
          </span>
        )}
      </SearchInputWrapper>
      {isFilterVisible && (
        <Box
          marginLeft={2}
          color={"primary.main"}
          width={20}
          height={32}
          display={"flex"}
          alignItems={"center"}
          sx={{ cursor: "pointer", position: "relative" }}
          onClick={onClickFilterModalOpen}
        >
          <FilterIcon width={"15"} strokeColor="currentColor" />
          {isFilterApplied && (
            <FilterActiveIndicator>
              <Icon iconType="TickIconWhite" />
            </FilterActiveIndicator>
          )}
        </Box>
      )}
    </SearchBarWrapper>
  );
};
