import { useNavigate } from "react-router-dom";
import { TdContainer } from "../../DataGrid.style";
import { Typography } from "@mui/material";
import { datenormalFormating } from "src/shared/utilities/dateConvertions";

export const FirstCell = (props: any) => {
  const navigate = useNavigate();
  const getPropsOriginalData = props?.props?.row?.original;
  const { WorkOrderID, ReportedDate, title } = getPropsOriginalData;
  const { routeState } = props?.props;

  return (
    <TdContainer>
      <Typography
        className={`first-link-text-transactions`}
        onClick={() =>
          navigate(`/requests/maintenance/detail/${WorkOrderID}`, {
            state: routeState,
          })
        }
        color={"gray.900"}
        component={"span"}
      >
        {title === " " || !title ? "----" : title}
      </Typography>
      {/* {property && (
        <span className="property-location">{`${property?.name} ${property?.city} ${property?.state}`}</span>
      )} */}
      <span
        onClick={() =>
          navigate(`/requests/maintenance/detail/${WorkOrderID}`, {
            state: routeState,
          })
        }
        className="date_val hidden-desktop"
      >
        {datenormalFormating(ReportedDate)}
      </span>
    </TdContainer>
  );
};
