import { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import {
  ContentContainerBottom,
  Header,
  HeadingSummaryBlock,
  ResendCode,
  Wrapper,
  ManualLoader,
  DivWrapper,
} from "../../../components";
import { EmailVerifiedRequestIcon } from "src/components/Media/Icon/Icons/Icons";

import { commonSpacing, theme } from "src/shared/theme/theme";
import { useResendEmailMutation } from "src/store/services/privateApi";

export const UnVerifiedEmailScreenContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    isAuthenticated,
    user,
    logout,
    getAccessTokenSilently,
    isLoading,
  }: any = useAuth0();

  const [resendEmailMutation, { isLoading: isLoadingResendEmail }] =
    useResendEmailMutation();

  /**
   * Render in intial load
   */
  useEffect(() => {
    if (isAuthenticated && user && user.email_verified) {
      navigate("/verified-email", {
        state: { isAuthorizedAccess: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, navigate, user, getAccessTokenSilently]);

  /**
   * Resend email event
   */
  const onClickResendEmailEvent = async () => {
    await resendEmailMutation(null);
  };

  /**
   * Email unverified screen button click
   */
  const onContinueBtnClick = () => {
    window.location.reload();
  };

  return (
    <>
      {(isLoadingResendEmail || isLoading) && <ManualLoader />}
      <Wrapper isFullScreen>
        <DivWrapper className="no_fullscreen">
          <Box
            component={"div"}
            className={"container_content"}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: 400 + commonSpacing.desktopPaddingB * 4,
              },
            }}
          >
            <Header
              isBackButtonVisible={true}
              onBackButtonClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
              paddingX={0}
            />
            <Box
              sx={{
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  marginBottom: "60px",
                },
                [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                  marginBottom: "103px",
                },
              }}
            >
              <HeadingSummaryBlock
                heading={t("headingContent.unVerifiedEmail.heading")}
                content={t("headingContent.unVerifiedEmail.content")}
                headingAlignment="left"
                mobileHeadingAlignment="left"
                contentAlignment="left"
                mobileContentAlignment="left"
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginBottom: 3,
              }}
            >
              <Box
                sx={{
                  width: "105px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                    marginBottom: "20px",
                  },
                  [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                    marginBottom: "70px",
                  },
                }}
              >
                <EmailVerifiedRequestIcon />
              </Box>

              <ResendCode
                isEmailVerification
                onClick={onClickResendEmailEvent}
              />
            </Box>
          </Box>

          <ContentContainerBottom className={"no_fullscreen"}>
            <Button
              variant="containedLarge"
              color="primary"
              type="button"
              onClick={onContinueBtnClick}
              fullWidth
              sx={{ overflow: "hidden" }}
            >
              {t("buttonTexts.continueButton")}
            </Button>
          </ContentContainerBottom>
        </DivWrapper>
      </Wrapper>
    </>
  );
};
