export const CONSTANT_ENABLED_FEATURES = {
  SCHEDULED_FOR: true, // used to enable the scheduled for
};

// set payment method statusses
export const CONSTANT_PAYMENT_METHOD_STATUSES = {
  ACTIVE: "ACTIVE",
  DELETED: "DELETED",
};

// set account balance statusses
export const CONSTANT_PAYMENT_STATUSES = {
  CERTIFIED_FUNDS: "Certified Funds Only",
  DO_NOT_ACCEPT: "Do Not Accept",
  ACCEPT: "Accept",
};
