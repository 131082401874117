import { styled } from "@mui/material/styles";

export const TopRow = styled("div")(
  ({ theme }) => `
  flex-direction: row;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.palette.gray[50]};
  padding-bottom:  ${theme.spacing(4)};
  position: relative;
`
);

export const SecondRow = styled("div")`
  flex-direction: row;
  flex: 1;
  display: flex;
`;

export const BottomSection = styled("div")(
  ({ theme }) => `
  flex-direction: row;
  display: flex;
  margin-top: ${theme.spacing(4)};
  justify-content: space-between;
  align-items: center;
`
);

export const TitleSection = styled("div")(
  ({ theme }) => `
  flex-direction: row;
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: ${theme.spacing(2)};
 `
);

export const TagWrapper = styled("div")(
  ({ theme }) => `
  border-radius: 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding-top: ${theme.spacing(0.75)};
  padding-bottom: ${theme.spacing(0.75)};
  padding-left: ${theme.spacing(3)};
  padding-right: ${theme.spacing(3)};
  display: none;

  &.un_editable {
   background: #EBEEF1 !important;
   color: ${theme.palette.gray[900]} !important;
   pointer-events: none;
  }

  &.editable {
   background: ${theme.palette.blue[50]};
   color: ${theme.palette.blue[500]};
   cursor: pointer;
  }

  &.warning {
   background: ${theme.palette.yellow[75]};
   color: ${theme.palette.gray[900]} !important;
   cursor: pointer;
  }

  &.complete-disabled {
    pointer-events: none !important;
  }
 `
);

export const EditIconWrapper = styled("div")(
  ({ theme }) => `
  width: 50px;
  height: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.darkBlue[50]};
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;

  span {
    margin-left: 4px;
    font-size: 12px;
    color: ${theme.palette.darkBlue[500]};
  }
  
  svg {
    width: 9px;
  }

  svg * {
    fill: ${theme.palette.darkBlue[500]};
  }
`
);
