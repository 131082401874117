export const workOrdersList = [
  {
    AssignedTo: "Maintenance ResProp",
    AssignedToPersonID: "64369563-1eec-4b67-9a31-26ab4bec5f37",
    WorkOrderID: "64ba1b03-a4b7-4b3f-a522-0c3b42efba3f",
    PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
    Number: 10350,
    ReportedDate: "2023-04-25T00:00:00",
    DueDate: "2023-04-27",
    Description: "Lights blowed out",
    Category: "Online Work Order",
    CategoryID: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
    Areas: ["Bathroom 1", "Kitchen"],
    Notes: "duplicate",
    Status: "Cancelled",
    Priority: "Medium",
    Cost: 0,
    Documents: [],
    LastModified: "2023-05-19T13:25:22.957",
    ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
    Appointment: "Call",
    Phone: "2105279142",
    ReportedBy: "Lance Mercadel",
    Unit: "306",
  },
  {
    AssignedTo: "Maintenance ResProp",
    AssignedToPersonID: "64369563-1eec-4b67-9a31-26ab4bec5f37",
    WorkOrderID: "b67268da-12af-4563-8df0-d32a4ddc1441",
    PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
    Number: 10433,
    ReportedDate: "2023-05-15T00:00:00",
    DueDate: "2023-05-17",
    Description: "Light is out in the middle ",
    Category: "Online Work Order",
    CategoryID: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
    Areas: ["Bathroom 1"],
    Notes: "duplicate",
    Status: "Cancelled",
    Priority: "Medium",
    Cost: 0,
    Documents: [
      {
        Name: "20230515_200454.jpg",
        Uri: "https://resman.blob.core.windows.net/resprop/173cdbfa-942a-40a2-8abc-5060621cebf7.jpg",
      },
    ],
    LastModified: "2023-05-19T13:23:01.01",
    ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
    Appointment: "Call",
    Phone: "2105279142",
    ReportedBy: "Lance Mercadel",
    Unit: "306",
  },
  {
    AssignedTo: "Maintenance ResProp",
    AssignedToPersonID: "64369563-1eec-4b67-9a31-26ab4bec5f37",
    WorkOrderID: "ecf5a2b8-3ae2-4d38-911f-f6378becb100",
    PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
    Number: 10349,
    ReportedDate: "2023-04-25T00:00:00",
    DueDate: "2023-04-27",
    Description: "The thing you pull for the light is off.",
    Category: "Online Work Order",
    CategoryID: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
    Areas: ["Bedroom 1", "Kitchen"],
    Notes: "duplicate",
    Status: "Cancelled",
    Priority: "Medium",
    Cost: 0,
    Documents: [],
    LastModified: "2023-05-11T12:21:44.167",
    ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
    Appointment: "Call",
    Phone: "2105279142",
    ReportedBy: "Lance Mercadel",
    Unit: "306",
  },
  {
    AssignedTo: "Maintenance ResProp",
    AssignedToPersonID: "64369563-1eec-4b67-9a31-26ab4bec5f37",
    WorkOrderID: "cb74e0a3-b296-4d63-9863-86c409c63ccb",
    PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
    Number: 10434,
    ReportedDate: "2023-05-15T00:00:00",
    DueDate: "2023-05-17",
    Description: "Light in the kitchen out",
    Category: "Online Work Order",
    CategoryID: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
    Areas: ["Kitchen"],
    Notes: "NA",
    Status: "Completed",
    Priority: "Medium",
    Cost: 0,
    CompletedBy: "Maintenance ResProp",
    CompletedByPersonID: "64369563-1eec-4b67-9a31-26ab4bec5f37",
    CompletedNotes: "Replaced light",
    CompletedDate: "2023-06-07T14:37:00",
    Documents: [
      {
        Name: "20230515_200414.jpg",
        Uri: "https://resman.blob.core.windows.net/resprop/bc08e7bb-304f-452d-b652-536a6787091e.jpg",
      },
    ],
    LastModified: "2023-06-07T19:37:23.453",
    ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
    Appointment: "Call",
    Phone: "2105279142",
    ReportedBy: "Lance Mercadel",
    Unit: "306",
  },
  {
    AssignedTo: "Maintenance ResProp",
    AssignedToPersonID: "64369563-1eec-4b67-9a31-26ab4bec5f37",
    WorkOrderID: "cf73c2b8-75b0-48a3-b20c-f17dec15bd3d",
    PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
    Number: 10542,
    ReportedDate: "2023-06-10T00:00:00",
    DueDate: "2023-06-12",
    Description: "Filter for air-conditioning ",
    Category: "Online Work Order",
    CategoryID: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
    Areas: ["Hallway"],
    Notes: "NA",
    Status: "Completed",
    Priority: "Medium",
    Cost: 0,
    CompletedBy: "Maintenance ResProp",
    CompletedByPersonID: "64369563-1eec-4b67-9a31-26ab4bec5f37",
    CompletedNotes: "Changed filter",
    StartedDate: "2023-06-12T12:00:00",
    CompletedDate: "2023-06-12T12:33:00",
    Documents: [
      {
        Name: "And blowed out please",
        Uri: "https://resman.blob.core.windows.net/resprop/4a6b3baa-f060-491f-bfcc-caf6cdbd27c8.jpg",
      },
    ],
    LastModified: "2023-06-12T17:33:57.857",
    ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
    Appointment: "Call",
    Phone: "2105279142",
    ReportedBy: "Lance Mercadel",
    Unit: "306",
  },
  {
    AssignedTo: "Maintenance ResProp",
    AssignedToPersonID: "64369563-1eec-4b67-9a31-26ab4bec5f37",
    WorkOrderID: "13f60550-1638-4aa6-9162-f2c06436b1bd",
    PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
    Number: 10435,
    ReportedDate: "2023-05-15T00:00:00",
    DueDate: "2023-05-17",
    Description: "Ceiling fan pull string isn't in the fan",
    Category: "Online Work Order",
    CategoryID: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
    Areas: ["Bedroom 1"],
    Notes: "NA",
    Status: "Completed",
    Priority: "Medium",
    Cost: 0,
    CompletedBy: "Maintenance ResProp",
    CompletedByPersonID: "64369563-1eec-4b67-9a31-26ab4bec5f37",
    CompletedNotes: "Ordering new fan.  short in the string switch.",
    StartedDate: "2023-06-07T14:00:00",
    CompletedDate: "2023-06-07T14:38:00",
    Documents: [
      {
        Name: "20230515_200517.jpg",
        Uri: "https://resman.blob.core.windows.net/resprop/04c172f7-c411-41b1-8e87-15c720a86d98.jpg",
      },
    ],
    LastModified: "2023-06-07T19:38:17.19",
    ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
    Appointment: "Call",
    Phone: "2105279142",
    ReportedBy: "Lance Mercadel",
    Unit: "306",
  },
  {
    AssignedTo: "Juryvette Rivera",
    AssignedToPersonID: "67aed1aa-9134-4075-89b8-e158657801e7",
    WorkOrderID: "e77faa95-9e78-4049-b4ac-14763d1fef69",
    PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
    Number: 10168,
    ReportedDate: "2023-03-13T00:00:00",
    DueDate: "2023-03-15",
    Description:
      "Ceiling fan needs lighting and plugs not functioning in all upper plugs ",
    Category: "Online Work Order",
    CategoryID: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
    Areas: ["Living Room"],
    Notes:
      "Ceiling fan needs lighting and plugs not functioning in all upper plugs ",
    Status: "Completed",
    Priority: "Medium",
    Cost: 0,
    CompletedBy: "Juryvette Rivera",
    CompletedByPersonID: "67aed1aa-9134-4075-89b8-e158657801e7",
    CompletedNotes:
      "Resident was home Ceiling is turning on properly and all plugs work, Verified everything with Female that was in the unit.  ",
    CompletedDate: "2023-03-14T15:19:00",
    Documents: [],
    LastModified: "2023-03-14T20:20:47.527",
    ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
    Appointment: "Call",
    Phone: "2105279142",
    ReportedBy: "Lance Mercadel",
    Unit: "306",
  },
  {
    AssignedTo: "Juryvette Rivera",
    AssignedToPersonID: "67aed1aa-9134-4075-89b8-e158657801e7",
    WorkOrderID: "cb3ae720-a63c-423e-a3c8-32af590964d9",
    PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
    Number: 10169,
    ReportedDate: "2023-03-13T00:00:00",
    DueDate: "2023-03-15",
    Description: "Garage disposal comes on and doesn't ",
    Category: "Online Work Order",
    CategoryID: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
    Areas: ["Kitchen"],
    Notes: "Garage disposal comes on and doesn't ",
    Status: "Completed",
    Priority: "Medium",
    Cost: 0,
    CompletedBy: "Juryvette Rivera",
    CompletedByPersonID: "67aed1aa-9134-4075-89b8-e158657801e7",
    CompletedNotes: "Unclogged disposal",
    CompletedDate: "2023-03-14T15:19:00",
    Documents: [],
    LastModified: "2023-03-14T20:19:18.07",
    ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
    Appointment: "Call",
    Phone: "2105279142",
    ReportedBy: "Lance Mercadel",
    Unit: "306",
  },
  {
    AssignedTo: "Juryvette Rivera",
    AssignedToPersonID: "67aed1aa-9134-4075-89b8-e158657801e7",
    WorkOrderID: "3d71389e-938e-48bc-8c50-7d7371e6026e",
    PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
    Number: 10167,
    ReportedDate: "2023-03-13T00:00:00",
    DueDate: "2023-03-15",
    Description: "Plugs not functionally right",
    Category: "Online Work Order",
    CategoryID: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
    Areas: ["Bathroom 1"],
    Notes: "\r\nNotes\r\n-----\r\nTop of the plugs not functionally ",
    Status: "Completed",
    Priority: "Medium",
    Cost: 0,
    CompletedBy: "Juryvette Rivera",
    CompletedByPersonID: "67aed1aa-9134-4075-89b8-e158657801e7",
    CompletedNotes: "Verified all plus with female resident that was home. ",
    CompletedDate: "2023-03-14T15:20:00",
    Documents: [],
    LastModified: "2023-03-14T20:21:14.97",
    ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
    Appointment: "Call",
    Phone: "2105279142",
    ReportedBy: "Lance Mercadel",
    Unit: "306",
  },
  {
    AssignedTo: "Juryvette Rivera",
    AssignedToPersonID: "67aed1aa-9134-4075-89b8-e158657801e7",
    WorkOrderID: "9d979c94-bd54-4f5d-9fb1-8f1b0916df85",
    PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
    Number: 10266,
    ReportedDate: "2023-04-03T00:00:00",
    DueDate: "2023-04-05",
    Description:
      "Can you confirm the rental criteria has been changed and attach a copy? We want to make sure we're compliant.  ",
    Category: "Online Work Order",
    CategoryID: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
    Areas: ["Bathroom 1"],
    Notes:
      "Can you confirm the rental criteria has been changed and attach a copy? We want to make sure we're compliant.\r\n ",
    Status: "Completed",
    Priority: "Medium",
    Cost: 0,
    CompletedBy: "Juryvette Rivera",
    CompletedByPersonID: "67aed1aa-9134-4075-89b8-e158657801e7",
    CompletedNotes: "office question not a maintenance request  ",
    CompletedDate: "2023-04-03T17:04:00",
    Documents: [
      {
        Name: "20230403_081911.heic",
        Uri: "https://resman.blob.core.windows.net/resprop/70d4d3c2-a703-4a25-953d-cb9bc45dee67.heic",
      },
    ],
    LastModified: "2023-04-03T22:04:26.58",
    ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
    Appointment: "Call",
    Phone: "2105279142",
    ReportedBy: "Lance Mercadel",
    Unit: "306",
  },
  {
    AssignedTo: "Juryvette Rivera",
    AssignedToPersonID: "67aed1aa-9134-4075-89b8-e158657801e7",
    WorkOrderID: "6c88f2f8-2cdf-4f7e-bf44-a69b5c063e14",
    PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
    Number: 10267,
    ReportedDate: "2023-04-03T00:00:00",
    DueDate: "2023-04-05",
    Description:
      "Can you confirm the rental criteria has been changed and attach a copy? We want to make sure we're compliant.  ",
    Category: "Online Work Order",
    CategoryID: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
    Areas: ["Living Room"],
    Notes:
      "Can you confirm the rental criteria has been changed and attach a copy? We want to make sure we're compliant.\r\n ",
    Status: "Completed",
    Priority: "Medium",
    Cost: 0,
    CompletedBy: "Juryvette Rivera",
    CompletedByPersonID: "67aed1aa-9134-4075-89b8-e158657801e7",
    CompletedNotes: "office question not a maintenance request  ",
    CompletedDate: "2023-04-03T17:00:00",
    Documents: [],
    LastModified: "2023-04-03T22:04:09.237",
    ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
    Appointment: "Call",
    Phone: "2105279142",
    ReportedBy: "Lance Mercadel",
    Unit: "306",
  },
  {
    AssignedTo: "David Olivarez",
    AssignedToPersonID: "8b68f6fc-8c1d-4aec-b4eb-ff027502485c",
    WorkOrderID: "df605a0b-e0eb-4be9-9c13-d547de8d02d8",
    PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
    Number: 10344,
    ReportedDate: "2023-04-25T00:00:00",
    DueDate: "2023-04-27",
    Description: "[CALLBACK Work Order 10191]: Leaking water from rain",
    Category: "Callback",
    CategoryID: "cb3b06ec-69a9-4401-8507-8243bd6940d6",
    Areas: [
      "Bathroom 1",
      "Bathroom 2",
      "Bedroom 1",
      "Bedroom 2",
      "Bedroom 3",
      "Hallway",
      "Kitchen",
      "Living Room",
      "Patio",
    ],
    Notes:
      "A callback for work order 10191 has been requested. Original notes: Leaking water from rain. Closet in bedroom.",
    Status: "Completed",
    Priority: "Medium",
    Cost: 0,
    CompletedBy: "David Olivarez",
    CompletedByPersonID: "8b68f6fc-8c1d-4aec-b4eb-ff027502485c",
    CompletedNotes: "set up with contractor for sheet rock repair ",
    CompletedDate: "2023-04-25T14:15:00",
    Documents: [],
    LastModified: "2023-04-25T19:17:12.13",
    ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
    Phone: "(210) 356-3569",
    ReportedBy: "Lance Mercadel",
    Unit: "306",
  },
  {
    AssignedTo: "David Olivarez",
    AssignedToPersonID: "8b68f6fc-8c1d-4aec-b4eb-ff027502485c",
    WorkOrderID: "cc406fe4-dae7-4d42-b6a3-beb5d3d018c5",
    PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
    Number: 10191,
    ReportedDate: "2023-03-18T00:00:00",
    DueDate: "2023-03-20",
    Description: "Leaking water from rain",
    Category: "Online Work Order",
    CategoryID: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
    Areas: ["Bedroom 1"],
    Notes: "Leaking water from rain. Closet in bedroom.",
    Status: "Completed",
    Priority: "Medium",
    Cost: 0,
    CompletedBy: "David Olivarez",
    CompletedByPersonID: "8b68f6fc-8c1d-4aec-b4eb-ff027502485c",
    CompletedNotes:
      "Schedule with vendor, pending a call back  completed by best in town.",
    CompletedDate: "2023-04-25T09:31:00",
    Documents: [
      {
        Name: "Celling leaking in closet",
        Uri: "https://resman.blob.core.windows.net/resprop/aa5f49ac-82e0-4b22-a57c-426f8bc03bf5.heic",
      },
    ],
    LastModified: "2023-04-25T14:31:09.557",
    ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
    Appointment: "Call",
    Phone: "2105279142",
    ReportedBy: "Lance Mercadel",
    Unit: "306",
  },
];

export const singleWorkOrderData = {
  AssignedTo: "Maintenance ResProp",
  AssignedToPersonID: "64369563-1eec-4b67-9a31-26ab4bec5f37",
  WorkOrderID: "64ba1b03-a4b7-4b3f-a522-0c3b42efba3f",
  PropertyID: "ff9c8307-9f90-4154-8dad-079770c1b79c",
  Number: 10350,
  ReportedDate: "2023-04-25T00:00:00",
  DueDate: "2023-04-27",
  Description: "Lights blowed out",
  Category: "Online Work Order",
  CategoryID: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
  Areas: ["Bathroom 1", "Kitchen"],
  Notes: "duplicate",
  Status: "Cancelled",
  Priority: "Medium",
  Cost: 0,
  Documents: [],
  LastModified: "2023-05-19T13:25:22.957",
  ReportedByPersonID: "b2c5653b-680a-448d-bc44-a343ffed7386",
  Appointment: "Call",
  Phone: "2105279142",
  ReportedBy: "Lance Mercadel",
  Unit: "306",
};

export const categoriesData = [
  {
    abbreviation: "43d48382-a1da-4930-9889-092d95dd4d1c",
    label: "After Hours Call",
  },
  {
    abbreviation: "c5f9cf95-1426-49df-a13b-5519a140a63d",
    label: "Appliances - Ceiling Fan",
  },
  {
    abbreviation: "0148f8e3-5fea-4885-bc1a-a4f73c2503d4",
    label: "Appliances - Dishwasher",
  },
  {
    abbreviation: "3150af91-6077-4846-9231-b56a65bfacbf",
    label: "Appliances - Dryer",
  },
  {
    abbreviation: "3e10bcac-3084-49e0-b167-98d6d1ca3297",
    label: "Appliances - Microwave",
  },
  {
    abbreviation: "c191605a-4069-44f5-aa36-dc1afa755cd9",
    label: "Appliances - Oven",
  },
  {
    abbreviation: "9926ab60-ff9d-45c6-a520-3f1109d9c844",
    label: "Appliances - Refrigerator",
  },
  {
    abbreviation: "c20b78cd-987a-4f9e-8b27-f2afc5811ba5",
    label: "Appliances - Stovetop",
  },
  {
    abbreviation: "4c6d8edd-bc9e-45a2-99f9-da7a4784e6af",
    label: "Appliances - Washing Machine",
  },
  {
    abbreviation: "65b83576-858b-4760-861b-f4c68fe13bc1",
    label: "Bath Hardware",
  },
  {
    abbreviation: "fba0d89e-89f6-479d-b5cf-4afcc8a15f51",
    label: "Blinds",
  },
  {
    abbreviation: "704ae591-275e-4f02-8c3c-fd0429260c36",
    label: "Cabinets",
  },
  {
    abbreviation: "cb3b06ec-69a9-4401-8507-8243bd6940d6",
    label: "Callback",
  },
  {
    abbreviation: "6eed71d0-f30c-4658-aa11-7f1d3a8459ec",
    label: "Clean",
  },
  {
    abbreviation: "9d977838-1677-4c84-8011-7f14ad0ba924",
    label: "Common Area",
  },
  {
    abbreviation: "bd1d3f56-fe01-4407-b8f1-33e8fd9d0c9a",
    label: "Countertops",
  },
  {
    abbreviation: "887dfd9a-0f77-4754-9923-8215be902484",
    label: "Doors",
  },
  {
    abbreviation: "b5061728-c137-4198-92c2-64f4de1cbd60",
    label: "Electrical",
  },
  {
    abbreviation: "15284f6b-cd3f-45d7-bd10-5426d92a817c",
    label: "Exterior Building Repairs",
  },
  {
    abbreviation: "e9d5310b-ac8f-4c92-9d2c-77ed7901847f",
    label: "Fireplace",
  },
  {
    abbreviation: "fd45ace3-46bf-4af8-8a0a-9e4a8495e25f",
    label: "Flooring",
  },
  {
    abbreviation: "aedeea3d-21a8-4d60-ac03-c0a50d094f0e",
    label: "Garage Door",
  },
  {
    abbreviation: "125f6923-48a1-41db-b7a2-efe407f8edd0",
    label: "Gates and Fences",
  },
  {
    abbreviation: "d3a23514-44ed-4348-be2b-5d826130487d",
    label: "HVAC",
  },
  {
    abbreviation: "60bc5aad-3700-42a8-8bc0-fa6febfcea25",
    label: "Inspection",
  },
  {
    abbreviation: "5b9d9b80-a67b-4dec-9a0a-7065b9175ab6",
    label: "Landscape",
  },
  {
    abbreviation: "142cc49e-0df8-4572-9f6a-3bb529b6d731",
    label: "Leak",
  },
  {
    abbreviation: "4f5ca0cc-8c2a-458c-a2c2-1ed4e506200a",
    label: "Life Safety or Potential Hazard",
  },
  {
    abbreviation: "3647cc2a-9ef6-4ac6-9d2b-cb7325178bb3",
    label: "Lights",
  },
  {
    abbreviation: "22525d10-2cc7-4d11-b40d-276d45a92d76",
    label: "Mailbox Keys/Locks",
  },
  {
    abbreviation: "766d0468-a742-493d-98a8-a61619b0ca3a",
    label: "Make Ready",
  },
  {
    abbreviation: "c614ecf6-92d9-4962-868f-eeed1766f90c",
    label: "Moisture Intrusion",
  },
  {
    abbreviation: "abb5e9ff-3ad3-4d34-83d5-000786a153d7",
    label: "Move Out Inspection",
  },
  {
    abbreviation: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2",
    label: "Online Work Order",
  },
  {
    abbreviation: "d8c2079d-2800-4c4d-b6d3-a142ce6363b4",
    label: "Paint",
  },
  {
    abbreviation: "a57fa3ea-3e2c-493c-bdff-87e3b14914f3",
    label: "Pest Control",
  },
  {
    abbreviation: "c6359863-7010-4849-b2da-6f4315608f66",
    label: "Plumbing - Bathtub",
  },
  {
    abbreviation: "b9fdf737-06ab-408a-b780-cfc27444eaba",
    label: "Plumbing - Faucet",
  },
  {
    abbreviation: "afb3b7d8-1da2-4547-a8a1-8d5974eeef68",
    label: "Plumbing - Garbage Disposal",
  },
  {
    abbreviation: "edc1c53c-b4b6-490b-b829-f2155631b1f1",
    label: "Plumbing - Shower",
  },
  {
    abbreviation: "bee0d92a-2a30-4e2a-960f-65bda59c1e81",
    label: "Plumbing - Sink",
  },
  {
    abbreviation: "86c00e2c-651a-4ab3-a987-67b97732fa79",
    label: "Plumbing - Toilet",
  },
  {
    abbreviation: "37505dc6-723e-4290-ba3f-3aa4007851b4",
    label: "Plumbing - Water Heater",
  },
  {
    abbreviation: "8226b53d-9444-4af0-8ba5-cfc0f61aae10",
    label: "Pool Maintenance",
  },
  {
    abbreviation: "cd08dc75-da77-49a7-bdc7-ae1838434e6a",
    label: "Preventative Maintenance",
  },
  {
    abbreviation: "5d0a132a-4987-4362-9419-a6d72596d42f",
    label: "Quality Inspection",
  },
  {
    abbreviation: "5af9b956-3fd9-4a36-b0a5-b38a00df9013",
    label: "Rehab Turn",
  },
  {
    abbreviation: "b5c31172-a200-4f41-b1d4-2e11924d2646",
    label: "Resurfacing",
  },
  {
    abbreviation: "4005ed4c-2792-451b-8435-f8470a2aee1f",
    label: "Roof",
  },
  {
    abbreviation: "932b2043-61f8-4184-be14-572e8698310c",
    label: "Suspect Growth",
  },
  {
    abbreviation: "8ef186f9-8476-467b-8112-038d366353a6",
    label: "Theft/Vandalism",
  },
  {
    abbreviation: "a0ad1d1a-3050-479c-9688-08384c8e4313",
    label: "Trashout",
  },
  {
    abbreviation: "2f2e7391-211f-4024-8aa1-9994f14e0501",
    label: "Unit Keys/Locks",
  },
  {
    abbreviation: "6a5c55ad-5f32-4403-9bbd-1a9f625e7acb",
    label: "Walls/Ceilings",
  },
  {
    abbreviation: "3356099c-319a-4188-98f6-7b6770439287",
    label: "Window",
  },
];

export const areasListData = [
  {
    label: "Bathroom 1",
  },
  {
    label: "Bathroom 2",
  },
  {
    label: "Bathroom 3",
  },
  {
    label: "Bedroom 1",
  },
  {
    label: "Bedroom 2",
  },
  {
    label: "Bedroom 3",
  },
  {
    label: "Hallway",
  },
  {
    label: "Kitchen",
  },
  {
    label: "Living Room",
  },
  {
    label: "Patio",
  },
];
