import React, { useEffect, useMemo } from "react";
import { useFilters, useTable } from "react-table";

import {
  Thead,
  TheadTr,
  TheadTh,
  TbodyWrapper,
  Tbody,
  TbodyTr,
  TbodyTd,
  TbodyContainer,
} from "./DataGrid.style";

import { TableWrapper } from "./TableWrapper/TableWrapper";

import {
  handleTheadColumnClassName,
  handleTbodyColumnClassName,
  handleColumns,
} from "src/helpers/helperHandlingDataGrid";
import { SearchResultEmpty } from "../SearchResultEmpty/SearchResultEmpty";
import { DataGridProps } from "src/shared/models/component/base.model";

export const DataGrid = ({
  dataObject,
  isHandleArrowClickable = true,
  searchInProgress,
  hideHeader = false,
  iconArrowVisible = true,
  searchText,
  limit = 20000,
  routeState,
  t,
  gridType = "request",
}: DataGridProps) => {
  const columns: any = useMemo(
    () => handleColumns(gridType, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const data: any = dataObject ? dataObject : [];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // setFilter is the key!!!
    // @ts-ignore
    setFilter,
  } = useTable(
    {
      columns: columns,
      data: data,
    },
    useFilters
  );

  // Listen for input changes outside
  useEffect(() => {
    // This will now use our custom filter for age
    if (gridType === "request") {
      if (searchText && searchText.length > 2) {
        setFilter("title", searchText);
      } else {
        setFilter("title", "");
      }
    }

    if (gridType === "activity") {
      if (searchText && searchText.length > 2) {
        setFilter("description", searchText);
      } else {
        setFilter("description", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <>
      <TableWrapper
        {...getTableProps()}
        isToggle={true}
        tabType={"transactions"}
      >
        <Thead className="invoices_table_head">
          {!hideHeader &&
            headerGroups.map((headerGroup: any) => {
              return (
                <TheadTr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, index: any) => {
                    //remove isOldInvoices because need to show all column
                    return (
                      <TheadTh
                        {...column.getHeaderProps()}
                        className={handleTheadColumnClassName(
                          index,
                          iconArrowVisible
                        )}
                      >
                        <span>
                          {column.render("Header", {
                            iconArrowVisible,
                          })}
                        </span>
                      </TheadTh>
                    );
                  })}
                </TheadTr>
              );
            })}
        </Thead>

        <TbodyWrapper {...getTableBodyProps()}>
          {rows?.length > 0 ? (
            rows.map((row: any, index: any) => {
              prepareRow(row);
              return (
                index < limit && (
                  <TbodyContainer {...row.getRowProps()}>
                    <Tbody className={`payout_item invoices_table_body`}>
                      <TbodyTr className="payout_item_tr invoices_table_tr">
                        {row.cells.map((cell: any, index: any) => {
                          return (
                            <TbodyTd
                              {...cell.getCellProps()}
                              className={handleTbodyColumnClassName(
                                index,
                                isHandleArrowClickable,
                                iconArrowVisible
                              )}
                            >
                              {cell.render("Cell", {
                                isHandleArrowClickable: true,
                                iconArrowVisible: iconArrowVisible,
                                routeState,
                              })}
                            </TbodyTd>
                          );
                        })}
                      </TbodyTr>
                    </Tbody>
                  </TbodyContainer>
                )
              );
            })
          ) : (
            <SearchResultEmpty
              content={
                searchInProgress
                  ? "Search result not found"
                  : "No results found"
              }
            />
          )}
        </TbodyWrapper>
      </TableWrapper>
    </>
  );
};
