import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import * as FullStory from "@fullstory/browser";
import { useFlags } from "launchdarkly-react-client-sdk";

import { AppRoutes } from "./routes/Routes";
import { store } from "./store/store";
import { securityOptions } from "./shared/config/storeConfig";
import { configs } from "./shared/config/config";

function App() {
  const { getAccessTokenSilently } = useAuth0();
  // set getAccessTokenSilently in global module to reuse it outside a React component
  securityOptions.setAccessTokenSilently(getAccessTokenSilently);

  /**
   * Enabled and Disabled Fullstory with the feature flags.
   */
  const { tpEnableFullstoryConfigs } = useFlags();
  useEffect(() => {
    if (tpEnableFullstoryConfigs) {
      FullStory.init({ orgId: configs.FULLSTORY_ORG_ID });
    }
  }, [tpEnableFullstoryConfigs]);

  return (
    <Provider store={store}>
      <div className="App">
        <AppRoutes />
      </div>
    </Provider>
  );
}

export default App;
