import React, { useState } from "react";
import classNames from "classnames";
import { Button, Typography } from "@mui/material";

import {
  CloseIcon,
  MessageBoxDangerIcon,
  NeturalIcon,
  DelinquentInfoIcon,
  MessageBoxGoIcon,
  MessageBoxWarningIcon,
} from "../../Media/Icon/Icons/Icons";
import { MessageWrapper } from "./Messages.style";

import { MessagesProps } from "src/shared/models/component/base.model";

export const Messages = ({
  messageHeading,
  messageContent,
  messageLinkText,
  messageLinkEvent,
  closeEvent,
  isDesktopView,
  hideCloseIcon = false,
  topMargin = 6,
  bottomMargin = 6,
  type = "error",
  isContentClickabale = false,
  onClickContent,
}: MessagesProps) => {
  const [visibility, setVisibility] = useState(true);

  const handleCloseEvent = () => [setVisibility(false)];

  const headerTextCommonStyles = { margin: 0, fontWeight: 600 };
  const headerTextStyle =
    type === "delinquent"
      ? { ...headerTextCommonStyles, fontSize: "12px", lineHeight: "14px" }
      : { ...headerTextCommonStyles, fontSize: "14px", lineHeight: "16px" };

  const messageTextCommonStyles = {
    marginTop: messageHeading ? 1 : 0,
    marginBottom: 0,
    fontWeight: 400,
  };

  const messageTextStyle =
    type === "delinquent"
      ? {
          ...messageTextCommonStyles,
          fontSize: "12px",
          lineHeight: "16px",
        }
      : {
          ...messageTextCommonStyles,
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "-0.24px",
        };

  return (
    <>
      {visibility && (
        <MessageWrapper
          className={`${isDesktopView ? "is_desktop" : "is_mobile"} ${type}`}
          sx={{ marginTop: topMargin, marginBottom: bottomMargin, paddingY: 4 }}
        >
          <div className="icon_wrapper">
            {type === "error" && <MessageBoxDangerIcon />}
            {type === "success" && <MessageBoxGoIcon />}
            {type === "warning" && <MessageBoxWarningIcon />}
            {type === "neutral" && <NeturalIcon />}
            {type === "default" && <NeturalIcon />}
            {type === "delinquent" && <DelinquentInfoIcon />}
            {type === "info" && <NeturalIcon fillColor="#635BFF" />}
          </div>
          {isContentClickabale ? (
            <div
              className={classNames([
                hideCloseIcon ? "message_content_no_button" : "message_content",
                "block-clickable",
              ])}
              onClick={onClickContent}
            >
              {messageHeading && (
                <Typography
                  variant="h2"
                  color={"gray.900"}
                  sx={headerTextStyle}
                >
                  {messageHeading}
                </Typography>
              )}
              {messageContent && (
                <Typography
                  variant="h2"
                  color={"gray.600"}
                  sx={messageTextStyle}
                >
                  {messageContent}
                </Typography>
              )}
            </div>
          ) : (
            <div
              className={
                hideCloseIcon ? "message_content_no_button" : "message_content"
              }
            >
              {messageHeading && (
                <Typography
                  variant="h2"
                  color={"gray.900"}
                  sx={headerTextStyle}
                >
                  {messageHeading}
                </Typography>
              )}
              {messageContent && (
                <Typography
                  variant="h2"
                  color={"gray.600"}
                  sx={messageTextStyle}
                >
                  {messageContent}
                </Typography>
              )}
            </div>
          )}
          {messageLinkText && (
            <div className="message_button">
              <Button onClick={messageLinkEvent} variant="buttonMessages">
                {messageLinkText}
              </Button>
            </div>
          )}
          {hideCloseIcon ? null : (
            <div
              onClick={closeEvent ? closeEvent : handleCloseEvent}
              className="icon_close"
            >
              <CloseIcon />
            </div>
          )}
        </MessageWrapper>
      )}
    </>
  );
};
