import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const ImageWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  width: 100%;
`
);

export const ImageContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  width: 100%;
`
);

export const BannerImage = styled("img")(
  ({ theme }) => `
  max-width: 100%;
`
);
