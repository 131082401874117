import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetPropertiesMutation } from "../../../store/services/publicApi";
import { useGetLeaseBalanceQuery } from "../../../store/services/privateApi";
import { ManualLoader, Wrapper } from "../../../components";
import { AppBar, Box, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { commonSpacing, theme } from "../../../shared/theme/theme";

import {
  RegisterReviewContainer,
  RegisterReviewSection,
} from "./Register.style";
import { PayCreditOrDebitCard } from "./PayCreditOrDebitCard";
import { PayACH } from "./PayACH";

export const LeasePayment = (props: any) => {
  const [searchParams] = useSearchParams();
  let leaseId = searchParams.get("leaseid") ?? searchParams.get("leaseId");
  const { data, isLoading: isLoadingLeaseBalance }: any =
    useGetLeaseBalanceQuery(leaseId!);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const [getPropertiesForIDMutation, { isLoading }] =
    useGetPropertiesMutation();
  const [propertyDetails, setPropertyDetails] = useState({
    name: "",
    city: "",
  });
  useEffect(() => {
    if (data?.propertyId) {
      getPropertiesForIDMutation(data?.propertyId).then((res) => {
        const { data } = res as { data: any };
        setPropertyDetails(data);
      });
    }
  }, [data]);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      {(isLoading || isLoadingLeaseBalance) && <ManualLoader />}
      <Wrapper>
        <AppBar style={{ alignItems: "center" }}>
          <Toolbar>
            <Typography variant="h4">
              {" "}
              {`${propertyDetails?.name}, ${propertyDetails?.city}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component={"div"}
          className={"container_content"}
          // ref={elementRef}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: 400 + commonSpacing.desktopPaddingB * 4,
            },
          }}
        >
          <Box height={"60px"}></Box>
          <RegisterReviewContainer>
            <RegisterReviewSection>
              <Typography fontWeight={"bold"}>Applicant Information</Typography>
              <Typography>
                <div style={{ display: "flex" }}>
                  <Box sx={{ width: 0.5 }}>
                    <p>Applicant Name :</p>
                  </Box>
                  <div>
                    <p>
                      {" "}
                      {data?.firstName} {data?.lastName}{" "}
                    </p>
                  </div>
                </div>
              </Typography>
            </RegisterReviewSection>
            {paymentSuccess && (
              <>
                <RegisterReviewSection>
                  <Typography fontWeight={"bold"}>Payment Success</Typography>
                  <p>
                    Your Payment was Successful! We will contact you shortly
                    with any updates on your application status
                  </p>
                </RegisterReviewSection>
              </>
            )}
            {!paymentSuccess && (
              <>
                <RegisterReviewSection>
                  <Typography fontWeight={"bold"}>Balance Due</Typography>
                  <Typography>
                    {data?.details?.map((t: any) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <Box sx={{ width: 0.5 }}>
                            <p>{t.description} :</p>
                          </Box>
                          <p> {t.amount.toFixed(2)} </p>
                        </div>
                      );
                    })}
                    <div style={{ display: "flex" }}>
                      <Box sx={{ width: 0.5 }}>
                        <p>Total :</p>
                      </Box>
                      <div>
                        <p> {data?.balance.toFixed(2)} </p>
                      </div>
                    </div>
                  </Typography>
                </RegisterReviewSection>
                <RegisterReviewSection>
                  <Typography fontWeight={"bold"}>Payment</Typography>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      aria-label="basic tabs example"
                      onChange={handleChange}
                    >
                      <Tab label="Card" {...a11yProps(0)} />
                      <Tab label="ACH" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  {value === 0 && (
                    <PayCreditOrDebitCard
                      onPaymentSuccess={() => {
                        setPaymentSuccess(true);
                      }}
                      amount={data?.balance}
                      leaseId={leaseId!}
                    />
                  )}
                  {value === 1 && (
                    <PayACH
                      onPaymentSuccess={() => {
                        setPaymentSuccess(true);
                        console.log("Payment Success");
                      }}
                      amount={data?.balance}
                      leaseId={leaseId!}
                    />
                  )}
                </RegisterReviewSection>
              </>
            )}
          </RegisterReviewContainer>
        </Box>
      </Wrapper>
    </>
  );
};
