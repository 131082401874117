import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "src/components/Media/Icon/Icon";
import { StatusChips } from "src/components/OtherUtilities/StatusChips/StatusChips";
import { theme } from "src/shared/theme/theme";

export const ThirdCell = (props: any) => {
  const navigate = useNavigate();

  const { isHandleArrowClickable, routeState } = props?.props;
  const getPropsOriginalData = props?.props?.row?.original;

  const handleCursorClass = isHandleArrowClickable ? "is_cursor" : "no_cursor";
  const { WorkOrderID, Number, Status } = getPropsOriginalData;

  return (
    <>
      <Box
        display={"flex"}
        sx={{
          [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
            justifyContent: "space-between",
            width: 175,
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            flexDirection: "column",
            justifyContent: "flex-end",
          },
        }}
        onClick={() =>
          navigate(`/requests/maintenance/detail/${WorkOrderID}`, {
            state: routeState,
          })
        }
      >
        <Box
          component={"span"}
          display={"flex"}
          className={`${handleCursorClass} amount_cell sub-cell-second fs-mask plusValue`}
          sx={{
            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              flexDirection: "column",
              justifyContent: "flex-end",
              textAlign: "right",
              marginBottom: 1,
            },
          }}
        >
          #{Number}
        </Box>
        <Box display={"flex"}>
          <StatusChips type={Status ? Status : "Submitted"} isResponsive />
        </Box>
      </Box>
      {props?.props?.iconArrowVisible && (
        <div
          onClick={() =>
            navigate(`/requests/maintenance/detail/${WorkOrderID}`, {
              state: routeState,
            })
          }
          className="sub-cell-third"
        >
          <Icon iconType="RightAlignedArrow" />
        </div>
      )}
    </>
  );
};
