import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ERROR_MESSAGES } from "../../../shared/utilities/validationMessages";
import {
  FormInput,
  FormInputPhoneNumber,
  FormSwitch,
  FormWrapper,
  ManualLoader,
  Messages,
  Wrapper,
} from "../../../components";
import { AppBar, Box, Button, Link, Toolbar, Typography } from "@mui/material";
import { commonSpacing, theme } from "../../../shared/theme/theme";
import {
  phoneOnChangeHandler,
  updateBindingsChange,
} from "../../../shared/utilities/formUtilities";
import {
  useCreateProspectUserMutation,
  useGetPropertiesMutation,
  useLazyGetLeasePersonQuery,
} from "../../../store/services/publicApi";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { RegisterReviewContainer } from "./Register.style";

export const RegisterProspectScreen = () => {
  const { loginWithRedirect } = useAuth0();
  const [btnSubmitted] = useState(false);
  const [isconfirmCriteria, setconfirmCriteria] = useState(false);
  const [isconfirmPrivacy, setconfirmPrivacy] = useState(false);
  const [isconfirmComms, setconfirmComms] = useState(false);
  const [
    getPropertiesForIDMutation,
    { isLoading: isLoadingGetPropertiesForID },
  ] = useGetPropertiesMutation();
  let propertyID = "";
  const [mutation, { isLoading: isLoadingCreateProspect }] =
    useCreateProspectUserMutation();
  const [errorMessage, setErrorMessage] = useState<string>("");
  /**
   * define the validation schema for form validation
   */
  const registerFormSchema = yup
    .object({
      phone: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(10, ERROR_MESSAGES.PHONE_NUMBER_INVALID),
      name: yup
        .string()
        .test(
          "has-first-and-last-name",
          "Name must include both first and last name",
          (value) => {
            if (!value) return false;
            const parts = value.trim().split(" ");
            const bool: boolean =
              parts.length >= 2 && parts[0].length > 0 && parts[1].length > 0;
            return bool;
          }
        )
        .required(ERROR_MESSAGES.REQUIRED),
      email: yup.string().email().required(ERROR_MESSAGES.REQUIRED),
      password: yup
        .string()
        .min(8, "Password must be at least 8 characters long")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .required(ERROR_MESSAGES.REQUIRED),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required(ERROR_MESSAGES.REQUIRED),
    })
    .required();
  const webAuthnSignIn = async () => {
    await loginWithRedirect({
      authorizationParams: {
        responseType: "code",
        redirectUri: `${window.location.origin}/lease/application${window.location.search}`,
        mode: "login",
        login_hint: getValues("email"),
      },
      appState: {
        returnTo: `/lease/application${window.location.search}`,
      },
    });
  };
  /**
   * initialize the hook form
   */
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<{
    phone: string;
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
  }>({
    resolver: yupResolver(registerFormSchema),
  });
  const [searchParams] = useSearchParams();
  propertyID =
    searchParams.get("propertyID") ??
    (searchParams.get("propertyId") as string);
  const [propertyDetails, setPropertyDetails] = useState({
    name: "",
    city: "",
  });
  const personId = searchParams.get("personId");
  const [
    triggerFetch,
    { data: leasePersonData, isLoading: isLeasePersonLoading },
  ] = useLazyGetLeasePersonQuery();

  const appSubmit = handleSubmit(async (data) => {
    let name = {};
    const names = data.name.split(" ");
    if (names.length === 2) {
      name = {
        FirstName: names[0],
        MiddleName: "",
        LastName: names[1],
      };
    } else if (names.length > 2) {
      name = {
        FirstName: names[0],
        MiddleName: names.slice(1, names.length - 1).join(" "),
        LastName: names[names.length - 1],
      };
    }
    const req = {
      propertyId: propertyID,
      password: data.password,
      prospect: {
        LeadManagement: {
          Prospects: {
            Prospect: {
              Customers: {
                Customer: {
                  Identification: personId
                    ? {
                        $IDType: "PersonID",
                        $IDValue: personId,
                      }
                    : undefined,
                  Name: name,
                  Email: data.email,
                  Phone: {
                    PhoneNumber: data.phone,
                    $PhoneType: "cell",
                  },
                  $Type: "prospect",
                },
              },
              Events: {
                Event: {
                  Agent: {
                    AgentID: {
                      $IDValue: "00000000-0000-0000-0000-000000000000",
                    },
                  },
                  FirstContact: "True",
                  $EventDate: moment().format("M/D/YYYY"),
                  $EventType: "prospect",
                },
              },
            },
          },
        },
      },
    };
    const res: any = await mutation(req);
    if (res?.error?.data) {
      setErrorMessage(
        res.error?.data?.err ||
          res.error?.data ||
          "There is an error. Please Try again."
      );
    } else {
      await webAuthnSignIn();
    }
  });
  useEffect(() => {
    if (propertyID) {
      getPropertiesForIDMutation(propertyID).then((res) => {
        const { data } = res as { data: any };
        setPropertyDetails(data);
      });
    }
    if (personId) {
      triggerFetch({ id: personId });
    }
    if (leasePersonData) {
      setValue(
        "name",
        `${leasePersonData.FirstName} ${leasePersonData.LastName}`
      );
      setValue("email", `${leasePersonData.Email}`);
    }
  }, [propertyID, leasePersonData, personId]);
  return (
    <>
      {(isLoadingGetPropertiesForID ||
        isLoadingCreateProspect ||
        isLeasePersonLoading) && <ManualLoader />}

      <Wrapper>
        <AppBar style={{ alignItems: "center" }}>
          <Toolbar>
            <Typography variant="h4">
              {" "}
              {`${propertyDetails?.name}, ${propertyDetails?.city}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <RegisterReviewContainer>
          <FormWrapper onSubmit={appSubmit} className="no_fullscreen">
            <Box
              component={"div"}
              className={"container_content"}
              sx={{
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: 400 + commonSpacing.desktopPaddingB * 4,
                },
              }}
            >
              <Box height={"60px"}></Box>
              <Box marginTop={2} marginBottom={2}>
                <Typography variant="h3" marginBottom={2}>
                  Create an account to register
                </Typography>
                <Typography fontSize={12} fontWeight={"bold"}>
                  Already Registered?{" "}
                  <Link onClick={webAuthnSignIn}>Click here to Login </Link>
                </Typography>
              </Box>
              <Box>
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange, value } }) => (
                    <FormInput
                      label="Your Name"
                      id={"name"}
                      isErrorMessageVisible={true}
                      {...register("name")}
                      error={errors?.name?.message}
                      onChange={(event: any) => {
                        updateBindingsChange(event, "name", setValue);
                      }}
                      value={value}
                      inputFocus={(e: any) => {
                        trigger("name");
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { onChange, value } }) => (
                    <FormInputPhoneNumber
                      label={"Phone"}
                      id={"phone"}
                      placeholder={"(123) 543-3454"}
                      {...register("phone")}
                      inputError={errors.phone?.message}
                      onChange={(e: any) => {
                        phoneOnChangeHandler(e, setValue, btnSubmitted);
                      }}
                      inputMode="numeric"
                      value={value}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <FormInput
                      label="Email"
                      id={"email"}
                      {...register("email")}
                      error={errors?.email?.message}
                      onChange={(event: any) => {
                        updateBindingsChange(event, "email", setValue);
                      }}
                      inputMode="email"
                      value={value}
                      inputFocus={(e: any) => {
                        trigger("email");
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="password"
                  render={({ field: { onChange, value } }) => (
                    <FormInput
                      label="Password"
                      id={"password"}
                      {...register("password")}
                      error={errors?.password?.message}
                      onChange={(event: any) => {
                        updateBindingsChange(event, "password", setValue);
                      }}
                      value={value}
                      inputFocus={(e: any) => {
                        trigger("password");
                      }}
                      type={"password"}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="confirmPassword"
                  render={({ field: { onChange, value } }) => (
                    <FormInput
                      label="Confirm Password"
                      id={"confirmPassword"}
                      {...register("confirmPassword")}
                      error={errors?.confirmPassword?.message}
                      onChange={(event: any) => {
                        updateBindingsChange(
                          event,
                          "confirmPassword",
                          setValue
                        );
                      }}
                      value={value}
                      inputFocus={(e: any) => {
                        trigger("confirmPassword");
                      }}
                      type={"password"}
                    />
                  )}
                />
                <FormSwitch
                  label="I consent to receive relevant email and/or text message at the email and/or telephone number  above. Standard data & messaging rates may apply."
                  id={"confirmComms"}
                  checked={isconfirmComms}
                  onChange={(e: any) => {
                    setconfirmComms(e.target.checked);
                  }}
                />
                <FormSwitch
                  label={`I have read and agree to the `}
                  labelLink={{
                    label: "Privacy Policy",
                    url: "https://tenantport.s3.amazonaws.com/documents/PrivacyPolicy-TenantPort.pdf",
                  }}
                  id={"confirmPrivacy"}
                  onChange={(e: any) => {
                    setconfirmPrivacy(e.target.checked);
                  }}
                  checked={isconfirmPrivacy}
                />
                <FormSwitch
                  label="I have read and agree to the "
                  labelLink={{
                    label: "Rental Criteria",
                    url: "https://tenantport.s3.amazonaws.com/documents/RP+Rental+Criteria+%26+Qualifications+(Effective+Jan+1%2C+2024)+(1).pdf",
                  }}
                  id={"confirmCriteria"}
                  onChange={(e: any) => {
                    setconfirmCriteria(e.target.checked);
                  }}
                  checked={isconfirmCriteria}
                />
              </Box>
              {errorMessage !== "" && (
                <Messages
                  messageHeading="Registration Error"
                  messageContent={errorMessage}
                  closeEvent={() => setErrorMessage("")}
                />
              )}
            </Box>
            <Button
              variant="containedLarge"
              color="primary"
              type="submit"
              fullWidth
              sx={{ overflow: "hidden" }}
              disabled={
                !(isconfirmPrivacy && isconfirmCriteria && isconfirmComms) ||
                isLoadingCreateProspect
              }
            >
              Create Account
            </Button>
          </FormWrapper>
        </RegisterReviewContainer>
      </Wrapper>
    </>
  );
};
