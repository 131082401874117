export const requestActionCardButtonItems = [
  {
    cardType: "MaintenanceRequest",
    btnTitleTranslationPath: "requests.requestActionCard.buttonOneText",
    isDisabled: false,
    navigationUrl: "/requests/maintenance/create",
    isActive: true,
  },
  {
    cardType: "GuestAccess",
    btnTitleTranslationPath: "requests.requestActionCard.buttonTwoText",
    isDisabled: true,
    navigationUrl: "/requests",
    isActive: true,
  },
  {
    cardType: "UpdateRoomMate",
    btnTitleTranslationPath: "requests.requestActionCard.buttonThreeText",
    isDisabled: true,
    navigationUrl: "/requests",
    isActive: true,
  },
  {
    cardType: "UpdatePets",
    btnTitleTranslationPath: "requests.requestActionCard.buttonFourText",
    isDisabled: true,
    navigationUrl: "/requests",
    isActive: true,
  },
];

export const handleRentActionCardButtonItems = (
  isCreditReportingReady: boolean,
  isCreditReportingDisabled: boolean
) => {
  const rentActionCardButtonItems = [
    {
      cardType: "MakeAPayment",
      btnTitleTranslationPath: "rent.rentActionCard.buttonOneText",
      isDisabled: false,
      navigationUrl: "/rent/payment",
      isActive: true,
    },
    {
      cardType: "LinkedAccounts",
      btnTitleTranslationPath: "rent.rentActionCard.buttonTwoText",
      isDisabled: false,
      navigationUrl: "/rent/linked-accounts",
      isActive: true,
    },
    {
      cardType: "PaymentHistory",
      btnTitleTranslationPath: "rent.rentActionCard.buttonThreeText",
      isDisabled: false,
      navigationUrl: "/rent/activity/list",
      isActive: true,
    },
    {
      cardType: "RentReporting",
      btnTitleTranslationPath: "rent.rentActionCard.buttonFourText",
      isDisabled: isCreditReportingDisabled || !isCreditReportingReady,
      navigationUrl: isCreditReportingReady
        ? "/rent/reporting/home"
        : "/rent/reporting/info",
      isActive: true,
    },
  ];

  return [rentActionCardButtonItems];
};

export const createBankAccountActionCardButtonItems = [
  {
    cardType: "BankLinking",
    btnTitleTranslationPath: "linkedBankAccount.createActionCard.buttonOneText",
    isDisabled: false,
    navigationUrl: "/rent/create/bank-account",
    isActive: true,
    paddingRight: 8,
    isFromPayment: false,
  },
  {
    cardType: "CreditOrDebitCard",
    btnTitleTranslationPath: "linkedBankAccount.createActionCard.buttonTwoText",
    isDisabled: false,
    navigationUrl: "/rent/create/card",
    isActive: false,
    paddingRight: 6,
    isFromPayment: false,
  },
];

export const createCardActionCardButtonItems = [
  {
    cardType: "BankLinking",
    btnTitleTranslationPath: "linkedBankAccount.createActionCard.buttonOneText",
    isDisabled: false,
    navigationUrl: "/rent/create/bank-account",
    isActive: false,
    paddingRight: 8,
    isFromPayment: false,
  },
  {
    cardType: "CreditOrDebitCard",
    btnTitleTranslationPath: "linkedBankAccount.createActionCard.buttonTwoText",
    isDisabled: false,
    navigationUrl: "/rent/create/card",
    isActive: true,
    paddingRight: 6,
    isFromPayment: false,
  },
];

export const autoPayMonthsOptions = [
  {
    name: "1st of month",
    value: 1,
  },
  {
    name: "2nd of month",
    value: 2,
  },
  {
    name: "3rd of month",
    value: 3,
  },
  // {
  //   name: "4th of month",
  //   value: 4,
  // },
];

export const creditScroreBgColor = [
  {
    color: "#CDDEE1",
    percentageValue: 10,
  },
  {
    color: "#AFCBCF",
    percentageValue: 18,
  },
  {
    color: "#92B7BE",
    percentageValue: 10,
  },
  {
    color: "#74A3AC",
    percentageValue: 13,
  },
  {
    color: "#56909A",
    percentageValue: 31,
  },
  {
    color: "#387C88",
    percentageValue: 18,
  },
];

export const creditScroreIntervals = [300, 450, 580, 850];
