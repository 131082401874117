import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ActionItemCard,
  DataGridCard,
  Header,
  HeaderFixed,
  Messages,
  GridEmptyCard,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";

import { requestActionCardButtonItems } from "src/shared/assets/data";

import { useGetWorkOrdersQuery } from "src/store/services/privateApi";

export const RequestsLandingScreenContainer = () => {
  const navigate = useNavigate(); // init navigate from useNavigate hook
  const { t } = useTranslation(); // init t from useTranslation hook

  // get the work orders (requests) list from api
  const { data, isSuccess, isLoading, isError, error }: any =
    useGetWorkOrdersQuery({
      isDateFilterAvailable: false,
    });

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide
          paddingX={0}
          headerText={t("requests.common.heading")}
          withCenteredHeaderText
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          <ActionItemCard
            navigate={navigate}
            t={t}
            actionItemsData={requestActionCardButtonItems}
            cardTitle={t("requests.requestActionCard.title")}
          />
          {
            /**
             * display DataGridCard component with requests data
             * If transaction data length is more than 0 &&  api data loading is done
             */
            isSuccess && data && data.length > 0 && !isLoading && (
              <DataGridCard
                t={t}
                dataObject={data}
                dataGridCardTitle={t("requests.common.heading")}
                isRecentWorkOrders
                isCardLinkAvailable
                navigate={navigate}
                isTooltipInfoEnabled
                tooltipContent={t("requests.common.tooltipContent")}
                gridType="request"
                iconArrowVisible={true}
                isHandleArrowClickable={true}
              />
            )
          }
          {
            /**
             * display GridEmptyCard component for empty requests data with empty state content
             * this component will display after api data loading is done
             */
            isSuccess && data && data.length === 0 && !isLoading && (
              <GridEmptyCard
                title={t("requests.common.heading")}
                emptyStateContent={t(
                  "requests.requestActionCard.emptyCardContent2"
                )}
                isTooltipInfoEnabled
                tooltipContent={t("requests.common.tooltipContent")}
                onClickBtn={() =>
                  navigate("/requests/maintenance/create", {
                    state: { isFromHome: false },
                  })
                }
                buttonText={t("requests.requestActionCard.emptyCardBtntext")}
              />
            )
          }

          {
            /**
             * display GridEmptyCard component without empty state content
             * this component will display while api data loading
             */
            isLoading && !isSuccess && (
              <GridEmptyCard
                title={t("requests.common.heading")}
                isTooltipInfoEnabled
                tooltipContent={t("requests.common.tooltipContent")}
                isEmptyCardDataVisble={false}
                isInnerLoaderEnabled
              />
            )
          }

          {isError && (
            <Messages
              topMargin={0}
              messageHeading={t("requests.common.heading")}
              messageContent={error.data.message ?? error?.data?.error}
              hideCloseIcon
            />
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
