import { styled } from "@mui/material/styles";

export const Box = styled("div")(
  ({ theme }) => `
    width: 100%;
    display: flex;
    justify-content: center;
  `
);

export const BoxContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  border-top 1px solid ${theme.palette.gray[100]};
  border-bottom 1px solid ${theme.palette.gray[100]};
  padding: 10px 24px;
  background-color: ${theme.palette.gray[100]};
  width: 100%;
  max-width: 100%;
  `
);
