import { styled } from "@mui/material/styles";
export const RegisterReviewContainer = styled("div")(
  ({ theme }) =>
    ` width: 100%;
    margin: 0 auto;
    padding: 10px;
    font-family: Arial, sans-serif;
    background-color: #f5f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1)`
);

export const RegisterReviewSection = styled("div")(
  ({ theme }) =>
    `
    margin-bottom: 10px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  `
);

export const RegisterReviewSectionTitle = styled("h3")(
  ({ theme }) =>
    `
    margin-top: 0;
  `
);

export const RegisterReviewSectionContent = styled("div")(
  ({ theme }) =>
    `
    
    margin-top: 2px;
  `
);
