import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const ErrorMessageWrapper = styled(Box)(
  ({ theme }) => `
   display: flex;
   width: 100%;
   margin-top: ${theme.spacing(1)};

   span,
   svg {
    color: ${theme.palette.error.main};
   }

   div {
    width: 12px;
    height: 12px;
    margin-right:  ${theme.spacing(1.5)};
    position: relative;
    top: 2px;
    display: inline-flex;

    svg {
      height: 10px;
      width: auto;
    }
   }

   .error_content {
    font-size: 12px;
   }
`
);
