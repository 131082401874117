import { TdContainer } from "../../DataGrid.style";
import { Typography } from "@mui/material";
import {
  dateGetMonth,
  datenormalFormating,
} from "src/shared/utilities/dateConvertions";

export const FirstCell = (props: any) => {
  const getPropsOriginalData = props?.props?.row?.original;
  const { statementDate } = getPropsOriginalData;

  return (
    <TdContainer>
      <Typography
        className={`first-link-text-transactions`}
        color={"gray.900"}
        component={"span"}
      >
        {`${dateGetMonth(statementDate)} Statement`}
      </Typography>
      <span className="date_val hidden-desktop">
        {datenormalFormating(statementDate)}
      </span>
    </TdContainer>
  );
};
