import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const WrapperContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
   height: calc(var(--vh, 1vh) * 100);
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    height: 100vh;
  }
`
);

export const FixedParentWrapper = styled(Box)(
  ({ theme }) => `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    background: linear-gradient(181.57deg, #E2E9FF -39.28%, #FFFFFF 49.33%);
    background-repeat: no-repeat;
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    background: linear-gradient(181.57deg, #E2E9FF -39.28%, #FFFFFF 49.33%);
    background-repeat: no-repeat;
  }
`
);

export const FixedParentWrapperContainer = styled(Box)(
  ({ theme }) => `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
  }
`
);

export const BackIcon = styled(Box)(
  ({ theme }) => `
   position: absolute;
   left: ${theme.spacing(4)};
   top: ${theme.spacing(8)};
   cursor: pointer;

    svg {
     fill: ${theme.palette.gray[600]};
     position: relative;
    }
`
);
