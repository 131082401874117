import { useTranslation } from "react-i18next";

import { Box, Button, Stack, Typography } from "@mui/material";

import { Modal } from "../Modal/Modal";
import { ConfirmIcon } from "src/components/Media/Icon/Icons/Icons";

import { CustomerAdditionConfirmModalIconWrapper } from "./ConfirmationModal.style";

export const ConfirmationModal = ({
  onCloseEvent,
  onFormSubmit,
  title,
  content,
  btnTitle,
  hideClose,
  cancelBtnTitle,
  onClickCancelEvent,
  isYesNoConfirmationModal,
}: any) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        onCloseEvent={onCloseEvent}
        hideClose={hideClose}
        isMobileBottomFixed={false}
      >
        {!isYesNoConfirmationModal && (
          <CustomerAdditionConfirmModalIconWrapper marginBottom={3}>
            <ConfirmIcon />
          </CustomerAdditionConfirmModalIconWrapper>
        )}
        <Box
          marginBottom={4}
          paddingX={5}
          paddingTop={isYesNoConfirmationModal ? 1 : 0}
        >
          <Stack spacing={3}>
            {title && (
              <Typography
                variant={"h3ExtraBold"}
                textAlign={"center"}
                color={"gray.900"}
              >
                {title}
              </Typography>
            )}
            {content && (
              <Typography
                variant={isYesNoConfirmationModal ? "h3" : "h2"}
                textAlign="center"
                color={"messageLink.main"}
                sx={{
                  lineHeight: 1.5,
                  fontWeight: 400,
                }}
              >
                {content}
              </Typography>
            )}
          </Stack>
        </Box>
        <Box marginBottom={2} justifyContent={"center"} display={"flex"}>
          {isYesNoConfirmationModal && (
            <Button
              onClick={onClickCancelEvent}
              variant={"outlined"}
              size="small"
              color="primary"
              type="button"
              sx={{
                overflow: "hidden",
                width: 90,
                height: 30,
                mr: 1,
                minWidth: 100,
              }}
            >
              {cancelBtnTitle ? cancelBtnTitle : t("buttonTexts.cancelButton")}
            </Button>
          )}
          <Button
            onClick={onFormSubmit}
            variant={isYesNoConfirmationModal ? "contained" : "containedLarge"}
            size="small"
            color="primary"
            type="button"
            sx={
              isYesNoConfirmationModal
                ? {
                    overflow: "hidden",
                    width: 90,
                    height: 30,
                    ml: 1,
                    minWidth: 100,
                  }
                : { overflow: "hidden", width: 130, height: 44 }
            }
          >
            {btnTitle ? btnTitle : t("buttonTexts.doneButton")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
