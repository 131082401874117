import { Box, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ConfirmationModal,
  Header,
  HeaderFixed,
  ManualLoader,
  Messages,
  ShadowCard,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";
import { ReviewCard } from "src/components/Cards/ReviewCard/ReviewCard";
import { EditFilledIcon } from "src/components/Media/Icon/Icons/Icons";
import { AccountInfoUpdateFormModal } from "src/components/Modals/AccountInfoUpdateFormModal/AccountInfoUpdateFormModal";
import { phoneNumberMask } from "src/shared/utilities/formUtilities";
import {
  useGetUserQuery,
  useResetPasswordEmailMutation,
} from "src/store/services/privateApi";

export const SettingsAccountInfoScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tpAccountUpdateProfileFeature } = useFlags();

  const { data, isSuccess, isLoading, error }: any = useGetUserQuery(null);
  const [resetPasswordEmailSendMutation, { isLoading: isLoadingRPE }] =
    useResetPasswordEmailMutation();

  const [errorMessage, setErrorMessage] = useState("");
  const [resendConfirmationModalVisible, setResendConfirmationModalVisible] =
    useState(false);
  const [updateAccountInfoModal, setUpdateAccountInfoModal] = useState(false);

  const handleBackButton = () => {
    navigate("/settings");
  };

  const handleResendEmail = () => {
    setResendConfirmationModalVisible(true);
  };

  const onClickResendConfirmationModalSubmit = async () => {
    const response: any = await resetPasswordEmailSendMutation(null);
    if (response) {
      setResendConfirmationModalVisible(false);
      if (response.error) {
        setErrorMessage(
          response?.error?.data?.error ?? response?.error?.data?.message
        );
      } else {
        setErrorMessage("");
      }
    }
  };

  useEffect(() => {
    if (!isSuccess) {
      setErrorMessage(error?.data ?? "Unable to fetch the user details");
    }
  }, [isSuccess, error]);

  return (
    <>
      {(isLoading || isLoadingRPE) && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide
          paddingX={0}
          isLogoVisible={false}
          headerText={t("settings.AccountInformation.title")}
          withCenteredHeaderText
          isBackButtonVisible={true}
          onBackButtonClick={handleBackButton}
        />
      </HeaderFixed>
      <Wrapper
        isAuthorizedWrapper
        idSelector={"wrapperContainer"}
        isSettingDesktop={false}
      >
        <WrpperAuthorizedContainer>
          {isSuccess && data && data?.user && (
            <ShadowCard
              display={"flex"}
              flexDirection={"column"}
              paddingLeft={4}
              paddingRight={4}
              paddingTop={5}
              paddingBottom={3}
              marginTop={2}
              marginBottom={4}
              className={"normal_shadow"}
              sx={{ width: "100%" }}
            >
              <ReviewCard
                title={t("captions.personalDetails")}
                isShadowWrappedIcon={true}
                name={`${data?.user?.person?.FirstName ?? ""} ${
                  data?.user?.person?.LastName ?? ""
                } `}
                isInnerTitle={true}
                editIconClickEvent={() => setUpdateAccountInfoModal(true)}
                hideEdit={!tpAccountUpdateProfileFeature}
              >
                {data?.user?.person?.MobilePhone && (
                  <Typography variant="h1" color={"gray.700"} marginBottom={3}>
                    {phoneNumberMask(data?.user?.person?.MobilePhone)}
                  </Typography>
                )}

                {data?.user?.email && (
                  <Typography variant="h1" color={"gray.700"} marginBottom={3}>
                    {data?.user?.email}
                  </Typography>
                )}
                <Typography
                  variant="h1"
                  color={"gray.700"}
                  marginBottom={3}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {"•••••••••••••••"}{" "}
                  <Box
                    component={"div"}
                    ml={1}
                    onClick={handleResendEmail}
                    display={"none"}
                    sx={{ cursor: "pointer" }}
                  >
                    <EditFilledIcon />
                  </Box>
                </Typography>
              </ReviewCard>
            </ShadowCard>
          )}
          {errorMessage !== "" && (
            <Messages
              messageHeading={t("settings.AccountInformation.title")}
              messageContent={errorMessage}
              closeEvent={() => setErrorMessage("")}
            />
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>

      {isSuccess && data && data?.user && (
        <>
          {updateAccountInfoModal && (
            <AccountInfoUpdateFormModal
              t={t}
              userData={data}
              setErrorMessage={setErrorMessage}
              isLoadingUserDataSuccess={isSuccess}
              closeIdModalEvent={() => setUpdateAccountInfoModal(false)}
            />
          )}

          {resendConfirmationModalVisible && (
            <ConfirmationModal
              onCloseEvent={() => setResendConfirmationModalVisible(false)}
              title={t("settings.ChangePasswordConfirmation.title")}
              content={t("settings.ChangePasswordConfirmation.content")}
              onFormSubmit={onClickResendConfirmationModalSubmit}
            />
          )}
        </>
      )}
    </>
  );
};
