import { Header, ImageBanner } from "src/components";
import { Box, Typography } from "@mui/material";
import { IDENTITY_IMAGES } from "src/shared/assets/images";
import { useTranslation } from "react-i18next";
import { SearchPageContainer } from "../Search/SearchPageContainer";
import { onDesktop } from "src/shared/theme/breakpoint";

export interface IIdentityStatusScreen {
  onBackButtonClick?: VoidFunction;
  onSubmitEvent?: VoidFunction;
  submitButtonText?: string;
}
export const IdentityFailed = ({
  onSubmitEvent,
  onBackButtonClick,
  submitButtonText,
}: IIdentityStatusScreen) => {
  const { t } = useTranslation();

  return (
    <SearchPageContainer
      submitButtonText={submitButtonText || t("buttonTexts.continueButton")}
      onSubmitEvent={onSubmitEvent}
    >
      <Header
        isBackButtonVisible={Boolean(onBackButtonClick)}
        onBackButtonClick={onBackButtonClick}
        paddingX={0}
      />
      <ImageBanner
        imgAlt="Identity Failed"
        imgPath={IDENTITY_IMAGES.FAILED}
        imgWidth="352px"
      />
      <Box textAlign="center" sx={{ mb: 4, [onDesktop]: { mb: 12 } }}>
        <Typography variant="h6ExtraBold" component="div" mb={3}>
          {t("headingContent.statusses.identityFailed.heading")}
        </Typography>
        <Typography variant="body2">
          {t("headingContent.statusses.identityFailed.content")}
        </Typography>
      </Box>
    </SearchPageContainer>
  );
};
