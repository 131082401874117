import {
  Header,
  HeaderFixed,
  LanguageSwitcher,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const SettingLanguagesScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBackButton = () => {
    navigate("/settings");
  };

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide
          onBackButtonClick={handleBackButton}
          isBackButtonVisible
          paddingX={0}
          headerText={t("settings.Languages.title")}
          withCenteredHeaderText
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          <LanguageSwitcher isDropDownType={false} />
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
