import { styled } from "@mui/material/styles";
export const LogoWrapper = styled("div")(
  ({ theme }) => `
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
    
  @media(max-width: ${theme.breakpoints.values.sm}px){
    &.logo-for-desktop {
      display: none !important;
    }
  }
  `
);
