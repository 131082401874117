import { Box } from "@mui/material";
import { childrenInterface } from "src/shared/models/component/base.model";
import { ErrorCardWrapper } from "./ErrorCard.style";

/**
 * Primary UI component for user interaction
 */
export const ErrorCard = ({ children }: childrenInterface) => {
  return (
    <ErrorCardWrapper className={`card-wrapper-home-main`}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: 3,
          cursor: "pointer",
        }}
      >
        {children}
      </Box>
    </ErrorCardWrapper>
  );
};
