import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const NotificationMessageWrapper = styled(Box)(
  ({ theme }) => `
    flex-direction: row;
    display: flex;
    flex: 1;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 6px 16px rgba(0, 0, 0, 0.1);
    background-color: ${theme.palette.yellow[50]};
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
    padding-top: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(1)};
    
    &.is_desktop {
     @media(min-width: ${theme.breakpoints.values.sm}px){
      padding-left: ${theme.spacing(5)};
      padding-right: ${theme.spacing(8)};
     }

     @media(max-width: ${theme.breakpoints.values.sm}px){
      padding-left: ${theme.spacing(1)};
      padding-right: ${theme.spacing(4)};
      padding-top: ${theme.spacing(4)};
      padding-bottom: ${theme.spacing(4)};
     }
    }

    &.is_mobile {
     padding-left: ${theme.spacing(1)};
     padding-right: ${theme.spacing(4)};
     padding-top: ${theme.spacing(4)};
     padding-bottom: ${theme.spacing(4)};
    }
`
);

export const CardArrow = styled(Box)(
  ({ theme }) => `
  align-items: center;
  justify-content: center;
  color: ${theme.palette.gray[300]} !important;

  svg {
    width: 18px;
  }
`
);

export const IconWrapper = styled(Box)(
  ({ theme }) => `
   align-items: center;
   justify-content: center;
   display: flex;
   padding-top: 3.5px;
`
);

export const MessageContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: ${theme.spacing(4)};
`
);
