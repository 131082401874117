import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { ErrorCard, Header, Wrapper } from "src/components";

export const Error404ScreenContainer = () => {
  const navigate = useNavigate();

  const onClickBackButton = async () => {
    navigate("/");
  };

  return (
    <Wrapper>
      <Header isBackButtonVisible={false} />
      <Box paddingTop={6} paddingX={10} sx={{ textAlign: "center" }}>
        <ErrorCard>
          <Typography
            variant="h9Bold"
            component={"p"}
            color={"primary"}
            textAlign={"center"}
            marginBottom={3}
          >
            {"404"}
          </Typography>
          <Typography
            variant="h3"
            component={"p"}
            color={"gray.600"}
            textAlign={"center"}
            marginBottom={6}
          >
            {"We cant seem to find the page you are looking for."}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            type="button"
            sx={{ width: "100px", marginX: "auto" }}
            onClick={onClickBackButton}
          >
            {"Back"}
          </Button>
        </ErrorCard>
      </Box>
    </Wrapper>
  );
};
