import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { RJSFSchema } from "@rjsf/utils";
import { commonSpacing, theme } from "../../../shared/theme/theme";
import React, { useEffect, useState } from "react";
import { ManualLoader, Messages, Wrapper } from "../../../components";

import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetPropertiesMutation,
  useGetUnitQuery,
} from "../../../store/services/publicApi";
import { DropdownData } from "../../../components/FormElements/AddressFields/DropdownState/DropdownState.data";
import {
  useGetUserQuery,
  usePostLeaseApplicationMutation,
} from "../../../store/services/privateApi";
import { TermsAndConditionsWidget } from "./TermsAndConditions";
import { ReviewInformation } from "./ReviewInformation";
import { ConfirmUnitInformation } from "./ConfirmUnitInformation";
import { FormInputWrapper } from "../../../components/FormElements/FormInput/FormInput.style";
import { RegisterReviewContainer } from "./Register.style";
import CustomFileWidget from "./CustomFileWidget";
import moment from "moment";

function useStickyState(defaultValue: any, key: any) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}
export const LeaseApplication = () => {
  const navigate = useNavigate();
  const [beds, setBeds] = useState(1);
  const [pets, setPets] = useState(2);
  const globalSchema: RJSFSchema = {
    definitions: {
      phone: {
        type: "string",
        title: "Phone",
        format: "string",
        minLength: 10,
        maxLength: 10,
        pattern: "^[1-9]\\d{1,10}$",
      },
      state: {
        type: "string",
        title: "State",
        enum: DropdownData.map((d) => d.abbreviation),
      },
      address: {
        type: "object",
        title: "",
        properties: {
          country: { type: "string", title: "Country", default: "USA" },
          street: { type: "string", title: "Street Address" },
          city: { type: "string", title: "City" },
          state: {
            type: "string",
            title: "State",
            enum: DropdownData.map((d) => d.abbreviation),
          },
          zip: { type: "string", title: "Zip" },
        },
      },
      requiredAddress: {
        type: "object",
        title: "",
        properties: {
          country: { type: "string", title: "Country", default: "USA" },
          street: { type: "string", title: "Street Address" },
          city: { type: "string", title: "City" },
          state: {
            type: "string",
            title: "State",
            enum: DropdownData.map((d) => d.abbreviation),
          },
          zip: { type: "string", title: "Zip" },
        },
        required: ["street", "city", "state", "zip"],
      },
    },
  };
  const schema0: RJSFSchema = {
    type: "object",
    title: "Lease Application",
    properties: {
      confirmUnitInformation: { type: "string", title: `` },
      agreeToProceed: {
        type: "boolean",
        title: "I agree to proceed with the application for the unit above",
        format: "checkbox",
        default: false,
      },
    },
    required: ["agreeToProceed"],
  };
  const schema1: RJSFSchema = {
    type: "object",

    definitions: {
      identification: {
        type: "object",
        properties: {
          notApplicable: {
            type: "boolean",
            title: "Not Applicable",
            format: "checkbox",
            default: false,
          },
        },
        dependencies: {
          notApplicable: {
            oneOf: [
              {
                properties: {
                  notApplicable: {
                    enum: [true],
                  },
                  usCitizen: {
                    type: "boolean",
                    title: "US Citizen",
                    format: "checkbox",
                    default: false,
                  },
                },
              },
              {
                properties: {
                  notApplicable: {
                    enum: [false],
                  },
                  number: { type: "string", title: "Identification" },
                  type: {
                    type: "string",
                    title: "Type",
                    oneOf: [
                      { title: "SSN", enum: ["SocialSecurity"] },
                      { title: "ITIN", enum: ["ITIN"] },
                      { title: "EIN", enum: ["EIN"] },
                      { title: "Drivers License", enum: ["DriversLicense"] },
                    ],
                  },
                  usCitizen: {
                    type: "boolean",
                    title: "US Citizen",
                    format: "checkbox",
                    default: false,
                  },
                },
                required: ["number", "type"],
              },
            ],
          },
        },
      },
      dvLicense: {
        type: "object",
        properties: {
          notApplicable: {
            type: "boolean",
            title: "Not Applicable",
            format: "",
            default: false,
          },
        },
        dependencies: {
          notApplicable: {
            oneOf: [
              {
                properties: {
                  notApplicable: {
                    enum: [true],
                  },
                },
              },
              {
                properties: {
                  notApplicable: {
                    enum: [false],
                  },
                  number: { type: "string", title: "Identification" },
                  state: globalSchema.definitions!.state,
                  dvFileFront: {
                    type: "string",
                    format: "data-url",
                    title: "Upload Front Picture",
                  },
                  dvFileBack: {
                    type: "string",
                    format: "data-url",
                    title: "Upload Back Picture",
                  },
                },
                required: ["number", "state", "dvFileFront", "dvFileBack"],
              },
            ],
          },
        },
      },
      applicantInfo: {
        title: "Applicant Information",
        properties: {
          name: { type: "string", title: "Name" },
          birthdate: { type: "string", title: "Birthdate", format: "date" },
          phone: globalSchema.definitions!.phone,
          email: { type: "string", title: "Email", format: "email" },
          identification: {
            $ref: "#/definitions/identification",
            title: "Identification",
          },
          dvLicense: {
            $ref: "#/definitions/dvLicense",
            title: "Driver License",
          },
        },
        required: ["name", "birthdate", "phone", "email"],
      },
    },
    properties: {
      applicantInfo: {
        $ref: "#/definitions/applicantInfo",
      },
    },
  };
  const schema3: RJSFSchema = {
    definitions: {
      currentAddress: {
        type: "object",
        title: "Current Address",
        properties: {
          address: globalSchema.definitions!.requiredAddress,
          moveInDate: { type: "string", title: "Move In Date", format: "date" },
          reasonForLeaving: { type: "string", title: "Reason for Leaving" },
          rented: {
            type: "boolean",
            title: "Are you Renting?",
            format: "checkbox",
            default: false,
          },
        },
        dependencies: {
          rented: {
            oneOf: [
              {
                properties: {
                  rented: {
                    enum: [false],
                  },
                },
              },
              {
                properties: {
                  rented: {
                    enum: [true],
                  },
                  landlord: {
                    type: "string",
                    title: "Community or Landlord Name",
                  },
                  landlordContact: { type: "string", title: "Contact Name" },
                  landlordPhone: globalSchema.definitions!.phone,
                },
                required: ["landlord", "landlordContact", "landlordPhone"],
              },
            ],
          },
        },
        required: ["moveInDate"],
      },
    },
    type: "object",
    properties: {
      currentAddress: {
        $ref: "#/definitions/currentAddress",
        title: "Current Address",
      },
    },
  };
  const schema4: RJSFSchema = {
    definitions: {
      otherIncome: {
        type: "object",
        title: "",
        properties: {
          incomeType: {
            type: "string",
            title: "Type",
            enum: [
              "Adoption Assistance Payments",
              "Alimony",
              "Alimony/Palimony",
              "Annuity",
              "Business",
              "Child Support",
              "Employment",
              "Federal Wage",
              "Federal Wages",
              "General Assistance/Rental Assistance",
              "General Assistance/Welfare",
              "Indian Trust",
              "Military Pay",
              "Non-Federal Wage",
              "Other",
              "Other Income from Assets",
              "Other Non Wage",
              "Other Non-Wage source",
              "Pension",
              "Pensions",
              "Regular Gifts",
              "Retirement & Other Like Accounts",
              "Safe Harbor Income Source",
              "Section 8 Voucher",
              "Social Security",
              "SSI",
              "TANF",
              "TANF/Welfare",
              "Unemployment",
            ],
          },
          contactName: { type: "string", title: "Contact Name" },
          monthlyIncome: {
            type: "number",
            title: "Monthly Income",
            format: "number",
          },
          effectiveDate: {
            type: "string",
            title: "Effective Date",
            format: "date",
          },
          address: globalSchema.definitions!.address,
        },
        required: ["incomeType", "monthlyIncome", "effectiveDate"],
      },
      employment: {
        type: "object",
        title: "Employment",
        properties: {
          notApplicable: {
            type: "boolean",
            title: "Not Applicable",
            format: "checkbox",
            default: false,
          },
        },
        dependencies: {
          notApplicable: {
            oneOf: [
              {
                properties: {
                  notApplicable: {
                    enum: [true],
                  },
                },
              },
              {
                properties: {
                  notApplicable: {
                    enum: [false],
                  },
                  industry: {
                    type: "string",
                    title: "Industry",
                    enum: [
                      "Automotive",
                      "Construction",
                      "Education",
                      "Finance & Insurance",
                      "Government",
                      "HealthCare",
                      "Manufacturing",
                      "Other",
                      "Public Admin",
                      "Real Estate",
                      "Retail",
                      "Service",
                      "Technology",
                      "Unknown",
                    ],
                  },
                  employer: { type: "string", title: "Employer" },
                  title: { type: "string", title: "Title" },
                  dateFrom: { type: "string", title: "Start", format: "date" },
                  dateTo: { type: "string", title: "End", format: "date" },
                  monthlyIncome: { type: "number", title: "Monthly Income" },
                  superVisorName: { type: "string", title: "Supervisor Name" },
                  superVisorPhone: globalSchema.definitions!.phone,
                  email: {
                    type: "string",
                    title: "Email Address",
                    format: "email",
                  },
                  address: globalSchema.definitions!.address,
                },
                required: [
                  "employer",
                  "dateFrom",
                  "superVisorName",
                  "monthlyIncome",
                ],
              },
            ],
          },
        },
      },
    },
    type: "object",
    properties: {
      currentEmployment: {
        $ref: "#/definitions/employment",
        title: "Current Employment",
      },
      otherIncome: {
        items: {
          $ref: "#/definitions/otherIncome",
        },
        title: "Other Income",
        type: "array",
      },
    },
  };
  const schema5: RJSFSchema = {
    definitions: {
      emergencyContact: {
        type: "object",
        title: "Emergency Contact",
        properties: {
          name: { type: "string", title: "Name" },
          relationship: { type: "string", title: "Relationship" },
          phone: globalSchema.definitions!.phone,
          email: { type: "string", title: "Email", format: "email" },
          address: globalSchema.definitions!.address,
        },
        required: ["name", "email", "relationship"],
      },
    },
    type: "object",
    properties: {
      emergencyContact: {
        $ref: "#/definitions/emergencyContact",
      },
    },
  };
  const schema6: RJSFSchema = {
    definitions: {
      pSchema: {
        type: "object",
        title: "Pet",
        properties: {
          name: { type: "string", title: "Name" },
          type: {
            type: "string",
            title: "Type",
            enum: ["Dog", "Cat", "Bird", "Fish", "Other"],
          },
          breed: { type: "string", title: "Breed" },
          color: { type: "string", title: "Color" },
          age: { type: "number", title: "Age (years)" },
          weight: { type: "number", title: "Weight (lbs.)" },
          registration: {
            type: "object",
            title: "Registration",
            properties: {
              type: { type: "string", title: "Type" },
              issuer: { type: "string", title: "Issuer" },
              number: { type: "string", title: "Number" },
            },
          },
        },
        required: ["name", "type", "breed", "color", "age", "weight"],
      },
      pet: {
        type: "object",
        title: "Pets",
        properties: {
          noPets: {
            type: "boolean",
            title: "I dont have any pets",
            format: "checkbox",
            default: false,
          },
        },
        dependencies: {
          noPets: {
            oneOf: [
              {
                properties: {
                  noPets: {
                    enum: [true],
                  },
                },
              },
              {
                properties: {
                  noPets: {
                    enum: [false],
                  },
                  pet: {
                    title: `Add Pets( Max 2 Per Unit)`,
                    type: "array",
                    minItems: pets === 0 ? 0 : 1,
                    maxItems: pets,
                    items: {
                      $ref: "#/definitions/pSchema",
                    },
                  },
                },
              },
            ],
          },
        },
      },
    },
    type: "object",
    properties: {
      pet: {
        $ref: "#/definitions/pet",
      },
    },
  };
  const schema7: RJSFSchema = {
    definitions: {
      vehicle: {
        type: "object",
        title: "Vehicle",
        properties: {
          year: { type: "number", title: "Year", minimum: 1900, maximum: 2030 },
          make: { type: "string", title: "Make" },
          model: { type: "string", title: "Model" },
          color: { type: "string", title: "Color" },
          licensePlateNumber: { type: "string", title: "License plate number" },
          licensePlateState: globalSchema.definitions!.state,
        },
        required: [
          "year",
          "make",
          "model",
          "color",
          "licensePlateNumber",
          "licensePlateState",
        ],
      },
    },
    type: "object",
    properties: {
      vehicle: {
        type: "object",
        properties: {
          noVehicles: {
            type: "boolean",
            title: "I dont have any Cars/Trucks",
            format: "checkbox",
            default: false,
          },
        },
        dependencies: {
          noVehicles: {
            oneOf: [
              {
                properties: {
                  noVehicles: {
                    enum: [true],
                  },
                },
              },
              {
                properties: {
                  noVehicles: {
                    enum: [false],
                  },
                  vehicle: {
                    title: "Add Vehicle",
                    type: "array",
                    minItems: 1,
                    items: {
                      $ref: "#/definitions/vehicle",
                    },
                  },
                },
              },
            ],
          },
        },
      },
    },
  };
  const schema11: RJSFSchema = {
    definitions: {
      otherApplicants: {
        type: "object",
        title: "",
        properties: {
          householdStatus: {
            type: "string",
            title: "Relationship",
            enum: ["Spouse", "Dependent", "Roommate"],
          },
          name: { type: "string", title: "Name" },
        },
        dependencies: {
          householdStatus: {
            oneOf: [
              {
                properties: {
                  householdStatus: {
                    enum: ["Spouse", "Roommate"],
                  },
                  email: { type: "string", title: "Email", format: "email" },
                },
                required: ["email"],
              },
              {
                properties: {
                  householdStatus: {
                    enum: ["Dependent"],
                  },
                  birthDate: {
                    type: "string",
                    title: "Birth Date",
                    format: "date",
                  },
                },
                required: ["birthDate"],
              },
            ],
          },
        },
        required: ["householdStatus", "name"],
      },
    },
    type: "object",
    title: "HouseHold Members",
    properties: {
      otherApplicants: {
        type: "object",
        title: "",
        properties: {
          noOthers: {
            type: "boolean",
            title: "I dont have any household Members",
            format: "checkbox",
            default: false,
          },
        },
        dependencies: {
          noOthers: {
            oneOf: [
              {
                properties: {
                  noOthers: {
                    enum: [true],
                  },
                },
              },
              {
                properties: {
                  noOthers: {
                    enum: [false],
                  },
                  otherApplicants: {
                    title: `Add Members(Max ${2 * beds})`,
                    type: "array",
                    minItems: 1,
                    maxItems: 2 * beds,
                    items: {
                      $ref: "#/definitions/otherApplicants",
                    },
                  },
                },
              },
            ],
          },
        },
      },
    },
  };
  const schema8: RJSFSchema = {
    definitions: {
      referral: {
        type: "object",
        title: "Referral",
        properties: {
          source: {
            type: "string",
            title: "How did you hear about us?",
            enum: [
              "Community Website",
              "Apartment List",
              "ApartmentRatings.com",
              "Apartments.com",
              "Digible",
              "Preferred Employer Program",
              "RentPath - Rentals.com",
              "SightMap",
            ],
          },
          agent: { type: "string", title: "Referring Agent" },
          friend: { type: "string", title: "Referring friend or person" },
          agency: { type: "string", title: "Referring agency" },
          comments: { type: "string", title: "Other Comments" },
        },
        required: ["source"],
      },
      misc: {
        type: "object",
        title: "Miscellaneous ",
        properties: {
          referral: {
            $ref: "#/definitions/referral",
          },
          movingReason: {
            type: "string",
            title: "Reason for Moving",
            enum: ["COVID", "Employment", "Family", "Other"],
          },
        },
      },
    },
    type: "object",
    properties: {
      misc: {
        $ref: "#/definitions/misc",
      },
    },
  };
  const schema9: RJSFSchema = {
    type: "object",
    title: "Terms and Conditions",
    properties: {
      termsAndConditionsContent: { type: "string", title: `` },
      agreetoTerms: {
        type: "boolean",
        title: "I agree to the terms and conditions",
        format: "checkbox",
        default: false,
      },
    },
    required: ["agreetoTerms"],
  };
  const schema10: RJSFSchema = {
    type: "object",
    title: "Review Information",
    properties: {
      reviewContent: { type: "string", title: `` },
      agreeToReview: {
        type: "boolean",
        title: "I confirm the information provided is correct to my knowledge",
        format: "checkbox",
        default: false,
      },
    },
    required: ["agreeToReview"],
  };

  const [searchParams] = useSearchParams();
  let propertyID =
    searchParams.get("propertyID") ??
    (searchParams.get("propertyId") as string);
  let unitID = searchParams.get("unitID");
  let moveInDate = searchParams.get("moveInDate");
  let term = searchParams.get("leaseTerm");
  let pricing = searchParams.get("pricing");
  let unitNumber = searchParams.get("unitNumber");
  let leaseId = searchParams.get("leaseId");
  let personId = searchParams.get("personId");

  const schemas = [
    schema0,
    schema1,
    schema3,
    schema4,
    schema5,
    schema6,
    schema7,
  ];
  if (!leaseId) {
    schemas.push(schema11);
  }
  schemas.push(schema8, schema9, schema10);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [postLeaseApplication, { isLoading: isLoadingPostLease }] =
    usePostLeaseApplicationMutation();

  const handleSubmit = async (prevData: any) => {
    setFormData({ ...prevData, ...formData });
    const body = {
      propertyId: propertyID,
      unitId: unitData?.id,
      leaseStart: unitData?.moveInDate,
      term: term,
      application: formData,
      leaseId: leaseId,
      personId: personId,
    };
    const res: any = await postLeaseApplication(body);
    if (res?.error) {
      setErrorMessage(
        res?.error?.data?.message ?? "Unable to submit lease Application."
      );
    } else {
      navigate(`/lease/payment?leaseid=${res.data?.leaseId}`);
    }
  };

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useStickyState({}, "formData");

  const [
    getPropertiesForIDMutation,
    {
      isError: isGetPropertiesError,
      isLoading: isLoadingGetPropertiesForID,
      error: getPropertiesError,
    },
  ] = useGetPropertiesMutation();
  const customValidate = (formData: any, errors: any) => {
    if (formData.phone?.toString()?.length !== 10) {
      errors.phone?.addError("Phone should be 10 digits");
    }
    if (formData.agreeToProceed !== true) {
      errors.agreeToProceed?.addError("You must agree to Proceed");
    }
    if (formData.agreetoTerms !== true) {
      errors.agreetoTerms?.addError(
        "You must agree to the terms and conditions"
      );
    }
    if (formData.otherApplicants?.otherApplicants?.length > 0) {
      formData.otherApplicants.otherApplicants.forEach(
        (applicant: any, index: number) => {
          if (
            applicant.birthDate &&
            applicant.householdStatus === "Dependent" &&
            moment().diff(moment(applicant.birthDate, "YYYY-MM-DD"), "years") >
              18
          ) {
            console.log(
              applicant.birthDate +
                " " +
                moment().diff(
                  moment(applicant.birthDate, "YYYY-MM-DD"),
                  "years"
                )
            );
            errors.otherApplicants.otherApplicants[index].birthDate?.addError(
              "Dependents should be less than 18 years old"
            );
          }
        }
      );
    }
    return errors;
  };
  // @ts-ignore
  const handleNext = (nextData) => {
    setFormData({ ...nextData, ...formData });
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const {
    data: unitData,
    isError: isGetUnitError,
    isLoading: isLoadingGetUnit,
    error: getUnitError,
  }: any = useGetUnitQuery({
    unitID: unitID ?? unitNumber,
    moveInDate,
    term,
    propertyID,
    leaseId,
  });
  const { data: userData, isLoading: isLoadingGetUser }: any =
    useGetUserQuery(null);

  const [propertyDetails, setPropertyDetails] = useState({
    name: "",
    city: "",
  });
  let [houseHoldMembers, setHouseHoldMembers] = useState([]);
  useEffect(() => {
    if (propertyID) {
      getPropertiesForIDMutation(propertyID).then((res) => {
        const { data } = res as { data: any };
        setPropertyDetails(data);
      });
    }
    if (userData?.user && formData?.applicantInfo?.email === undefined) {
      const { phone, email, person } = userData?.user;
      setFormData({
        applicantInfo: {
          email: email,
          phone: phone,
          name: `${person.FirstName} ${person.LastName}`,
        },
      });
    }

    if (unitData?.property) {
      setPropertyDetails(unitData.property);
    }
    if (unitData?.bed) {
      setBeds(parseInt(unitData.bed));
    }
    if (unitData?.numPetsAllowed) {
      setPets(parseInt(unitData.numPetsAllowed));
    }
    setHouseHoldMembers(
      unitData?.people
        ? unitData?.people?.filter((p: any) => p.person.id !== personId)
        : []
    );
  }, [propertyID, userData, unitData]);
  function transformErrors(errors: any[]) {
    return errors.map((error) => {
      console.log(JSON.stringify(error));
      if (error.message === "must match exactly one schema in oneOf") {
        error.message = "";
      }
      if (error.message === "must be equal to one of the allowed values") {
        error.message = "";
      }
      // Add more custom error messages as needed
      return error;
    });
  }
  return (
    <>
      {(isLoadingGetPropertiesForID ||
        isLoadingGetUnit ||
        isLoadingGetUser ||
        isLoadingPostLease) && <ManualLoader />}
      <Wrapper>
        <AppBar style={{ alignItems: "center" }}>
          <Toolbar>
            <Typography variant="h4">
              {" "}
              {`${propertyDetails?.name}, ${propertyDetails?.city}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component={"div"}
          className={"container_content"}
          // ref={elementRef}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: 400 + commonSpacing.desktopPaddingB * 4,
            },
          }}
        >
          <Box height={"60px"}></Box>
          <RegisterReviewContainer>
            <Box>
              {isGetUnitError || isGetPropertiesError ? (
                <div>
                  There was an error {getUnitError?.data?.message}{" "}
                  {(getPropertiesError as any)?.data?.message}
                </div>
              ) : (
                <div style={{ position: "relative" }}>
                  <FormInputWrapper>
                    <Form
                      showErrorList={false}
                      schema={schemas[step]}
                      onSubmit={(data) => {
                        console.log(
                          `step is ${step} and schema length is ${
                            schemas.length
                          } data is ${JSON.stringify(data.formData)}`
                        );
                        step === schemas.length - 1
                          ? handleSubmit(data.formData)
                          : handleNext(data.formData);
                      }}
                      formData={formData}
                      onChange={(e) => setFormData(e.formData)}
                      validator={validator}
                      customValidate={customValidate}
                      noHtml5Validate={true}
                      transformErrors={transformErrors}
                      focusOnFirstError={true}
                      widgets={{
                        FileWidget: CustomFileWidget,
                      }}
                      uiSchema={{
                        termsAndConditionsContent: {
                          "ui:widget": TermsAndConditionsWidget,
                        },
                        reviewContent: {
                          "ui:widget": ReviewInformation,
                          "ui:options": {
                            schemas: schemas,
                            formData: formData,
                            adminFee: unitData?.adminFee,
                            applicationFee: unitData?.applicationFee,
                            leaseId: leaseId,
                            houseHoldMembers: houseHoldMembers,
                          },
                        },
                        confirmUnitInformation: {
                          "ui:widget": ConfirmUnitInformation,
                          "ui:options": {
                            data: unitData,
                            query: {
                              pricing: pricing,
                              moveInDate: moveInDate,
                              leaseId: leaseId,
                            },
                          },
                        },
                      }}
                    >
                      {errorMessage !== "" && (
                        <Messages
                          messageHeading={"Lease Application"}
                          messageContent={errorMessage}
                          closeEvent={() => setErrorMessage("")}
                          bottomMargin={4}
                          topMargin={4}
                        />
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "2px",
                        }}
                      >
                        {step > 0 && (
                          <Button
                            variant="contained"
                            style={{ display: "flex" }}
                            color="primary"
                            onClick={handlePrev}
                          >
                            Previous
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          style={{ display: "flex" }}
                          color="primary"
                          type={"submit"}
                        >
                          {step === schemas.length - 1 ? "Submit" : "Next"}
                        </Button>
                      </div>
                    </Form>
                  </FormInputWrapper>
                </div>
              )}
            </Box>
          </RegisterReviewContainer>
        </Box>
      </Wrapper>
    </>
  );
};
