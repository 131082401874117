import { useTranslation } from "react-i18next";

import { Logo } from "../../Media/Logo/Logo";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button, Typography } from "@mui/material";
import { Stack, SxProps, Theme } from "@mui/system";

import { HeaderProps } from "src/shared/models/component/base.model";
import { BackIcon, HeaderTextBlock, HeaderBottomWrapper } from "./Header.style";

import { theme } from "src/shared/theme/theme";
import { LanguageSwitcher } from "src/components/OtherUtilities/LanguageSwitcher/LanguageSwitcher";
import { MainMenu } from "../../Navigations/MainMenu/MainMenu";

import { EditIconWrapper } from "./Header.style";
import { EditFilledIcon } from "src/components/Media/Icon/Icons/Icons";

export const Header = ({
  isLogoClickable,
  logoMarginIndex,
  isBackButtonVisible = false,
  isSkipButtonVisible = false,
  isLogoLeftAligned = false,
  onBackButtonClick,
  onSkipButtonClick,
  paddingX = 3,
  backButtonId,
  isLogoVisible = true,
  isLanguageSwitcher = false,
  isMainMunuVisible = false,
  isBackButtonHide = false,
  isSkipButtonHide = false,
  headerText,
  withCenteredHeaderText = false,
  isLogoOnlyForDesktop,
  headerPaddingTop = 6,
  headerPaddingBottom = 8,
  isEditIconVisible,
  onClickEditIcon,
  bottomHighlightContent,
  isBottomContentVisible = false,
  isHeaderBottomFixedInDesktopView,
}: HeaderProps) => {
  const { t } = useTranslation();
  // const isOnNativeApp = useSelector((state: RootState) => state.appState);

  const getSxForHeaderText = (): SxProps<Theme> | undefined => {
    if (withCenteredHeaderText || isEditIconVisible) {
      return {
        position: "absolute",
        top: 24,
        left: 56,
        right: 56,
        textAlign: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
      };
    }

    return undefined;
  };

  return (
    <>
      <Stack
        paddingX={paddingX}
        paddingTop={headerPaddingTop}
        paddingBottom={headerPaddingBottom}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          display="flex"
          marginBottom={0}
        >
          {!isLogoLeftAligned && (
            <BackIcon
              sx={{
                visibility: isBackButtonVisible ? "visible" : "hidden",
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  display: isBackButtonHide ? "none" : "inline-flex",
                },
              }}
              onClick={onBackButtonClick}
            >
              <ArrowBackIosNewIcon sx={{ height: 18 }} id={backButtonId} />
            </BackIcon>
          )}
          {isLogoVisible && !headerText && (
            <>
              <Logo
                isLogoClickable={isLogoClickable}
                logoMarginIndex={logoMarginIndex}
                isLogoOnlyForDesktop={isLogoOnlyForDesktop}
              />
            </>
          )}

          {headerText && (
            <HeaderTextBlock>
              <Logo
                isLogoClickable={isLogoClickable}
                logoMarginIndex={logoMarginIndex}
                isLogoOnlyForDesktop={isLogoOnlyForDesktop}
              />
              <Typography
                variant="h4ExtraBold"
                className="heading_content"
                sx={getSxForHeaderText()}
              >
                {headerText}
              </Typography>
            </HeaderTextBlock>
          )}

          {!isLanguageSwitcher && (
            <Button
              variant="text"
              sx={{
                visibility: isSkipButtonVisible ? "visible" : "hidden",
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  display: isSkipButtonHide ? "none" : "inline-flex",
                },
              }}
              onClick={onSkipButtonClick}
            >
              {t("linkTexts.skipLink")}
            </Button>
          )}
          {isLanguageSwitcher && <LanguageSwitcher />}
          {isMainMunuVisible && <MainMenu />}
          {isEditIconVisible && (
            <EditIconWrapper onClick={onClickEditIcon}>
              <EditFilledIcon />
            </EditIconWrapper>
          )}
        </Stack>
      </Stack>
      {isBottomContentVisible && (
        <HeaderBottomWrapper
          className={
            isHeaderBottomFixedInDesktopView ? "is_fixed" : "is_absolute"
          }
        >
          {bottomHighlightContent && (
            <Typography
              component="p"
              variant="h3"
              textAlign={"center"}
              textTransform={"capitalize"}
              sx={{
                marginBottom: 3,
                color: "gray.700",
                fontWeight: 300,
              }}
            >
              {bottomHighlightContent}
            </Typography>
          )}
        </HeaderBottomWrapper>
      )}
    </>
  );
};
