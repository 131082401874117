import { Header, ImageBanner } from "src/components";
import { Box, Typography } from "@mui/material";
import { IDENTITY_IMAGES } from "src/shared/assets/images";
import { useTranslation } from "react-i18next";
import { IIdentityStatusScreen } from "./Failed";
import { SearchPageContainer } from "../Search/SearchPageContainer";
import { onDesktop } from "src/shared/theme/breakpoint";

export const IdentityApproved = ({ onSubmitEvent }: IIdentityStatusScreen) => {
  const { t } = useTranslation();

  return (
    <SearchPageContainer
      submitButtonText={t("buttonTexts.continueButton")}
      onSubmitEvent={onSubmitEvent}
    >
      <Header isBackButtonVisible={false} paddingX={0} />
      <ImageBanner
        imgAlt="Identity Approved"
        imgPath={IDENTITY_IMAGES.APPROVED}
        imgWidth="352px"
      />
      <Box textAlign="center" sx={{ mb: 4, [onDesktop]: { mb: 12 } }}>
        <Typography variant="h6ExtraBold" component="div" mb={3}>
          {t("headingContent.statusses.identityApproved.heading")}
        </Typography>
        <Typography variant="body2">
          {t("headingContent.statusses.identityApproved.content")}
        </Typography>
      </Box>
    </SearchPageContainer>
  );
};
