import { styled } from "@mui/material/styles";
import { Box, FormLabel } from "@mui/material";

export const FormTextAreaWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 ${theme.spacing(4.5)}; 

    &.small {
      textarea {
        height: 55px !important;
      }
    }

    &.medium {
      textarea {
        height: 140px !important;
      }
    }

    &.large {
      textarea {
        height: 220px !important;
      }
    }

    textarea {
      color: ${theme.palette.gray[900]}; 
      font-size: 14px;
      width: 100%;
      border-color: ${theme.palette.gray[100]};
      border-radius: ${theme.shape.borderRadius}px;
      background: ${theme.palette.white} !important;
      padding: ${theme.spacing(3)}; 
      

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 50px white inset;
      }

      &:focus-visible {
        outline: 0;
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${theme.palette.gray[400]}; 
        font-size: 12px;
        font-weight: 400;
        opacity: 1; /* Firefox */
      }
  
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${theme.palette.gray[400]}; 
        font-size: 12px;
        font-weight: 400;
      }
  
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${theme.palette.gray[400]}; 
        font-size: 12px;
        font-weight: 400;
      }
    }

    &.error_input {
      textarea {
        color: ${theme.palette.error.main};
        border-color: ${theme.palette.error.main} !important;
      }
    }

    &.resize-off {
      textarea {
        resize: none;
      }
    }
`
);

export const FormtextAreaContainer = styled(Box)(
  ({ theme }) => `
    display: flex;
    width: 100%;
    position: relative;
`
);

export const FormTextAreaLabel = styled(FormLabel)(
  ({ theme }) => `
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 ${theme.spacing(3)};

  &.default-label {
    color: ${theme.palette.primary.main};
  }

  &.error-label {
    color: ${theme.palette.error.main};
  }
`
);

export const HighLightText = styled(Box)`
  margin: 8px 0 0;
  color: var(--black100);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;
