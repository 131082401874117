import { useTranslation } from "react-i18next";

import { commonSpacing, theme } from "src/shared/theme/theme";

import { Box, Button, Typography } from "@mui/material";
import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  ContentContainerBottom,
  DivWrapper,
} from "../../../components";
import { AccountSearchConfirmationIcon } from "src/components/Media/Icon/Icons/Icons";

export interface ISearchVendor {
  onBackButtonClick?: () => void;
  number?: string;
  email?: string;
  onSubmitEvent?: () => void;
}

export const ResidentAccountAlreadyAvailable = ({
  onBackButtonClick,
  number,
  email,
  onSubmitEvent,
}: ISearchVendor) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <DivWrapper className="no_fullscreen">
        <Box
          component={"div"}
          className={"container_content"}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: 400 + commonSpacing.desktopPaddingB * 4,
            },
          }}
        >
          <Header
            isBackButtonVisible={true}
            onBackButtonClick={onBackButtonClick}
            paddingX={0}
          />
          <Box sx={{ marginBottom: 14 }}>
            <HeadingSummaryBlock
              heading={t(
                "headingContent.statusses.accountALreadyAvailable.heading"
              )}
              headingAlignment="left"
              content={t(
                "headingContent.statusses.accountALreadyAvailable.content1",
                {
                  numberOrEmail:
                    number && !email
                      ? `${number} `
                      : !number && email
                      ? `${email} `
                      : `${number} / ${email} `,
                }
              )}
              contentAlignment="left"
            />
            <Typography
              variant="body2"
              sx={{
                textAlign: "left",
                marginTop: 3,
              }}
            >
              {t("headingContent.statusses.accountALreadyAvailable.content2")}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: 3,
              color: "primary.main",
            }}
          >
            <AccountSearchConfirmationIcon />
          </Box>
        </Box>

        <ContentContainerBottom className={"no_fullscreen"}>
          <Button
            variant="containedLarge"
            color="primary"
            type="button"
            onClick={onSubmitEvent}
            fullWidth
            sx={{ overflow: "hidden" }}
          >
            {t("buttonTexts.loginButtonText")}
          </Button>
        </ContentContainerBottom>
      </DivWrapper>
    </Wrapper>
  );
};
