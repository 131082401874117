import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Typography,
} from "@mui/material";

import {
  FormAuthorizedWrapper,
  ShadowCard,
  ActionItemCard,
  FormInput,
  ManualLoader,
  ButtonAuthorizedWrapper,
  Messages,
  ConfirmationModal,
  AddressFields,
  PaymentCardFields,
  FormRadioButton,
  FormInputErrorMessage,
} from "src/components";

import { createCardActionCardButtonItems } from "src/shared/assets/data";
import {
  AddCreditOrDebitCardFormProps,
  AddressDefaultValues,
  AddressFormProps,
} from "src/shared/models/component/base.model";
import { updateBindingsChange } from "src/shared/utilities/formUtilities";
import { addCrreditCardFormSchemaObj } from "src/shared/utilities/validationSchemas";

import {
  AddCreditOrDebitCardFormParams,
  addCreditOrDebitCardParamsProps,
} from "src/shared/models/containers/rent.model";

import {
  useAddPaymentCardMutation,
  useGetPaymentMethodsQuery,
  useUpdateDefaultBankAccountMutation,
} from "src/store/services/privateApi";

import {
  checkDefaultPaymentMethod,
  resetTheAddCardFormFields,
} from "src/helpers/helperRent";
import { DropdownData } from "src/components/FormElements/AddressFields/DropdownState/DropdownState.data";

export const AddCreditOrDebitCardForm = ({
  navigate,
  t,
  isFromPayment,
  isOnlyCard,
}: AddCreditOrDebitCardFormProps) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isMarkedAsDefaultPaymentMethod, setIsMarkedAsDefaultPaymentMethod] =
    useState<boolean>(true);
  const [
    isDefaultPaymentOptionUnEditable,
    setIsDefaultPaymentOptionUnEditable,
  ] = useState<boolean>(true);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [addressStates, setAddressStates] =
    useState<AddressFormProps>(AddressDefaultValues);

  const [isCardDetailsAdded, setIsCardDetailsAdded] = useState(false);
  const [cardNumber, setCardNumber] = useState<any>();
  const [cardCVC, setCardCVC] = useState<any>();
  const [cardExpiryDate, setCardExpiryDate] = useState<any>();
  const [namesAdded, setNamesAdded] = useState<
    "none" | "added" | "worng_entry"
  >("none");

  const [addCard, { isLoading: isLoadingAddCard }] =
    useAddPaymentCardMutation();
  const [updateDefaultBankAccount, { isLoading: isLoadingUpdateBA }] =
    useUpdateDefaultBankAccountMutation();
  const { data: paymentMethods, isSuccess } = useGetPaymentMethodsQuery(null);

  useEffect(() => {
    const [autoEnableDefaultUdate] = checkDefaultPaymentMethod(
      isSuccess,
      paymentMethods
    );
    if (autoEnableDefaultUdate) {
      setIsMarkedAsDefaultPaymentMethod(true);
      setIsDefaultPaymentOptionUnEditable(true);
    } else {
      setIsDefaultPaymentOptionUnEditable(false);
    }
  }, [isSuccess, paymentMethods]);

  const addBankAccountFormSchema = yup
    .object(addCrreditCardFormSchemaObj)
    .required();

  const resetCardDetails = () => {
    setCardExpiryDate("");
    setCardCVC("");
    setCardNumber("");
    setNamesAdded("none");
  };

  /**
   * initialize the hook form
   */
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<AddCreditOrDebitCardFormParams>({
    resolver: yupResolver(addBankAccountFormSchema),
  });

  useEffect(() => {
    resetTheAddCardFormFields(setValue);
    resetCardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formSubmit = handleSubmit(
    async (data: AddCreditOrDebitCardFormParams) => {
      if (isCardDetailsAdded) {
        const setBillingStreetAddress = `${data.line1}${
          data.line2 ? "," : ""
        } ${data.line2 ?? ""}`;

        const getCardNumber: string = data.cardNumber
          ? `${data.cardNumber}`
          : "";

        const getExpiryDate = data.cardExpiryYear
          ? `${data.cardExpiryYear}`
          : "";
        const getMonth = getExpiryDate.slice(0, 2);
        const getYear = getExpiryDate.slice(-2);

        const getState: any =
          DropdownData &&
          DropdownData.filter(
            (state: { label: string; abbreviation: string }) =>
              state.label === data?.state
          );

        const payloadParams: addCreditOrDebitCardParamsProps = {
          PayerFirstName: data.payerFirstName,
          PayerLastName: data.payerLastName,
          CreditCardType: data.cardType, //'Visa', 'MasterCard', 'Discover', 'Amex'
          CreditCardNumber: getCardNumber.replace(/\s+/g, ""),
          CreditCardExpMonth: getMonth,
          CreditCardExpYear: getYear,
          CreditCardCvv2: data.cardCVC,
          BillingFirstName: data.payerFirstName,
          BillingLastName: data.payerLastName,
          BillingStreetAddress: setBillingStreetAddress,
          BillingCity: data?.city,
          BillingState: getState ? getState[0].abbreviation : "",
          BillingCountry: "US",
          BillingZip: data?.postal_code,
          Label: data.label,
          IsDebitCard: data.addCard,
        };

        const response: any = await addCard(payloadParams);

        if (response) {
          if (response.error) {
            setErrorMessage(
              response?.error?.data?.error ??
                response?.error?.data?.message ??
                response?.error?.data?.message?._text ??
                "Unable to create card."
            );
          } else {
            if (
              isMarkedAsDefaultPaymentMethod &&
              response?.data?.paymentMethodId
            ) {
              const updateDefaultResponse: any = await updateDefaultBankAccount(
                {
                  id: response?.data?.paymentMethodId,
                }
              );

              if (updateDefaultResponse && updateDefaultResponse.error) {
                setErrorMessage(
                  response?.error?.data?.error ??
                    response?.error?.data?.message ??
                    "Unable to mark as default, but account has been created successfully."
                );
              } else {
                setErrorMessage("");
                setIsMarkedAsDefaultPaymentMethod(false);
                resetTheAddCardFormFields(setValue);
                resetCardDetails();
                setConfirmationModalOpen(true);
              }
            } else {
              setErrorMessage("");
              resetTheAddCardFormFields(setValue);
              setIsMarkedAsDefaultPaymentMethod(false);
              resetCardDetails();
              setConfirmationModalOpen(true);
            }
          }
        }
      }
    }
  );

  const onChangeMarkedAsDefault = (event: any) => {
    const isDefaultChecked = event.target.checked;
    if (isDefaultChecked) {
      setIsMarkedAsDefaultPaymentMethod(true);
    } else {
      setIsMarkedAsDefaultPaymentMethod(false);
    }
  };

  const onSubmitConfirmationModalBtn = () => {
    setConfirmationModalOpen(false);
    if (isFromPayment) {
      navigate("/rent/payment");
    } else {
      navigate("/rent/linked-accounts");
    }
  };

  let actionItemsData = createCardActionCardButtonItems;

  if (isFromPayment) {
    actionItemsData.map((x) => (x.isFromPayment = true));
  }

  /**
   * used to get firstname last name, when input onchange
   * @param event Returning input events when onchange
   */
  const updateBindingsChangeGetNames = (event: any) => {
    const getValue = event.target.value;
    const getNames = getValue.split(" ");

    setValue("cardHolderName", event.target.value);

    if (getNames && getNames.length > 1) {
      const getFirstName = getNames[0];
      const getLastName = getNames[getNames.length - 1];

      if (getLastName && getLastName.length > 0) {
        setNamesAdded("added");
        setValue("payerFirstName", getFirstName);
        setValue("payerLastName", getLastName);
      } else {
        setNamesAdded("worng_entry");
      }
    } else {
      setValue("payerFirstName", "");
      setValue("payerLastName", "");

      if (getValue.length > 0) {
        setNamesAdded("worng_entry");
      } else {
        setNamesAdded("none");
      }
    }
  };

  return (
    <>
      {(isLoadingAddCard || isLoadingUpdateBA) && <ManualLoader />}
      <FormAuthorizedWrapper onSubmit={formSubmit} autoComplete="off">
        <ShadowCard padding={4} marginBottom={6}>
          {!isOnlyCard && (
            <ActionItemCard
              navigate={navigate}
              t={t}
              actionItemsData={actionItemsData}
              cardWrapperType="no_shadow"
              paddingX={0}
              paddingY={0}
              cardMarginBottom={4}
            />
          )}
          <Box>
            <Controller
              control={control}
              name="addCard"
              render={({ field: { onChange, value } }) => (
                <Box>
                  <FormLabel id="demo-radio-buttons-group-label">
                    {"Card Type"}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ marginBottom: errors?.addCard?.message ? 0 : 3 }}
                  >
                    <FormRadioButton
                      label={"Debit Card"}
                      value={value ?? ""}
                      id={"Yes"}
                      checked={value === "Yes"}
                      {...register("addCard")}
                      handleChange={() => setValue("addCard", "Yes")}
                      labelFontVariant="h2"
                      marginRight={4}
                    />
                    <FormRadioButton
                      label={"Credit Card"}
                      value={value ?? ""}
                      id={"No"}
                      checked={value === "No"}
                      {...register("addCard")}
                      handleChange={() => setValue("addCard", "No")}
                      labelFontVariant="h2"
                      marginRight={4}
                    />
                  </RadioGroup>
                  {errors?.addCard?.message && (
                    <Box sx={{ paddingBottom: 5 }} display={"flex"}>
                      <FormInputErrorMessage>
                        {errors?.addCard?.message}
                      </FormInputErrorMessage>
                    </Box>
                  )}
                </Box>
              )}
            />
            <Controller
              control={control}
              name="cardHolderName"
              render={({ field: { value } }) => (
                <FormInput
                  label={"Card Holder Name"}
                  id={"cardHolderName"}
                  placeholder={""}
                  {...register("cardHolderName")}
                  error={
                    namesAdded === "none" && errors?.cardHolderName?.message
                      ? errors?.cardHolderName?.message
                      : namesAdded === "worng_entry" &&
                        errors?.payerLastName?.message
                      ? errors?.payerLastName?.message
                      : ""
                  }
                  onChange={(event: any) => {
                    updateBindingsChangeGetNames(event);
                  }}
                  inputFocus={(e: any) => {
                    trigger("cardHolderName");
                  }}
                  value={value}
                />
              )}
            />

            <PaymentCardFields
              setValue={setValue}
              errors={errors}
              setIsCardDetailsAdded={setIsCardDetailsAdded}
              cardNumber={cardNumber}
              setCardNumber={setCardNumber}
              cardCVC={cardCVC}
              setCardCVC={setCardCVC}
              cardExpiryDate={cardExpiryDate}
              setCardExpiryDate={setCardExpiryDate}
            />

            <AddressFields
              register={register}
              control={control}
              getValue={getValues}
              setValue={setValue}
              setAddressStates={setAddressStates}
              addressStates={addressStates}
              trigger={trigger}
              errors={errors}
              isPOBoxTextVisible={false}
            />

            <FormControlLabel
              sx={{
                marginBottom: 3,
                marginTop: -1,
                pointerEvents: isDefaultPaymentOptionUnEditable
                  ? "none"
                  : "auto",
                opacity: isDefaultPaymentOptionUnEditable ? "0.6" : "1",
              }}
              control={
                <Checkbox
                  name="marked_as_default"
                  size="small"
                  onChange={onChangeMarkedAsDefault}
                  checked={isMarkedAsDefaultPaymentMethod}
                />
              }
              label={
                <Typography variant="h2" color={"gray.700"}>
                  {"Make this my default payment method"}
                </Typography>
              }
            />
          </Box>
          <Controller
            control={control}
            name="label"
            render={({ field: { value } }) => (
              <FormInput
                label={"Payment Label"}
                id={"label"}
                placeholder={"Assign a Nickname to this method"}
                {...register("label")}
                error={errors?.label?.message}
                onChange={(event: any) => {
                  event.target.value = event.target.value.replace("  ", " ");
                  updateBindingsChange(event, "label", setValue);
                }}
                value={value}
                inputFocus={(e: any) => {
                  trigger("label");
                }}
                highLightText={"Assign a Nickname to this method"}
              />
            )}
          />

          {errorMessage !== "" && (
            <Messages
              messageHeading={"Bank Linking"}
              messageContent={errorMessage}
              closeEvent={() => setErrorMessage("")}
              bottomMargin={4}
              topMargin={4}
            />
          )}
        </ShadowCard>
        <ButtonAuthorizedWrapper>
          <Button
            variant="containedLarge"
            color="primary"
            fullWidth
            type="submit"
            sx={{ overflow: "hidden" }}
          >
            {t("buttonTexts.addButton")}
          </Button>
        </ButtonAuthorizedWrapper>
      </FormAuthorizedWrapper>

      {confirmationModalOpen && (
        <ConfirmationModal
          onFormSubmit={onSubmitConfirmationModalBtn}
          title={t("requests.addOrUpdateRequest.confirmationModalTitle")}
          content={"Your card has successfully been added."}
          btnTitle={t("buttonTexts.okButton")}
          hideClose={true}
        />
      )}
    </>
  );
};
