import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { IMAGES } from "src/shared/assets/images";
// import { useLocation } from "react-router-dom";

export type ITlogoImage = "CLIENT" | "DEFAULT" | "NONE";

export const LogoImage = () => {
  const [logoImg, setLogoImg] = useState<ITlogoImage>("NONE");

  useEffect(() => {
    const location = window.location.origin;
    if (location.includes("app.resprop")) {
      setLogoImg("CLIENT");
    } else {
      setLogoImg("DEFAULT");
    }
  }, []);

  return (
    <>
      {
        /**
         * empty div will display untill logo loading
         */
        logoImg === "NONE" && <Box height={"30px"} />
      }

      {
        /**
         * TenantPort default image
         */
        logoImg === "DEFAULT" && (
          <>
            <img src={IMAGES.TP_LOGO} alt="LOGO" height={30} />
          </>
        )
      }

      {
        /**
         * Resident portal logo
         */
        logoImg === "CLIENT" && (
          <>
            <svg
              preserveAspectRatio="xMidYMid meet"
              data-bbox="0 0 2028.83 472.83"
              viewBox="0 0 2028.83 472.83"
              xmlns="http://www.w3.org/2000/svg"
              height="40"
            >
              <g>
                <path
                  d="m148.09 284.22-46.26-66.3a82.36 82.36 0 0 1-8.74.32H42v66H0V57.82h93.14c59.52 0 96.39 30.41 96.39 80.54 0 34.28-17.47 59.51-48.2 71.48l52.08 74.38ZM91.16 93.4H42v90.24h49.16c36.87 0 56-16.82 56-45.28s-19.13-44.96-56-44.96Z"
                  fill="#387C88"
                ></path>
                <path
                  d="m563.33 260.29 14.5-32.67c18.11 14.56 46.9 24.91 74.71 24.91 35.26 0 50.14-12.62 50.14-29.43 0-48.84-134.55-16.82-134.55-100.92 0-36.54 29.11-67.59 91.2-67.59 27.17 0 55.63 7.11 75.37 20l-13.27 32.67c-20.37-12-42.69-17.79-62.42-17.79-34.93 0-49.16 13.58-49.16 30.73 0 48.19 134.23 16.81 134.23 99.94 0 36.22-29.44 67.27-91.54 67.27-35.19.05-70.45-10.95-89.21-27.12Z"
                  fill="#387C88"
                ></path>
                <path
                  d="M1553.63 91.46c-45.61 0-79.57 33.31-79.57 79.56s34 79.57 79.57 79.57 79.56-33.31 79.56-79.57-33.96-79.56-79.56-79.56Z"
                  fill="#387C88"
                ></path>
                <path
                  d="M1237.98 93.4h-49.15v90.24h49.16c36.87 0 56-16.82 56-45.28s-19.16-44.96-56.01-44.96Z"
                  fill="#387C88"
                ></path>
                <path
                  d="M768.66 0v342.79h1260.17V0Zm175 218.89h-51.09v65.33h-42V57.82h93.15c59.51 0 96.38 30.41 96.38 80.54 0 49.8-36.91 80.53-96.42 80.53Zm351.23 65.33-46.25-66.3a82.36 82.36 0 0 1-8.74.32h-51v66h-42V57.82h93.15c59.51 0 96.38 30.41 96.38 80.54 0 34.28-17.46 59.51-48.19 71.48l52.08 74.38Zm258.72 3.24c-70.19 0-121.94-49.49-121.94-116.44s51.75-116.43 121.94-116.43 122 49.13 122 116.43-51.78 116.44-121.98 116.44Zm324.38-68.57h-51.1v65.33h-42V57.82h93.15c59.51 0 96.38 30.41 96.38 80.54 0 49.8-36.9 80.53-96.41 80.53Z"
                  fill="#387C88"
                ></path>
                <path
                  d="M941.73 93.4h-49.16v89.91h49.16c36.87 0 56-16.49 56-45s-19.13-44.91-56-44.91Z"
                  fill="#387C88"
                ></path>
                <path
                  d="M1876.07 93.4h-49.16v89.91h49.16c36.87 0 56-16.49 56-45s-19.13-44.91-56-44.91Z"
                  fill="#387C88"
                ></path>
                <path
                  d="m451.83 471.72-.11-52.72-26.13 43.63h-6.56l-26.1-42.91v52h-14.1V392.2h12.16l31.58 52.71 31-52.71h12.16l.1 79.52Z"
                  fill="#387C88"
                ></path>
                <path
                  d="M584.34 453.32h-39.76l-7.84 18.4h-15.22l35.78-79.53h14.53l35.91 79.53h-15.45Zm-4.89-11.6-15-34.76-14.87 34.76Z"
                  fill="#387C88"
                ></path>
                <path
                  d="M733.95 392.2v79.52h-12.12l-43.85-53.85v53.85h-14.7V392.2h12.16l43.85 53.85V392.2Z"
                  fill="#387C88"
                ></path>
                <path
                  d="M852.31 453.32h-39.76l-7.84 18.4h-15.23l35.79-79.53h14.56l35.88 79.53h-15.45Zm-4.89-11.6-15-34.76-14.88 34.76Z"
                  fill="#387C88"
                ></path>
                <path
                  d="M984.42 431.05h14v31.67c-8.29 6.7-19.88 10.11-31.12 10.11-24.66 0-42.83-17.15-42.83-40.9s18.17-40.89 43-40.89c13.29 0 24.43 4.43 31.93 12.95l-9.32 9.09a29.47 29.47 0 0 0-21.92-9.09c-16.93 0-28.86 11.47-28.86 27.94 0 16.13 11.93 28 28.74 28a32.18 32.18 0 0 0 16.36-4.09Z"
                  fill="#387C88"
                ></path>
                <path
                  d="M1122.83 459.34v12.38h-59.65V392.2h58v12.39h-43.25v20.67h38.4v12.16h-38.4v21.92Z"
                  fill="#387C88"
                ></path>
                <path
                  d="m1257.83 471.72-.12-52.72-26.13 43.63h-6.59l-26.16-42.91v52h-14V392.2h12.15l31.59 52.71 31-52.71h12.16l.1 79.52Z"
                  fill="#387C88"
                ></path>
                <path
                  d="M1398.36 459.34v12.38h-59.64V392.2h58v12.39h-43.28v20.67h38.39v12.16h-38.4v21.92Z"
                  fill="#387C88"
                  data-color="1"
                ></path>
                <path
                  d="M1530.93 392.2v79.52h-12.16l-43.85-53.85v53.85h-14.66V392.2h12.16l43.85 53.85V392.2Z"
                  fill="#387C88"
                ></path>
                <path
                  d="M1613.83 404.72h-26.36v-12.5h67.48v12.5h-26.32v67h-14.8Z"
                  fill="#387C88"
                ></path>
                <path
                  fill="#387C88"
                  d="M466.32 57.82v34.62H301.05V57.82h165.27z"
                ></path>
                <path
                  fill="#387C88"
                  d="M343.1 248.97v-62.42h109.32v-32.98H301.05v130.66h169.81v-35.26H343.1z"
                ></path>
              </g>
            </svg>
          </>
        )
      }
    </>
  );
};
