import React, { FC, forwardRef } from "react";

import {
  FormTextAreaWrapper,
  FormtextAreaContainer,
  HighLightText,
  FormTextAreaLabel,
} from "./FormTextArea.style";

import { FormInputErrorMessage } from "../FormInputErrorMessage/FormInputErrorMessage";

import { TextAreaProps } from "../../../shared/models/component/base.model";
import { TextareaAutosize } from "@mui/material";

export const FormTextArea: FC<TextAreaProps> = forwardRef<
  HTMLTextAreaElement,
  TextAreaProps
>(
  (
    {
      id,
      label,
      placeholder,
      error,
      isValid = false,
      highLightText,
      maxWidth = "100%",
      inputMode = "text",
      value,
      readonly,
      onChange,
      inputFocus,
      disableCopyPaste = false,
      onKeyPress,
      isResizeEnabled,
      size = "large",
    },
    ref,
    ...props
  ) => {
    const handleInputClasses =
      error && !isValid
        ? "error_input"
        : isValid
        ? "valid_input"
        : "normal_input";

    return (
      <FormTextAreaWrapper
        sx={{ maxWidth: maxWidth }}
        className={`${handleInputClasses} icon_not_enabled ${
          isResizeEnabled ? "resize-on" : "resize-off"
        } ${size}`}
      >
        {label && (
          <FormTextAreaLabel
            htmlFor={id}
            className={error ? "error-label" : "default-label"}
          >
            {label}
          </FormTextAreaLabel>
        )}
        <FormtextAreaContainer>
          <TextareaAutosize
            id={id}
            aria-label={label}
            placeholder={placeholder}
            inputMode={inputMode}
            value={value}
            key={id}
            {...props}
            disabled={readonly}
            onChange={onChange}
            onBlur={inputFocus && inputFocus}
            onCopy={(e: any) => {
              if (disableCopyPaste) {
                e.preventDefault();
                return false;
              }
            }}
            onPaste={(e: any) => {
              if (disableCopyPaste) {
                e.preventDefault();
                return false;
              }
            }}
            onCut={(e: any) => {
              if (disableCopyPaste) {
                e.preventDefault();
                return false;
              }
            }}
            onKeyPress={onKeyPress}
          />
        </FormtextAreaContainer>
        {highLightText && <HighLightText>{highLightText}</HighLightText>}
        {error && !isValid && (
          <FormInputErrorMessage>{error}</FormInputErrorMessage>
        )}
      </FormTextAreaWrapper>
    );
  }
);
