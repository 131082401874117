// import request grid related tabel cells__
import { FirstCell, SecondCell, ThirdCell } from "../TbodyCells/RequestGrid";
// import activity grid related tabel cells__
import {
  FirstCellActivityGrid,
  SecondCellActivityGrid,
  ThirdCellActivityGrid,
} from "../TbodyCells/ActivityGrid";
// import reporting history grid related tabel cells__
import {
  FirstCellReportingHisoryGrid,
  SecondCellReportingHisoryGrid,
  ThirdCellReportingHisoryGrid,
} from "../TbodyCells/ReportingHisoryGrid";

/**
 * this file contains the following functions to handle the coulmns and and values of the relavant grids
 * * * RequestGridColoumns - used to handle the column name and values if request grid
 * * * ActivityGridColoumns - used to handle the column name and values if activity grid
 * * * ReportingHistoryGridColoumns - used to handle the column name and values if reporting history grid
 */

/**
 * used to handle the column name and values if request grid
 * @param t translation method pass as a parameter
 * @returns columns array object with coumn name and values
 */
export const RequestGridColoumns = (t: any) => {
  return [
    {
      Header: t("requests.requestGrid.coloumnOneName"),
      accessor: "title",
      // Set a custom filter for age
      canFilter: true,
      Cell: (props: any) => {
        return <FirstCell props={props} />;
      },
    },
    {
      Header: t("requests.requestGrid.coloumnTwoName"),
      accessor: "ReportedDate",
      Cell: (props: any) => {
        return <SecondCell props={props} />;
      },
    },
    {
      Header: t("requests.requestGrid.coloumnThreeName"),
      accessor: "Number",
      Cell: (props: any) => {
        return <ThirdCell props={props} />;
      },
    },
  ];
};

/**
 * used to handle the column name and values if activity grid
 * @param t translation method pass as a parameter
 * @returns columns array object with coumn name and values
 */
export const ActivityGridColoumns = (t: any) => {
  return [
    {
      Header: t("requests.requestGrid.coloumnOneName"),
      accessor: "description",
      // Set a custom filter for age
      canFilter: true,
      Cell: (props: any) => {
        return <FirstCellActivityGrid props={props} />;
      },
    },
    {
      Header: t("requests.requestGrid.coloumnTwoName"),
      accessor: "date",
      Cell: (props: any) => {
        return <SecondCellActivityGrid props={props} />;
      },
    },
    {
      Header: "AMOUNT",
      accessor: "amount",
      Cell: (props: any) => {
        return <ThirdCellActivityGrid props={props} />;
      },
    },
  ];
};

/**
 * used to handle the column name and values if reporting history grid
 * @param t translation method pass as a parameter
 * @returns columns array object with coumn name and values
 */
export const ReportingHistoryGridColoumns = (t: any) => {
  return [
    {
      Header: t("requests.requestGrid.coloumnOneName"),
      accessor: "Description",
      // Set a custom filter for age
      canFilter: true,
      Cell: (props: any) => {
        return <FirstCellReportingHisoryGrid props={props} />;
      },
    },
    {
      Header: t("requests.requestGrid.coloumnTwoName"),
      accessor: "Date",
      Cell: (props: any) => {
        return <SecondCellReportingHisoryGrid props={props} />;
      },
    },
    {
      Header: "AMOUNT",
      accessor: "Amount",
      Cell: (props: any) => {
        return <ThirdCellReportingHisoryGrid props={props} />;
      },
    },
  ];
};
