import { styled } from "@mui/material/styles";

export const TermsTextWrapper = styled("div")(
  ({ theme }) => `
  width: 100%;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  border: none;
  svg {
    height: 24px;
  }
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    justify-content: flex-start;
  }
  @media (min-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
  }
`
);

export const TermsTextRight = styled("div")(
  ({ theme }) => `
  margin-left: ${theme.spacing(3)};
`
);
