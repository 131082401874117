import { Grid, Typography } from "@mui/material";
import { To } from "react-router-dom";
import { ActionIconButton } from "src/components/Buttons/ActionIconButton/ActionIconButton";
import { ShadowCard } from "src/components/CommonNestedStyles/FormWrapper.style";
import {
  ActionItemCardProps,
  ActionIconButtonType,
} from "src/shared/models/component/base.model";

export const ActionItemCard = ({
  t,
  navigate,
  cardTitle,
  cardMarginBottom = 8,
  actionItemsData,
  cardWrapperType = "default",
  paddingX = 4,
  paddingY = 4,
}: ActionItemCardProps) => {
  return (
    <ShadowCard
      paddingX={paddingX}
      paddingY={paddingY}
      marginBottom={cardMarginBottom}
      className={cardWrapperType}
    >
      {cardTitle && (
        <Typography variant="body1" color={"gray.700"} mb={4}>
          {cardTitle}
        </Typography>
      )}
      <Grid container spacing={4}>
        {actionItemsData &&
          actionItemsData.map(
            (
              item: {
                cardType: ActionIconButtonType;
                btnTitleTranslationPath: string;
                navigationUrl: To;
                isDisabled: boolean | undefined;
                isActive: boolean | undefined;
                paddingRight: string | number | undefined;
                isFromPayment: boolean;
              },
              index: string
            ) => {
              return (
                <Grid item xs={6} key={index}>
                  <ActionIconButton
                    cardType={item.cardType}
                    btnTitle={t(item.btnTitleTranslationPath)}
                    onClickBtnEvent={() => {
                      if (item.isFromPayment) {
                        navigate(item.navigationUrl, {
                          state: {
                            isFromPayment: item.isFromPayment,
                          },
                        });
                      } else {
                        navigate(item.navigationUrl);
                      }
                    }}
                    isDisabled={item.isDisabled}
                    isActive={item.isActive}
                    paddingRight={item.paddingRight ? item.paddingRight : 4}
                  />
                </Grid>
              );
            }
          )}
      </Grid>
    </ShadowCard>
  );
};
