import { styled } from "@mui/material/styles";

export const FilterTagWrapper = styled("div")(
  ({ theme }) => `
  width: 100%;
  display: flex;

  .picker_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(26, 31, 54, 0.37);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .picker_container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    max-width: 304px;
    padding: 24px 0 16px;
    justify-content: center;
    border-radius: 10px;
  }

  .picker_button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 0;

    span {
      cursor: pointer;
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      color:${theme.palette.primary.main};
    }

    button {
      min-width: 83px;
      height: 36px;
      cursor: pointer;
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
    }
  }

  .custom-picker {
    width: 100%;
    border: none;

    .react-datepicker__aria-live {
      display: none;
    }

    .react-datepicker__month-container {
      width: 100%;
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 16px;
    }

    .react-datepicker__header {
      background-color: #fff;
      border-bottom: none;
      border-top-left-radius: 0;
      padding: 0 0 8px;
    }

    .react-datepicker__current-month {
      font-size: 14px;
      line-height: 16px;
      color: #333333;
      margin-bottom: 25px;
      display: none;
    }

    .react-datepicker__header__dropdown {
      margin-bottom: 25px;
    }

    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view,
    .react-datepicker__month-year-read-view {
      visibility: visible !important;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #333333;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
      border-width: 2px 2px 0 0;
      right: -14px;
      top: 2px;
    }

    .react-datepicker__navigation {
      top: -8px;
      position: relative;
    }

    .react-datepicker__navigation-icon::before {
      border-color: ${theme.palette.primary.main};
      border-width: 2px 2px 0 0;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name,
    .react-datepicker__day--keyboard-selected {
      font-weight: 500;
      font-size: 14px;
      display: inline-block;
      width: 41px;
      line-height: 28px;
      text-align: center;
      margin: 3px 0;
      color: ${theme.palette.gray[700]};
      background: #fff;
    }

    .react-datepicker__day-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.palette.primary.main};
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-range,
    .react-datepicker__day--selected.react-datepicker__day--outside-month {
      background: ${theme.palette.primary.main};
      color: ${theme.palette.white};
      border-radius: 0;

      &.react-datepicker__day--range-end {
        border-radius: 0 30px 30px 0;
      }
    }

    .react-datepicker__day--selected {
      border-radius: 50%;
      width: 28px;
      margin: 3px 6.5px;

      &.react-datepicker__day--in-range {
        border-radius: 0;
        margin: 3px 0;
        width: 41px;
      }

      &.react-datepicker__day--range-start {
        border-radius: 30px 0 0 30px;
      }
    }

    .react-datepicker__day.react-datepicker__day--disabled,
    .react-datepicker__day--outside-month {
      color: ${theme.palette.gray[300]};
    }

    .react-datepicker__day.react-datepicker__day--disabled {
      pointer-events: none;
    }

    .react-datepicker__year-wrapper {
      display: flex;
      width: 100%;
      max-height: 200px;
      flex-wrap: wrap;
      justify-content: center;
      overflow-y: auto;
      padding-bottom: ${theme.spacing(1)};

      > div {
        width: 54px;
        align-items: center;
        text-align: center;
        margin: ${theme.spacing(1.5, 5)};
        padding: ${theme.spacing(2)};
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: ${theme.palette.gray[900]};
        border-radius: 4px;

        &[tabindex="0"] {
         background-color: ${theme.palette.blue[75]};
        }

        &.react-datepicker__year-text--disabled {
          color: ${theme.palette.gray[300]};
          pointer-events: none;
        }
      }
    }

    .react-datepicker__month-wrapper {
      display: flex;
      width: 100%;
      max-height: 200px;
      flex-wrap: wrap;
      justify-content: center;
      overflow-y: auto;
      padding-bottom: ${theme.spacing(1)};

      > div {
        width: 54px;
        align-items: center;
        text-align: center;
        margin: ${theme.spacing(1.5, 5)};
        padding: ${theme.spacing(2)};
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: ${theme.palette.gray[900]};
        border-radius: 4px;

        &[tabindex="0"] {
         background-color: ${theme.palette.blue[75]};
        }
      }
    }
  }
`
);

export const ButtonCustomDate = styled("button")(
  ({ theme }) => `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: ${theme.spacing(4)};
  border: 1px solid ${theme.palette.gray[100]};
  height: 38px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${theme.palette.gray[900]};
  white-space: nowrap;
  cursor: pointer;
  background: ${theme.palette.white};
  position: relative;

  &.inActive {
    svg {
      margin-right: 8px;
    }
  }

  .lable_name {
    width: 100%;
    top: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &.active {
    background: #dfe6fe;
    border: 1px solid ${theme.palette.primary.main};
    color: ${theme.palette.primary.main};
    font-weight: 700;
    position: relative;

    .close-icon {
      position: absolute;
      right: 12px;
      height: 11px;
      cursor: pointer;
      z-index: 1;
    }
  }
`
);

export const HeaderRow = styled("div")(
  ({ theme }) => `
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-left: ${theme.spacing(2)};
  padding-right: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(2)};
  justify-content: center;
  align-items: center;
`
);

export const YearContainer = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
