import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Box, Button, FormLabel, RadioGroup } from "@mui/material";

import {
  FormAuthorizedWrapper,
  FormInput,
  ManualLoader,
  ButtonAuthorizedWrapper,
  Messages,
  FormRadioButton,
  FormInputErrorMessage,
} from "src/components";

import { ACHFormParams } from "src/shared/models/containers/rent.model";

import { usePostAchPaymentMutation } from "src/store/services/privateApi";
import { resetTheAddBankAccountFormFields } from "src/helpers/helperRent";
import { achFormSchemaObj } from "src/shared/utilities/validationSchemas";
import { useTranslation } from "react-i18next";

export const PayACH = ({
  amount,
  leaseId,
  onPaymentSuccess,
}: {
  amount: number;
  leaseId: string;
  onPaymentSuccess: Function;
}) => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [namesAdded, setNamesAdded] = useState<
    "none" | "added" | "worng_entry"
  >("none");
  const { t } = useTranslation();

  useEffect(() => {}, []);

  const [
    paymentMutation,
    { isLoading: isLoadingPayment, error: paymentError },
  ] = usePostAchPaymentMutation();

  const achFormSchema = yup.object(achFormSchemaObj).required();

  /**
   * initialize the hook form
   */
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<ACHFormParams>({
    resolver: yupResolver(achFormSchema),
  });
  for (const property in errors) {
    // @ts-ignore
    console.log(`${property}: ${errors[property].message}`);
  }
  useEffect(() => {
    resetTheAddBankAccountFormFields(setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formSubmit = handleSubmit(async (data: ACHFormParams) => {
    console.log("sbumit");
    const setAccountType =
      data.account_type === "checking"
        ? "Checking"
        : data.account_type === "savings"
        ? "Savings"
        : data.account_type === "business_checking"
        ? "Business Checking"
        : "";

    const payloadParams: any = {
      PayerFirstName: data.payerFirstName,
      PayerLastName: data.payerLastName,
      AccountType: setAccountType, // "Checking", "Business Checking", "Savings"
      RoutingNumber: data.routing_number,
      AccountNumber: data.account_number,
      CurrencyCode: "USD",
      Message: "Application Fee",
      leaseId: leaseId,
      TotalAmount: amount,
      AccountFullName: data.accountHolderName,
    };

    const response: any = await paymentMutation(payloadParams);

    if (response) {
      if (response.error) {
        setErrorMessage(
          response?.error?.data?.error ??
            response?.error?.data?.message?.Message ??
            "Payment Failed."
        );
      } else {
        onPaymentSuccess();
      }
    }
  });

  const isValidAccNumConf = () => {
    if (paymentError) {
      return false;
    }
    if (errors?.account_number_conf?.message) {
      return false;
    }

    const accNum = getValues("account_number");
    const accNumConf = getValues("account_number_conf");
    if (!accNum || !accNumConf) {
      return false;
    }
    if (accNum !== accNumConf) {
      return false;
    }

    return true;
  };

  const isValidAccNum = () => {
    if (paymentError) {
      return false;
    }
    if (errors?.account_number?.message) {
      return false;
    }

    const accNum = getValues("account_number") ?? "";
    if (accNum.length < 5 || accNum.length > 17) {
      return false;
    }

    return true;
  };

  /**
   * used to get firstname last name, when input onchange
   * @param event Returning input events when onchange
   */
  const updateBindingsChangeGetNames = (event: any) => {
    const getValue = event.target.value;
    const getNames = getValue.split(" ");

    setValue("accountHolderName", event.target.value);

    if (getNames && getNames.length > 1) {
      const getFirstName = getNames[0];
      const getLastName = getNames[getNames.length - 1];

      if (getLastName && getLastName.length > 0) {
        setNamesAdded("added");
        setValue("payerFirstName", getFirstName);
        setValue("payerLastName", getLastName);
      } else {
        setNamesAdded("worng_entry");
      }
    } else {
      setValue("payerFirstName", "");
      setValue("payerLastName", "");

      if (getValue.length > 0) {
        setNamesAdded("worng_entry");
      } else {
        setNamesAdded("none");
      }
    }
  };

  return (
    <>
      {isLoadingPayment && <ManualLoader />}
      <FormAuthorizedWrapper onSubmit={formSubmit}>
        <Controller
          control={control}
          name="account_type"
          render={({ field: { onChange, value } }) => (
            <Box>
              <FormLabel id="demo-radio-buttons-group-label">
                {t("formInput.accountType.label")}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ marginBottom: errors?.account_type?.message ? 0 : 3 }}
              >
                <FormRadioButton
                  label={t("formInput.accountType.option1")}
                  value={value ?? ""}
                  id={"checking"}
                  checked={value === "checking"}
                  {...register("account_type")}
                  handleChange={() => setValue("account_type", "checking")}
                  labelFontVariant="h2"
                  marginRight={"10px"}
                />
                <FormRadioButton
                  label={t("formInput.accountType.option2")}
                  value={value ?? ""}
                  id={"savings"}
                  checked={value === "savings"}
                  {...register("account_type")}
                  handleChange={() => setValue("account_type", "savings")}
                  labelFontVariant="h2"
                  marginRight={"10px"}
                />
                <FormRadioButton
                  label={t("formInput.accountType.option3")}
                  value={value ?? ""}
                  checked={value === "business_checking"}
                  id={"business_checking"}
                  {...register("account_type")}
                  handleChange={() =>
                    setValue("account_type", "business_checking")
                  }
                  labelFontVariant="h2"
                  marginRight={"0"}
                />
              </RadioGroup>
              {errors?.account_type?.message && (
                <Box sx={{ paddingBottom: 5 }} display={"flex"}>
                  <FormInputErrorMessage>
                    {errors?.account_type?.message}
                  </FormInputErrorMessage>
                </Box>
              )}
            </Box>
          )}
        />
        <Box>
          <Controller
            control={control}
            name="accountHolderName"
            render={({ field: { value } }) => (
              <FormInput
                label={t("formInput.accountName")}
                id={"accountHolderName"}
                placeholder={""}
                {...register("accountHolderName")}
                error={
                  namesAdded === "none" && errors?.accountHolderName?.message
                    ? errors?.accountHolderName?.message
                    : namesAdded === "worng_entry" &&
                      errors?.payerLastName?.message
                    ? errors?.payerLastName?.message
                    : ""
                }
                onChange={(event: any) => {
                  updateBindingsChangeGetNames(event);
                }}
                inputFocus={(e: any) => {
                  trigger("accountHolderName");
                }}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="account_number"
            render={({ field: { onChange, value } }) => (
              <FormInput
                label={t("formInput.accountNumber")}
                id={"accountNumber"}
                placeholder={"XXXXXXXXX"}
                {...register("account_number")}
                isValidationIconVisible
                inputMode="numeric"
                onChange={(e: any) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  onChange(e);
                }}
                error={errors?.account_number?.message}
                isValid={isValidAccNum()}
                maxLength={17}
                value={value}
                inputFocus={(e: any) => {
                  trigger("account_number");
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="account_number_conf"
            render={({ field: { onChange, value } }) => (
              <FormInput
                label={t("formInput.confirmAccountNumber")}
                id={"confirmAccountNumber"}
                placeholder={"XXXXXXXXX"}
                {...register("account_number_conf")}
                onChange={(e: any) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  onChange(e);
                }}
                isValidationIconVisible
                inputMode="numeric"
                error={errors?.account_number_conf?.message}
                isValid={isValidAccNumConf()}
                maxLength={17}
                disableCopyPaste={true}
                value={value}
                inputFocus={(e: any) => {
                  trigger("account_number_conf");
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="routing_number"
            render={({ field: { onChange, value } }) => (
              <FormInput
                label={t("formInput.routingNumber")}
                id={"routingNumber"}
                placeholder={"XXXXXXXXX"}
                {...register("routing_number")}
                inputMode="numeric"
                error={errors?.routing_number?.message}
                maxLength={9}
                onChange={(e: any) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  onChange(e);
                }}
                value={value}
                inputFocus={(e: any) => {
                  trigger("routing_number");
                }}
              />
            )}
          />
        </Box>

        {errorMessage !== "" && (
          <Messages
            messageHeading={"Payment Failure"}
            messageContent={errorMessage}
            closeEvent={() => setErrorMessage("")}
            bottomMargin={4}
            topMargin={4}
          />
        )}
        <ButtonAuthorizedWrapper>
          <Button
            variant="containedLarge"
            color="primary"
            fullWidth
            type="submit"
            sx={{ overflow: "hidden" }}
          >
            {t("buttonTexts.submitButton")}
          </Button>
        </ButtonAuthorizedWrapper>
      </FormAuthorizedWrapper>
    </>
  );
};
