import { Box, Typography } from "@mui/material";
import { useComponentVisible } from "src/hooks";
import { CustomSelectionProps } from "src/shared/models/component/base.model";
import { ShadowCard } from "src/components/CommonNestedStyles/FormWrapper.style";

export const CustomSelection = ({
  defaultValue,
  options,
  optionOnClickEvent,
  theme,
  sxTag = {
    paddingY: "3px",
    display: "flex",
    alignItems: "center",
    width: "94px",
    justifyContent: "center",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "30px",
    background: theme.palette.blue[50],
  },
}: CustomSelectionProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleOptionOnClickEvent = (item: any) => {
    optionOnClickEvent && optionOnClickEvent(item);
    setIsComponentVisible(false);
  };

  return (
    <Box ref={ref} position={"relative"}>
      <Box onClick={() => setIsComponentVisible(true)}>
        <Typography
          component={"div"}
          variant="h1Bold"
          color={"primary"}
          sx={sxTag}
        >
          {defaultValue}
        </Typography>
      </Box>
      {isComponentVisible && (
        <ShadowCard
          pt={3}
          px={2}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            position: "absolute",
            top: "-50%",
            left: "0",
            width: "100px",
          }}
        >
          {options &&
            options.map((item: { name: string }, index: any) => (
              <Box
                width={"100%"}
                mb={3}
                sx={{
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "gray.800",
                }}
                onClick={() => handleOptionOnClickEvent(item)}
                key={index}
              >
                {item.name}
              </Box>
            ))}
        </ShadowCard>
      )}
    </Box>
  );
};
