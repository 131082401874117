import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const BackIcon = styled(Box)(
  ({ theme }) => `
   justify-content: flex-start;
   cursor: pointer;

    svg {
     fill: ${theme.palette.gray[600]};
     position: relative;
     left: -8px;
    }
`
);

export const HeaderTextBlock = styled(Box)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.values.sm}px) {
    .heading_content{
      display: none;
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    .logo_wrapper{
      display: none;
    }
  }
`
);

export const EditIconWrapper = styled("div")(
  ({ theme }) => `
  display: inline-flex;
  cursor: pointer;
  border: 1px solid ${theme.palette.primary.main};
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;

  svg *  {
    fill: ${theme.palette.primary.main};
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    margin-left: ${theme.spacing(6)};
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: ${theme.spacing(4)};
  }
`
);

export const HeaderBottomWrapper = styled(Box)(
  ({ theme }) => `
  background: #E7F6F9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1 !important;

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    max-width: 100% !important;

    height: 130px;
    padding-top: 93px;
    position: absolute !important;

    &.is_fixed {
      position: fixed !important;
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    position: absolute !important;
    height: 120px;
    padding-top: 83px;
  }
`
);

export const LogoURLWrapper = styled(Box)(
  ({ theme }) => `
  height: 90px;
  width: 100%;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  `
);
