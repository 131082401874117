import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

import {
  ButtonAuthorizedWrapper,
  DivAuthorizedWrapper,
  Header,
  HeaderFixed,
  RentReportingInfoCard,
  TermsText,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";

export const RentReportingInfoScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide
          paddingX={0}
          headerText={t("rentReporting.header")}
          withCenteredHeaderText
          onBackButtonClick={() => navigate("/rent")}
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          <DivAuthorizedWrapper className="note_notify">
            <RentReportingInfoCard />
            <ButtonAuthorizedWrapper>
              <TermsText
                mainContent={t(
                  "rentReporting.reportingInfo.informationContent"
                )}
                subContent={t(
                  "rentReporting.reportingInfo.informationSubContent"
                )}
                marginIndex="0 0 16px"
              />
              <Button
                variant="containedLarge"
                color="primary"
                fullWidth
                type="button"
                sx={{ overflow: "hidden" }}
                onClick={() => navigate("/rent/reporting/identity")}
              >
                {t("buttonTexts.continueButton")}
              </Button>
            </ButtonAuthorizedWrapper>
          </DivAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
