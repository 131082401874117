import { useTranslation } from "react-i18next";

import { commonSpacing, theme } from "src/shared/theme/theme";

import { Box, Button, Typography } from "@mui/material";
import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  ContentContainerBottom,
  DivWrapper,
} from "../../../components";
import { WarningIcon } from "src/components/Media/Icon/Icons/Icons";

export interface ISearchVendor {
  onBackButtonClick?: () => void;
}

export const InvitationExpiredScreen = ({
  onBackButtonClick,
}: ISearchVendor) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <DivWrapper className="no_fullscreen">
        <Box
          component={"div"}
          className={"container_content"}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: 400 + commonSpacing.desktopPaddingB * 4,
            },
          }}
        >
          <Header
            isBackButtonVisible={true}
            onBackButtonClick={onBackButtonClick}
            paddingX={0}
          />
          <Box
            sx={{
              marginTop: 10,
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                marginBottom: 15,
              },
              [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                marginBottom: 30,
              },
            }}
          >
            <HeadingSummaryBlock
              heading={t("headingContent.invitationExpired.heading")}
              headingAlignment="center"
              mobileHeadingAlignment="center"
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: 3,
            }}
          >
            <Box
              sx={{
                width: "60px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                marginBottom: 11,
              }}
            >
              <WarningIcon width={"50"} height={"50"} fillColor="#FDD570" />
            </Box>
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
              }}
            >
              {t("headingContent.invitationExpired.content")}
            </Typography>
          </Box>
        </Box>

        <ContentContainerBottom className={"no_fullscreen"}>
          <Button
            variant="containedLarge"
            color="primary"
            type="button"
            onClick={onBackButtonClick}
            fullWidth
            sx={{ overflow: "hidden" }}
          >
            {t("buttonTexts.goBackButton")}
          </Button>
        </ContentContainerBottom>
      </DivWrapper>
    </Wrapper>
  );
};
