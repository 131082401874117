import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { configs } from "./config";
import { GetTokenSilentlyOptions } from "@auth0/auth0-react";

let getAccessTokenSilently: (
  options?: GetTokenSilentlyOptions | undefined
) => Promise<string>;

export const securityOptions = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>
  ) => (getAccessTokenSilently = func),
};

export const prepareHeaders = async (headers: any) => {
  const access_token = await securityOptions.getAccessTokenSilently()();

  if (access_token) {
    headers.set("authorization", `Bearer ${access_token}`);
  }

  headers.set("Content-Type", "application/json");
  return headers;
};

export const prepareHeadersAuthLess = (headers: any) => {
  headers.set("Content-Type", "application/json");
  return headers;
};

export const prepareBaseQuery = (isAuthLess: boolean) => {
  return fetchBaseQuery({
    baseUrl: configs.base_url,
    prepareHeaders: isAuthLess ? prepareHeadersAuthLess : prepareHeaders,
  });
};
