import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { FilterTag } from "../../FilterTag/FilterTag";
import { FilterContentWrapper } from "../../FilterModal/FilterModal.style";
import { CustomDateFilter } from "../../CustomDateFilter/CustomDateFilter";

import { getYTD } from "src/shared/utilities/dateConvertions";
import { Accordion } from "src/components/OtherUtilities/Accordion/Accordion";

export interface TransactionDateFilterProps {
  onChangeDate?: any;
  selectedDateOption?: string;
  onChangeDateCustomEvent?: any;
  onCancelDateCustomEvent?: any;
  selectedDateRange?: any;
  resetFilter?: boolean;
  isRangeFilterActive?: boolean;
}

export const DateFilter = ({
  onChangeDate,
  selectedDateOption,
  onChangeDateCustomEvent,
  onCancelDateCustomEvent,
  selectedDateRange,
  resetFilter,
  isRangeFilterActive,
}: TransactionDateFilterProps) => {
  const { t } = useTranslation();

  const setYTDValue = getYTD();

  const datesList = [
    {
      name: t("dataGrid.filter.dateFilter.all"),
      id: "all",
      active: true,
      type: "checkbox",
    },
    { name: "Today", id: "1", active: false, type: "checkbox" },
    {
      name: t("dataGrid.filter.dateFilter.last7"),
      id: "7",
      active: false,
      type: "checkbox",
    },
    {
      name: t("dataGrid.filter.dateFilter.last30"),
      id: "30",
      active: false,
      type: "checkbox",
    },
    { name: "Last 12 Months", id: "364", active: false, type: "checkbox" },
    { name: "YTD", id: `${setYTDValue}`, active: false, type: "checkbox" },
    { name: "date", id: "date", active: false, type: "date" },
  ];

  return (
    <Accordion
      title={t("dataGrid.filter.dateFilter.title")}
      isFilterActive={selectedDateOption !== "all"}
    >
      <FilterContentWrapper>
        {datesList &&
          datesList.map((option: any, index: any) => {
            return (
              <Fragment key={index}>
                {option.type === "checkbox" && option.id !== "date" && (
                  <FilterTag
                    title={option.name}
                    key={option.id}
                    inputName={option.id}
                    inputType="checkbox"
                    idValue={option.id}
                    inputValue={option.id === selectedDateOption}
                    handleOnChange={onChangeDate}
                  />
                )}
                {option.type === "date" && (
                  <CustomDateFilter
                    onDateChange={onChangeDateCustomEvent}
                    key={option.id}
                    isActive={option.active}
                    selectedDateRange={selectedDateRange}
                    cancelDateSelection={onCancelDateCustomEvent}
                    resetFilter={resetFilter}
                    isRangeFilterActive={isRangeFilterActive}
                  />
                )}
              </Fragment>
            );
          })}
      </FilterContentWrapper>
    </Accordion>
  );
};
