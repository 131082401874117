export const requestsEnTranslation = {
  requests: {
    common: {
      heading: "Requests",
      tooltipContent:
        "All emergency and maintenance tickets by you are referred to as requests.",
      emptyStateTitle: "No requests yet",
      emptyStateContent: "Your most recent requests will appear here.",
    },
    requestActionCard: {
      title: "Do you have a request? Let us know",
      buttonOneText: "Maintenance Request",
      buttonTwoText: "Guest Access",
      buttonThreeText: "Add/Remove Roommate",
      buttonFourText: "Add/Remove Pets",
      inProgressCardTitle: "Requests in progress",
      emptyCardContent: "You have no current requests",
      emptyCardContent2: "You have no recent requests",
      emptyCardBtntext: "New Request",
    },
    addOrUpdateRequest: {
      newRequestTitle: "New Requests",
      updateRequestTitle: "Update Request",
      enterValidDetailMessage: "Please enter the valid details",
      enterMissingDetailMessage: "Please add the missing details",
      confirmationModalTitle: "Success !",
      confirmationModalContentForUpdate:
        "Your request {{number}} has been successfully updated.",
      confirmationModalContentForNew:
        "Your request {{number}} has been successfully submitted.",
    },
    requestDetail: {
      titlePrefix: "Request",
      timlineCard: {
        title: "TIMELINE",
        timelineOneTitle: "Request Submitted",
        timelineTwoTitle: "Queued for Work",
        timelineThreeTitle: "Maintenance Scheduled",
        timelineFourTitle: `Request completed`,
        timelineFiveTitle: `Request closed`,
        timelineSixTitle: `Request canceled`,
      },
      statusUpdateConfirmationMessage:
        "Are you sure you want to cancel this request?",
    },
    requestGrid: {
      coloumnOneName: "TITLE",
      coloumnTwoName: "DATE",
      coloumnThreeName: "#NUMBER",
    },
  },
};
