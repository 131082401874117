import React from "react";
import {
  RegisterReviewSection,
  RegisterReviewSectionContent,
  RegisterReviewSectionTitle,
} from "./Register.style";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { TooltipInfo } from "../../../components/OtherUtilities/TooltipInfo/TooltipInfo";

export const ConfirmUnitInformation = (props: any) => {
  const { data, query } = props?.options || {};
  const Section = RegisterReviewSection;

  const SectionTitle = RegisterReviewSectionTitle;

  const SectionContent = RegisterReviewSectionContent;
  const { formData } = props?.options || {};
  const {} = formData || {};
  const pricingChanged = parseFloat(data?.rent) !== parseFloat(query?.pricing);
  const moveInChanged = moment(data?.moveInDate, "MM/DD/YYYY")
    .startOf("day")
    .isAfter(moment(query?.moveInDate, "YYYY-MM-DD"));
  return (
    <Section>
      <SectionTitle>Unit Details</SectionTitle>
      <SectionContent>
        <Box display={"flex"}>
          <Typography>Unit Number: {data?.number}</Typography>
        </Box>
        <Box display={"flex"} paddingTop={4}>
          <Typography>
            {" "}
            {data?.bed} Bed(s) {data?.bath} Bath(s)
          </Typography>
        </Box>
        <Box display={"flex"} paddingTop={4}>
          <Typography>Term: {data?.term} Months</Typography>
        </Box>
        <Box display={"flex"} paddingTop={4}>
          <Typography>MoveIn: {data?.moveInDate}</Typography>
          {!query?.leaseId && moveInChanged && (
            <TooltipInfo aligned={"right"}>
              <Box width={"154px"}>
                {" "}
                Latest MoveIn Possible from{" "}
                {moment(query?.moveInDate, "YYYY-MM-DD").format("MM/DD/YYYY")}
              </Box>
            </TooltipInfo>
          )}
        </Box>
        <Box display={"flex"} paddingTop={4}>
          <Typography>Rent: {data?.rent} per month</Typography>
          {!query?.leaseId && pricingChanged && (
            <TooltipInfo aligned={"left"}>
              <Box width={"154px"}>
                Rent updated to {data?.rent} from{" "}
                {parseFloat(query?.pricing).toFixed(2)} based on MoveIn
              </Box>
            </TooltipInfo>
          )}
        </Box>
      </SectionContent>
    </Section>
  );
};
