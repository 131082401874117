import React, { useState } from "react";
import classNames from "classnames";
import { Box, Typography } from "@mui/material";

import {
  TopRow,
  TagWrapper,
  TitleSection,
  SecondRow,
  BottomSection,
  EditIconWrapper,
} from "./RequestDetailTop.style";
import {
  ConfirmationModal,
  ManualLoader,
  Messages,
  ShadowCard,
  StatusChips,
  StatusDropDown,
} from "src/components";

import { RequestDetailProps } from "src/shared/models/component/base.model";
import { dateFormatWithTimeZoneForEarning } from "src/shared/utilities/dateConvertions";
import { EditFilledIcon } from "src/components/Media/Icon/Icons/Icons";
import { useCreateWorkOrderMutation } from "src/store/services/privateApi";
import { CreateOrderBodyParams } from "src/shared/models/containers/request.model";

export const RequestDetailTop = ({
  data,
  t,
  isEditIconVisible,
  onClickEditIcon,
}: RequestDetailProps) => {
  const status = data?.Status ? data?.Status : "Submitted";
  const [selectedRequestStatus, setSelectedRequestStatus] =
    useState<string>("");
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [createWorkOrderMutation, { isLoading: isLoadingCreateOrder }] =
    useCreateWorkOrderMutation();

  const optionOnClickEvent = (item: any) => {
    if (item && item.type) {
      setSelectedRequestStatus(item.type);
      setIsConfirmationModalVisible(true);
    }
  };

  const onSubmitConfirmationModalBtnEvent = async () => {
    if (data) {
      let setCreateOrderBody: CreateOrderBodyParams = {
        reportedDate: data.ReportedDate,
        title: data.title ?? "",
        categoryId: data.Category ?? "",
        areas: data.Areas ? data.Areas[0] : "",
        priority: data.Priority ?? "",
        dueDate: data.DueDate ?? "",
        description: data.description,
        phone: data.Phone ? data.Phone : "",
        status: selectedRequestStatus,
        workorderId: data?.WorkOrderID,
      };

      if (data.Appointment) {
        setCreateOrderBody = {
          ...setCreateOrderBody,
          appointment: data.Appointment,
        };
      }

      const response: any = await createWorkOrderMutation(setCreateOrderBody);

      if (response) {
        onCancelConfirmationModalBtnEvent();

        if (response?.data) {
          setErrorMessage("");
        } else {
          setErrorMessage(
            response?.error?.data?.error ??
              response?.error?.data?.message ??
              t("requests.addOrUpdateRequest.enterMissingDetailMessage")
          );

          setTimeout(function () {
            setErrorMessage(""); // this is only on one time __
          }, 5000);
        }
      }
    }
  };

  const onCancelConfirmationModalBtnEvent = () => {
    setSelectedRequestStatus("");
    setIsConfirmationModalVisible(false);
  };

  return (
    <>
      {isLoadingCreateOrder && <ManualLoader />}
      {errorMessage !== "" && (
        <Messages
          messageHeading={`${t("requests.requestDetail.titlePrefix")} #${
            data ? data.Number : ""
          }`}
          messageContent={errorMessage}
          closeEvent={() => setErrorMessage("")}
          bottomMargin={6}
          topMargin={0}
        />
      )}
      <ShadowCard padding={4} marginBottom={8}>
        <TopRow>
          <TitleSection>
            <Typography variant="h4" color={"gray.800"} fontWeight={500}>
              {data.title === " " || !data.title ? "----" : data.title}
            </Typography>
          </TitleSection>
          <TagWrapper className={classNames(["un_editable"])}>
            {"High"}
          </TagWrapper>
        </TopRow>
        <BottomSection>
          <SecondRow>
            <Typography
              variant="h3"
              color={"gray.800"}
              sx={{ marginRight: 0.75 }}
              fontWeight={400}
            >
              {data.ReportedDate
                ? dateFormatWithTimeZoneForEarning(data.ReportedDate)
                : "----"}
            </Typography>
            {isEditIconVisible && (
              <EditIconWrapper onClick={onClickEditIcon}>
                <EditFilledIcon /> <span>{"Edit"}</span>
              </EditIconWrapper>
            )}
          </SecondRow>
          <Box display={"flex"} alignItems={"center"}>
            {status === "Submitted" ? (
              <StatusDropDown
                defaultValue={
                  selectedRequestStatus === ""
                    ? "Submitted"
                    : selectedRequestStatus
                }
                options={[{ type: "Cancelled" }]}
                optionOnClickEvent={optionOnClickEvent}
              />
            ) : (
              <StatusChips type={data?.Status ? data?.Status : "Submitted"} />
            )}
          </Box>
        </BottomSection>
      </ShadowCard>

      {isConfirmationModalVisible && (
        <ConfirmationModal
          onFormSubmit={onSubmitConfirmationModalBtnEvent}
          onClickCancelEvent={onCancelConfirmationModalBtnEvent}
          content={t("requests.requestDetail.statusUpdateConfirmationMessage")}
          btnTitle={t("buttonTexts.yesButton")}
          hideClose={true}
          isYesNoConfirmationModal
        />
      )}
    </>
  );
};
