import { ShadowCard, AfterBottomRow } from "src/components";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { RequestDetailProps } from "src/shared/models/component/base.model";

export const RequestDetailBottom = ({ data, t }: RequestDetailProps) => {
  return (
    <ShadowCard padding={4} marginBottom={8}>
      <Box display={"flex"} flexDirection={"column"}>
        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h3SemiBold" component={"p"} color={"blue.500"}>
              {t("formInput.areas")}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2" component={"p"} color={"gray.700"}>
              {data?.Areas &&
                data?.Areas.length > 1 &&
                data?.Areas.map((item: string, index: any) => {
                  return `${item},`;
                })}
              {data?.Areas && data?.Areas.length === 1
                ? data?.Areas[0]
                : "----"}
            </Typography>
          </Box>
        </AfterBottomRow>

        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h3SemiBold" component={"p"} color={"blue.500"}>
              {t("formInput.category")}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2" component={"p"} color={"gray.700"}>
              {data?.Category ? data?.Category : "----"}
            </Typography>
          </Box>
        </AfterBottomRow>

        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h3SemiBold" component={"p"} color={"blue.500"}>
              {t("formInput.issueType")}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2" component={"p"} color={"gray.700"}>
              {data?.title ? data?.title : "----"}
            </Typography>
          </Box>
        </AfterBottomRow>

        <AfterBottomRow className="content" flexDirection={"column"}>
          <Box className="content_left" marginBottom={2}>
            <Typography variant="h3SemiBold" component={"p"} color={"blue.500"}>
              {t("formInput.description")}
            </Typography>
          </Box>
          <Box className="content_right full">
            <Typography variant="h2" component={"p"} color={"gray.700"}>
              {data?.description ? data?.description : "----"}
            </Typography>
          </Box>
        </AfterBottomRow>
        {data?.Appointment === "Permission Given" && (
          <FormControlLabel
            sx={{ marginBottom: 1, marginTop: 2 }}
            control={
              <Checkbox
                name="confirm_subscription"
                size="small"
                checked={true}
              />
            }
            label={
              <Typography variant="h2" color={"gray.700"}>
                {t("formInput.consentCheck")}
              </Typography>
            }
          />
        )}
      </Box>
    </ShadowCard>
  );
};
