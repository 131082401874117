import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import { Grid, Box } from "@mui/material";

import { FormAddressWrapper, HighLightText } from "./AddressFields.style";
import { FormInput } from "../FormInput/FormInput";
import { DropdownState } from "./DropdownState/DropdownState";
import { GoogleSearchAutoComplete } from "./GoogleSearchAutoComplete/GoogleSearchAutoComplete";

import { AddressFieldsProps } from "src/shared/models/component/base.model";

/**
 * Primary UI component for user interaction
 */
export const AddressFields = ({
  onClick,
  isSelected,
  isHover = true,
  isIconEnabled = true,
  isIndividual = false,
  isValid = true,
  maxWidth = "100%",
  setAddressStates,
  addressStates,
  register,
  errors,
  control,
  getValue,
  setValue,
  trigger,
  isVerifiedOnlyForSetting,
  isPOBoxTextVisible = true,
  highLightText,
  ...rest
}: AddressFieldsProps) => {
  const { t } = useTranslation();

  const handleInputClasses = !isValid
    ? "error_input"
    : isValid
    ? "valid_input"
    : "normal_input";

  return (
    <FormAddressWrapper className={handleInputClasses}>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="line1"
              render={({ field: { onChange, value } }) => (
                <GoogleSearchAutoComplete
                  id="line1"
                  label={t("formInput.addressLine1")}
                  {...register("line1")}
                  error={errors?.line1?.message}
                  value={value || addressStates?.line1 || ""}
                  onChange={onChange}
                  onInputChange={(e: any) => {
                    setValue("line1", e);
                  }}
                  onSelect={(e: any) => {
                    setValue("state", e.state);
                    setValue("line1", e.line1);
                    setValue("city", e.city);
                    setValue("zipCode", e.zip);
                    setValue("postal_code", e.zip);
                    trigger([
                      "line1",
                      "state",
                      "city",
                      "postal_code",
                      "zipCode",
                    ]);
                  }}
                  inputFocus={(e: any) => {
                    trigger("line1");
                  }}
                  readonly={
                    isVerifiedOnlyForSetting && value !== "" && value !== null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="line2"
              render={({ field: { onChange, value } }) => (
                <FormInput
                  id="line2"
                  {...register("line2")}
                  value={value || ""}
                  label={t("formInput.addressLine2")}
                  error={errors?.line2?.message}
                  onChange={onChange}
                  readonly={
                    isVerifiedOnlyForSetting && value !== "" && value !== null
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              control={control}
              name="city"
              render={({ field: { onChange, value } }) => (
                <FormInput
                  id="city"
                  {...register("city")}
                  label={t("formInput.city")}
                  value={value || addressStates.city || ""}
                  error={errors?.city?.message}
                  onChange={onChange}
                  inputFocus={(e: any) => {
                    trigger("city");
                  }}
                  readonly={
                    isVerifiedOnlyForSetting && value !== "" && value !== null
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={4} paddingRight={1} paddingLeft={1}>
            <Controller
              control={control}
              name="state"
              render={({ field: { onChange, value } }) => {
                return (
                  <DropdownState
                    id={"state"}
                    {...register("state")}
                    placeholder={t("formInput.state")}
                    error={errors?.state?.message}
                    inputValue={value || ""}
                    onChange={(_event: any, selectedOption: any) => {
                      if (selectedOption) {
                        onChange(selectedOption.label);
                      }
                    }}
                    inputFocus={(e: any) => {
                      trigger("state");
                    }}
                    readonly={
                      isVerifiedOnlyForSetting && value !== "" && value !== null
                    }
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              control={control}
              name="postal_code"
              render={({ field: { onChange, value } }) => (
                <FormInput
                  id="zipCode"
                  {...register("postal_code")}
                  label={t("formInput.zipCode")}
                  value={value || addressStates.zipCode || ""}
                  error={errors?.postal_code?.message}
                  onChange={onChange}
                  inputMode="numeric"
                  inputFocus={(e: any) => {
                    trigger("postal_code");
                  }}
                  readonly={
                    isVerifiedOnlyForSetting && value !== "" && value !== null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {highLightText && (
              <HighLightText className="no-mb">{highLightText}</HighLightText>
            )}
            {isPOBoxTextVisible && (
              <HighLightText>
                {t("formInput.addressLine1HiglightText")}
              </HighLightText>
            )}
          </Grid>
        </Grid>
      </Box>
    </FormAddressWrapper>
  );
};
