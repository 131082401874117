import { styled } from "@mui/material/styles";

export const InformationListBlockInnerContainer = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InformationListBlockOuterWrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
