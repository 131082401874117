import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const LandingBannerContainer = styled(Box)(
  ({ theme }) => `
     display: flex;
     width: 100%;
     overflow: hidden;
     position: relative;
     justify-content: center;
     margin-bottom: ${theme.spacing(4)};
     border-radius: 8px;

     @media (max-width: ${theme.breakpoints.values.sm}px) {
      height: calc((var(--vh, 1vh) * 100) - 300px);
      
     }
   
     @media (min-width: ${theme.breakpoints.values.sm}px) {
       height: 480px;
     }
`
);

export const LandingBanner = styled(Box)(
  ({ theme }) => `
     display: flex;
     width: 100%;
     overflow: hidden;
     position: relative;
     justify-content: center;
     border-radius: 8px;

     img {
      object-fit: cover;
      object-position: center;
      border-radius: 8px;
     }

     @media (max-width: ${theme.breakpoints.values.sm}px) {
      img {
        width: 100%;
        max-height: calc((var(--vh, 1vh) * 100) - 200px);
      }
     }
   
     @media (min-width: ${theme.breakpoints.values.sm}px) {
       img {
        width: 100%;
        max-width: 380px;
       }
     }
`
);
