import React from "react";

export const ReviewAddress = (props: any) => {
  const { address } = props || {};
  return (
    <p>
      Address:{" "}
      {address ? (
        <>
          {address.street}
          {address.city ? `, ${address.city} ` : ""}
          {address.state ? `, ${address.state}` : ""}
          {address.zip ? ` ${address.zip}` : ""}
        </>
      ) : (
        "No address provided"
      )}
    </p>
  );
};
