import { createApi } from "@reduxjs/toolkit/query/react";
import {
  prepareBaseQuery,
  prepareHeaders,
  prepareHeadersAuthLess,
} from "src/shared/config/storeConfig";
import { searchPropertyBodyParams } from "src/shared/models/containers/account.model";

export const publicApi = createApi({
  reducerPath: "publicApi",
  baseQuery: prepareBaseQuery(true),
  endpoints: (builder) => ({
    getProperties: builder.mutation({
      query: (abbreviationCode: string) => ({
        url: `public/properties/${abbreviationCode}`,
        method: "GET",
      }),
    }),
    getUnit: builder.query({
      queryFn: async (
        { leaseId, unitID, moveInDate, term, propertyID },
        queryApi,
        extraOptions,
        baseQuery
      ) => {
        const url = !leaseId
          ? `public/units/${unitID}?moveInDate=${moveInDate}&term=${term}&propertyId=${
              propertyID ?? ""
            }`
          : `/v1/lease/${leaseId}`;
        let headers = new Headers();
        headers = leaseId
          ? await prepareHeaders(headers)
          : await prepareHeadersAuthLess(headers);
        return baseQuery({
          url: url,
          method: "GET",
          headers: headers,
        });
      },
    }),
    getUnits: builder.mutation({
      query: (propertyID: string) => ({
        url: `/public/units/for-propertyId/${propertyID}`,
        method: "GET",
      }),
    }),
    searchProperty: builder.mutation({
      query: (searchPropertyBody: searchPropertyBodyParams) => ({
        url: `/public/validate-resident`,
        method: "POST",
        body: searchPropertyBody,
      }),
    }),
    getAllProperties: builder.mutation({
      query: () => ({
        url: `/public/properties/`,
        method: "GET",
      }),
    }),
    getSinglePropertyDetails: builder.mutation({
      query: (propertyCode) => ({
        url: `/public/properties/${propertyCode}`,
        method: "GET",
      }),
    }),
    searchPropertyForInvitation: builder.mutation({
      query: (invitationId: string) => ({
        url: `/public/validate-resident/for-invitation/${invitationId}`,
        method: "GET",
      }),
    }),
    postIDVerification: builder.mutation({
      query: (body) => ({
        url: `v1/identity-verification`,
        body,
        method: "POST",
      }),
    }),
    getIDVerification: builder.mutation({
      query: (idvId) => ({
        url: `v1/identity-verification/${idvId}`,
        method: "GET",
      }),
    }),
    createProspectUser: builder.mutation({
      query: (body) => ({
        url: `v1/lease/prospect`,
        body,
        method: "POST",
      }),
    }),
    getLeasePerson: builder.query({
      query: (args) => ({
        url: `v1/lease/person/${args.id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetPropertiesMutation,
  useGetUnitsMutation,
  useSearchPropertyMutation,
  useGetAllPropertiesMutation,
  useSearchPropertyForInvitationMutation,
  usePostIDVerificationMutation,
  useGetIDVerificationMutation,
  useCreateProspectUserMutation,
  useGetUnitQuery,
  useLazyGetLeasePersonQuery,
} = publicApi;
