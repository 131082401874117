import React from "react";
import { HeadingSummaryBlockProps } from "../../../shared/models/component/base.model";
import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { theme } from "../../../shared/theme/theme";

export const HeadingSummaryBlock = ({
  heading,
  content,
  contentNewLine,
  spacing = 3,
  headingAlignment = "left",
  contentAlignment = "left",
  mobileHeadingAlignment,
  mobileContentAlignment,
}: HeadingSummaryBlockProps) => {
  return (
    <Stack spacing={spacing}>
      <Typography
        variant="h6ExtraBold"
        sx={{
          [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
            textAlign: headingAlignment,
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            textAlign: mobileHeadingAlignment
              ? mobileHeadingAlignment
              : headingAlignment,
          },
        }}
      >
        {heading}
      </Typography>
      <Box>
        {content && (
          <Typography
            variant="body2"
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                textAlign: contentAlignment,
              },
              [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                textAlign: mobileContentAlignment
                  ? mobileContentAlignment
                  : contentAlignment,
              },
            }}
          >
            {content}
          </Typography>
        )}
        {contentNewLine && (
          <Typography
            variant="body2"
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                textAlign: contentAlignment,
                marginTop: -1,
              },
              [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                textAlign: mobileContentAlignment
                  ? mobileContentAlignment
                  : contentAlignment,
                marginTop: -1,
              },
            }}
          >
            {contentNewLine}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
