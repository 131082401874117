export interface RegisterFormParams {
  unit: string;
  phone: string;
  propertyCode?: string;
  propertyName?: string;
}

export interface AccountSearchFormParams {
  unit: string;
  email: string;
  phone: string;
  propertyCode?: string;
  propertyName?: string;
}

export interface PropertyParams {
  id: string;
  name: string;
  abbreviation: string;
  createdDate: string;
  updatedDate: string;
}

export interface UnitParams {
  label: string;
  abbreviation: string;
}

export interface searchPropertyBodyParams extends AccountSearchFormParams {
  propertyCode: string;
}

export interface fullStoryCustomPropertiesParams {
  unit?: string;
  phone?: string;
  propertyCode?: string;
  invitationId?: string;
  invitationStatus?: invitationStatusTypes;
}

export interface invitationResponseParams {
  id?: string;
  email?: string;
  phone?: any;
  status?: string;
  unit?: string;
  property?: string;
  createdDate?: string;
  updatedDate?: string;
  isKycSuccess?: boolean;
}

export type invitationStatusTypes = "COMPLETED" | "PENDING" | "EXPIRED";

export type residentSearchScreenVisibleTypes =
  | "NONE"
  | "SEARCH_FORM"
  | "USER_EXIST"
  | "ACCOUNT_EXIST"
  | "INVITATION_EXPIRED"
  | "USER_NOT_FOUND"
  | "RENT_REPORTING_IDENTITY"
  | "IDENTITY_FAILED"
  | "IDENTITY_APPROVED";

export const invitationResponseInit = {
  id: null,
  email: null,
  phone: null,
  status: null,
  unit: null,
  property: null,
  createdDate: null,
  updatedDate: null,
};

export interface UpdateUserAccountPayloads {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobilePhone?: string;
  homePhone?: string;
}

export interface AccountUpdateFormParams {
  fullName?: string;
  phone: string;
  firstName?: string;
  lastName?: string;
  homePhone?: string;
  email?: string;
}

export interface PropertySearchRouteParams {
  isFromePropertyLanding: boolean;
  propertyCodeFPL: string | undefined;
  propertyNameFPL: string;
  propertyIdFPL: string;
  propertyIsActiveFPL: boolean;
  propertyCityFPL?: string;
  getPropertyStateFPL?: string;
}

export interface IDVerificationParams {
  ssn: string;
  dob: string;
  email: string;
  phone?: string;
  user?: {
    firstName?: string;
    lastName?: string;
  };
  personId?: string;
}

export const PropertySearchRouteInits = {
  isFromePropertyLanding: false,
  propertyCodeFPL: undefined,
  propertyNameFPL: "",
  propertyIdFPL: "",
  propertyIsActiveFPL: false,
  propertyCityFPL: "",
  getPropertyStateFPL: "",
};

export const propertySearchResponseDataStateInit = {
  unit: "",
  property: "",
  email: "",
  phone: "",
  propertyCode: "",
  phoneWithtMaskValue: "",
  invitationId: "",
};
