import React, { FC, forwardRef, useState } from "react";

import { FormInputErrorMessage } from "../FormInputErrorMessage/FormInputErrorMessage";

import { TextField } from "@mui/material";

import { MaskInputProps } from "src/shared/models/component/base.model";
import {
  FormInputContainer,
  FormInputWrapper,
} from "./FormInputPhoneNumber.style";

export const FormInputPhoneNumber: FC<MaskInputProps> = forwardRef<
  HTMLInputElement,
  MaskInputProps
>(
  (
    {
      id,
      label,
      type = "text",
      placeholder,
      inputError,
      isValid = false,
      maxWidth = "100%",
      inputMode = "text",
      value = "",
      readonly,
      onChange,
      autoFocus,
      autoComplete,
      isShrink = false,
      inputFocus,
    },
    ref,
    ...props
  ) => {
    const handleInputClasses =
      inputError && !isValid
        ? "error_input"
        : isValid
        ? "valid_input"
        : "normal_input";
    const [defaultVal, setDefaultVal] = useState("");
    const [preValue, setPreValue] = useState("");

    const getMaskedNumber = (event: any) => {
      let val = event.target.value;
      if (val.length > preValue.length) {
        val = val.replace(/\D/g, "");
        let num = "";
        if (val.length < 3) {
          num = `(${val.substring(0, 3)}`;
        } else {
          num = `(${val.substring(0, 3)}) ${val.substring(
            3,
            6
          )}-${val.substring(6, 10)}`;
        }
        num = num.trim();
        val = num;
      }
      setPreValue(val);
      return val;
    };

    return (
      <FormInputWrapper
        style={{ maxWidth: maxWidth }}
        className={handleInputClasses}
      >
        <FormInputContainer>
          <TextField
            fullWidth
            label={label}
            value={!onChange ? defaultVal : value}
            id={id}
            ref={ref}
            type={type}
            aria-label={label}
            placeholder={placeholder}
            error={inputError?.length > 0}
            focused={value ? true : false}
            disabled={readonly}
            inputProps={{
              inputMode: inputMode,
              autoFocus: autoFocus,
              autoComplete: autoComplete,
            }}
            onChange={(event: any) => {
              if (onChange) {
                onChange(getMaskedNumber(event));
              } else {
                setDefaultVal(getMaskedNumber(event));
              }
            }}
            onBlur={inputFocus && inputFocus}
            {...props}
          />
        </FormInputContainer>
        {inputError && !isValid && (
          <FormInputErrorMessage>{inputError}</FormInputErrorMessage>
        )}
      </FormInputWrapper>
    );
  }
);
